import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actions as loadingActions } from './loadingSlice';
import { actions as messageActions } from './messageSlice';
import axios from '../utils/axiosUtil';

export const articlesInit = createAsyncThunk(
  'articlesInit',
  async (params, { dispatch }) => {
    dispatch(loadingActions.multiLoadingOn());
    const [articlesRes, shopsRes, brandsRes] = await Promise.all([
      axios.get('articles/list', {
        params: {
          status_flg: params?.status_code ? params.status_code : 999,
          sort: 'article_code_desc',
          count: 10,
        },
      }),
      axios.get('shops/list', { params: { count: 0, sort: 'shop_code_asc' } }),
      axios.get('brands/list', {
        params: { count: 0, sort: 'brand_name_asc' },
      }),
    ]);
    dispatch(loadingActions.multiLoadingOff());

    return {
      statusAggregate: articlesRes.status_aggregate ?? {},
      articlesList: articlesRes?.item ?? [],
      total: articlesRes.total,
      brands: (brandsRes?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
      shops: (shopsRes?.item ?? []).map(s => ({
        ...s,
        label: s.shop_code + ':' + s.shop_name,
        value: s.shop_code,
      })),
    };
  }
);

export const fetchArticles = createAsyncThunk(
  'fetchArticles',
  async params => await axios.get('articles/list', { params })
);

export const fetchArticleDetail = createAsyncThunk(
  'fetchArticleDetail',
  async params => await axios.get('articles/detail', { params })
);

export const fetchTags = createAsyncThunk(
  'fetchTags',
  async params => await axios.get('tags/list', { params })
);

export const fetchTagCategory = createAsyncThunk(
  'fetchTagCategory',
  async params => await axios.get('tags/category/list', { params })
);

export const saveBaseInfo = createAsyncThunk(
  'saveBaseInfo',
  async (params, { dispatch, getState }) => {
    dispatch(loadingActions.multiLoadingOn());

    dispatch(
      fetchTagCategory({
        brand_code: params.brand_code,
        count: 0,
        sort: 'tag_category_code_asc',
      })
    );

    const updateRes = await axios.post('articles/update', params);

    dispatch(fetchArticles(getState().articles.searchParams)).then(() =>
      dispatch(loadingActions.multiLoadingOff())
    );

    return updateRes;
  }
);

export const fetchStaffs = createAsyncThunk(
  'fetchStaffs',
  async params => await axios.get('staff/list', { params })
);
export const fetchMallBrand = createAsyncThunk(
  'fetchMallBrand',
  async params =>
    await axios.get('/brands/list', {
      params: {
        mall_flg: 1,
        offset: 0,
        status: 0,
        sort: 'brand_code_asc',
      },
    })
);
export const fetchCoordinates = createAsyncThunk(
  'fetchCoordinates',
  async params => await axios.get('coordinate/list', { params })
);

export const updateArticle = createAsyncThunk(
  'updateArticle',
  async params => await axios.post('articles/update', params)
);
export const approvalArticle = createAsyncThunk(
  'approvalArticle',
  async (params, { dispatch }) => {
    await axios.post('articles/approval', params);

    dispatch(
      messageActions.setMessage({
        btnText: '戻る',
        messages: ['更新が完了しました'],
        onCloseAction: actions.closeEditModal(),
      })
    );
  }
);

export const updateApprovalArticle = createAsyncThunk(
  'updateApprovalArticle',
  async ({ updateParams, approvalParams }, { dispatch }) => {
    await axios.post('articles/update', updateParams);

    await axios.post('articles/approval', approvalParams);

    dispatch(
      messageActions.setMessage({
        btnText: '戻る',
        messages: ['更新が完了しました'],
        onCloseAction: actions.closeEditModal(),
      })
    );
  }
);

export const saveDraftArticle = createAsyncThunk(
  'saveDraftArticle',
  async (params, { dispatch }) => {
    await axios.post('articles/update', params);

    dispatch(
      messageActions.setMessage({
        btnText: '閉じる',
        messages: ['保存しました'],
        onCloseAction: actions.closeEditModal(),
      })
    );
  }
);

export const registArticle = createAsyncThunk(
  'registArticle',
  async (params, { dispatch }) => {
    await axios.post('articles/update', params);

    dispatch(
      messageActions.setMessage({
        btnText: '閉じる',
        messages: ['設定が完了しました'],
        onCloseAction: actions.closeSignUpModal(),
      })
    );
  }
);

export const registDraftArticle = createAsyncThunk(
  'registDraftArticle',
  async (params, { dispatch }) => {
    await axios.post('articles/update', params);

    dispatch(
      messageActions.setMessage({
        btnText: '閉じる',
        messages: ['保存しました'],
        onCloseAction: actions.closeSignUpModal(),
      })
    );
  }
);

export const uploadImage = createAsyncThunk(
  'uploadImage',
  async params => await axios.post('common/upload/image', params)
);

const initialSearchParams = {
  article_code: '',
  title: '',
  user_name: '',
  brand_code: [],
  shop_code: [],
  status_flg: 999,
  offset: 0,
  count: 10,
  sort: 'article_code_desc',
};

const initialState = {
  articlesList: [],
  activeArticle: {},
  deleteArticle: {},
  brands: [],
  shops: [],
  staffs: [],
  coordinates: [],
  tags: [],
  mallBrandList: [],
  categories: [],
  staffsReady: false,
  statusAggregate: {},
  selectedBrand: null,
  editModalVisible: false,
  currentArticleCode: '',
  deleteModalVisible: false,
  signUpModalVisible: false,
  coordinateSearchModalVisible: false,
  confirmModalVisible: false,
  searchParams: initialSearchParams,
  total: 0,
};

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
        status_flg: 999,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    refineSearch: (state, { payload }) => {
      return {
        ...state,
        searchParams: {
          ...initialSearchParams,
          sort: state.searchParams.sort,
          status_flg: payload,
        },
      };
    },
    saveBrand: (state, { payload }) => ({
      ...state,
      selectedBrand: payload,
    }),
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      activeArticle: payload,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      deleteArticle: payload,
    }),
    openEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: true,
    }),
    openSignUpModal: state => ({
      ...state,
      signUpModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      activeArticle: {},
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    closeEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: false,
    }),
    closeSignUpModal: state => ({
      ...state,
      signUpModalVisible: false,
      currentArticleCode: '',
      staffsReady: false,
    }),
    clearCurrentArticleCode: state => ({
      ...state,
      currentArticleCode: '',
      staffsReady: false,
    }),
    clearActiveArticle: state => ({ ...state, activeArticle: {} }),
    openConfirmModal: state => ({
      ...state,
      confirmModalVisible: true,
    }),
    closeConfirmModal: state => ({
      ...state,
      confirmModalVisible: false,
    }),
    clearCoordinates: state => ({
      ...state,
      coordinates: [],
    }),
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(articlesInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchArticles.fulfilled, (state, { payload }) => ({
      ...state,
      articlesList: payload?.item ?? [],
      total: payload?.total || 0,
      statusAggregate: payload?.status_aggregate ?? {},
    }));
    builder.addCase(fetchArticleDetail.fulfilled, (state, { payload }) => ({
      ...state,
      activeArticle: payload?.item ?? {},
      editModalVisible: true,
      selectedBrand: payload?.item?.brand?.brand_code,
    }));
    builder.addCase(fetchCoordinates.fulfilled, (state, { payload }) => ({
      ...state,
      coordinates: payload?.item ?? [],
    }));
    builder.addCase(updateArticle.fulfilled, state => ({
      ...state,
      editModalVisible: false,
      deleteModalVisible: false,
      signUpModalVisible: false,
      editDeleteModalVisible: false,
      confirmModalVisible: false,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(approvalArticle.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(updateApprovalArticle.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(saveBaseInfo.fulfilled, (state, { payload }) => ({
      ...state,
      currentArticleCode: payload.article_code,
    }));
    builder.addCase(saveDraftArticle.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(registArticle.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(registDraftArticle.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(fetchStaffs.fulfilled, (state, { payload }) => ({
      ...state,
      staffsReady: true,
      staffs: (payload?.item ?? []).map(t => ({
        ...t,
        label: t.user_name,
        value: t.user_code,
      })),
    }));
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => ({
      ...state,
      tags: (payload?.item ?? []).map(t => ({
        ...t,
        label: t.tag_name,
        value: t.tag_code,
      })),
    }));
    builder.addCase(fetchTagCategory.fulfilled, (state, { payload }) => ({
      ...state,
      categories: (payload?.item ?? [])
        .filter(t => t.active_tag_count > 0 && t.category_status === '使用中')
        .map(t => ({
          ...t,
          label: t.tag_category_name,
          value: t.tag_category_code,
        })),
    }));
    builder.addCase(fetchMallBrand.fulfilled, (state, { payload }) => ({
      ...state,
      mallBrandList: (payload?.item ?? [])
        .filter(b => b.mall_flg === 1)
        .map(b => ({
          ...b,
          label: b.brand_name,
          value: b.brand_code,
        })),
    }));
  },
});

export const actions = articlesSlice.actions;

export default articlesSlice.reducer;
