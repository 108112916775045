import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';

const initialSearchParams = {
  user_code: '',
  stamp_rally_code: '',
  stamp_rally_name: '',
  exchange_start_date: null,
  exchange_end_date: null,
  offset: 0,
  count: 10,
  sort: 'user_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,
  csvModalVisible: false,
  favoriteItem: [],
  pointExchangeResultList: [],
};

export const fetchPointExchangeCsvFavoriteItem = createAsyncThunk(
  'fetchPointExchangeCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchPointExchangeCsvFavoriteItemCreate = createAsyncThunk(
  'fetchPointExchangeCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response.params;
  }
);

export const fetchPointExchangeCsvDownload = createAsyncThunk(
  'fetchPointExchangeCsvDownload',
  async (params, { dispatch }) => {
    const result = await axios.get('csv/download', { params });
    if (!!!result) {
      dispatch(globalActions.showCommonErrorModal());
    }
    return result;
  }
);
export const fetchPointExchangeResultList = createAsyncThunk(
  'fetchPointExchangeResultList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const pointExchangeResultList = await axios.get(
      '/stamp_rally/point_result',
      {
        params,
      }
    );

    dispatch(globalActions.setPrimaryLoading(false));

    return pointExchangeResultList;
  }
);

export const pointExchangeResultSlice = createSlice({
  name: 'pointExchangeResultSlice',
  initialState,
  reducers: {
    openCsvDownloadModal: state => ({
      ...state,
      csvModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvModalVisible: false,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchPointExchangeResultList.fulfilled]: (state, { payload }) => {
      state.pointExchangeResultList = payload.item ?? [];
      state.total = payload.total_count;
    },
    [fetchPointExchangeCsvFavoriteItem.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchPointExchangeCsvFavoriteItemCreate.fulfilled]: (
      state,
      { payload }
    ) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchPointExchangeCsvDownload.fulfilled]: (state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `ポイント交換実績一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
      ),
  },
});

export const pointExchangeResultActions = pointExchangeResultSlice.actions;

export default pointExchangeResultSlice.reducer;
