import axios from '../utils/axiosUtil';
import moment from 'moment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';

const initialSearchParams = {
  user_code: '',
  stamp_code: '',
  stamp_name: '',
  brand_code: [],
  shop_code: [],
  from_create_at: null,
  to_create_at: null,
  offset: 0,
  count: 10,
  sort: 'user_code_desc',
  csv_brand_code: [''],
  csv_shop_code: [''],
  csv_from_create_at: null,
  csv_to_create_at: null,
};

const initialState = {
  searchParams: initialSearchParams,
  stampedDetailList: [],
  total: 0,
  showFields: [],
  csvDownloadModalVisible: false,
  selectColumsVisible: false,
  favoriteItem: [],
};

export const fetchStampedDetailList = createAsyncThunk(
  'fetchStampedDetailList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const stampResultList = await axios.get('/stamp/result', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return stampResultList;
  }
);

export const fetchStampedResultCsvFavoriteItem = createAsyncThunk(
  'fetchStampedResultCsvFavoriteItem',
  async () =>
    axios.get('csv/favorite_item/list', { params: { screen_type: '3' } })
);

export const updateStampedResultCsvFavoriteItem = createAsyncThunk(
  'updateStampedResultCsvFavoriteItem',
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post(
      'csv/favorite_item/create',
      params
    );
    updateFavoriteRes?.code === '0' &&
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));
    return params.favorite;
  }
);

export const downloadStampedResult = createAsyncThunk(
  'downloadStampedResult',
  async (params, { dispatch }) => {
    const result = await axios.get('csv/download', { params });
    if (!!!result) {
      dispatch(globalActions.showCommonErrorModal());
    }
    return result;
  }
);

export const stampedDetailSlice = createSlice({
  name: 'stampedDetailSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    openCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    openSelectColumsModal: state => ({
      ...state,
      selectColumsVisible: true,
    }),
    closeSelectColumsModal: state => ({
      ...state,
      selectColumsVisible: false,
    }),
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      showFields: payload,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchStampedDetailList.fulfilled]: (state, { payload }) => {
      state.stampedDetailList = payload.item ?? [];
      state.total = payload.total_count;
    },
    [fetchStampedResultCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload?.item?.favorite ?? {};
    },
    [updateStampedResultCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload;
    },
    [downloadStampedResult.fulfilled]: (_, { payload }) => {
      if (payload && typeof payload === 'string') {
        downloadFile(
          payload,
          `押印実績明細一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
        );
      }
    },
  },
});

export const stampedDetailActions = stampedDetailSlice.actions;

export default stampedDetailSlice.reducer;
