import axios from '../utils/axiosUtil';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';

const initialState = {
  updateProvisionalPasswordModalVisible: false,
  updatePasswordModalVisible: false,
  accountInfo: null,
  provisionalAccountInfo: null,
  refreshToggle: false,
  accountShopList: [],
  accountBrandList: [],
};

export const login = createAsyncThunk(
  'login',
  async params =>
    await axios.post('account/login', params, {
      headers: {
        corporation_code: params.corporation_code,
      },
    })
);

export const logout = createAsyncThunk(
  'logout',
  async params => await axios.post('account/logout', params)
);

export const generatePassword = createAsyncThunk(
  'generatePassword',
  async (params, { dispatch }) => {
    await axios.post('provisionalpassword/generate', params, {
      headers: {
        corporation_code: params.corporation_code,
      },
    });

    dispatch(globalActions.showSingleModal('メールを送信しました'));
  }
);

export const updateProvisionalPassword = createAsyncThunk(
  'updateProvisionalPassword',
  async params => {
    await axios.post('password/update', params, {
      headers: {
        corporation_code: params.corporation_code,
        token: params.token,
      },
    });
  }
);

export const updatePassword = createAsyncThunk(
  'updatePassword',
  async (params, { dispatch }) => {
    await axios.post('password/update', params);

    dispatch(globalActions.showSingleModal('変更が完了しました'));
  }
);

export const fetchAccountRelatedInfo = createAsyncThunk(
  'fetchAccountRelatedInfo',
  async (_, { dispatch, getState }) => {
    dispatch(globalActions.setMultiLoading(true));

    const [shopsRes, brandsRes, accountRes] = await Promise.all([
      axios.get('shops/list'),
      axios.get('brands/list'),
      axios.get('staff/detail', {
        params: {
          staff_code: getState().account.accountInfo.staff_code,
        },
      }),
    ]);
    dispatch(globalActions.setMultiLoading(false));

    return {
      brands: (brandsRes?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
      shops: (shopsRes?.item ?? []).map(s => ({
        ...s,
        label: s.shop_name,
        value: s.shop_code,
        brand_code: s.brand?.brand_code,
        latitude: s.position?.[0],
        longitude: s.position?.[1],
      })),

      accountInfo: accountRes.item ?? {},
    };
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setUpdateProvisionalPasswordModalVisible: (state, { payload }) => {
      state.updateProvisionalPasswordModalVisible = payload;
    },
    setUpdatePasswordModalVisible: (state, { payload }) => {
      state.updatePasswordModalVisible = payload;
    },
    setRefresh: state => {
      state.refreshToggle = !state.refreshToggle;
    },
    clearAccount: () => initialState,
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      if (payload.login_type === '0') {
        state.provisionalAccountInfo = payload;
        state.updateProvisionalPasswordModalVisible = true;
      } else {
        state.accountInfo = payload;
      }
    },
    [logout.fulfilled]: () => initialState,
    [logout.rejected]: () => initialState,
    [updateProvisionalPassword.fulfilled]: state => {
      state.updateProvisionalPasswordModalVisible = false;

      const updatedAccountInfo = {
        ...state.provisionalAccountInfo,
        login_type: '1',
      };
      state.provisionalAccountInfo = null;
      state.accountInfo = updatedAccountInfo;
    },
    [updatePassword.fulfilled]: state => {
      state.updatePasswordModalVisible = false;
    },
    [fetchAccountRelatedInfo.fulfilled]: (state, { payload }) => {
      state.accountShopList = payload.shops;
      state.accountBrandList = payload.brands;

      const { img, brand, ...rest } = payload.accountInfo;

      state.accountInfo = {
        ...state.accountInfo,
        ...rest,
        profile_image_url: payload.accountInfo.img,
        brand_code: brand?.brand_code,
        brand_name: brand?.brand_name,
      };
    },
  },
});

export const accountActions = accountSlice.actions;
export default accountSlice.reducer;
