import './style.scss';

const CircleTitle = ({ title, color }) => {
  const size = 10;
  const radius = size / 2;
  return (
    <div className="circle-title">
      <svg width={size} height={size}>
        <circle cx={radius} cy={radius} r={radius} fill={color} />
      </svg>
      <div className="title">{title}</div>
    </div>
  );
};

export default CircleTitle;
