import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lotteryColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import Icons from '../../constants/Icons';
import {
  fetchLotteryCopy,
  fetchLotteryDelete,
  fetchLotteryDetail,
  fetchLotteryList,
  fetchLotteryReference,
  lotteryActions,
} from '../../slices/lotterySlice';
import TableTitle from '../../components/tableTitle';
import DeleteModal from '../../components/modal/deleteModal';
import TcTable from '../../components/tcTable';
import TcCheckbox from '../../components/tcCheckbox';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import './style.scss';
import { useUpdateEffect } from 'react-use';
import InputContainer from '../../components/inputContainer';
import LotteryModal from '../../components/lotteryModal';
import LotteryReferenceModal from '../../components/lotteryModal/reference';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import TextBox from '../../components/util/textBox';

const Lottery = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { total, deleteLottery, searchParams, lotteryList } = useSelector(
    state => state.lottery
  );

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => {
      const {
        public_start_date,
        public_end_date,
        entry_public_start_date,
        entry_public_end_date,
        result_public_start_date,
        result_public_end_date,
        ...rest
      } = values;

      const params = {
        ...rest,
        public_start_date: dateFormat(public_start_date, 'YYYY/MM/DD'),
        public_end_date: dateFormat(public_end_date, 'YYYY/MM/DD'),
        entry_public_start_date: dateFormat(
          entry_public_start_date,
          'YYYY/MM/DD'
        ),
        entry_public_end_date: dateFormat(entry_public_end_date, 'YYYY/MM/DD'),
        result_public_start_date: dateFormat(
          result_public_start_date,
          'YYYY/MM/DD'
        ),
        result_public_end_date: dateFormat(
          result_public_end_date,
          'YYYY/MM/DD'
        ),
      };

      dispatch(lotteryActions.saveSearchParams(params));
    },
  });

  useUpdateEffect(() => {
    dispatch(fetchLotteryList(searchParams));
  }, [searchParams]);

  useEffect(() => {
    dispatch(lotteryActions.saveSearchParams(null));
    return () => dispatch(lotteryActions.clear());
  }, [dispatch]);

  const columns = [
    ...lotteryColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(lotteryActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        !current.delete_flag ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchLotteryDetail(current))}
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        ),
    },
    {
      title: '複製',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconCopy
          className="tc-action-icon"
          height={'20px'}
          width={'20px'}
          onClick={() => dispatch(fetchLotteryCopy(current))}
        />
      ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        !current.delete_flag ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => {
              dispatch(lotteryActions.openDeleteModal(current));
            }}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchLotteryReference(current))}
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle title="新規登録・抽選一覧" icon={<Icons.IconLottery />} />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="抽選CD" fieldName="lottery_code" />
          <TextBox formik={formik} label="抽選名" fieldName="lottery_name" />
          <DateTimeRangePicker
            formik={formik}
            label="キャンペーン表示開始日"
            fromFieldName="public_start_date"
            toFieldName="public_end_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="応募受付日"
            fromFieldName="entry_public_start_date"
            toFieldName="entry_public_end_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="抽選結果公開日"
            fromFieldName="result_public_start_date"
            toFieldName="result_public_end_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['delete_flag']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'delete_flag',
                !!formik.values['delete_flag'] ? 0 : 1
              )
            }
          />
        </div>
      </div>

      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(lotteryActions.openEditModal(null))}
      />
      <TcTable
        columns={columns}
        dataSource={lotteryList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(lotteryActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <LotteryModal />
      <LotteryReferenceModal />
      <DeleteModal
        modalVisible={!!deleteLottery}
        onCancel={() => dispatch(lotteryActions.closeDeleteModal())}
        item={'抽選'}
        itemValue={deleteLottery?.lottery_code}
        onOk={() =>
          dispatch(
            fetchLotteryDelete({
              lottery_code: deleteLottery?.lottery_code,
            })
          )
        }
      />
    </div>
  );
};

export default Lottery;
