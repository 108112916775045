import { Modal, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { stampRallyActions } from '../../slices/stampRallySlice';
import { exchangeType } from '../../constants/options';
import ReferenceItem from '../referenceItem';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import { dateFormat } from '../../utils/fnUtil';
import noimage from '../../images/noimage.png';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import './style.scss';
import React from "react";
import ImagesReference from "../imagesReference";

/* クーポン参照モーダル */

const StampRallyReferenceModal = () => {
  const dispatch = useDispatch();

  const {
    accountInfo: { new_contents_flag },
  } = useSelector(state => state.account);

  const { referenceModalVisible, referenceStamp } = useSelector(
    state => state.stampRally
  );

  return (
    <Modal
      className={'stamp-modal tc-modal'}
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={() => dispatch(stampRallyActions.closeReferenceModal())}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'台紙参照'} />
        <div className="input-area stamp-rally-reference-input-area">
          <div className="image-area">
            <span className={'pre-label'}>バナー画像</span>
            <Image
              src={referenceStamp?.stamp_rally_image_url || noimage}
              width={150}
              height={150}
              preview={false}
            />
          </div>
          <ReferenceItem
            label="台紙名"
            value={referenceStamp?.stamp_rally_name}
          />

          {new_contents_flag !== 0 ? (
            <div className="text-area">
              <span className="pre-label editor-detail-title">台紙説明</span>
              <span className="editor-detail">
                <RichTextEditor
                  readOnly={true}
                  editorState={toSlateState(
                    referenceStamp?.stamp_rally_rich_text
                  )}
                />
              </span>
            </div>
          ) : (
            <ReferenceItem
              label="台紙説明"
              value={referenceStamp?.stamp_rally_text}
            />
          )}

          <div className="reference-image-area">
            <label className="text-required">画像</label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
              }}
            >
              <ImagesReference
                image_url={referenceStamp?.image_url}
                from={0}
                to={4}
                width={150}
                height={150}
              />
              <ImagesReference
                image_url={referenceStamp?.image_url}
                from={5}
                to={9}
                width={150}
                height={150}
              />
            </div>
          </div>

          <ReferenceItem
            label="表示期間"
            multiValue={[
              dateFormat(referenceStamp?.use_date_from, 'YYYY/M/D'),
              ` ${referenceStamp?.use_hour_from}:`,
              `${referenceStamp?.use_minute_from}　〜　`,
              dateFormat(referenceStamp?.use_date_to, 'YYYY/M/D'),
              ` ${referenceStamp?.use_hour_to}:`,
              `${referenceStamp?.use_minute_to}`,
            ]}
            joinString=""
          />
          <ReferenceItem
            label="有効期限"
            value={`${
              {
                0: '最新台紙初回押印日',
                1: '最新台紙最終押印日',
              }[referenceStamp?.valid_days_flag]
            }から ${referenceStamp?.valid_days ?? ''} 日`}
          />
          <ReferenceItem
            label="スタンプ数"
            value={`${referenceStamp?.stamp_rally_count} 個`}
          />
          <ReferenceItem
            label="交換種別"
            value={
              exchangeType.find(r => r.value === referenceStamp?.exchange_type)
                ?.label
            }
          />
          {referenceStamp?.exchange_type === '1' ? (
            <ReferenceItem
              label="ポイント数"
              value={`${referenceStamp?.point} pt`}
            />
          ) : (
            <ReferenceItem
              label="クーポン"
              value={`${referenceStamp?.coupon_code ?? ''}\t${
                referenceStamp?.coupon_name ?? ''
              }`}
            />
          )}
          <ReferenceItem label="定型文" value={referenceStamp?.fixed_phrase} />
          <div className="button-container">
            <TcButton
              text={'閉じる'}
              theme={'white'}
              onClick={() => dispatch(stampRallyActions.closeReferenceModal())}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StampRallyReferenceModal;
