import { Modal } from 'antd';
import Button from '../../tcButton';
import './style.scss';

/* お知らせモーダル */

const InformationModal = ({
  modalVisible,
  setModalVisible,
  item,
  onClickClose = () => setModalVisible(false),
}) => {
  return (
    <Modal
      className={'tc-modal-information tc-modal'}
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      destroyOnClose={true}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <div className="tc-modal-content">
        <div className="tc-modal-information-title-area">
          <div className="tc-modal-information-date">{item?.date}</div>
          <div className="tc-modal-information-title">
            {item?.information_title}
          </div>
        </div>
        <div className="input-area">
          <div className="tc-modal-information-contens">{item?.contents}</div>
        </div>
        <div className="button-container">
          <Button text="閉じる" onClick={onClickClose} />
        </div>
      </div>
    </Modal>
  );
};

export default InformationModal;
