import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import axios from '../utils/axiosUtil';
import moment from 'moment';
import { downloadFile } from '../utils/fnUtil';

const initialSearchParams = {
  lottery_code: '',
  lottery_name: '',
  user_code: '',
  status: [],
  coupon_code: '',
  deleted_flg: '',
  offset: 0,
  count: 10,
  sort: 'lottery_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,
  editModalVisible: false,
  csvDownloadModalVisible: false,
  currentLotteryApplicant: null,
  csvLotteryApplicant: null,
  deleteLotteryApplicant: null,
  referenceEnquete: null,
  summaryEnquete: null,
  chartData: null,
  templateList: [],
  favoriteItem: [],
  categoryList: [],
  lotteryApplicantList: [],
  couponList: [],
  summaryList: [],
};

export const fetchLotteryApplicantCsvFavoriteItem = createAsyncThunk(
  'fetchLotteryApplicantCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchLotteryApplicantCsvFavoriteItemCreate = createAsyncThunk(
  'fetchLotteryApplicantCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response.params;
  }
);

export const fetchLotteryApplicantCsvDownload = createAsyncThunk(
  'fetchLotteryApplicantCsvDownload',
  async params => await axios.get('csv/download', { params })
);

export const fetchLotteryApplicantList = createAsyncThunk(
  'fetchLotteryApplicantList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('lottery/applicant/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const lotteryApplicantSlice = createSlice({
  name: 'lotteryApplicantSlice',
  initialState,
  reducers: {
    openCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchLotteryApplicantCsvFavoriteItem.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchLotteryApplicantCsvFavoriteItemCreate.fulfilled]: (
      state,
      { payload }
    ) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchLotteryApplicantCsvDownload.fulfilled]: (state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `応募者・当選者一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
      ),
    [fetchLotteryApplicantList.fulfilled]: (state, { payload }) => ({
      ...state,
      total: payload?.total_count ?? 0,
      lotteryApplicantList: [
        ...payload?.item?.lottery_applicant_list.map(applicant => ({
          ...applicant,
        })),
      ],
    }),
  },
});

export const lotteryApplicantActions = lotteryApplicantSlice.actions;

export default lotteryApplicantSlice.reducer;
