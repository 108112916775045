import React from 'react';
import Chart from 'react-google-charts';
import ChartLegend from './legend';
import './style.scss';

const pie_chart_colors = [
  '#4A60A1',
  '#8B9EE3',
  '#F16BA6',
  '#FFA7E2',
  '#C9C1D7',
  '#0086AB',
  '#5F519C',
  '#EBABB9',
  '#F6D4AA',
  '#FFF7AA',
  '#EDF3AA',
  '#97DBE1',
  '#86B6DD',
  '#AFA6D3',
  '#D44458',
  '#E58611',
  '#B49B72',
  '#CBDC15',
  '#00716B',
  '#1C4479',
];

const bar_chart_colors = ['#4A60A1'];
const bar_group_width_min = 20;
const bar_group_width_max = 60;
const column_group_width_min = 20;
const column_group_width_max = 65;
const column_width_max = 1100;
const column_width_unit = 80;
const coloumn_branch_index = 9;
const bar_branch_index = 7;
const bar_chart_min_height = 300;
const bar_chart_height = 70;
const tick_conditions = [1, 1.5, 2.5, 5, 10];

const getAutoTicks = (data, count) => {
  let max = Math.max(...data.filter((_, i) => i > 0).map(([_, v]) => v));

  const digitCount = max ? Math.floor(Math.log10(max)) : 0;

  if (digitCount) {
    const base = Math.pow(10, digitCount);
    max = Math.min(...tick_conditions.map(v => v * base).filter(v => v > max));
  } else {
    max = max < 5 ? 5 : 10;
  }

  const unit = max / (count * 2);

  return Array.from({ length: count * 2 + 1 }, (_, index) => unit * index);
};

const getGroupWidth = (data, min, max, branchIndex) =>
  Math.min(min + ((data.length - 1) * (max - min)) / (branchIndex - 2), max);

const pieChartOptions = {
  colors: pie_chart_colors,
  chartArea: { height: '60%', top: 30, bottom: 60, left: '17%', right: '0%' },
  legend: { position: 'none' },
  tooltip: {
    isHtml: false,
    showColorCode: true,
    textStyle: {
      bold: false,
    },
  },
  hAxis: {
    textStyle: {
      fontSize: 14,
    },
  },
  vAxis: {
    textStyle: {
      fontSize: 14,
    },
  },
  pieSliceText: 'none',
};

const columnChartOptionsBase = {
  colors: bar_chart_colors,
  legend: { position: 'none' },
  enableInteractivity: false,
  chartArea: {
    height: '70%',
    top: 30,
  },
  hAxis: {
    textStyle: {
      fontSize: 14,
    },
  },
  vAxis: {
    textStyle: {
      fontSize: 14,
    },
    baselineColor: 'none',
    format: '#,###,###',
  },
};

const barChartOptionBase = {
  colors: bar_chart_colors,
  chartArea: { height: '90%', top: 30, bottom: 50, right: 50 },
  legend: { position: 'none' },
  enableInteractivity: false,
  hAxis: {
    textStyle: {
      fontSize: 14,
    },
    baselineColor: 'none',
    format: '#,###,###',
  },
  vAxis: {
    textStyle: {
      fontSize: 14,
    },
  },
};

const barChartOptions = data => ({
  ...barChartOptionBase,
  bar: {
    groupWidth: `${getGroupWidth(
      data,
      bar_group_width_min,
      bar_group_width_max,
      bar_branch_index
    )}%`,
  },
  hAxis: {
    ...barChartOptionBase.hAxis,
    ticks: getAutoTicks(data, 5, 0).map((val, i) =>
      i != 0 && i % 2 == 0 ? val : { v: val, f: '' }
    ),
  },
});

const columnChartOption = data => ({
  ...columnChartOptionsBase,
  bar: {
    groupWidth: `${getGroupWidth(
      data,
      column_group_width_min,
      column_group_width_max,
      coloumn_branch_index
    )}%`,
  },
  hAxis:
    data.length < coloumn_branch_index - 1
      ? {
          viewWindow: {
            min: -1,
            max: data.length,
          },
        }
      : {},
  vAxis: {
    ...columnChartOptionsBase.vAxis,
    ticks: getAutoTicks(data, 5, 0).map((val, i) =>
      i != 0 && i % 2 == 0 ? val : { v: val, f: '' }
    ),
  },
});

const barChartEvents = [
  {
    eventName: 'ready',
    callback: ({ chartWrapper }) => {
      const container = chartWrapper.visualization.container;
      const xLabels = container.querySelectorAll('[text-anchor="middle"]');

      for (let i = 0; i < xLabels.length; i++) {
        xLabels[i].setAttribute(
          'y',
          barChartOptionBase.chartArea.top -
            xLabels[i].getAttribute('font-size')
        );
      }

      const yLabels = container.querySelectorAll('[text-anchor="end"]');

      for (let i = 0; i < yLabels.length; i++) {
        yLabels[i].setAttribute('x', 0);
        yLabels[i].setAttribute('text-anchor', 'start');
      }

      const gridlines = container.querySelectorAll('[width="1"]');

      for (let i = 0; i < gridlines.length; i++) {
        if (!i) {
          gridlines[i].setAttribute('fill', '#3E3739');
          continue;
        }

        i % 2 != 0
          ? gridlines[i].setAttribute('fill', '#8B87884D')
          : gridlines[i].setAttribute('fill', '#C4C2C3');
      }
    },
  },
];

const columnChartEvents = [
  {
    eventName: 'ready',
    callback: ({ chartWrapper }) => {
      const gridlines =
        chartWrapper.visualization.container.querySelectorAll('[height="1"]');

      for (let i = 0; i < gridlines.length; i++) {
        if (!i) {
          gridlines[i].setAttribute('fill', '#3E3739');
          continue;
        }

        i % 2 != 0
          ? gridlines[i].setAttribute('fill', '#8B87884D')
          : gridlines[i].setAttribute('fill', '#C4C2C3');
      }
    },
  },
];

const chartHeight = data =>
  Math.max((data.length - 1) * bar_chart_height, bar_chart_min_height);

const chartWidth = (data, max) =>
  data.length < coloumn_branch_index
    ? max
    : Math.min(
        Math.max((data.length - 1) * column_width_unit, max),
        column_width_max
      );

const ChartFactory = ({ type, data, height = 300, columnChartWidth = 550 }) => {
  const legendData = data.filter((_, i) => i > 0);

  const chart = type => {
    switch (type) {
      case '00':
        return (
          <>
            <Chart
              chartType="PieChart"
              data={data}
              options={pieChartOptions}
              height={height}
              width="95%"
            />
            <ChartLegend data={legendData} colors={pie_chart_colors} />
          </>
        );
      case '01': {
        const width = chartWidth(data, columnChartWidth);

        return (
          <>
            <Chart
              chartType="ColumnChart"
              data={data}
              options={columnChartOption(data)}
              height={height}
              width={width}
              chartEvents={columnChartEvents}
            />
            <ChartLegend
              data={legendData}
              colors={bar_chart_colors}
              width={width}
            />
          </>
        );
      }
      case '02':
        return (
          <>
            <Chart
              chartType="BarChart"
              data={data}
              options={barChartOptions(data)}
              height={chartHeight(data)}
              width="100%"
              chartEvents={barChartEvents}
            />
            <ChartLegend data={legendData} colors={bar_chart_colors} />
          </>
        );

      default:
        return <></>;
    }
  };

  return <div className="chart-container">{chart(type)}</div>;
};

export default ChartFactory;
