import axios from '../utils/axiosUtil';
import axiosJsonpAdapter from 'axios-jsonp';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { prefectures } from '../constants/options';
import { globalActions } from './globalSlice';
import { accountActions } from './accountSlice';

const initialSearchParams = {
  shop_code: '',
  shop_name: '',
  brand_code: [],
  category_code: [],
  prefectures: [],
  tel_number: '',
  start_date: '',
  end_date: '',
  deleted_flag: 0,
  count: 10,
  sort: 'shop_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  shopList: [],
  total: 0,
  shopModalVisible: false,
  shopDetail: null,
  shopReference: null,
  shopReferenceModalVisible: false,
  total_count: null,
  active_count: null,
  latitude: null,
  longitude: null,
  radius: 0,
};

export const fetchShopList = createAsyncThunk(
  'fetchShopList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('/shops/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchShopDetail = createAsyncThunk(
  'fetchShopDetail',
  async params => await axios.get('/shops/detail', { params })
);

export const searchZip = async params =>
  await axios.get('https://zipcloud.ibsnet.co.jp/api/search', {
    params,
    adapter: axiosJsonpAdapter,
  });

export const updateShop = createAsyncThunk(
  'updateShop',
  async (params, { dispatch, getState }) => {
    await axios.post(`/shops/${params.isCreate ? 'create' : 'update'}`, params);

    dispatch(fetchShopList(getState().shopManagement.searchParams));

    dispatch(accountActions.setRefresh());

    dispatch(
      globalActions.showSingleModal(
        `${params.isCreate ? '登録' : '更新'}が完了しました`
      )
    );
  }
);
export const deleteShop = createAsyncThunk(
  'deleteShop',
  async (params, { dispatch, getState }) => {
    await axios.post('/shops/delete', params);

    dispatch(fetchShopList(getState().shopManagement.searchParams));

    dispatch(accountActions.setRefresh());
  }
);

export const fetchShopReference = createAsyncThunk(
  'fetchShopReference',
  async params => await axios.get('/shops/detail', { params })
);

export const shopManagementSlice = createSlice({
  name: 'shopManagement',
  initialState,
  reducers: {
    setRadius: (state, { payload }) => {
      state.radius = payload;
    },
    setLatitude: (state, { payload }) => {
      state.latitude = payload;
    },
    setLongitude: (state, { payload }) => {
      state.longitude = payload;
    },
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setShopModalVisible: (state, { payload }) => {
      state.shopModalVisible = payload;
    },
    resetShopModal: state => {
      state.radius = null;
      state.latitude = null;
      state.longitude = null;
      state.shopModalVisible = false;
      state.shopDetail = null;
      state.shopReferenceModalVisible = false;
      state.shopReference = null;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [fetchShopList.fulfilled]: (state, { payload }) => {
      state.shopList = (payload.item ?? []).map(shop => ({
        ...shop,
        brand_name: shop.brand?.brand_name,
        tel: shop.tel?.number1
          ? `${shop.tel.number1}-${shop.tel.number2}-${shop.tel.number3}`
          : '',
        category_name: !!shop.category?.length
        ? shop.category?.length > 1
          ? `${shop.category[0].category_name}\r\n他${
            shop.category.length - 1
            }カテゴリ`
          : `${shop.category[0].category_name}`
        : '-',
      }));
      state.total = payload.search_count;
      state.total_count = payload.total_count;
      state.active_count = payload.active_count;
    },
    [fetchShopDetail.fulfilled]: (state, { payload }) => {
      state.shopDetail = adjustDetailItem(payload.item);

      state.shopModalVisible = true;
    },
    [fetchShopReference.fulfilled]: (state, { payload }) => {
      state.shopReference = adjustDetailItem(payload.item);
      state.shopReferenceModalVisible = true;
    },
    [updateShop.fulfilled]: state => {
      state.shopModalVisible = false;
    },
  },
});

const adjustDetailItem = item => {
  const detail = item ?? {};

  const {
    brand,
    category,
    zip,
    tel,
    fax,
    shop_holidays,
    start_date,
    end_date,
    location,
    prefectures: prefectures_code,
    municipality,
    house_info,
    house_number,
    irregular_holidays,
    business_hours1,
    business_hours2,
    icon_image_url,
    check_in_flag,
    ...rest
  } = detail;
  return {
    ...rest,
    ...shop_holidays,
    brand_code: brand?.brand_code,
    brand_name: brand?.brand_name,
    category_code: category?.map(c => c.category_code),
    zip1: zip?.number1,
    zip2: zip?.number2,
    prefectures_code,
    prefectures_name: prefectures.find(p => p.value === prefectures_code)
      ?.label,
    address1: municipality,
    address2: house_number,
    address3: house_info,
    tel1: tel?.number1,
    tel2: tel?.number2,
    tel3: tel?.number3,
    fax1: fax?.number1,
    fax2: fax?.number2,
    fax3: fax?.number3,
    opening_time1: business_hours1?.start ?? '',
    closing_time1: business_hours1?.end ?? '',
    sales_time_remark1: business_hours1?.description,
    opening_time2: business_hours2?.start ?? '',
    closing_time2: business_hours2?.end ?? '',
    sales_time_remark2: business_hours2?.description,
    opening_date: start_date,
    closed_date: end_date,
    irregular_holiday: irregular_holidays,
    location1: location?.latitude,
    location2: location?.longitude,
    shop_image_url: icon_image_url,
    check_in_flag: check_in_flag ?? 0,
  };
};

export const shopManagementActions = shopManagementSlice.actions;
export default shopManagementSlice.reducer;
