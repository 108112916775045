import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { genderOptions4, prefectures } from '../../constants/options';
import { dateFormat, numberFormat } from '../../utils/fnUtil';
import {
  fetchUserList,
  userManagementActions,
  downloadUser,
  fetchUserCsvFavoriteItem,
  updateUserCsvFavoriteItem,
} from '../../slices/userManagementSlice';
import { userFavoriteItemOptions } from '../../constants/options';
import PageTitle from '../../components/pageTitle';
import TableTitle from '../../components/tableTitle';
import TcButton from '../../components/tcButton';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import InputContainer from '../../components/inputContainer';
import './style.scss';
import TextBox from '../../components/util/textBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import SelectBox from '../../components/util/selectBox';
import CustomNumberPicker from '../../components/util/customNumberPicker';

const UserManagement = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const {
    accountShopList,
    accountInfo: { add_user_data_full, corporation_code },
  } = useSelector(state => state.account);

  const {
    searchParams,
    userList,
    total,
    total_count,
    csvDownloadModalVisible,
    favoriteItem,
  } = useSelector(state => state.userManagement);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,

    onSubmit: values => {
      const {
        from_birthday,
        to_birthday,
        from_download_day,
        to_download_day,
        from_update_day,
        to_update_day,
        ...rest
      } = values;

      const params = {
        ...rest,
        from_birthday: dateFormat(from_birthday, 'YYYY/MM/DD'),
        to_birthday: dateFormat(to_birthday, 'YYYY/MM/DD'),
        from_download_day: dateFormat(from_download_day, 'YYYY/MM/DD'),
        to_download_day: dateFormat(to_download_day, 'YYYY/MM/DD'),
        from_update_day: dateFormat(from_update_day, 'YYYY/MM/DD'),
        to_update_day: dateFormat(to_update_day, 'YYYY/MM/DD'),
      };

      dispatch(userManagementActions.saveSearchParams(params));
    },
  });

  useEffect(
    () => dispatch(fetchUserList(searchParams)),
    [searchParams, dispatch]
  );

  useEffect(() => {
    dispatch(fetchUserCsvFavoriteItem());
    return dispatch(userManagementActions.reset());
  }, [dispatch]);

  const columns = [
    {
      title: '端末ID',
      sortField: 'device_id',
    },
    {
      title: '会員番号',
      sortField: 'user_code',
    },
    {
      title: '生年月日',
      sortField: 'birthday',
      render: v => dateFormat(v, 'YYYY/M/D'),
    },
    {
      title: '都道府県',
      sortField: 'prefectures',
      render: prefectureCode =>
        prefectures.find(p => p.value === prefectureCode)?.label,
    },
    {
      title: '性別',
      sortField: 'gender',
      render: gender => genderOptions4.find(p => p.value === gender)?.label,
    },
    {
      title: 'お気に入り店舗',
      sortField: 'favorite_shop',
      render: shops => (
                  <span className="display-linebreak">
                    {shops
                      ? `${shops[0]?.shop_name ?? ''}${
                          shops.length > 1 ? `\n他${shops.length - 1}店舗` : ''
                        }`
                      : ''}
                  </span>
                ),
    },
    ...(add_user_data_full ?? []).map(a => ({
      title: a.name,
      sortField: a.field_name,
    })),
    {
      title: 'ダウンロード日',
      sortField: 'create_at',
      render: v => dateFormat(v, 'YYYY/M/D'),
    },
    {
      title: '更新日',
      sortField: 'update_at',
      render: v => dateFormat(v, 'YYYY/M/D'),
    },
  ].map(col => ({
    title: (
      <ColumnTitle
        title={col.title}
        formik={formik}
        sortField={col.sortField}
        onClick={sort =>
          dispatch(userManagementActions.saveSearchParams({ sort }))
        }
      />
    ),
    dataIndex: col.sortField,
    render: col.render,
  }));

  const userFavOptions = [
    { label: '端末ID', value: 'device_id' },
    { label: '会員番号', value: 'user_code' },
    { label: '生年月日', value: 'birthday' },
    { label: '都道府県', value: 'prefectures' },
    { label: '性別', value: 'gender' },
    { label: 'お気に入り店舗', value: 'favorite_shop' },
    ...(add_user_data_full ?? []).map(a => ({
      label: a.name,
      value: a.field_name,
    })),
    { label: 'ダウンロード日', value: 'create_at' },
    { label: '更新日', value: 'update_at' },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="ユーザー管理"
        subTitle={[`全ユーザー数 ${numberFormat(total_count)}`]}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1472}>
          <TextBox formik={formik} label="端末ID" fieldName="device_id" />
          <TextBox formik={formik} label="会員番号" fieldName="user_code" />
          <DateTimeRangePicker
            formik={formik}
            label="生年月日"
            fromFieldName="from_birthday"
            toFieldName="to_birthday"
          />
          <SelectBox
            formik={formik}
            label="都道府県"
            fieldName="prefectures"
            options={
              selectOptionList?.filter(s => s.field_name === '都道府県')?.[0]
                ?.select_option ?? []
            }
          />
          <SelectBox
            formik={formik}
            label="性別"
            fieldName="gender"
            options={
              selectOptionList?.filter(s => s.field_name === '性別')?.[0]
                ?.select_option ?? []
            }
          />
          <SelectBox
            formik={formik}
            label="お気に入り店舗"
            fieldName="favorite_shop"
            options={accountShopList}
          />
          {(add_user_data_full ?? []).map(a => {
            switch (a.filter_type) {
              case '0':
                return (
                  <TextBox
                    formik={formik}
                    label={a.name}
                    fieldName={`add_user_data_params.${a.field_name}`}
                  />
                );
              case '1':
              case '2':
                return (
                  <SelectBox
                    formik={formik}
                    label={a.name}
                    fieldName={`add_user_data_params.${a.field_name}`}
                    mode={a.filter_type === '2' ? 'multiple' : null}
                    options={(a.filter_list ?? []).map(l => ({
                      label: l.list_name,
                      value: l.list_code,
                    }))}
                  />
                );
              case '3':
                return (
                  <span style={{ display: 'flex' }}>
                    <CustomNumberPicker
                      label={a.name}
                      formik={formik}
                      fieldName={`add_user_data_params.${a.field_name}_start`}
                      placeholder={'0'}
                      width={170}
                      suffix={'〜'}
                      from={a.filter_range?.start ?? 0}
                      to={a.filter_range?.end ?? 0}
                    />
                    <CustomNumberPicker
                      formik={formik}
                      fieldName={`add_user_data_params.${a.field_name}_end`}
                      width={170}
                      placeholder="0"
                      style={{ marginLeft: 10 }}
                      from={a.filter_range?.start ?? 0}
                      to={a.filter_range?.end ?? 0}
                    />
                  </span>
                );
              case '4':
                return (
                  <DateTimeRangePicker
                    formik={formik}
                    label={a.name}
                    fromFieldName={`add_user_data_params.${a.field_name}_start`}
                    toFieldName={`add_user_data_params.${a.field_name}_end`}
                  />
                );
              default:
                return null;
            }
          })}
          <DateTimeRangePicker
            formik={formik}
            label="ダウンロード日"
            fromFieldName="from_download_day"
            toFieldName="to_download_day"
          />
          <DateTimeRangePicker
            formik={formik}
            label="更新日"
            fromFieldName="from_update_day"
            toFieldName="to_update_day"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickCsvOutput={() =>
          dispatch(userManagementActions.openCsvDownloadModal())
        }
      />
      <TcTable
        columns={columns}
        dataSource={userList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(userManagementActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <CsvDownloadModal
        line={2}
        modalVisible={csvDownloadModalVisible}
        options={userFavOptions}
        favoriteItem={favoriteItem}
        onFavoriteItem={checkedList => {
          const param = {
            screen_type: '4',
            favorite: {},
          };
          userFavoriteItemOptions
            .filter(item => item)
            .forEach(item => (param.favorite[item.value] = '0'));
          checkedList
            .filter(item => item)
            .forEach(item => (param.favorite[item] = '1'));
          dispatch(updateUserCsvFavoriteItem(param));
        }}
        onClose={() => dispatch(userManagementActions.closeCsvDownloadModal())}
        onDownload={checkedList => {
          dispatch(
            downloadUser({
              screen_type: '6',
              corporation_code,
              columns: checkedList,
              custom_columns: userFavOptions
                .filter(u => checkedList.includes(u.value))
                .map(u => ({
                  column_field: u.value,
                  column_name: u.label,
                })),
            })
          );
        }}
      />
    </div>
  );
};

export default UserManagement;
