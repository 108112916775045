import React, { useEffect, useRef, useState } from 'react';

const InputContainer = ({
  parent,
  max = 350,
  min = 0,
  parentPadding = 48,
  childMargin = 24,
  children,
}) => {
  const ref = useRef(null);
  const [maxWidth, setMaxWidth] = useState(max);
  const [minWidth, setMinWidth] = useState(min);
  const [widthList, setWidthList] = useState([]);

  const handleResize = () => {
    const containerWidth =
      parent.current.getBoundingClientRect().width - parentPadding;

    if (containerWidth >= max) {
      setMaxWidth(max);
      return;
    }

    if (containerWidth < minWidth) {
      setMaxWidth(minWidth);
      return;
    }

    const width = widthList.reduce((acc, cur, i) => {
      const tmp = acc + cur + (!!i ? childMargin : 0);
      return tmp < containerWidth ? tmp : acc;
    }, 0);

    setMaxWidth(Math.max(minWidth, width));
  };

  useEffect(
    () =>
      setWidthList(
        [...ref.current.children].map(v =>
          Math.ceil(v.getBoundingClientRect().width)
        )
      ),
    [children]
  );

  useEffect(() => {
    setMinWidth(!!min ? min : Math.max(...widthList, 0));
  }, [widthList]);

  useEffect(() => {
    if (!parent) return;
    handleResize();
    const windowHandleResize = () => handleResize();
    window.addEventListener('resize', windowHandleResize);
    return () => {
      window.removeEventListener('resize', windowHandleResize);
    };
  }, [minWidth]);

  return (
    <div
      className="input-container"
      style={{ maxWidth, minWidth, gap: 24 }}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default InputContainer;
