import axios from 'axios';
import qs from 'qs';
import store from '../slices/store';
import { globalActions } from '../slices/globalSlice';
import { accountActions } from '../slices/accountSlice';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const dispatchErrorMessage = response => {
  store.dispatch(
    globalActions.showErrorModal({
      title: ['AC05010', 'AC05011', 'CS07001'].includes(response?.data?.code)
        ? ''
        : 'エラー',
      message: `${
        response?.data?.display_message ||
        '通信に失敗しました\r\n通信環境の良い場所で再度お試しください'
      }${
        (response?.data?.code &&
          !['AC05010', 'AC05011', 'CS07001'].includes(response.data.code) &&
          '\r\nエラーコード : ' + response?.data?.code) ||
        ''
      }`,
    })
  );
};

instance.interceptors.request.use(
  config => {
    const {
      staff_code: operator_code,
      corporation_code,
      token,
    } = store.getState().account.accountInfo ?? {};

    store.dispatch(globalActions.setLoading(true));

    const headers = {
      ...config.headers,
      ...(corporation_code && { corporation_code }),
      ...(token && { token }),
    };

    return config.method === 'get'
      ? {
          ...config,
          params: {
            ...config.params,
            ...(operator_code && { operator_code }),
          },
          headers,
        }
      : {
          ...config,
          ...(config.isFileUpload
            ? { data: config.data }
            : {
                data: {
                  ...config.data,
                  ...(operator_code && { operator_code }),
                },
              }),
          headers,
        };
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => {
    store.dispatch(globalActions.setLoading(false));
    return response.data;
  },
  async error => {
    console.log(error.response);

    !error?.config?.ignoreError && dispatchErrorMessage(error?.response);

    if (['AC05010', 'AC05011'].includes(error?.response?.data?.code)) {
      store.dispatch(accountActions.clearAccount());
    }

    store.dispatch(globalActions.loadingOff());

    return Promise.reject(error);
  }
);

export default instance;
