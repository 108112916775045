import { Upload } from 'antd';
import store from '../../slices/store';
import { globalActions } from '../../slices/globalSlice';
import Papa from 'papaparse';

const BaseFileUpload = ({ children, onChange, maxLine, ...rest }) => {
  const beforeUpload = file => {
    const showError = message => {
      store.dispatch(
        globalActions.showErrorModal({
          title: '読み取りエラー',
          message,
        })
      );
    };

    const isFormateValid = ['text/plain', 'text/csv'].includes(file.type);
    if (!isFormateValid) {
      showError('csv,txt形式でアップロードしてください');
      return false;
    }

    const getFileTxt = (file, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsText(file, 'UTF-8');
    };

    getFileTxt(file, fileString => {
      const isLt20M = file.size / 1024 / 1024 <= 20;
      if (!isLt20M) {
        showError('ファイルは20M以下でアップロードしてください');
        return false;
      }

      const idList = Papa.parse(fileString, { delimiter: '' });
      idList.data = idList.data.filter(Boolean).filter(e => e != '');

      if (idList.data.length === 0) {
        showError('読み取りできるデータがありません');
        return false;
      }

      if (maxLine && idList.data.length > maxLine) {
        showError(`アップロードするIDは${maxLine}件までで設定してください`);
        return false;
      }

      onChange({
        file: file,
        dataCnt: idList.data.length,
        errorCnt: idList.errors.length,
      });
    });

    return false;
  };

  return (
    <Upload
      accept={'.csv,.txt'}
      beforeUpload={beforeUpload}
      showUploadList={false}
      {...rest}
    >
      {children}
    </Upload>
  );
};

export default BaseFileUpload;
