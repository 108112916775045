import { memo, useEffect } from 'react';
import classNames from 'classnames';
import Icons from '../../constants/Icons';
import './style.scss';
import { menuOptions } from '../../constants/options';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSelectOption } from '../../slices/nameSlice';

const PageTitle = memo(
  ({
    title,
    icon = <Icons.IconDatabase className="page-title-icon" />,
    subTitle = [],
    subTitleClass,
    isStampRally,
  }) => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector(state => state.account);

    const findScreenCodeByTitle = targetTitle => {
      const findOption = options => {
        const subOption = options
          .flatMap(option => option.subMenu || [])
          .find(subOption => subOption.label === targetTitle);

        if (subOption) {
          return subOption;
        }

        return options.find(option => option.label === targetTitle);
      };

      const option = isStampRally
        ? menuOptions?.filter(m => m.label === 'スタンプラリー')
        : menuOptions;

      const targetOption = findOption(option);
      const screenCode = targetOption ? targetOption.screen_code : null;

      dispatch(
        fetchSelectOption({
          operator_code: accountInfo.staff_code,
          used_screen_code: screenCode,
        })
      );
    };

    useEffect(() => {
      if (title) {
        findScreenCodeByTitle(title);
      }
    }, [title]);

    return (
      <div className="page-title">
        <span className="page-title-left">
          {icon}
          <span className="title">{title}</span>
        </span>
        {subTitle.map((sub, i) => (
          <span
            key={i}
            className={classNames(
              {
                'sub-info': true,
              },
              subTitleClass
            )}
          >
            {sub}
          </span>
        ))}
      </div>
    );
  }
);

export default PageTitle;
