import { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountRelatedInfo } from '../../slices/accountSlice';
import Header from '../header';
import Footer from '../footer';

const ProtectedRoute = () => {
  const dispatch = useDispatch();

  const { accountInfo, refreshToggle } = useSelector(state => state.account);

  const staff_code = accountInfo?.staff_code;

  useEffect(() => {
    staff_code && dispatch(fetchAccountRelatedInfo());
  }, [refreshToggle, staff_code, dispatch]);

  return accountInfo ? (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
