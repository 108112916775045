import { Modal } from 'antd';
import Button from '../../tcButton';
import { useEffect, useState } from 'react';
import TcCheckbox from '../../tcCheckbox';
import './style.scss';
import ModalTitle from '../../modalTitle';

/* 項目選択モダール */

const SelectColumsModal = ({
  modalTitle,
  title,
  modalVisible,
  closeModal,
  onDisplay,
  options,
  chekedValues,
  initialize = true,
}) => {
  const optionsDouble = (() => {
    const newList = [[]];
    for (const option of options) {
      const last = newList[newList.length - 1];
      if (last.length === 2) {
        newList.push([option]);
      } else {
        last.push(option);
      }
    }
    return newList;
  })();

  const createCheckBox = option => {
    return option ? (
      <TcCheckbox
        key={option.value}
        label={option.label}
        onChange={e => checkBoxesOnChange(option.value, e.target.checked)}
        checked={checkedList?.find(e => e === option.value)?.length > 0}
        className="check-box"
        wrapperStyle={{ width: `${100 / 2}%` }}
      />
    ) : (
      <></>
    );
  };

  const [checkedList, setCheckedList] = useState(chekedValues);
  const [checkAllFlg, setCheckAllFlg] = useState();
  const onCheckAllChange = e => {
    const checkVals = options.map(option => option?.value);
    setCheckedList(e.target.checked ? checkVals : []);
    setCheckAllFlg(!checkAllFlg);
  };
  const checkBoxesOnChange = (value, check) => {
    const checkList = check
      ? [...checkedList, value]
      : checkedList.filter(e => e !== value);
    setCheckedList(checkList);
    setCheckAllFlg(options.filter(e => e?.value).length === checkList.length);
  };
  useEffect(() => {
    if (initialize) {
      setCheckedList(chekedValues);
      setCheckAllFlg(options.length === chekedValues.length);
    } else {
      setCheckAllFlg(options.length === checkedList.length);
    }
  }, [modalVisible]);

  return (
    <>
      <Modal
        visible={modalVisible}
        centered
        footer={null}
        closable={false}
        width={450}
        onCancel={closeModal}
      >
        <div className="selectColumsModal-content">
          <div className="contents-area">
            {modalTitle && <ModalTitle title={modalTitle} />}
            <div>{title}</div>
            <TcCheckbox
              label={'全選択'}
              onChange={onCheckAllChange}
              checked={checkAllFlg}
              className="all-check-box"
              bold={true}
            />
            <div className="checkbox-group">
              {optionsDouble.map((options, i) => (
                <div key={i} className="checkbox-line">
                  {[...Array(2)].map((e, i) => createCheckBox(options?.[i]))}
                </div>
              ))}
            </div>
          </div>
          <div className="button-area">
            <Button
              text="閉じる"
              onClick={() => closeModal()}
              style={{
                width: 140,
                height: 30,
                fontSize: 14,
                marginTop: 40,
              }}
              theme={'white'}
            />
            <Button
              key={checkedList}
              text="切替"
              onClick={() => {
                onDisplay(checkedList);
              }}
              style={{
                width: 140,
                height: 30,
                fontSize: 14,
                marginTop: 40,
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectColumsModal;
