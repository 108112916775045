import React, { useState } from 'react';
import {
  CircleF,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import './style.scss';

const containerStyle = {
  width: '100%',
  height: '350px',
  borderRadius: '3px',
};

const defaultCenter = {
  lat: 35.69575,
  lng: 139.77521,
};

const circleOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};

const GeoMap = ({ makers, center, setCenter }) => {

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCp6dXSnionQVxju_wW8T08EH5C8HeHo_c',
    language: 'ja',
  });

  const onClick = e => {
    setCenter({
      lat: e.latLng.lat() ?? null,
      lng: e.latLng.lng() ?? null,
    });
  };

  return (
    <div className="map-wrapper" style={{ marginTop: 20 }}>
      <div>
        <div className="map">
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onClick={onClick}
            >
              {makers.map((v, i) => (
                <MarkerF position={v} key={i}>
                  <CircleF
                    center={v}
                    radius={Number(v.radius) ?? 0}
                    options={circleOptions}
                  />
                </MarkerF>
              ))}
            </GoogleMap>
          ) : (
            <div>Now Loading</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeoMap;
