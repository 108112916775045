import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import './style.scss';
import {templateActions} from "../../slices/templateSlice";

const TemplateReferenceModal = () => {
  const dispatch = useDispatch();

  const { referenceTemplate } = useSelector(
    state => state.template
  );

  const { selectOptionList } = useSelector(state => state.name);

  const {
    template_code,
    template_type,
    template_title,
    template_text,
  } = referenceTemplate ?? {};

  const modalVisible = !!referenceTemplate;

  const onBack = () => dispatch(templateActions.closeReferenceModal());

  return (
    <Modal
      className={'template-modal tc-modal'}
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'定型文参照'} />

        <div className="input-area template-modal-area">

          <div className="reference-area">
            <span className={'pre-label'}>定型文CD</span>
            {template_code}
          </div>

          <div className="reference-area">
            <span className={'pre-label'}>定型文種別</span>
            {template_type?.map(type => {
              const matchingOption =
                selectOptionList?.filter(
                  s => s.field_name === '定型文種別'
                )?.[0]?.select_option?.find(option => option.value === type);
              return <span>{`${matchingOption.label}　`}</span>;
            })}
          </div>

          <div className="reference-area">
            <span className={'pre-label'}>定型文タイトル</span>
            {template_title}
          </div>

          <div className="reference-area" style={{alignItems: "flex-start"}}>
            <span className={'pre-label'}>定型文</span>
            <div className="reference-template-text">
              {template_text}
            </div>
          </div>

          <div className="button-container">
            <TcButton text="閉じる" theme="black" onClick={onBack} />
          </div>
        </div>

      </div>
    </Modal>
  );
};

export default TemplateReferenceModal;
