import './style.scss';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Icon from '@ant-design/icons';
import { menuOptions } from '../../constants/options';
import Icons from '../../constants/Icons';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import axios from "axios";

const Humberger = () => {
  const bottomMenuOptions = [
    {
      label: 'プライバシーポリシー',
      name: 'privacy',
      link: '/privacy',
    },
    {
      label: '管理画面操作マニュアル',
      name: 'manual',
      // onclick: () => {
      //   const newWindow = window.open("https://tenco:tencohelp@www.tenco.jp/help/")
      //   if (newWindow) newWindow.opener = null;
      // },
      onclick: async () => {
        const url = 'https://www.tenco.jp/help/';
        const auth = 'Basic ' + btoa('tenco:tencohelp'); // Base64エンコード

        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Authorization': auth,
            },
            credentials: 'include', // 必要に応じて設定します
          });

          if (response.ok) {
            const newWindow = window.open('https://www.tenco.jp/help/');
            if (newWindow) newWindow.opener = null;
          } else {
            console.error('Authentication failed');
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      },
    },
  ];

  const navigate = useNavigate();

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);

  const { accountInfo } = useSelector(state => state.account);

  const openMenu = () => {
    setIsOpenMenu(true);
  };

  const closeMenu = () => {
    setOpenAccordion(null);
    setIsOpenMenu(false);
  };

  const handleClickAway = () => closeMenu();

  const createRow = menuOption => {
    const subMenu = menuOption.subMenu;
    return subMenu
      ? createAccordionParent(menuOption)
      : createParent(menuOption);
  };

  const createParent = menuOption => {
    return (
      <li
        key={menuOption.name}
        className="hmenu-li-style"
        onClick={() => {
          closeMenu();
          navigate(menuOption.link);
        }}
      >
        <div className="icon-title">
          <Icon component={menuOption.image} className={'menu-icon'} />
          <div>{menuOption.label}</div>
        </div>
      </li>
    );
  };

  const createAccordionParent = menuOption => {
    const isClose = openAccordion !== menuOption.name;
    return (
      <>
        <li
          key={menuOption.name}
          className="hmenu-li-style"
          onClick={() => setOpenAccordion(isClose ? menuOption.name : null)}
        >
          <div className="icon-title">
            <Icon component={menuOption.image} className={'menu-icon'} />
            <div>{menuOption.label}</div>
          </div>
          <Icon
            component={isClose ? Icons.IconChevronDown : Icons.IconChevronUp}
            className={'arrow-icon'}
          />
        </li>
        {menuOption.subMenu
          .filter(e => accountInfo[e.name] === 1)
          .map(e => createAccordionChild(e, isClose))}
      </>
    );
  };

  const createAccordionChild = (menuOption, isClose) => {
    return (
      <li
        key={menuOption.name}
        className={classNames({
          'hmenu-li-style': true,
          child: true,
          hidden: isClose,
        })}
        onClick={() => {
          closeMenu();
          navigate(menuOption.link);
        }}
      >
        <div className="icon-title">{menuOption.label}</div>
      </li>
    );
  };

  const createBottomMenu = menuOption => {
    return menuOption.onclick ? (
      <li
        className="link-text"
        key={menuOption.name}
        onClick={() => {
          closeMenu();
          menuOption.onclick();
        }}
      >
        {menuOption.label}
        <Icon component={Icons.IconExternalLink} className={'link-icon'} />
      </li>
    ) : (
      <li key={menuOption.name} onClick={() => closeMenu()}>
        <Link to={menuOption.link}>
          {menuOption.label}
          <Icon component={Icons.IconExternalLink} className={'link-icon'} />
        </Link>
      </li>
    );
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="hmenu-wrapper">
          <div
            className={classNames({
              'hmenu-logo': true,
              active: isOpenMenu,
            })}
            onClick={isOpenMenu ? closeMenu : openMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          {isOpenMenu ? (
            <div id="hmenu" className="openmenu">
              <ul className="hmenu-ul-style">
                <div className="top-menu">
                  {menuOptions
                    .filter(e => accountInfo[e.name] === 1)
                    .map(e => createRow(e))}
                </div>
                <div className="bottom-menu">
                  {bottomMenuOptions.map(e => createBottomMenu(e))}
                </div>
              </ul>
            </div>
          ) : (
            <div id="hmenu">
              <ul className="hmenu-ul-style">
                <div className="top-menu" />
                <div className="bottom-menu" />
              </ul>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};

export default Humberger;
