// import crypto from "crypto";
// import cryptoRandomString from "crypto-random-string";
// import { menuOptions } from "../constants/options";
// import moment from "moment";

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getBase64Promise = img => {
  const reader = new FileReader();
  return new Promise(resolve => {
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(img);
  });
};

export const downloadFile = (content, fileName) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display:none";
  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), content], {
    type: "text/csv",
  });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.parentNode.removeChild(a);
};

export const createTimeOptions = length =>
  Array.from({ length }, (_, k) => ({
    label: `${k}`.padStart(2, 0),
    value: `${k}`.padStart(2, 0),
  }));

export const createNumberOptions = (length = 151, from = 100) =>
  Array.from({ length }, (_, k) => ({
    label: from + k,
    value: from + k,
  }));

export const handlerFormikFieldChange = (formik, field, value) => {
  formik.setFieldValue(field, value);
  setTimeout(() => formik.setFieldTouched(field, true));
};

export const spliceArray = (orgArray, targetIndex, target) => {
  const temp = [...orgArray];

  temp.splice(targetIndex, 1, target);

  return temp;
};

export const spliceArrayByMultiValue = (orgArray, targetIndex, values = []) => {
  const temp = [...orgArray];

  temp.splice(targetIndex, values.length, ...values);

  return temp;
};

export const replaceArray = (orgArray, from, target = []) =>
  orgArray.splice(from, target.length, ...target) && orgArray;

export const swapArray = (array, from, to) => {
  const temp = [...array];

  [temp[to], temp[from]] = [temp[from], temp[to]];
  return temp;
};

// export const getMenuArray = () =>
//   menuOptions
//     .map(menu => [menu.name, ...(menu.subMenu ?? []).map(sub => sub.name)])
//     .flat();

// export const getDefaultMenuObject = () =>
//   getMenuArray().reduce((pre, next, current) => ({
//     ...(current === 1 ? { [pre]: 0 } : pre),
//     [next]: 0,
//   }));

// export const generatePassword = () =>
//   cryptoString(cryptoRandomString({ length: 8 }));

// export const cryptoString = target =>
//   crypto.createHash("sha256").update(target, "utf8").digest("hex");

export const isAllNullOrAllNotNull = (fileds = []) =>
  fileds.every(f => f) || fileds.every(f => !f);

export const toMoneyString = str =>
  str || str === 0 ? new Intl.NumberFormat().format(str) : "";

export const MoneyFormatString = value => {
  if (typeof value !== 'string') {
    return value;
  }
  var preffix = '¥';
  var code = value.indexOf('-') >= 0 ? '-' : '';
  var money = value.replace('-', '').replace('¥', '');

  return `${code}${preffix}${money}`;
};
// export const validDatePickChecker = (dateString, type, state, setter) => {
//   const anotherField = type === "from" ? "to" : "from";
//   if (dateString && state[anotherField]) {
//     if (type === "from") {
//       if (moment(dateString).isSameOrBefore(moment(state[anotherField]))) {
//         setter({ ...state, [type]: moment(dateString) });
//       } else {
//         setter({
//           ...state,
//           [type]: moment(state[anotherField]),
//         });
//       }
//     }
//     if (type === "to") {
//       if (moment(state[anotherField]).isSameOrBefore(moment(dateString))) {
//         setter({ ...state, [type]: moment(dateString) });
//       } else {
//         setter({
//           ...state,
//           [type]: moment(state[anotherField]),
//         });
//       }
//     }
//   } else {
//     if (dateString) {
//       setter({ ...state, [type]: moment(dateString) });
//     } else {
//       setter({ ...state, [type]: null });
//     }
//   }
// };

export const updateAccountAuth = _account => {
  let account = { ..._account };
  const brandAuth = [
    "tag_flag",
    "tag_category_flag",
    "tagList_flag",
    "notice_flag",
    "notified_flag",
    "notice_list_flag",
    "shop_mst_flag",
    "product_master_flag",
    "best_list_flag",
  ];
  const shopAuth = [
    "coordinate_flag",
    "blog_flag",
    "shop_analysis_flag",
    "brand_analysis_flag",
    "ranking_flag",
    "user_mst_flag",
    "brand_mst_flag",
  ];
  const adminAuth = [
    "payment_reward_flag",
    "reward_rate_setting_flag",
    "payment_reward_confirmation_flag",
    "corporation_master_flag"
  ];
  brandAuth.forEach(menu => {
    account[menu] =
      (account.user_auth === "0" ||
        account.user_auth === "1" ||
        account.user_auth === "2") &&
      account?.[menu] === 1
        ? 1
        : 0;
  });
  shopAuth.forEach(menu => {
    account[menu] =
      (account.user_auth === "0" ||
        account.user_auth === "1" ||
        account.user_auth === "2" ||
        account.user_auth === "3") &&
      account?.[menu] === 1
        ? 1
        : 0;
  });
  adminAuth.forEach(menu => {
    account[menu] =
      (account.user_auth === "0" || account.user_auth === "1") &&
      account?.[menu] === 1
        ? 1
        : 0;
  });
  return account;
};

export const caculateTaxIncludePrice = (sellingPrice, taxRate = 0) =>
  sellingPrice || sellingPrice === "0"
    ? sellingPrice * (1 + taxRate / 100)
    : "";

export const unicodeUnescape = str =>
  str?.match(/\\u.{4}/ig)?.map(e => String.fromCharCode(e.replace('\\u', '0x')))?.join('') || str;
