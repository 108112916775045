import { memo } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reserveActions } from '../../slices/reserveSlice';
import { dateFormat, numberFormat } from '../../utils/fnUtil';
import ReferenceItem from '../referenceItem';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import './style.scss';

const ReserveReferenceModal = memo(() => {
  const dispatch = useDispatch();

  const { accountShopList } = useSelector(state => state.account);

  const { reserveReferenceModalVisible, reserveReference } = useSelector(
    state => state.reserve
  );

  const shopInfo = (reserveReference?.shop_list ?? [])
    .map(s => accountShopList.find(shop => shop.value === s)?.label)
    .join('\n');

  return (
    <Modal
      className="reserve-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      destroyOnClose={true}
      visible={reserveReferenceModalVisible}
      onCancel={() => dispatch(reserveActions.resetReserveModal())}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'プッシュ通知結果'} />
        <div className="input-area">
          <ReferenceItem
            label={'配信区分'}
            value={reserveReference?.send_type == '1' ? '予約配信' : '即時配信'}
          />
          <ReferenceItem
            label={'配信日'}
            value={`${dateFormat(
              reserveReference?.send_plan_date,
              'YYYY/M/D',
              '-'
            )} ${reserveReference?.send_reserve_time ?? '-'}`}
          />
          <ReferenceItem
            label={'配信結果'}
            value={`配信予定数：${numberFormat(reserveReference?.send_plan_count) !== '' ? numberFormat(reserveReference?.send_plan_count) : '-'}`}
          />
          <ReferenceItem
            label={'開封結果'}
            multiValue={[
              `全体数:${numberFormat(reserveReference?.total_count)}`,
              `開封数:${numberFormat(reserveReference?.total_opened_count)}`,
              `未開封数:${numberFormat(
                reserveReference?.total_unopened_count
              )}`,
            ]}
            joinString="    "
          />
          <ReferenceItem
            label={'配信条件'}
            value={
              { 0: '配信条件設定無', 1: '配信条件設定有' }[
              reserveReference?.cond_flag
              ]
            }
          />
          <div className="button-container">
            <TcButton
              text={'閉じる'}
              theme="white"
              onClick={() => dispatch(reserveActions.resetReserveModal())}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ReserveReferenceModal;
