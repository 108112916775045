import Link from './link';
import ImageBlock from './imageBlock';

export const Element = props => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'image':
      return <ImageBlock {...props} />;
    case 'link':
      return <Link {...props} />;
    case 'align-left':
      return (
        <div {...attributes} style={{ textAlign: 'left' }}>
          {children}
        </div>
      );
    case 'align-center':
      return (
        <div {...attributes} style={{ textAlign: 'center' }}>
          {children}
        </div>
      );
    case 'align-right':
      return (
        <div {...attributes} style={{ textAlign: 'right' }}>
          {children}
        </div>
      );
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>;
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <div {...attributes}>{children}</div>;
  }
};
