import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import axios from '../utils/axiosUtil';
import moment from 'moment';
import { dateFormat, downloadFile } from '../utils/fnUtil';
import { couponTypes } from '../constants/options';

const initialSearchParams = {
  enquete_code: '',
  category_code: [],
  enquete_name: '',
  public_start_date: null,
  public_end_date: null,
  deleted_flg: 0,
  offset: 0,
  count: 10,
  sort: 'enquete_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,

  editModalVisible: false,

  currentEnquete: null,
  csvEnquete: null,
  deleteEnquete: null,
  referenceEnquete: null,
  summaryEnquete: null,
  chartData: null,

  templateList: [],
  favoriteItem: [],
  categoryList: [],
  enqueteList: [],
  couponList: [],
  summaryList: [],
};

export const fetchEnqueteTemplateList = createAsyncThunk(
  'fetchEnqueteTemplateList',
    async () => {
      const TemplateRes = await axios.get('/settings/template/list', {params: {template_type: 1}});
      return {
        templateList: (TemplateRes?.item ?? []).map(t => ({
          ...t,
          label: t.template_title,
          value: t.template_code,
          template_text: t.template_text,
        })),
      };
    }
);

export const fetchEnqueteCouponList = createAsyncThunk(
  'fetchEnqueteCouponList',
  async params =>
    await axios.get('/coupon/list', {
      params: {
        coupon_type: couponTypes['enquete'],
      },
    })
);

export const fetchEnqueteCsvFavoriteItem = createAsyncThunk(
  'fetchEnqueteCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchEnqueteCsvFavoriteItemCreate = createAsyncThunk(
  'fetchEnqueteCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response.params;
  }
);

export const fetchEnqueteCsvDownload = createAsyncThunk(
  'fetchEnqueteCsvDownload',
  async params => await axios.get('csv/download', { params })
);

export const fetchEnqueteList = createAsyncThunk(
  'fetchEnqueteList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('enquete/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchEnqueteCopy = createAsyncThunk(
  'fetchEnqueteCopy',
  async params =>
    await axios.get('enquete/detail', {
      params: { enquete_code: params.enquete_code },
    })
);

export const fetchEnqueteDetail = createAsyncThunk(
  'fetchEnqueteDetail',
  async params =>
    await axios.get('enquete/detail', {
      params: { enquete_code: params.enquete_code },
    })
);

export const fetchEnqueteReference = createAsyncThunk(
  'fetchEnqueteReference',
  async params =>
    await axios.get('enquete/detail', {
      params: { enquete_code: params.enquete_code },
    })
);

export const fetchEnqueteCreate = createAsyncThunk(
  'fetchEnqueteCreate',
  async (params, { dispatch }) => {
    await axios.post('enquete/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchEnqueteUpdate = createAsyncThunk(
  'fetchEnqueteUpdate',
  async (params, { dispatch }) => {
    await axios.post('enquete/update', { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchEnqueteSummary = createAsyncThunk(
  'fetchEnqueteSummary',
  async params =>
    await axios.get('enquete/summary', {
      params: { enquete_code: params.enquete_code },
    })
);

export const fetchQuestionSummary = createAsyncThunk(
  'fetchQuestionSummary',
  async params => {
    const result = await axios.get('enquete/question/summary', {
      params,
    });

    return { ...result, summary_type: params.summary_type };
  }
);

export const fetchEnqueteDelete = createAsyncThunk(
  'fetchEnqueteDelete',
  async params => await axios.post('enquete/delete', { ...params })
);

export const enqueteSlice = createSlice({
  name: 'enqueteSlice',
  initialState,
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteEnquete: payload,
    }),
    openCsvDownloadModal: (state, { payload }) => ({
      ...state,
      csvEnquete: payload,
    }),
    openChartModal: (state, { payload }) => ({
      ...state,
      chartData: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentEnquete: null,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteEnquete: null,
    }),
    closeReferenceModal: state =>
      !!!state.chartData
        ? {
            ...state,
            referenceEnquete: null,
            summaryEnquete: null,
            summaryList: [],
          }
        : { ...state },
    closeCsvDownloadModal: state => ({
      ...state,
      csvEnquete: null,
    }),
    closeChartModal: state => ({
      ...state,
      chartData: null,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchEnqueteTemplateList.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchEnqueteCouponList.fulfilled]: (state, { payload }) => ({
      ...state,
      couponList: [
        ...payload?.item?.map(coupon => ({
          label: coupon.coupon_name,
          value: coupon.coupon_code,
        })),
      ],
    }),
    [fetchEnqueteCsvFavoriteItem.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchEnqueteCsvFavoriteItemCreate.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchEnqueteCsvDownload.fulfilled]: (state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `アンケート一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
      ),
    [fetchEnqueteList.fulfilled]: (state, { payload }) => ({
      ...state,
      total: payload?.total_count ?? 0,
      enqueteList: [
        ...payload?.item?.enquete_list.map(enquete => ({
          ...enquete,
          category_name: !!enquete?.category_name?.length
            ? enquete?.category_name.length > 1
              ? `${enquete.category_name[0]}\r\n他${
                  enquete.category_name.length - 1
                }カテゴリ`
              : `${enquete.category_name[0]}`
            : '-',
        })),
      ],
    }),
    [fetchEnqueteCopy.fulfilled]: (state, { payload }) => {
      const {
        enquete_code,
        create_user,
        create_at,
        update_user,
        update_at,
        deleted_flg,
        answer_count,
        public_push_send_status,
        category_name,
        rich_enquete_contents,
        coupon_code,
        ...rest
      } = payload?.item?.enquete;

      return {
        ...state,
        editModalVisible: true,
        currentEnquete: {
          ...rest,
          rich_enquete_contents: rich_enquete_contents
            ? rich_enquete_contents
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
      };
    },
    [fetchEnqueteDetail.fulfilled]: (state, { payload }) => {
      const {
        create_user,
        create_at,
        update_user,
        update_at,
        deleted_flg,
        answer_count,
        public_push_send_status,
        category_name,
        rich_enquete_contents,
        ...rest
      } = payload?.item?.enquete;

      return {
        ...state,
        editModalVisible: true,
        currentEnquete: {
          ...rest,
          rich_enquete_contents: rich_enquete_contents
            ? rich_enquete_contents
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
      };
    },
    [fetchEnqueteReference.fulfilled]: (state, { payload }) => {
      const {
        create_user,
        create_at,
        update_user,
        update_at,
        deleted_flg,
        answer_count,
        public_push_send_status,
        rich_enquete_contents,
        ...rest
      } = payload?.item?.enquete;
      return {
        ...state,
        referenceEnquete: {
          ...rest,
          rich_enquete_contents: rich_enquete_contents
            ? rich_enquete_contents
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
      };
    },
    [fetchEnqueteCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
    }),
    [fetchEnqueteUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      currentEnquete: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchEnqueteSummary.fulfilled]: (state, { payload }) => {
      const {
        create_user,
        create_at,
        update_user,
        update_at,
        deleted_flg,
        answer_count,
        public_push_send_status,
        ...rest
      } = payload?.item?.enquete;

      return {
        ...state,
        summaryEnquete: rest,
      };
    },
    [fetchQuestionSummary.fulfilled]: (state, { payload }) => {
      const {
        item: {
          question: { question_code, answer_list },
          summary_list,
        },
        summary_type,
      } = payload;

      return {
        ...state,
        summaryList: [
          ...state.summaryList.filter(v => v.question_code !== question_code),
          {
            question_code: question_code,
            summary_type: summary_type,
            answer_list_total: answer_list,
            answer_list_summary: summary_list,
          },
        ],
      };
    },
    [fetchEnqueteDelete.fulfilled]: state => ({
      ...state,
      deleteEnquete: null,
      searchParams: { ...state.searchParams },
    }),
  },
});

export const enqueteActions = enqueteSlice.actions;

export default enqueteSlice.reducer;
