import React, {useState} from 'react';
import InputSelect from '../inputSelect';
import { Tooltip } from 'antd';
import Icons from '../../constants/Icons';
import './style.scss';
import {useUpdateEffect} from "react-use";
import SelectBox from "../util/selectBox";
import TextBox from "../util/textBox";

const CouponList = ({
  formik,
  initial,
  isUpdate,
  couponList
}) => {
  const { coupon_list } = formik.values;

  const coupon_count_min = 1;

  const filterCouponList = (i) => {
    const selectedValue = formik.values.coupon_list?.[i]?.coupon_code;
    return couponList?.filter(item => {
      return item?.value === selectedValue || !coupon_list?.some(coupon => coupon.coupon_code === item.value);
    });
  }

  return (
    <div className="coupon-list">
      {coupon_list?.map((_, i) => (
        <div className="coupon-border">

          {!isUpdate && (
            <div className="delete-btn-area" style={{marginBottom: '10px'}}>
              {!isUpdate && coupon_list?.length > coupon_count_min && (
                <Icons.IconXCircleWh
                  onClick={async () => {
                    const values = {
                      ...formik.values,
                      coupon_list: [
                        ...formik.values['coupon_list']?.filter(
                          (_, index) => index !== i
                        ),
                      ],
                    };
                    formik.setValues(values);
                    formik.setTouched({
                      ...formik.touched,
                      coupon_list: formik.touched['coupon_list']?.filter(
                        (_, index) => index !== i
                      ),
                    });

                    const errors = await formik.validateForm(values);

                    formik.setErrors(errors);
                  }}
                />
              )}
            </div>
          )}

          <div className="coupon-input-area">
            {!isUpdate ? (
              <SelectBox
                formik={formik}
                label="クーポン"
                fieldName={`coupon_list.${i}.coupon_code`}
                placeholder="クーポンを選択してください"
                options={filterCouponList(i)}
                width={'100%'}
              />
            ) : (
              <div className="lottery-modal-update-coupon">
                <span className="lottery-label-required" style={{minWidth: '100px'}}>クーポン</span>
                <span className="lottery-coupon_code">{coupon_list?.[i]?.coupon_code}</span>
                <span className="lottery-text-after-code">{couponList?.filter(c => c?.value === coupon_list?.[i]?.coupon_code).map(coupon => coupon?.label)}</span>
              </div>
            )}

            <div className="lottery-coupon-list-winner_count">
              <TextBox formik={formik} label="当選本数" fieldName={`coupon_list.${i}.winner_count`} placeholder="00" width={'30%'} />
              <span style={{ marginLeft: 3, lineHeight: '42px'}}>本</span>
            </div>
          </div>
        </div>
      ))}

      {!isUpdate && (
        <div className="add-btn-area">
          <Tooltip
            title="クーポン枠追加"
            color="#C4A6A9"
            overlayClassName="add-tooltip"
          >
            <Icons.IconPlus
              className="plus-icon"
              onClick={() =>
                formik.setFieldValue('coupon_list', [
                  ...coupon_list,
                  initial,
                ])
              }
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default CouponList;
