import React from 'react';
import { getFormikArrayProps } from '../../utils/fnUtil';
import { Input, Tooltip } from 'antd';
import Icons from '../../constants/Icons';
import classNames from 'classnames';
import './style.scss';
import TextBox from '../util/textBox';

const AnswerList = ({ formik, fieldName }) => {
  const answer_max = 20;
  const width = 550;
  const answers = formik.getFieldProps(fieldName).value;

  const inputListItemClassName = (list, i) =>
    classNames('tc-input-list-item', {
      'not-end': i < list.length - 1,
    });

  const formikArrayProps = fieldName
    ? getFormikArrayProps(formik, fieldName)
    : null;

  const [parent, index, field] = fieldName.split('.');

  return (
    <>
      <div className="answer-list" style={{ width }}>
        <div className="tc-input-info">
          <span className="tc-input-error">
            {formikArrayProps.touched && formikArrayProps.error}
          </span>
        </div>
        <div className="tc-input-line">
          <span className="pre-label label-required">回答選択肢</span>
          <div className="tc-input-list">
            {answers?.map((_, i) => (
              <div className={inputListItemClassName(answers, i)}>
                <TextBox
                  formik={formik}
                  placeholder={'回答選択肢を入力してください'}
                  fieldName={`${fieldName}.${i}.answer_text`}
                  hasLabel={false}
                  width={410}
                />
                <div className="btn-area">
                  {i === answers.length - 1 && answers.length < answer_max ? (
                    <Icons.IconPlusWh
                      className="icon"
                      onClick={() =>
                        formik.setFieldValue(`${fieldName}`, [
                          ...formik.getFieldProps(`${fieldName}`).value,
                          {
                            answer_text: null,
                          },
                        ])
                      }
                    />
                  ) : (
                    <Icons.IconXCircleWh
                      className="icon-x"
                      onClick={async () => {
                        const values = {
                          ...formik.values,
                          question_list: [
                            ...formik.values[parent].map((v, questionIndex) =>
                              questionIndex === Number(index)
                                ? {
                                    ...v,
                                    answer_list: formik.values[parent][index][
                                      field
                                    ].filter(
                                      (_, answerIndex) => answerIndex !== i
                                    ),
                                  }
                                : v
                            ),
                          ],
                        };

                        formik.setValues(values);

                        if (!!formik.touched[parent]) {
                          const answers =
                            formik.touched[parent]?.[index]?.[field]?.filter(
                              (_, answerIndex) => answerIndex !== i
                            ) ?? [];

                          formik.setTouched({
                            ...formik.touched,
                            question_list: [
                              ...formik.touched[parent].filter(
                                (_, questionIndex) =>
                                  questionIndex !== Number(index)
                              ),
                              {
                                ...formik.touched[parent][index],
                                answer_list: answers.some(v => !!v)
                                  ? answers
                                  : null,
                              },
                            ],
                          });
                        }

                        const errors = await formik.validateForm(values);

                        formik.setErrors(errors);
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnswerList;
