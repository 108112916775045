import { memo } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { shopManagementActions } from '../../slices/shopManagementSlice';
import { weekCheckboxOptions } from '../tcCheckbox/weekCheckbox';
import ReferenceItem from '../referenceItem';
import ModalTitle from '../modalTitle';
import TcAvatar from '../tcAvatar';
import TcButton from '../tcButton';
import './style.scss';
import { dateFormat } from '../../utils/fnUtil';

const ShopReferenceModal = memo(() => {
  const dispatch = useDispatch();

  const { shopReferenceModalVisible, shopReference } = useSelector(
    state => state.shopManagement
  );

  return (
    <Modal
      className="shop-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      visible={shopReferenceModalVisible}
      onCancel={() => dispatch(shopManagementActions.resetShopModal())}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'店舗参照'} />
        <div className="input-area">
          <ReferenceItem label={'店舗CD'} value={shopReference?.shop_code} />
          <ReferenceItem label={'ブランド'} value={shopReference?.brand_name} />
          <ReferenceItem label={'店舗名'} value={shopReference?.shop_name} />
          <ReferenceItem
            label={'店舗名(カナ)'}
            value={shopReference?.shop_name_kana}
          />
          <ReferenceItem
            label={'表示優先順'}
            value={shopReference?.display_order}
          />
          <ReferenceItem
            label={'アプリ表示'}
            value={{ 0: '非表示', 1: '表示' }[shopReference?.app_display_flag]}
          />
          <ReferenceItem
            label={'郵便番号'}
            value={`${shopReference?.zip1 ?? ''}-${shopReference?.zip2 ?? ''}`}
          />
          <ReferenceItem
            label={'都道府県'}
            value={shopReference?.prefectures_name}
          />
          <ReferenceItem label={'市区町村'} value={shopReference?.address1} />
          <ReferenceItem label={'町名番地'} value={shopReference?.address2} />
          <ReferenceItem label={'館情報'} value={shopReference?.address3} />
          <ReferenceItem
            label={'電話番号'}
            multiValue={[
              shopReference?.tel1,
              shopReference?.tel2,
              shopReference?.tel3,
            ]}
          />
          <ReferenceItem
            label={'FAX'}
            multiValue={[
              shopReference?.fax1,
              shopReference?.fax2,
              shopReference?.fax3,
            ]}
          />
          <ReferenceItem
            label={'営業時間1'}
            multiValue={[
              shopReference?.opening_time1,
              shopReference?.closing_time1,
            ]}
            joinString="~"
            subValue={shopReference?.sales_time_remark1 || '-'}
          />
          <ReferenceItem
            label={'営業時間2'}
            multiValue={[
              shopReference?.opening_time2,
              shopReference?.closing_time2,
            ]}
            joinString="~"
            subValue={shopReference?.sales_time_remark2 || '-'}
          />
          <ReferenceItem
            label={'定休日'}
            value={weekCheckboxOptions
              .map(o => (shopReference?.[o.field] === 1 ? o.label : ''))
              .filter(o => o)
              .join('、')}
          />
          <ReferenceItem label={'外部リンク'} value={shopReference?.link} />
          <ReferenceItem label={'店舗紹介文'} value={shopReference?.appeal} />
          <ReferenceItem
            label={'開店日'}
            value={dateFormat(shopReference?.opening_date, 'YYYY/M/D')}
            subValue={`閉店日 ${
              dateFormat(shopReference?.closed_date, 'YYYY/M/D') || '-'
            }`}
          />
          <ReferenceItem
            label={'位置情報'}
            value={`緯度 ${shopReference?.location1 || '-'}`}
            subValue={`経度 ${shopReference?.location2 || '-'}`}
          />
          <TcAvatar src={shopReference?.shop_image_url} readOnly={true} />
          <div className="button-container">
            <TcButton
              text={'閉じる'}
              theme="white"
              onClick={() => dispatch(shopManagementActions.resetShopModal())}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ShopReferenceModal;
