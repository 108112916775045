import moment from 'moment';
import { memo } from 'react';
import DatePickerBase from './datePickerBase';
import NumberPicker from '../../components/numberPicker';
import { get } from 'lodash';
import './style.scss';

const RangePicker = memo(
  ({
    formik,
    fromField,
    toField,
    rangeText,
    preLabelClassName,
    uneditable,
    useTimePicker,
    usePopContainer,
    disabled,
    fromDisabledDate = null,
    toDisabledDate = null,
    useNumberPicker,
    startNumber,
    endNumber,
    width,
  }) => {
    return (
      <div className="tc-range-picker">
        {useNumberPicker ? (
          <NumberPicker
            formik={formik}
            from={startNumber}
            to={endNumber}
            formName={fromField}
            endFormName={toField}
            style={{ width }}
            placeholder="0"
            allowClear={true}
          />
        ) : (
          <DatePickerBase
            formik={formik}
            fieldName={fromField}
            width={width}
            uneditable={uneditable}
            useTimePicker={useTimePicker}
            usePopContainer={usePopContainer}
            disabled={disabled}
            disabledDate={
              fromDisabledDate ||
              (current =>
                toField && current > moment(get(formik.values, toField, null)))
            }
          />
        )}
        {toField && (
          <>
            {rangeText ? (
              <span className={preLabelClassName}>{rangeText}</span>
            ) : (
              <span className="range-text">~</span>
            )}
            {useNumberPicker ? (
              <NumberPicker
                formik={formik}
                from={startNumber}
                to={endNumber}
                formName={toField}
                startFormName={fromField}
                style={{ width }}
                placeholder="0"
                allowClear={true}
              />
            ) : (
              <DatePickerBase
                disabledDate={
                  toDisabledDate ||
                  (current =>
                    current < moment(get(formik.values, fromField, null)))
                }
                formik={formik}
                disabled={disabled}
                fieldName={toField}
                width={width}
                uneditable={uneditable}
                useTimePicker={useTimePicker}
                usePopContainer={usePopContainer}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default RangePicker;
