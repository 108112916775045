import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { shopManagementcolumns } from '../../constants/columns';
import {
  dateFormat,
  handlerFormikFieldChange,
  numberFormat,
} from '../../utils/fnUtil';
import { CATEGORY_TYPE } from '../../constants/enums';
import { globalActions } from '../../slices/globalSlice';
import {
  shopManagementActions,
  fetchShopList,
  fetchShopDetail,
  fetchShopReference,
  deleteShop,
} from '../../slices/shopManagementSlice';
import {
  fetchCategoryList,
} from '../../slices/categorySlice';
import TcButton from '../../components/tcButton';
import PageTitle from '../../components/pageTitle';
import TableTitle from '../../components/tableTitle';
import ColumnTitle from '../../components/columnTitle';
import TcCheckbox from '../../components/tcCheckbox';
import ShopModal from '../../components/shopModal';
import ShopReferenceModal from '../../components/shopModal/shopReferenceModal';
import TcTable from '../../components/tcTable';
import Icons from '../../constants/Icons';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';

const ShopManagement = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountBrandList } = useSelector(state => state.account);

  const { categoryList } = useSelector(state => state.category);

  const { selectOptionList } = useSelector(state => state.name);

  const { searchParams, shopList, total, total_count, active_count } =
    useSelector(state => state.shopManagement);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      const { start_date, end_date, ...rest } = values;

      const params = {
        ...rest,
        start_date: dateFormat(start_date, 'YYYY/MM/DD'),
        end_date: dateFormat(end_date, 'YYYY/MM/DD'),
      };

      dispatch(shopManagementActions.saveSearchParams(params));
    },
  });

  useEffect(
    () => {
      dispatch(fetchShopList(searchParams));
      dispatch(fetchCategoryList({ category_type: CATEGORY_TYPE.SHOP}))
    },
    [searchParams, dispatch]
  );

  useEffect(() => () => dispatch(shopManagementActions.reset()), [dispatch]);

  const columns = [
    ...shopManagementcolumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort =>
            dispatch(shopManagementActions.saveSearchParams({ sort }))
          }
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        current.status === 2 ? (
          <Icons.IconEditGy className="table-cell-icon tc-disabled-icon" />
        ) : (
          <Icons.IconEdit
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(fetchShopDetail({ shop_code: current.shop_code }))
            }
          />
        ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        current.status === 2 ? (
          <Icons.IconSearch
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                fetchShopReference({
                  shop_code: current.shop_code,
                })
              )
            }
          />
        ) : (
          <Icons.IconTrash
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                globalActions.showDeleteModal({
                  message: `${current.shop_code}  ${current.shop_name}\nを削除します\nよろしいですか?`,
                  tip: '※同一のCDは使用不可となります',
                  deleteAction: deleteShop({
                    shop_code: current.shop_code,
                  }),
                })
              )
            }
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="店舗管理"
        subTitle={[
          `全店舗数 ${numberFormat(total_count)}`,
          `利用中店舗数 ${numberFormat(active_count)}`,
        ]}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="店舗CD" fieldName="shop_code" />
          <TextBox formik={formik} label="店舗名" fieldName="shop_name" />
          <SelectBox
            formik={formik}
            label="ブランド"
            fieldName="brand_code"
            options={accountBrandList}
            mode="multiple"
          />
          <SelectBox
            formik={formik}
            label="店舗カテゴリ"
            fieldName="category_code"
            options={categoryList.map(d => ({
              label: d.category_name,
              value: d.category_code
            }))}
            mode="multiple"
          />
          <SelectBox
            formik={formik}
            label="都道府県"
            fieldName="prefectures"
            options={
              selectOptionList?.filter(s => s.field_name === '都道府県')?.[0]
                ?.select_option ?? []
            }
          />
          <TextBox formik={formik} label="電話番号" fieldName="tel_number" />
          <DateTimeRangePicker
            formik={formik}
            label="登録日"
            fromFieldName="start_date"
            toFieldName="end_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={formik.values.deleted_flag === 1}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flag',
                formik.values.deleted_flag === 1 ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickCsv={() => dispatch(globalActions.openFileUploadModal('0'))}
        onClickAdd={() =>
          dispatch(shopManagementActions.setShopModalVisible(true))
        }
      />
      <TcTable
        columns={columns}
        dataSource={shopList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(shopManagementActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <ShopModal />
      <ShopReferenceModal />
    </div>
  );
};

export default ShopManagement;
