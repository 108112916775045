import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { handlerFormikFieldChange, numberFormat } from '../../utils/fnUtil';
import { brandManagementcolumns } from '../../constants/columns';
import {
  fetchBrandList,
  brandManagementActions,
  fetchBrandDetail,
  deleteBrand,
} from '../../slices/brandManagementSlice';
import {
  fetchCategoryList,
} from '../../slices/categorySlice';
import { globalActions } from '../../slices/globalSlice';
import { CATEGORY_TYPE } from '../../constants/enums';
import TcButton from '../../components/tcButton';
import PageTitle from '../../components/pageTitle';
import TableTitle from '../../components/tableTitle';
import ColumnTitle from '../../components/columnTitle';
import BrandModal from '../../components/brandModal';
import TcCheckbox from '../../components/tcCheckbox';
import TcTable from '../../components/tcTable';
import Icons from '../../constants/Icons';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';

const BrandManagement = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountBrandList, accountInfo } = useSelector(state => state.account);

  const { categoryList } = useSelector(state => state.category);
  
  const { searchParams, brandList, total, total_count, active_count } =
    useSelector(state => state.brandManagement);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: async values =>
      dispatch(brandManagementActions.saveSearchParams(values)),
  });

  useEffect(
    () => {
      dispatch(fetchBrandList(searchParams));
      dispatch(fetchCategoryList({ category_type: CATEGORY_TYPE.BRAND}))
    },
    [searchParams, dispatch]
  );

  useEffect(() => () => dispatch(brandManagementActions.reset()), [dispatch]);

  const columns = [
    ...brandManagementcolumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort =>
            dispatch(brandManagementActions.saveSearchParams({ sort }))
          }
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        current.status === 2 ? (
          <Icons.IconEditGy className="table-cell-icon tc-disabled-icon" />
        ) : (
          <Icons.IconEdit
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(fetchBrandDetail({ brand_code: current.brand_code }))
            }
          />
        ),
    },
  ];

  if (accountInfo.staff_auth === '0') {
    columns.push({
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        current.status === 2 ? (
          <Icons.IconTrashGy className="table-cell-icon" />
        ) : (
          <Icons.IconTrash
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                globalActions.showDeleteModal({
                  message: `${current.brand_code}  ${current.brand_name}\nを削除します\nよろしいですか?`,
                  tip: '※同一のCDは使用不可となります',
                  deleteAction: deleteBrand({
                    brand_code: current.brand_code,
                  }),
                })
              )
            }
          />
        ),
    });
  }

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="ブランド管理"
        subTitle={[
          `全ブランド数 ${numberFormat(total_count)}`,
          `利用中ブランド数 ${numberFormat(active_count)}`,
        ]}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={724}>
          <TextBox formik={formik} label="ブランドCD" fieldName="brand_code" />
          <SelectBox
            formik={formik}
            label="ブランド名"
            fieldName="brand_name"
            options={accountBrandList.map(brand => ({
              value: brand.label,
              label: brand.label,
            }))}
          />
          <SelectBox
            formik={formik}
            label="ブランドカテゴリ"
            fieldName="category_code"
            options={categoryList.map(d => ({
              label: d.category_name,
              value: d.category_code
            }))}
            mode="multiple"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          {accountInfo.staff_auth === '0' && (
            <TcCheckbox
              className="deleted-checkbox"
              label={'削除済み'}
              checked={formik.values.deleted_flag === 1}
              onChange={() =>
                handlerFormikFieldChange(
                  formik,
                  'deleted_flag',
                  formik.values.deleted_flag === 1 ? 0 : 1
                )
              }
            />
          )}
        </div>
      </div>
      <TableTitle
        searchCount={total}
        message={
          '※新規追加・削除に関してはシステム管理者にお問い合わせください'
        }
        onClickAdd={
          accountInfo.staff_auth === '0'
            ? () => dispatch(brandManagementActions.setBrandModalVisible(true))
            : null
        }
      />
      <TcTable
        columns={columns}
        dataSource={brandList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(brandManagementActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <BrandModal />
    </div>
  );
};

export default BrandManagement;
