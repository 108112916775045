import axios from '../utils/axiosUtil';
import moment from 'moment';
import { remove } from "lodash";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createFormData } from './notificationSlice';
import { globalActions } from './globalSlice';

const initialSearchParams = {
  title: '',
  message: '',
  shop: '',
  from_create_at: null,
  to_create_at: null,
  delete_flag: false,
  count: 10,
  sort: 'document_id_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  geopushList: [],
  total: 0,
  geopushModalVisible: false,
  geopushDetail: null,
  latitude: null,
  longitude: null,
  radius: 0,
};

export const fetchGeopushList = createAsyncThunk(
  'fetchGeopushList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('/notification/document/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchGeopushDetail = createAsyncThunk(
  'fetchGeopushDetail',
  async params => {
    const detail = await axios.get('/notification/reserve/detail', { params });
    return detail?.item ?? { document_id: params.document_id };
  }
);

export const updateGeopush = createAsyncThunk(
  'updateGeopush',
  async (params, { dispatch, getState }) => {
    await axios.post(
      `/notification/reserve/${params.isCreate ? 'create' : 'update'}`,
      createFormData(params), {
        headers: { 'content-type': 'multipart/form-data' },
        isFileUpload: true,
      });

    dispatch(fetchGeopushList(getState().geopush.searchParams));

    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchGeopushFileCheck = createAsyncThunk(
  'fetchGeopushFileCheck',
  async params =>
    await axios.post(
      '/notification/geopush/filecheck',
      createFormData(params),
      {
        headers: { 'content-type': 'multipart/form-data' },
        isFileUpload: true,
      }
    )
);

export const cancelGeopush = createAsyncThunk(
  'cancelGeopush',
  async (params, { dispatch, getState }) => {
    await axios.post('/notification/geopush/cancel', params);

    dispatch(fetchGeopushList(getState().geopush.searchParams));

    dispatch(globalActions.showSingleModal('配信設定をキャンセルしました'));
  }
);

export const geopushSlice = createSlice({
  name: 'geopush',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setGeopushModalVisible: (state, { payload }) => {
      state.geopushModalVisible = payload;
    },
    setEditFlag: (state, { payload }) => {
      let flag = state.geopushList[payload.index].edit_flag;
      flag = !flag;

      let row = {...payload.list[payload.index]};
      row.edit_flag = flag;
      state.geopushList[payload.index] = row;
    },
    setLocationInfoList: (state, { payload }) => {
      state.geopushList = payload.location_info_list;
    },
    deleteLocationInfo: (state, { payload }) => {   
      state.geopushList = remove(state.geopushList, (_, i) => i !== payload.index);
    },
    resetGeopushModal: state => {
      state.geopushModalVisible = false;
      state.geopushDetail = null;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [fetchGeopushList.fulfilled]: (state, { payload }) => {
      state.geopushList = payload.item ?? [];
      state.total = payload.total_count;
    },
    [fetchGeopushDetail.fulfilled]: (state, { payload }) => {
      state.geopushList = payload.location_info_list ?? [];
      state.total = payload.total_count;
      state.geopushDetail = {
        document_id: payload.document_id,
        reserve_id: payload.reserve_id,
        send_type: payload.send_type ?? '1',
        repeat_type: payload.repeat_type ?? '0',
        send_date: payload.send_plan_date ?? '',
        send_plan_date_from: payload.send_plan_date_from ? payload.send_plan_date_from.split(' ')[0] : '',
        send_plan_time_from_hh: payload.send_plan_date_from
          ? moment(payload.send_plan_date_from.split(' ')[1], 'HH:mm').format('HH')
          : null,
        send_plan_time_from_mm: payload.send_plan_date_from
        ? moment(payload.send_plan_date_from.split(' ')[1], 'HH:mm').format('mm')
        : null,
        send_plan_date_to: payload.send_plan_date_to ? payload.send_plan_date_to.split(' ')[0] : '',
        send_plan_time_to_hh: payload.send_plan_date_to
          ? moment(payload.send_plan_date_to.split(' ')[1], 'HH:mm').format('HH')
          : null,
        send_plan_time_to_mm: payload.send_plan_date_to
        ? moment(payload.send_plan_date_to.split(' ')[1], 'HH:mm').format('mm')
        : null,
        send_time_mm: payload.send_reserve_time
        ? moment(payload.send_reserve_time, 'HH:mm').format('mm')
        : null,
        repeat_send_time_hh: payload.repeat_send_time
          ? moment(payload.repeat_send_time, 'HH:mm').format('HH')
          : null,
        repeat_send_time_mm: payload.repeat_send_time
          ? moment(payload.repeat_send_time, 'HH:mm').format('mm')
          : null,
        repeat_period: payload.repeat_period,
        timing:
          payload.repeat_period === 'day'
            ? payload.timing?.day
            : payload.repeat_period === 'week'
            ? payload.timing?.week
            : payload.repeat_period === 'month'
            ? payload.timing?.month?.month_day
              ? 0
              : 1
            : null,
        repeat_send_time: payload.repeat_send_time,
        repeat_start_date: payload.repeat_start_date ?? '',
        repeat_end_date: payload.repeat_end_date ?? '',
        cond_flag: payload.cond_flag ?? '0',
        from_age: payload.from_age,
        to_age: payload.to_age,
        gender: payload.gender,
        birth_month: payload.birth_month,
        prefectures_list: payload.prefectures_list,
        shop_list: payload.shop_list,
        location_info_list: payload.location_info_list,
        file_type: payload.list_type,
        upload_file_name: payload.upload_file_name,
        add_user_data_params: payload.add_user_data_params,
        isCreate: false,
      };
      state.geopushModalVisible = true;
    },
    [updateGeopush.fulfilled]: state => {
      state.geopushDetail = null;
      state.geopushModalVisible = false;
    },
    [cancelGeopush.fulfilled]: state => {
      state.geopushDetail = null;
      state.geopushModalVisible = false;
    },
  },
});

export const geopushActions = geopushSlice.actions;
export default geopushSlice.reducer;
