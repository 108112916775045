import axios from '../utils/axiosUtil';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchShopList } from './shopManagementSlice';
import { fetchStaffList } from './staffManagementSlice';
import { accountActions } from './accountSlice';

const initialState = {
  globalModalConfig: {
    title: '',
    message: '',
    tip: '',
    buttons: [],
    deleteModal: false,
  },
  fileUploadModalVisible: false,
  screenType: null,
  registerEnable: false,
  errorCount: 0,
  successCount: 0,
  checkResult: [],
  loading: false,
  multiLoading: false,
  primaryLoading: false,
  previewModalVisible: false,
  previewModalContents: {
    text: '',
    images: Array(10).fill(''),
  },
};

const createFormData = params => {
  const formData = new FormData();

  const jsonData = {
    screen_type: params.screenType,
    operator_code: params.userCode,
  };

  formData.append('csv_file', params.fileInfo);
  formData.append(
    'jsonValue',
    new Blob([JSON.stringify(jsonData)], { type: 'application/json' })
  );

  return formData;
};

export const csvCheck = createAsyncThunk(
  'csvCheck',
  async params =>
    await axios.post('/csv/check', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    })
);

export const csvUpload = createAsyncThunk(
  'csvUpload',
  async (params, { dispatch, getState }) => {
    const uploadRes = await axios.post('/csv/upload', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    });

    uploadRes?.code === '0' &&
      dispatch(globalActions.showSingleModal('登録が完了しました'));

    params.screenType === '0'
      ? dispatch(fetchShopList(getState().shopManagement.searchParams))
      : dispatch(fetchStaffList(getState().staffManagement.searchParams));

    dispatch(accountActions.setRefresh());

    dispatch(globalActions.closeFileUploadModal());
  }
);

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    showSingleModal: (state, { payload }) => {
      state.globalModalConfig = {
        title: payload,
        message: '',
        tip: '',
        buttons: [
          {
            btnText: '閉じる',
            btnTheme: 'black',
          },
        ],
      };
    },
    showCommonErrorModal: (state) => {
      state.globalModalConfig = {
        title: 'エラー',
        message: '通信に失敗しました\r\n通信環境の良い場所で再度お試しください',
        tip: '',
        buttons: [
          {
            btnText: '閉じる',
            btnTheme: 'black',
          },
        ],
      };
    },
    showErrorModal: (state, { payload }) => {
      state.globalModalConfig = {
        title: payload.title,
        message: payload.message,
        tip: '',
        buttons: [
          {
            btnText: '閉じる',
            btnTheme: 'black',
          },
        ],
      };
    },
    showSelectModal: (state, { payload }) => {
      state.globalModalConfig = {
        title: payload.title,
        message: payload.message,
        tip: payload.tip,
        buttons: payload.buttons.map((btn, i) => ({
          btnText: btn.btnText || (i === 0 ? 'はい' : 'いいえ'),
          btnTheme: btn.btnTheme || (i === 0 ? 'white' : 'black'),
          btnAction: btn.btnAction,
        })),
      };
    },
    showDeleteModal: (state, { payload }) => {
      state.globalModalConfig = {
        title: '削除確認',
        message: payload.message,
        deleteModal: true,
        tip: payload.tip,
        buttons: [
          {
            btnText: '削除する',
            btnTheme: 'red',
            btnAction: payload.deleteAction,
          },
          {
            btnText: '戻る',
            btnTheme: 'white',
          },
        ],
      };
    },
    showCustomModal: (state, { payload }) => {
      state.globalModalConfig = payload;
    },
    reset: () => initialState,
    openFileUploadModal: (state, { payload }) => {
      state.fileUploadModalVisible = true;
      state.screenType = payload;
    },
    closeFileUploadModal: state => ({
      ...state,
      checkResult: [],
      registerEnable: false,
      successCount: 0,
      errorCount: 0,
      fileUploadModalVisible: false,
    }),
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setMultiLoading: (state, { payload }) => {
      state.multiLoading = payload;
    },
    setPrimaryLoading: (state, { payload }) => {
      state.primaryLoading = payload;
    },
    loadingOff: state => {
      state.loading = false;
      state.multiLoading = false;
      state.primaryLoading = false;
    },
    openPreviewModal: (state, { payload }) => {
      state.previewModalVisible = true;
      state.previewModalContents = payload;
    },
    closePreviewModal: state => ({
      ...state,
      previewModalVisible: false,
      previewModalContents: initialState.previewModalContents,
    }),
  },
  extraReducers: {
    [csvCheck.fulfilled]: (state, { payload }) => ({
      ...state,
      checkResult: payload?.item ?? [],
      registerEnable: payload?.success_count > 0,
      successCount: payload?.success_count ?? 0,
      errorCount: payload?.error_count ?? 0,
    }),
  },
});

export const globalActions = globalSlice.actions;
export default globalSlice.reducer;
