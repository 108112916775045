import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState :{
    loadingState: false,
    multiLoadingFlg: false,
    subText: null,
  },
  reducers: {
    multiLoadingOn : (state, { payload }) =>({
      ...state,
      loadingState : true,
      multiLoadingFlg : true,
      subText: payload?.subText ?? null,
    }),
    multiLoadingOff : state =>({
      ...state,
      loadingState : false,
      multiLoadingFlg : false,
      subText: null,
    }),
    loadingOn : (state, { payload }) =>({
      ...state,
      loadingState : true,
      subText: payload?.subText ?? null,
    }),
    loadingOff : (state) =>({
      ...state,
      loadingState : false,
      subText: null,
    }),
  },
});

export const actions = loadingSlice.actions;

export default loadingSlice.reducer;
