import { memo } from 'react';
import { Input } from 'antd';
import {
  compareMemoObject,
  compareMemoArray,
  getFormikArrayProps,
} from '../../../utils/fnUtil';
import Icons from '../../../constants/Icons';
import classNames from 'classnames';
import './style.scss';

const PasswordBox = memo(
  ({
    className,
    disabled = false,
    displayValue,
    fieldName,
    flexEnd,
    formik,
    isBold,
    isRequired = false,
    label,
    listFieldName = null,
    placeholder,
    prefix,
    preLabel,
    suffix,
    uneditable,
    visibilityToggle = true,
    width = 350,
  }) => {
    const wrapperClassName = classNames('tc-input-password-wrapper', className);
    const labelClassName = classNames('tc-input-password-label', {
      'label-bold': isBold,
      'label-disabled': disabled,
      'label-required': isRequired,
    });
    const preLabelClassName = classNames('tc-input-password-preLabel', {
      'label-disabled': disabled,
      'label-required': isRequired && !disabled,
      'label-required-disabled': isRequired && disabled,
    });
    const prefixClassName = classNames('prefix', {
      'label-disabled': disabled,
    });
    const suffixClassName = classNames('suffix', {
      'label-disabled': disabled,
    });
    const labelAreaClassName = classNames('tc-input-password-label-area', {
      'flex-end': !!preLabel || flexEnd,
    });

    const defultPlaceHolder = disabled
      ? ''
      : placeholder ?? `${label || preLabel || ''}を入力してください`;

    const formikArrayProps = listFieldName
      ? getFormikArrayProps(formik, listFieldName)
      : null;

    fieldName = listFieldName ? listFieldName : fieldName;

    return (
      <div className={wrapperClassName} style={{ width }}>
        <div className={labelAreaClassName}>
          {label && <label className={labelClassName}>{label}</label>}
          {!disabled && (
            <span className="tc-input-password-error">
              {!!formikArrayProps
                ? formikArrayProps.touched && formikArrayProps.error
                : formik.touched[fieldName] && formik.errors[fieldName]}
            </span>
          )}
        </div>
        <div className="tc-input-password-line">
          {preLabel && <span className={preLabelClassName}>{preLabel}</span>}
          {prefix && <span className={prefixClassName}>{prefix}</span>}
          {uneditable ? (
            <span className="uneditable">
              {displayValue ?? formik.values[fieldName]}
            </span>
          ) : (
            <Input.Password
              placeholder={defultPlaceHolder}
              {...formik.getFieldProps(fieldName)}
              visibilityToggle={visibilityToggle}
              autoComplete="new-password"
              disabled={disabled}
              iconRender={visible =>
                disabled ? null : visible ? (
                  <Icons.IconEye />
                ) : (
                  <Icons.IconEyeOff />
                )
              }
            />
          )}
          {suffix && <span className={suffixClassName}>{suffix}</span>}
        </div>
      </div>
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, [
      'fieldName',
      'fromFieldName',
      'toFieldName',
    ]) &&
    pre.disabled === next.disabled &&
    pre.fieldName === next.fieldName &&
    (pre.listFieldName ? compareMemoArray(pre, next) : true)
);

export default PasswordBox;
