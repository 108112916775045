import React, { useState, useEffect, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Slider } from 'antd';
import { fetchHeatmapList, heatmapActions } from '../../slices/heatmapSlice';
import { globalActions } from '../../slices/globalSlice';
import Icons from '../../constants/Icons';
import Map from '../../components/map';
import TcButton from '../../components/tcButton';
import DataNone from '../../components/dataNone';
import PageTitle from '../../components/pageTitle';
import Yup from '../../utils/yupUtil';
import './style.scss';
import { dateFormat } from '../../utils/fnUtil';
import InputContainer from '../../components/inputContainer';
import DatePickerWrapper from '../../components/util/datePickerWrapper';
import CustomNumberPicker from '../../components/util/customNumberPicker';

const Heatmap = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const [sliderPlay, setSliderPlay] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [updateSlider, setUpdateSlider] = useState(false);

  const { searchParams, resultList, center, errorMessage } = useSelector(
    state => state.heatmap
  );

  const formik = useFormik({
    initialValues: searchParams,
    validationSchema: Yup.object({
      search_date: Yup.string()
        .nullable()
        .test(
          'search_date',
          '日時を選択してください',
          (value, testContext) =>
            value &&
            testContext.parent.start_time &&
            testContext.parent.end_time
        ),
    }),
    onSubmit: async values => {
      // 掲載期間前後チェック
      const startTime = Number(start_time);
      const endTime = Number(end_time);

      if (startTime > endTime) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              '表示範囲開始時間は表示範囲終了時間より前の時間を入力してください',
          })
        );
        return;
      }

      dispatch(
        heatmapActions.saveSearchParams({
          ...values,
          search_date: dateFormat(search_date, 'YYYY/MM/DD'),
          start_time: startTime,
          end_time: endTime,
        })
      );
    },
  });

  useUpdateEffect(
    () => dispatch(fetchHeatmapList(searchParams)),
    [searchParams]
  );

  useUpdateEffect(() => {
    if (errorMessage) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: errorMessage,
        })
      );
      dispatch(heatmapActions.hiddenErrorMessage());
    }
  }, [errorMessage]);

  useUpdateEffect(() => {
    setSliderPlay(false);
    setSliderValue(0);
  }, [resultList]);

  useEffect(() => {
    // 停止中の場合、終了
    if (!sliderPlay) return;

    // 再生中の場合、1秒ごとにスライダーを動かす
    if (sliderValue === resultList.length - 1) setSliderValue(0);
    const interval = setInterval(() => {
      setUpdateSlider(true);
    }, 1000);
    return () => interval && clearInterval(interval);
  }, [sliderPlay]);

  useEffect(() => {
    if (updateSlider) {
      setUpdateSlider(false);
      const newSliderValue = sliderValue + 1;
      setSliderValue(newSliderValue);
      if (newSliderValue === resultList.length - 1) {
        setSliderPlay(false);
      }
    }
  }, [updateSlider]);

  useEffect(() => {
    return () => dispatch(heatmapActions.clear());
  }, [dispatch]);

  const { search_date, start_time, end_time } = formik.values;

  const dataNone = !resultList || resultList?.length === 0;

  return (
    <div className="content-body management-page heatmap-page" ref={parent}>
      <PageTitle
        title="反応マップ"
        icon={<Icons.IconBarChart className="heatmap-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={2500}>
          <div className="form-container">
            <div className="tc-input-info">
              <label className="tc-input-label">表示範囲</label>
              <span className="tc-input-error">
                {formik.touched.search_date && formik.errors.search_date}
              </span>
            </div>
            <div className="form-line">
              <DatePickerWrapper
                formik={formik}
                fieldName="search_date"
                width={150}
                hasLabel={false}
              />
              <CustomNumberPicker
                fieldName="start_time"
                zeroPadding={true}
                formik={formik}
                from={0}
                to={23}
                hasLabel={false}
                style={{ marginLeft: 24 }}
              />
              <span className="suffix">時</span>
              <span className="range">〜</span>
              <CustomNumberPicker
                fieldName="end_time"
                zeroPadding={true}
                formik={formik}
                from={0}
                to={23}
                hasLabel={false}
                style={{ marginLeft: 24 }}
              />
              <span className="suffix">時</span>
            </div>
          </div>
          <TcButton
            text={'表示'}
            disabled={!search_date || !start_time || !end_time}
            onClick={formik.handleSubmit}
            style={{ margin: 0 }}
          />
          <div className="slider-area">
            {sliderPlay ? (
              <Icons.IconStop
                disabled={dataNone}
                className={'play-icon'}
                onClick={() => resultList?.length > 0 && setSliderPlay(false)}
                style={{ cursor: dataNone ? 'not-allowed' : 'pointer' }}
              />
            ) : (
              <Icons.IconPlay
                disabled={dataNone}
                className={'play-icon'}
                onClick={() => resultList?.length > 0 && setSliderPlay(true)}
                style={{ cursor: dataNone ? 'not-allowed' : 'pointer' }}
              />
            )}
            <Slider
              disabled={dataNone}
              className="slider"
              min={0}
              max={resultList?.length - 1 || 0}
              value={sliderValue}
              tipFormatter={
                dataNone ? null : v => resultList?.[v]?.summary_date
              }
              onChange={v => setSliderValue(v)}
            />
          </div>
        </InputContainer>
      </div>
      <div className="map-area">
        {!dataNone ? (
          <Map
            data={resultList?.[sliderValue]?.location_summary_list}
            center={center}
          />
        ) : (
          <div className="no-data">
            {!resultList ? '表示範囲を指定してください' : <DataNone />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Heatmap;
