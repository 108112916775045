import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { lineAccountActions, fetchLineAccountCreate, fetchLineAccountUpdate } from '../../slices/lineAccountSlice';
import { mapValues } from 'lodash';
import ModalTitle from '../modalTitle';
import Button from '../tcButton';
import Yup from '../../utils/yupUtil';
import './style.scss';
import TextBox from '../util/textBox';

/* LINE公式アカウント登録/更新モーダル */

const LineAccountModal = () => {
  const dispatch = useDispatch();

  const { editModalVisible, currentLineAccount } = useSelector(state => state.lineAccount);

  const isUpdate = currentLineAccount;

  const initialValues = {
    account_code: null,
    account_name: null,
    provider_id: null,
    channel_id: null,
    channel_access_token: null,
  };

  const yupObject = Yup.object({
    account_name: Yup.string().nullable().required(),
    provider_id: Yup.string().nullable().required(),
    channel_id: Yup.string().nullable().required(),
    channel_access_token: Yup.string().nullable().required(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: yupObject,
    onSubmit: async values =>
      dispatch(isUpdate ? fetchLineAccountUpdate(values) : fetchLineAccountCreate(values)),
  });

  const { account_code } = formik.values;

  useUpdateEffect(() => {
    currentLineAccount &&
      formik
        .setValues({
          ...currentLineAccount,
        })
        .then(() => formik.setTouched(mapValues(yupObject, () => true)))
        .then(() => formik.validateForm());
  }, [currentLineAccount]);

  useUpdateEffect(() => {
    !editModalVisible && formik.resetForm();
    formik.validateForm();
  }, [editModalVisible]);

  const onBack = () => {
    dispatch(lineAccountActions.closeEditModal());
    formik.resetForm();
  };

  return (
    <Modal
      className={'line-account-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={isUpdate ? 'LINE公式アカウント編集' : 'LINE公式アカウント登録'}
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          {isUpdate && (
            <div className="text-area">
              <label>公式アカウントCD</label>
              <span>{account_code}</span>
            </div>
          )}
          <TextBox
            formik={formik}
            preLabel="公式アカウント名"
            fieldName="account_name"
            width={'100%'}
            isRequired
          />
          <TextBox
            formik={formik}
            preLabel="プロバイダID"
            fieldName="provider_id"
            width={'100%'}
            uneditable={!!isUpdate}
            isRequired={!isUpdate}
          />
          <TextBox
            formik={formik}
            preLabel="チャネルID"
            fieldName="channel_id"
            width={'100%'}
            uneditable={!!isUpdate}
            isRequired={!isUpdate}
          />
          <TextBox
            formik={formik}
            preLabel="チャネルアクセストークン"
            fieldName="channel_access_token"
            width={'100%'}
            isRequired
          />
          <div className="button-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            <Button
              text={isUpdate ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LineAccountModal;
