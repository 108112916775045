import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import { Modal } from 'antd';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import { gachaActions } from '../../slices/gachaSlice';
import { thousandsSeparator } from '../../utils/fnUtil';
const GachaResultModal = () => {
  const dispatch = useDispatch();
  const { applicant } = useSelector(state => state.gacha);
  const onBack = () => dispatch(gachaActions.closeResultModal());

  const initialValues = {
    applicant_count: 0,
    rank_list: [],
    lottery_method: 0,

  };

  const { applicant_count, rank_list, lottery_method } = applicant || initialValues;

  return (
    <Modal
      className={'gacha-result-modal tc-modal'}
      visible={!!applicant}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title="ガチャ結果" />
        <div className="input-area">
          <div className="result-area">
            <span className="pre-label">参加人数</span>
            <span className="font-sm">
              {thousandsSeparator(applicant_count)}
            </span>
            <span className="space-left-small">人</span>
          </div>
          <table>
            <tbody>
              {rank_list.map(v => (
                <tr>
                  {v.rank != '9' ? (
                      <td className="pre-label">{v.rank}等</td>
                  ):(
                      <td className="pre-label">ハズレ</td>
                  )}
                  {((!!v.winner_probability || v.winner_probability == 0 || v.rank == '9') &&
                      lottery_method === 1) && (
                      <td>
                        設定当選確率：
                        <span className="font-sm">
                          {v.rank == '9' ? '-' :`${thousandsSeparator(v.winner_probability)}%　`}
                        </span>
                      </td>
                  )}
                  {((!!v.max_winner_count || v.rank == '9') && lottery_method === 0) && (
                    <td>
                      設定当選数：
                      <span className="font-sm">
                        {v.rank == '9' ? ' - ' : `${thousandsSeparator(v.max_winner_count)}本`}
                      </span>
                    </td>
                  )}
                  <td className={(!!v.max_winner_count || v.rank == '9') && 'space-left'}>
                    当選数：
                    <span className="font-sm">
                      {`${thousandsSeparator(v.winner_count)}本`}
                    </span>
                  </td>
                  {((!!v.remaining_count || v.rank == '9') && lottery_method === 0) && (
                    <td className="space-left">
                      残り本数：
                      <span className="font-sm">
                        {v.rank == '9' ? '-' : `${thousandsSeparator(v.remaining_count)}本`}
                      </span>
                    </td>
                  )}
                  <td className="space-left">
                    {!!v.point ? (
                      <>
                        ポイント数：
                        <span className="font-sm">{`${v.point}pt`}</span>
                      </>
                    ) : (
                      <>
                        スタンプ数：
                        <span className="font-sm">
                          {`${thousandsSeparator(v.stamp_count)}個`}
                        </span>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="button-container">
            <TcButton text="閉じる" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GachaResultModal;
