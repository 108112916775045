import React, { useEffect, useRef, useState } from 'react';
import {
  enqueteActions,
  fetchEnqueteCouponList,
  fetchQuestionSummary,
} from '../../slices/enqueteSlice';
import {
  fetchCategoryList,
} from '../../slices/categorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Modal, Table } from 'antd';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import moment from 'moment';
import {
  ageTypes,
  answerTypeOptions,
  flagOptions,
  flagOptions2,
  flagOptions3,
  genderOptions5,
  graphTypeOptions,
  prefectures,
  summaryFlagTypes,
  summaryTypeOptions,
} from '../../constants/options';
import { CATEGORY_TYPE } from '../../constants/enums';
import './style.scss';
import ChartFactory from '../chartFactory';
import ChartModal from './chart';
import Icons from '../../constants/Icons';
import { getOptionLabel } from '../../utils/fnUtil';
import ChartLegend from '../chartFactory/legend';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import TcCheckbox from '../tcCheckbox';

const EnqueteReferenceModal = () => {
  const dispatch = useDispatch();
  const {
    referenceEnquete,
    couponList,
    summaryEnquete,
    summaryList,
    chartData,
  } = useSelector(state => state.enquete);

  const {
    accountInfo: { new_contents_flag },
  } = useSelector(state => state.account);

  const { categoryList } = useSelector(state => state.category);

  const initialQuestion = {
    image_url: null,
    question_name: null,
    required_flag: '1',
    answer_type: '0',
    answer_list: [{ answer_text: null }],
    answer_result_display_flag: '1',
    graph_type: '0',
    summary_flag: {
      summary_sex_flag: '1',
      summary_age_flag: '0',
      summary_address_flag: '0',
      summary_favorite_shop_flag: '0',
    },
  };

  const initialValues = {
    enquete_code: null,
    category_code: [],
    enquete_name: '',
    enquete_contents: null,
    rich_enquete_contents: null,
    banner_image_url: null,
    public_start_date: null,
    public_start_hour: null,
    public_start_minute: null,
    public_end_date: null,
    public_end_hour: null,
    public_end_minute: null,
    fixed_phrase: null,
    coupon_code: null,
    notice_flag: '1',
    question_list: [initialQuestion],
  };

  const {
    enquete_code,
    category_code,
    enquete_name,
    enquete_contents,
    rich_enquete_contents,
    banner_image_url,
    fixed_phrase,
    public_start_date,
    public_end_date,
    coupon_code,
    notice_flag,
    question_list,
  } = referenceEnquete || summaryEnquete || initialValues;

  const answer_type_flag = 3;

  const [modalClosable, setModalClosable] = useState(true);

  const ref = useRef(null);

  const modalVisible = !!referenceEnquete || !!summaryEnquete;

  const onBack = () => dispatch(enqueteActions.closeReferenceModal());

  const dateToString = date => (
    <div className="font-sm">
      <span>{moment(date)?.format('YYYY/M/D HH:mm')}</span>
    </div>
  );

  const getChartData = answer_list =>
    answer_list.reduce(
      (acc, answer) => [...acc, [answer.answer_text, answer.summary_count]],
      [['answer_text', 'summary_count']]
    );

  const getSummaryType = value =>
    summaryTypeOptions.find(v => v.label === summaryFlagTypes[value]).value;

  const summaryLabelFactory = (summaryType, value) => {
    switch (summaryType) {
      case '00':
        return value;
      case '01':
        return getOptionLabel(ageTypes, value);
      case '02':
        return getOptionLabel(genderOptions5, value === '00' ? '0' : value);
      case '03':
        return getOptionLabel(prefectures, value) ?? '未登録';
      case '04':
        return value === '00' ? '未登録' : value;
      default:
        return null;
    }
  };

  const getTableColumns = (summary, graph_type) => [
    {
      width: '160px',
      className: 'answer-column',
      dataIndex: 'summary_unit',
      render: current => {
        return (
          <div className="summary-area">
            <span>{summaryLabelFactory(summary.summary_type, current)}</span>
            <Icons.IconGraph
              className="btn-icon"
              onClick={() => {
                if (!modalClosable) return;
                const rect = ref.current.getBoundingClientRect();
                const answers = summary.answer_list_summary.find(
                  v => v.summary_unit === current
                ).answer_list;

                const data = [
                  ...summary.answer_list_total
                    .filter(
                      answer =>
                        !answers.some(a => a.answer_code === answer.answer_code)
                    )
                    .map(v => ({
                      ...v,
                      summary_count: 0,
                    })),
                  ...answers,
                ].sort(
                  (v1, v2) => Number(v1.answer_code) - Number(v2.answer_code)
                );

                dispatch(
                  enqueteActions.openChartModal({
                    graph_type: graph_type,
                    data: getChartData(data),
                    scrollTop: ref.current.scrollTop,
                    x: rect.width,
                    y: rect.y,
                  })
                );
              }}
            />
          </div>
        );
      },
    },
    ...summary.answer_list_total.map((answer, i) =>
      i < summary.answer_list_total.length - 1
        ? {
            title: answer.answer_text,
            width: '160px',
            dataIndex: answer.answer_code,
            className: 'answer-column',
            render: count =>
              count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          }
        : {
            title: answer.answer_text,
            dataIndex: answer.answer_code,
            className: 'answer-column',
            render: count =>
              count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          }
    ),
  ];

  const getDataSource = summary => {
    const initial = summary.answer_list_total.reduce(
      (acc, item) => ({
        ...acc,
        [item.answer_code]: 0,
      }),
      {}
    );

    return summary.answer_list_summary.reduce(
      (acc, sum) => [
        ...acc,
        sum.answer_list.reduce(
          (acc, answer) => ({
            ...acc,
            [answer.answer_code]: answer.summary_count,
          }),
          {
            summary_unit: sum.summary_unit,
            ...initial,
          }
        ),
      ],
      []
    );
  };

  useEffect(() => setModalClosable(!chartData), [chartData]);

  useEffect(
    () =>
      modalVisible &&
      dispatch(fetchCategoryList({ category_type: CATEGORY_TYPE.ENQUETE})) &&
      dispatch(fetchEnqueteCouponList()),
    [modalVisible]
  );

  useEffect(
    () =>
      summaryEnquete &&
      summaryEnquete.question_list
        ?.filter(question => Number(question.answer_type) < 3)
        .map(async question => {
          const list = Object.entries(question.summary_flag).find(
            ([_, v]) => !!Number(v)
          );

          const type = !!list ? getSummaryType(list[0]) : '00';

          dispatch(
            fetchQuestionSummary({
              enquete_code: summaryEnquete.enquete_code,
              question_code: question.question_code,
              summary_type: type,
            })
          );
        }),
    [summaryEnquete]
  );

  return (
    <Modal
      className={'enquete-reference-modal tc-modal'}
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={modalClosable}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={referenceEnquete ? 'アンケート参照' : 'アンケート結果'}
        />
        <div className="input-area" ref={ref}>
          <div className="check-area">
            <span className={'pre-label'}>アンケートCD</span>
            <div className="font-sm">{enquete_code}</div>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>アンケートカテゴリ</span>
            <div className="font-sm">
              {' '}
              {!!category_code.length
                ? category_code
                    .map((c, i) => getOptionLabel(categoryList.map(d => ({
                      label: d.category_name,
                      value: d.category_code
                    })), c))
                    .join(',')
                : '-'}
            </div>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>アンケート名</span>
            <div className="font-sm">{enquete_name}</div>
          </div>
          <div className="check-area">
            <span className={'pre-label editor-detail-title'}>
              アンケート説明
            </span>
            {new_contents_flag !== 0 ? (
              <div className="text-area" style={{ flexGrow: 1 }}>
                <span className="editor-detail">
                  <RichTextEditor
                    readOnly={true}
                    editorState={toSlateState(rich_enquete_contents)}
                  />
                </span>
              </div>
            ) : (
              <div className="font-sm">
                {!!enquete_contents ? enquete_contents : '-'}
              </div>
            )}
          </div>
          <div className="image-area">
            <span className={'pre-label'}>画像</span>
            <div className="image-select">
              {!!banner_image_url && (
                <Image
                  src={banner_image_url}
                  width={150}
                  height={150}
                  preview={false}
                />
              )}
            </div>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>定型文</span>
            <div className="font-sm">{!!fixed_phrase ? fixed_phrase : '-'}</div>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>アンケート期間</span>
            {dateToString(public_start_date)}
            <span style={{ marginLeft: '10px', marginRight: '10px' }}>〜</span>
            {dateToString(public_end_date)}
          </div>
          <div className="check-area">
            <span className={'pre-label'}>クーポン</span>
            <div className="font-sm">
              {!!coupon_code && !!couponList?.length
                ? couponList
                    .filter(coupon => coupon.value === coupon_code)
                    .map(coupon => (
                      <>
                        <span>{coupon.value}</span>
                        <span className="space-left">{coupon.label}</span>
                      </>
                    ))
                : '-'}
            </div>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>プッシュ配信通知</span>
            <div className="font-sm">
              {getOptionLabel(flagOptions, notice_flag)}
            </div>
          </div>

          {question_list?.map((_, i) => (
            <div className="question-border">
              <div className="question-input-area">
                <div className="image-area">
                  <span className={'pre-label'}>画像</span>
                  <div className="image-select">
                    {!!question_list[i].image_url && (
                      <Image
                        src={question_list[i].image_url}
                        width={150}
                        height={150}
                        preview={false}
                      />
                    )}
                  </div>
                </div>

                <div className="check-area">
                  <span className={'pre-label'}>設問名</span>
                  <div className="font-sm">
                    {question_list[i].question_name}
                  </div>
                </div>

                <span className="check-area">
                  <span className="pre-label">回答必須</span>
                  <div className="font-sm">
                    {getOptionLabel(
                      flagOptions2,
                      question_list[i].required_flag
                    )}
                  </div>
                </span>

                <span className="check-area">
                  <span className="pre-label">回答区分</span>
                  <div className="font-sm">
                    {getOptionLabel(
                      answerTypeOptions,
                      question_list[i].answer_type
                    )}
                  </div>
                </span>

                {question_list[i].answer_type < answer_type_flag && (
                  <>
                    <span className="answer-area">
                      <span className="pre-label">回答選択肢</span>

                      <div className="answer-area-item">
                        {question_list[i].answer_list
                          .map((v, i) => v.answer_text)
                          .join(',')}
                      </div>
                    </span>

                    <span className="check-area">
                      <span className="pre-label">回答結果表示方法</span>
                      <div className="font-sm">
                        {getOptionLabel(
                          flagOptions3,
                          question_list[i].answer_result_display_flag
                        )}
                      </div>
                    </span>
                    <span className="check-area">
                      <span className="pre-label">グラフタイプ</span>
                      <div className="font-sm">
                        {getOptionLabel(
                          graphTypeOptions,
                          question_list[i].graph_type
                        )}
                      </div>
                    </span>
                  </>
                )}

                {referenceEnquete &&
                  question_list[i].answer_type < answer_type_flag && (
                    <span className="check-area">
                      <span className="pre-label">集計処理</span>
                      <div className="font-sm">
                        {Object.entries(question_list[i].summary_flag)
                          .filter(([_, v]) => !!Number(v))
                          .map(([k, _]) => summaryFlagTypes[k])
                          .join(',')}
                      </div>
                    </span>
                  )}

                {summaryEnquete &&
                  (question_list[i].answer_type < answer_type_flag ? (
                    !!summaryList?.length &&
                    summaryList
                      .filter(
                        v => v.question_code === question_list[i].question_code
                      )
                      .map(summary =>
                        summary.answer_list_total.some(
                          v => !!v.summary_count
                        ) ? (
                          <>
                            <div className="graph-area">
                              <span className={'pre-label'}>全体グラフ</span>
                              <div className="chart-area">
                                <ChartFactory
                                  type={question_list[i].graph_type}
                                  data={getChartData(summary.answer_list_total)}
                                />
                              </div>
                            </div>
                            <span className="check-area">
                              <span className="pre-label">集計処理</span>
                              <span className="option-list">
                                {Object.entries(question_list[i].summary_flag)
                                  .filter(([_, v]) => !!Number(v))
                                  .map(([k, _], i) => (
                                    <TcCheckbox
                                      label={summaryFlagTypes[k]}
                                      isRadio={true}
                                      className={i > 0 ? 'radio-left' : ''}
                                      checked={
                                        getSummaryType(k) ===
                                        summary.summary_type
                                      }
                                      onChange={() => {
                                        dispatch(
                                          fetchQuestionSummary({
                                            enquete_code:
                                              summaryEnquete.enquete_code,
                                            question_code:
                                              summary.question_code,
                                            summary_type: getSummaryType(k),
                                          })
                                        );
                                      }}
                                    />
                                  ))}
                              </span>
                            </span>

                            <div className="table-area">
                              <Table
                                columns={getTableColumns(
                                  summary,
                                  question_list[i]?.graph_type
                                )}
                                dataSource={getDataSource(summary)}
                                pagination={false}
                                scroll={{ x: true }}
                              />
                            </div>
                          </>
                        ) : (
                          <span className="check-area">
                            <span className="pre-label">集計処理</span>
                            <div className="font-sm">
                              {Object.entries(question_list[i].summary_flag)
                                .filter(([_, v]) => !!Number(v))
                                .map(([k, _]) => summaryFlagTypes[k])
                                .join(',')}
                            </div>
                          </span>
                        )
                      )
                  ) : (
                    <span className="chart-legend-area">
                      <span className="pre-label"></span>
                      <div className="chart-legend">
                        <ChartLegend
                          data={summaryEnquete.question_list[i].answer_list.map(
                            v => [v.answer_text, v.summary_count]
                          )}
                          width={'auto'}
                        />
                      </div>
                    </span>
                  ))}
              </div>
            </div>
          ))}

          <div className="button-container">
            <TcButton text="閉じる" theme="white" onClick={onBack} />
          </div>
          {summaryEnquete && <ChartModal />}
        </div>
      </div>
    </Modal>
  );
};

export default EnqueteReferenceModal;
