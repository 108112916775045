import axios from '../utils/axiosUtil';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import { accountActions } from './accountSlice';

const initialSearchParams = {
  brand_code: '',
  brand_name: null,
  category_code: [],
  offset: 0,
  count: 10,
  sort: 'brand_code_desc',
  deleted_flag: 0,
};

const initialState = {
  searchParams: initialSearchParams,
  brandList: [],
  total: 0,
  brandModalVisible: false,
  total_count: null,
  active_count: null,
};

export const fetchBrandList = createAsyncThunk(
  'fetchBrandList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('/brands/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchBrandDetail = createAsyncThunk(
  'fetchBrandDetail',
  async params => await axios.get('/brands/detail', { params })
);

export const updateBrand = createAsyncThunk(
  'updateBrand',
  async (params, { dispatch, getState }) => {
    await axios.post(
      `/brands/${params.isCreate ? 'create' : 'update'}`,
      params
    );

    dispatch(fetchBrandList(getState().brandManagement.searchParams));

    dispatch(accountActions.setRefresh());

    dispatch(
      globalActions.showSingleModal(
        `${params.isCreate ? '登録' : '更新'}が完了しました`
      )
    );
  }
);
export const deleteBrand = createAsyncThunk(
  'deleteBrand',
  async (params, { dispatch, getState }) => {
    await axios.post('/brands/delete', params);

    dispatch(fetchBrandList(getState().brandManagement.searchParams));

    dispatch(accountActions.setRefresh());
  }
);

export const brandManagementSlice = createSlice({
  name: 'brandManagement',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    setBrandModalVisible: (state, { payload }) => {
      state.brandModalVisible = payload;
    },
    resetBrandModal: state => {
      state.brandModalVisible = false;
      state.brandDetail = null;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [fetchBrandList.fulfilled]: (state, { payload }) => {
      state.brandList = (payload.item ?? []).map(brand => ({
        ...brand,
        category_name: !!brand.category?.length
        ? brand.category?.length > 1
          ? `${brand.category[0].category_name}\r\n他${
            brand.category.length - 1
            }カテゴリ`
          : `${brand.category[0].category_name}`
        : '-',
      }));
      state.total = payload.search_count;
      state.total_count = payload.total_count;
      state.active_count = payload.active_count;
    },
    [fetchBrandDetail.fulfilled]: (state, { payload }) => {
      state.brandDetail = payload.item ?? {};
      state.brandModalVisible = true;
    },
    [updateBrand.fulfilled]: state => {
      state.brandModalVisible = false;
    },
  },
});

export const brandManagementActions = brandManagementSlice.actions;
export default brandManagementSlice.reducer;
