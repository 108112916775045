import React, { useState, useMemo, useCallback } from 'react';
import { Leaf } from './elements/leaf';
import { createEditor } from 'slate';
import { Element } from './elements/element';
import { withHistory } from 'slate-history';
import { withLink } from './plugins/withLink';
import { withImage } from './plugins/withImage';
import { withHtml } from './plugins/withHtml';
import { getAvailableCount } from './utils/getData';
import { Slate, Editable, withReact } from 'slate-react';
import TcCheckbox from '../tcCheckbox';

import EditorToolbar from './editorToolBar';
import pipe from 'lodash/fp/pipe';
import './style.scss';

const createEditorWithPlugins = pipe(
  withHtml,
  withReact,
  withHistory,
  withImage,
  withLink
);

const RichTextEditor = ({
  editorState = [
    {
      type: 'content',
      children: [{ text: '' }],
    },
  ],
  onChange,
  error,
  articleCode,
  readOnly = false,
  setTouched,
  userCode,
  contentImageBlock,
  extraUpload,
}) => {
  const editor = useMemo(() => createEditorWithPlugins(createEditor()), []);

  const [deviceOption, setDeviceOption] = useState('0');
  const renderElement = useCallback(
    props => (
      <Element
        {...{
          ...props,
          contentImageBlock,
          extraUpload,
          element: {
            ...props.element,
            deviceMode: deviceOption === '1',
            articleCode,
            userCode,
          },
        }}
      />
    ),
    [deviceOption, articleCode]
  );
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return (
    <>
      <div className={'rich-text-editor-title'}>
        <span>
          <span className="validation-error">{error}</span>
        </span>
        <TcCheckbox
          className="device-mode"
          label={'スマホ幅で表示'}
          checked={deviceOption === '1'}
          onChange={v => setDeviceOption(v.target.checked ? '1' : 0)}
        />
      </div>
      <div className="rich-text-editor-area">
        <Slate
          editor={editor}
          value={editorState}
          onChange={onChange}
          initialValue={editorState}
        >
          {!readOnly && (
            <EditorToolbar
              deviceMode={deviceOption === '1'}
              availableCount={getAvailableCount(editorState)}
            />
          )}
          <Editable
            onBlur={() => {
              setTouched();
              editor.blurSelection = editor.selection;
            }}
            spellCheck={false}
            readOnly={readOnly}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            style={{
              backgroundColor: 'white',
              height: 450,
              minHeight: 450,
              padding: 20,
              width: deviceOption === '1' ? '416px' : '100%',
              maxWidth: '100%',
              overflowY: 'auto',
            }}
          />
        </Slate>
      </div>
    </>
  );
};

export default RichTextEditor;
