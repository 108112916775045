import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = props => {
  const { accountInfo } = useSelector(state => state.account);

  const isUserAuth = (accountInfo, tagPage) => {
    const pages = {
      topImage: 'top_image_flag',
      contents: 'top_content_flag',
      notification: 'notice_list_flag',
      reserve: 'notice_reserve_list_flag',
      geopush: 'notice_geopush_list_flag',
      coupon: 'coupon_list_flag',
      couponResult: 'coupon_result_flag',
      couponUserResult: 'coupon_user_result_flag',
      stamp: 'stamp_card_flag',
      qr: 'stamp_qr_flag',
      mountUserResult: 'stamp_user_result_flag',
      stampedDetail: 'stamp_result_flag',
      download: 'analysis_download_flag',
      activeUser: 'analysis_active_user_flag',
      heatmap: 'analysis_reaction_map_flag',
      userManagement: 'user_mst_flag',
      brandManagement: 'brand_mst_flag',
      shopManagement: 'shop_mst_flag',
      staffManagement: 'staff_mst_flag',
      enquete: 'enquete_list_flag',
      category: 'category_flag',
      stampRally: 'stamp_rally_card_flag',
      stampRallyUserResult: 'stamp_rally_user_result_flag',
      stampRallyResult: 'stamp_rally_result_flag',
      pointExchangeResult: 'point_exchange_result_flag',
      gacha: 'gacha_list_flag',
      gachaApplicant: 'gacha_applicant_list_flag',
      lottery: 'lottery_list_flag',
      lotteryApplicant: 'lottery_applicant_list_flag',
      lineRichmenu: 'line_richmenu_list_flag',
      lineAccount: 'line_account_list_flag',
      template: 'template_flag',
      name: 'name_flag',
    };

    return (
      tagPage === '' ||
      tagPage === 'top' ||
      tagPage === 'privacy' ||
      accountInfo[pages[tagPage]] === 1
    );
  };

  return isUserAuth(accountInfo, props.path.slice(1)) ? (
    <Outlet {...props} />
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
