import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';

const initialSearchParams = {
  user_code: '',
  stamp_rally_code: '',
  stamp_rally_name: '',
  from_expire_date: null,
  to_expire_date: null,
  from_first_stamp_rally_date: null,
  to_first_stamp_rally_date: null,
  from_last_stamp_rally_date: null,
  to_last_stamp_rally_date: null,
  deleted_flg: 0,
  offset: 0,
  count: 10,
  sort: 'user_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,

  csvModalVisible: false,
  selectColumsModalVisible: false,
  favoriteItem: [],
  stampRallyUserResultList: [],
  showFields: [],
};

export const fetchStampRallyUserResultCsvFavoriteItem = createAsyncThunk(
  'fetchStampRallyUserResultCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchStampRallyUserResultCsvFavoriteItemCreate = createAsyncThunk(
  'fetchStampRallyUserResultCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response.params;
  }
);

export const fetchStampUserResultList = createAsyncThunk(
  'fetchStampUserResultList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const stampRallyUserResultList = await axios.get('/stamp_rally/user', {
      params,
    });

    dispatch(globalActions.setPrimaryLoading(false));

    return stampRallyUserResultList;
  }
);

export const fetchStampRallyUserResultCsvDownload = createAsyncThunk(
  'fetchStampRallyUserResultCsvDownload',
  async (params, { dispatch }) => {
    const result = await axios.get('csv/download', { params });
    if (!!!result) {
      dispatch(globalActions.showCommonErrorModal());
    }
    return result;
  }
);

export const stampRallyUserResultSlice = createSlice({
  name: 'stampRallyUserResultSlice',
  initialState,
  reducers: {
    openCsvDownloadModal: state => ({
      ...state,
      csvModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvModalVisible: false,
    }),
    openSelectColumsModal: state => ({
      ...state,
      selectColumsModalVisible: true,
    }),
    closeSelectColumsModal: state => ({
      ...state,
      selectColumsModalVisible: false,
    }),
    saveShowFields: (state, { payload }) => ({
      ...state,
      showFields: payload,
      selectColumsModalVisible: false,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchStampRallyUserResultCsvFavoriteItem.fulfilled]: (
      state,
      { payload }
    ) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchStampRallyUserResultCsvFavoriteItemCreate.fulfilled]: (
      state,
      { payload }
    ) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchStampRallyUserResultCsvDownload.fulfilled]: (state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `ユーザー×台紙単位実績一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
      ),
    [fetchStampUserResultList.fulfilled]: (state, { payload }) => {
      state.stampRallyUserResultList = payload?.item ?? [];
      state.total = payload.total;
    },
  },
});

export const stampRallyUserResultActions = stampRallyUserResultSlice.actions;

export default stampRallyUserResultSlice.reducer;
