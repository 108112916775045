import axios from '../utils/axiosUtil';
import moment from 'moment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';

const initialSearchParams = {
  user_code: '',
  stamp_code: '',
  stamp_name: '',
  brands: [],
  shops: [],
  from_expire_date: null,
  to_expire_date: null,
  from_first_stamp_date: null,
  to_first_stamp_date: null,
  from_last_stamp_date: null,
  to_last_stamp_date: null,
  offset: 0,
  count: 10,
  sort: 'user_code_desc',
  csv_brands: [''],
  csv_shops: [''],
  csv_date_type: '0',
  csv_from_date: null,
  csv_to_date: null,
};

const initialState = {
  searchParams: initialSearchParams,
  mountUserResultList: [],
  shopList: [],
  showFields: [],
  total: 0,
  csvDownloadModalVisible: false,
  selectColumsVisible: false,
  favoriteItem: [],
};

export const fetchMountUserResultList = createAsyncThunk(
  'fetchMountUserResultList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const stampUserList = await axios.get('/stamp/user', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return stampUserList;
  }
);

export const fetchMountUserResultCsvFavoriteItem = createAsyncThunk(
  'fetchMountUserResultCsvFavoriteItem',
  async () =>
    axios.get('csv/favorite_item/list', { params: { screen_type: '2' } })
);

export const updateMountUserResultCsvFavoriteItem = createAsyncThunk(
  'updateMountUserResultCsvFavoriteItem',
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post(
      'csv/favorite_item/create',
      params
    );
    updateFavoriteRes?.code === '0' &&
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));
    return params.favorite;
  }
);

export const downloadMountUserResult = createAsyncThunk(
  'downloadMountUserResult',
  async (params, { dispatch }) => {
    const result = await axios.get('csv/download', { params });
    if (!!!result) {
      dispatch(globalActions.showCommonErrorModal());
    }
    return result;
  }
);

export const mountUserResultSlice = createSlice({
  name: 'mountUserResultSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      showFields: payload,
    }),
    openCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    openSelectColumsModal: state => ({
      ...state,
      selectColumsVisible: true,
    }),
    closeSelectColumsModal: state => ({
      ...state,
      selectColumsVisible: false,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchMountUserResultList.fulfilled]: (state, { payload }) => {
      state.mountUserResultList =
        payload.item.map(e => ({
          ...e,
          brand_list: Array.from(
            new Map(
              e.brand_list.map(brand => [brand.brand_code, brand])
            ).values()
          ),
        })) ?? [];
      state.total = payload.total_count;
    },
    [fetchMountUserResultCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload?.item?.favorite ?? {};
    },
    [updateMountUserResultCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload;
    },
    [downloadMountUserResult.fulfilled]: (_, { payload }) => {
      if (payload && typeof payload === 'string') {
        downloadFile(
          payload,
          `ユーザー×台紙単位実績一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
        );
      }
    },
  },
});

export const mountUserResultActions = mountUserResultSlice.actions;

export default mountUserResultSlice.reducer;
