const PreviewSVG = ({ imageHeight, richmenuImage, maskBounds = [], chatBarText }) => {
  const baseWidth = 250;
  const baseHeight = 451;
  const menuHeight = (imageHeight ?? 1686) / 10;
  const menuBarHeight = 30;
  const messageAreaHeight = baseHeight - menuHeight - menuBarHeight;
  const menuBarY = baseHeight - menuBarHeight;
  return (
    <svg width={baseWidth} height={baseHeight}>
      <rect width={baseWidth} height={messageAreaHeight} fill={`#8CABD9`} />
      {richmenuImage ? (
        <image href={richmenuImage} width={baseWidth} height={menuHeight} x={0} y={messageAreaHeight} preserveAspectRatio="xMidYMid meet" />
      ) : (
        <rect width={baseWidth} height={menuHeight} y={messageAreaHeight} fill="#FFFFFF" />
      )}
      {maskBounds.map((maskBound, index) => (
        <MaskOverlay
          key={index}
          {...maskBound}
          baseY={messageAreaHeight}
          baseWidth={baseWidth}
          baseHeight={menuHeight}
          actionIndex={index}
        />
      ))}
      <line x1={0} y1={menuBarY} x2={baseWidth} y2={menuBarY} stroke={`#DFDFDF`} strokeWidth="2" />
      <rect width={baseWidth} height={menuBarHeight} y={menuBarY} fill="#FFFFFF" />
      <rect width={baseWidth} height={baseHeight} fill="none" stroke={`#7A96BE`} strokeWidth="1" />
      <text x="50%" y={menuBarY + (menuBarHeight / 2)} dominantBaseline="middle" textAnchor="middle" fill="black" fontSize={`0.7rem`}>{chatBarText}</text>
    </svg>
  );
};

const MaskOverlay = ({ x, y, width, height, baseY, baseWidth, baseHeight, actionIndex }) => {

  const overlayX = (baseWidth * x / 100);
  const overlayY = baseY + (baseHeight * y / 100);
  const overlayWidth = baseWidth * width / 100;
  const overlayHeight = baseHeight * height / 100;
  const centerX = overlayX + (overlayWidth / 2);
  const centerY = overlayY + (overlayHeight / 2);

  return (
    <svg>
      <rect
        x={overlayX}
        y={overlayY}
        width={overlayWidth}
        height={overlayHeight}
        fill="black"
        fillOpacity="0.4"
        stroke="#9B233F"
        strokeWidth={2}
      />
      <text x={centerX} y={centerY} textAnchor="middle" alignmentBaseline="middle" fontSize="medium" fill="white">
        {String.fromCharCode(65 + actionIndex)}
      </text>
    </svg>
  );
};

export default PreviewSVG;
