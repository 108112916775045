import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  fetchMountUserResultList,
  mountUserResultActions,
  fetchMountUserResultCsvFavoriteItem,
  updateMountUserResultCsvFavoriteItem,
  downloadMountUserResult,
} from '../../slices/mountUserResultSlice';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import { mountUserResultFavoriteItemOptions } from '../../constants/options';
import { mountUserResultColumns } from '../../constants/columns';
import Icons from '../../constants/Icons';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import ColumnTitle from '../../components/columnTitle';
import TableTitle from '../../components/tableTitle';
import TcTable from '../../components/tcTable';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import SelectColumsModal from '../../components/modal/selectCloumsModal';
import TcCheckbox from '../../components/tcCheckbox';
import moment from 'moment';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';

const MountUserResult = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountBrandList, accountShopList } = useSelector(
    state => state.account
  );

  const {
    searchParams,
    mountUserResultList,
    total,
    csvDownloadModalVisible,
    selectColumsVisible,
    favoriteItem,
    showFields,
  } = useSelector(state => state.mountUserResult);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      const {
        from_expire_date,
        to_expire_date,
        from_first_stamp_date,
        to_first_stamp_date,
        from_last_stamp_date,
        to_last_stamp_date,
        ...rest
      } = values;
      const params = {
        ...rest,
        from_expire_date: dateFormat(from_expire_date, 'YYYY/MM/DD'),
        to_expire_date: dateFormat(to_expire_date, 'YYYY/MM/DD'),
        from_first_stamp_date: dateFormat(from_first_stamp_date, 'YYYY/MM/DD'),
        to_first_stamp_date: dateFormat(to_first_stamp_date, 'YYYY/MM/DD'),
        from_last_stamp_date: dateFormat(from_last_stamp_date, 'YYYY/MM/DD'),
        to_last_stamp_date: dateFormat(to_last_stamp_date, 'YYYY/MM/DD'),
      };

      dispatch(mountUserResultActions.saveSearchParams(params));
    },
  });

  const {
    brands,
    csv_brands,
    csv_shops,
    csv_date_type,
    csv_from_date,
    csv_to_date,
  } = formik.values;

  const filterCsvShopList = accountShopList.filter(
    s =>
      csv_brands.filter(b => b).length === 0 ||
      csv_brands.includes(s.brand_code)
  );

  useEffect(() => {
    dispatch(fetchMountUserResultCsvFavoriteItem());
    dispatch(
      mountUserResultActions.saveShowFields(
        columns.filter(
          c =>
            !mountUserResultColumns.filter(d => c.key === d.sortField)[0]
              .defaultHiddenFiled
        )
      )
    );
    return () => dispatch(mountUserResultActions.clear());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMountUserResultList(searchParams));
  }, [dispatch, searchParams]);

  const columns = [
    ...mountUserResultColumns.map(col => ({
      key: col.sortField,
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort =>
            dispatch(mountUserResultActions.saveSearchParams({ sort }))
          }
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
  ];

  const displayColumnOptions = [
    ...mountUserResultColumns.map(e => ({
      label: e.title,
      value: e.sortField,
    })),
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <div className="content-title">
        <PageTitle
          title="ユーザー×台紙単位実績一覧"
          icon={<Icons.IconStamp className="mount-icon" />}
          subTitle={[
            ' 発行済みの最新台紙情報が表示されます。最新以外の情報は押印実績明細一覧からご確認ください。',
          ]}
          subTitleClass="mount-page-title-note"
        />
      </div>
      <div className="search-form mount-page-search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="会員番号" fieldName="user_code" />
          <TextBox formik={formik} label="台紙CD" fieldName="stamp_code" />
          <TextBox formik={formik} label="台紙名" fieldName="stamp_name" />
          <SelectBox
            formik={formik}
            label="ブランド"
            fieldName="brands"
            mode="multiple"
            options={accountBrandList}
            extraOnChange={() => formik.setFieldValue('shops', [])}
          />
          <SelectBox
            formik={formik}
            label="店舗"
            fieldName="shops"
            mode="multiple"
            options={accountShopList.filter(
              shop => brands.length === 0 || brands.includes(shop.brand_code)
            )}
          />
          <DateTimeRangePicker
            formik={formik}
            label="最新台紙失効日"
            fromFieldName="from_expire_date"
            toFieldName="to_expire_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="最新台紙初回押印日"
            fromFieldName="from_first_stamp_date"
            toFieldName="to_first_stamp_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="最新台紙最終押印日"
            fromFieldName="from_last_stamp_date"
            toFieldName="to_last_stamp_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickCsvOutput={() => {
          handlerFormikFieldChange(
            formik,
            'csv_from_date',
            csv_from_date || moment().format('YYYY/M/1')
          );
          handlerFormikFieldChange(
            formik,
            'csv_to_date',
            csv_to_date || moment().format('YYYY/M/D')
          );
          dispatch(mountUserResultActions.openCsvDownloadModal());
        }}
        onClickDisplayChange={() =>
          dispatch(mountUserResultActions.openSelectColumsModal())
        }
      />
      <TcTable
        columns={columns.filter(e =>
          showFields.map(s => s.dataIndex).includes(e.dataIndex)
        )}
        dataSource={mountUserResultList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(mountUserResultActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <CsvDownloadModal
        line={5}
        width={820}
        requiredText={null}
        modalVisible={csvDownloadModalVisible}
        options={mountUserResultFavoriteItemOptions}
        favoriteItem={favoriteItem}
        disabled={!(csv_from_date && csv_to_date)}
        searchArea={
          <>
            <div style={{ display: 'flex' }}>
              <SelectBox
                formik={formik}
                label={'ブランド'}
                fieldName={'csv_brands'}
                mode={'multiple'}
                options={[{ label: 'すべて', value: '' }, ...accountBrandList]}
                width={300}
                customOnChange={(v, fieldName) => {
                  const isNoSelect = v.filter(value => value).length === 0;
                  const isLastEmpty = v[v.length - 1] === '';
                  const value =
                    isNoSelect || isLastEmpty ? [''] : v.filter(value => value);
                  handlerFormikFieldChange(formik, fieldName, value);
                  handlerFormikFieldChange(formik, 'csv_shops', ['']);
                }}
                isRequired
              />
              <SelectBox
                formik={formik}
                label={'店舗'}
                fieldName={'csv_shops'}
                mode={'multiple'}
                options={[{ label: 'すべて', value: '' }, ...filterCsvShopList]}
                width={300}
                customOnChange={(v, fieldName) => {
                  const isNoSelect = v.filter(value => value).length === 0;
                  const isLastEmpty = v[v.length - 1] === '';
                  const value =
                    isNoSelect || isLastEmpty ? [''] : v.filter(value => value);
                  handlerFormikFieldChange(formik, fieldName, value);
                }}
                style={{ marginLeft: 24 }}
                isRequired
              />
            </div>
            <div className="csv-modal-warning">
              ※ブランド/店舗で「すべて」を選択された場合、ダウンロードに時間がかかる場合があります。
            </div>
            <div className="csv-modal-date-container">
              <TcCheckbox
                label={'台紙発行日'}
                onChange={() =>
                  handlerFormikFieldChange(formik, 'csv_date_type', '0')
                }
                checked={csv_date_type === '0'}
                isRadio={true}
              />
              <TcCheckbox
                label={'最新台紙失効日'}
                onChange={() =>
                  handlerFormikFieldChange(formik, 'csv_date_type', '1')
                }
                checked={csv_date_type === '1'}
                className="csv-modal-checkbox"
                isRadio={true}
              />
              <DateTimeRangePicker
                formik={formik}
                fromFieldName="csv_from_date"
                toFieldName="csv_to_date"
                hasLabel={false}
                style={{ marginLeft: 24 }}
              />
            </div>
          </>
        }
        onFavoriteItem={checkedList => {
          const param = {
            screen_type: '2',
            favorite: {},
          };
          mountUserResultFavoriteItemOptions
            .filter(item => item)
            .forEach(item => (param.favorite[item.value] = '0'));
          checkedList
            .filter(item => item)
            .forEach(item => (param.favorite[item] = '1'));
          dispatch(updateMountUserResultCsvFavoriteItem(param));
        }}
        onClose={() => dispatch(mountUserResultActions.closeCsvDownloadModal())}
        onDownload={checkedList => {
          const params = {
            brands: csv_brands.filter(v => v),
            shops: csv_shops.filter(v => v),
            [{
              0: 'from_create_at',
              1: 'from_expire_date',
            }[csv_date_type]]: dateFormat(csv_from_date, 'YYYY/MM/DD'),

            [{
              0: 'to_create_at',
              1: 'to_expire_date',
            }[csv_date_type]]: dateFormat(csv_to_date, 'YYYY/MM/DD'),
          };
          dispatch(
            downloadMountUserResult({
              ...params,
              screen_type: '4',
              columns: checkedList,
            })
          );
        }}
      />
      <SelectColumsModal
        title="表示する項目を選択してください"
        modalVisible={selectColumsVisible}
        closeModal={() =>
          dispatch(mountUserResultActions.closeSelectColumsModal())
        }
        options={displayColumnOptions}
        onDisplay={checkedList => {
          dispatch(
            mountUserResultActions.saveShowFields(
              columns.filter(col =>
                checkedList.some(checkedCol => checkedCol === col.key)
              )
            )
          );
        }}
        chekedValues={mountUserResultColumns
          .filter(e => !e.defaultHiddenFiled)
          .map(e => e?.sortField)}
        initialize={false}
      />
    </div>
  );
};

export default MountUserResult;
