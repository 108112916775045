import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Yup from '../../utils/yupUtil';
import { useFormik } from 'formik';
import Modal from 'antd/lib/modal/Modal';
import ModalTitle from '../modalTitle';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import TcButton from '../tcButton';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';
import { templateOptions } from '../../constants/options';
import {
  fetchTemplateCreate,
  fetchTemplateUpdate,
  templateActions,
} from '../../slices/templateSlice';
import './style.scss';

const TemplateModal = () => {
  const dispatch = useDispatch();

  const { editModalVisible, currentTemplate } = useSelector(
    state => state.template
  );

  const { selectOptionList } = useSelector(state => state.name);

  const initialValues = {
    template_type: [],
    template_title: '',
    template_text: '',
  };

  const yupObject = {
    template_type: Yup.array().test(
      'is-required',
      '必須',
      value => value && value.length > 0
    ),
    template_title: Yup.string().nullable().required(),
    template_text: Yup.string().nullable().required(),
  };

  const isCreate = !currentTemplate;

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object(yupObject),
    onSubmit: async values => {
      return isCreate
        ? dispatch(fetchTemplateCreate({ ...values }))
        : dispatch(
            fetchTemplateUpdate({
              template_code: currentTemplate?.template_code,
              ...values,
            })
          );
    },
  });

  const onBack = () => dispatch(templateActions.closeEditModal());

  const setValues = () => {
    formik.setValues({
      ...currentTemplate,
    });
  };

  useEffect(
    () =>
      editModalVisible
        ? currentTemplate
          ? setValues()
          : formik.validateForm()
        : formik.resetForm(),
    [editModalVisible]
  );

  return (
    <Modal
      className={'template-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={isCreate ? '定型文登録' : '定型文編集'}
          tip={'*は必須項目です'}
        />
        <div className="input-area template-modal-area">
          {!isCreate && (
            <div className="code-area">
              <span className={'pre-label'}>定型文CD</span>
              {formik.values['template_code']}
            </div>
          )}
          {isCreate ? (
            <div className="template-modal-area-input">
              <SelectBox
                isRequired={true}
                formik={formik}
                label="定型文種別"
                fieldName={`template_type`}
                placeholder="定型文種別を選択してください"
                options={
                  selectOptionList?.filter(s => s.field_name === '定型文種別')?.[0]
                    ?.select_option ?? []
                }
                width={'100%'}
                mode="multiple"
                extraOnChange={(_, v) =>
                  formik.setFieldValue('template_type', v)
                }
              />
            </div>
          ) : (
            <div className="code-area">
              <span className={'pre-label'}>定型文種別</span>
              {formik?.values?.template_type?.map(type => {
                const matchingOption = templateOptions.find(
                  option => option.value === type
                );
                return <span>{`${matchingOption.label}　`}</span>;
              })}
            </div>
          )}
          <div className="template-modal-area-input">
            <TextBox
              isRequired={true}
              formik={formik}
              label="定型文タイトル"
              fieldName="template_title"
              width={'100%'}
            />
          </div>
          <div className="template-modal-area-text">
            <span className="cus-input-line input-textarea input-fixed-phrase">
              <label className="cus-pre-label">定型文</label>
              {formik.touched.template_text && formik.errors.template_text && (
                <span className="tc-textarea-error">
                  {formik.errors.template_text}
                </span>
              )}
              <span></span>
              <textarea
                placeholder="定型文を入力してください"
                spellCheck={false}
                autoComplete={'false'}
                value={formik?.values?.template_text}
                onChange={e => {
                  handlerFormikFieldChange(
                    formik,
                    'template_text',
                    e.target.value
                  );
                  formik.setFieldTouched('template_text', true);
                }}
                onBlur={() =>
                  !formik.values.template_text &&
                  formik.setFieldTouched('template_text', true)
                }
              />
            </span>
          </div>
          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={isCreate ? '登録' : '更新'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TemplateModal;
