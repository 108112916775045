import { useSelected, useFocused, useSlate } from "slate-react";
import "../style.scss";

const Link = ({ attributes, element, children }) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div className="element-link">
      <a
        {...attributes}
        href={element.url}
        rel="noreferrer"
        onClick={() => window.open(element.url, "_blank")}
      >
        {children}
      </a>
      {/* {selected && focused && (
        <div className="popup" contentEditable={false}>
          <a href={element.url} rel="noreferrer" target="_blank">
            {element.url}
          </a>
          <button onClick={() => {}}></button>
        </div>
      )} */}
    </div>
  );
};

export default Link;
