import axios from '../utils/axiosUtil';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import { accountActions } from './accountSlice';

const initialSearchParams = {
  staff_code: '',
  staff_name: '',
  staff_auth: null,
  shop_code: null,
  brand_code: [],
  deleted_flag: 0,
  offset: 0,
  count: 10,
  sort: 'staff_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  staffList: [],
  total: 0,
  staffModalVisible: false,
  staffReferenceModalVisible: false,
  staffReference: null,
  referrerStaff: null,
  total_count: null,
  active_count: null,
};

export const fetchStaffList = createAsyncThunk(
  'fetchStaffList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('/staff/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchStaffDetail = createAsyncThunk(
  'fetchStaffDetail',
  async params => await axios.get('/staff/detail', { params })
);

export const fetchStaffReference = createAsyncThunk(
  'fetchStaffReference',
  async params => await axios.get('/staff/detail', { params })
);

export const fetchReferrerStaffDetail = createAsyncThunk(
  'fetchReferrerStaffDetail',
  async params => await axios.get('/staff/detail', { params })
);

export const updateStaff = createAsyncThunk(
  'updateStaff',
  async (params, { dispatch, getState }) => {
    await axios.post(`/staff/${params.isCreate ? 'create' : 'update'}`, params);

    dispatch(fetchStaffList(getState().staffManagement.searchParams));

    dispatch(accountActions.setRefresh());

    dispatch(
      globalActions.showSingleModal(
        `${params.isCreate ? '登録' : '更新'}が完了しました`
      )
    );
  }
);

export const deleteStaff = createAsyncThunk(
  'deleteStaff',
  async (params, { dispatch, getState }) => {
    await axios.post('/staff/delete', params);

    dispatch(fetchStaffList(getState().staffManagement.searchParams));
  }
);

export const staffManagementSlice = createSlice({
  name: 'staffManagement',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setStaffModalVisible: (state, { payload }) => {
      state.staffModalVisible = payload;
    },
    resetStaffModal: state => {
      state.staffModalVisible = false;
      state.staffDetail = null;
      state.staffReferenceModalVisible = false;
      state.staffReference = null;
      state.referrerStaff = null;
    },
    clearReferrerStaff: state => ({
      ...state,
      referrerStaff: null,
    }),
    reset: () => initialState,
  },
  extraReducers: {
    [fetchStaffList.fulfilled]: (state, { payload }) => {
      state.staffList = payload.item ?? [];
      state.total = payload.search_count;
      state.total_count = payload.total_count;
      state.active_count = payload.active_count;
    },
    [fetchStaffDetail.fulfilled]: (state, { payload }) => {
      const { brand, tel, img, start_date, end_date, ...rest } =
        payload.item ?? {};
      state.staffDetail = {
        ...rest,
        brand_code: brand?.brand_code,
        tel1: tel?.number1,
        tel2: tel?.number2,
        tel3: tel?.number3,
        use_from_date: start_date,
        use_to_date: end_date,
        profile_image: img,
      };
      state.staffModalVisible = true;
    },
    [fetchStaffReference.fulfilled]: (state, { payload }) => {
      state.staffReference = payload.item ?? {};
      state.staffReferenceModalVisible = true;
    },
    [fetchReferrerStaffDetail.fulfilled]: (state, { payload }) => {
      state.referrerStaff = payload.item;
    },
    [updateStaff.fulfilled]: state => {
      state.staffModalVisible = false;
      state.staffDetail = null;
    },
  },
});

export const staffManagementActions = staffManagementSlice.actions;
export default staffManagementSlice.reducer;
