import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import { giftOptions, gachaGiftTypeOptions } from '../../constants/options';
import './style.scss';
import TcCheckbox from '../tcCheckbox';
import classNames from 'classnames';
import { Input } from 'antd';
import SelectBox from '../util/selectBox';
import TextBox from '../util/textBox';
import CustomNumberPicker from '../util/customNumberPicker';
import TcRadio from '../tcCheckbox/tcRadio';

const InputGift = ({ formik, isUpdate, disabled,lottery_disabled = false, loser_display_number }) => {
  const { couponList } = useSelector(state => state.gacha);
  const { gift_list, lottery_method, trigger ,loser_probability } = formik.values;

  const getLoseProbability = () =>{
      handlerFormikFieldChange(
          formik,
          `loser_probability`,
          gift_list
              .filter(v => v.rank != '9')
              .filter(v => v.is_checked)
              .reduce((acc, gift) => acc - (gift.winner_probability ?? 0), 100)
      )
  }

  useEffect(()=>{
      getLoseProbability();
  },[gift_list])

  const loseInputLine = classNames('input-line', {
    'disabled-theme': !isUpdate,
  });

  return (
    <span className="input-gift">
      <span
        className={classNames('pre-label label-required', {
          'label-disabled': disabled,
        })}
      >
        景品
      </span>
      <div className="gift-list">
        {gift_list.filter(v=> v.rank != '9').map((v, i) => {
          return(
              <div>
                  {isUpdate ? (
                      <div className="bold">{`${v.rank}等`}</div>
                  ) : (
                      <TcCheckbox
                          label={`${v.rank}等`}
                          className={'bold'}
                          disabled={disabled}
                          checked={
                              formik.getFieldProps(`gift_list.${i}.is_checked`).value
                          }
                          wrapperStyle={{ width: `fit-content` }}
                          onChange={() => {
                              if (!i) return;

                              if (formik.getFieldProps(`gift_list.${i}.is_checked`).value) {
                                  for (let j = i; j < gift_list.length; j++) {
                                      handlerFormikFieldChange(
                                          formik,
                                          `gift_list.${j}.is_checked`,
                                          false
                                      );
                                  }
                              } else {
                                  for (let j = 1; j <= i; j++) {
                                      handlerFormikFieldChange(
                                          formik,
                                          `gift_list.${j}.is_checked`,
                                          true
                                      );
                                  }
                              }
                          }}
                      />
                  )}
                  <>
                      {['0', '1', '2'].includes(trigger) && (
                          <div className="gift-input-area">
                              <div className="gift-radio-area">
                                  <TcCheckbox
                                      label={gachaGiftTypeOptions[0].label}
                                      wrapperStyle={{ width: `fit-content` }}
                                      isRadio={true}
                                      checked={
                                          !formik.getFieldProps(`gift_list.${i}.is_coupon`)
                                              .value
                                      }
                                      disabled={
                                          !formik.getFieldProps(`gift_list.${i}.is_checked`)
                                              .value || disabled || lottery_disabled
                                      }
                                      onChange={() =>
                                          handlerFormikFieldChange(
                                              formik,
                                              `gift_list.${i}.is_coupon`,
                                              false
                                          )
                                      }
                                  />
                              </div>
                              <TextBox
                                  formik={formik}
                                  placeholder={'00'}
                                  suffix={'個'}
                                  listFieldName={`gift_list.${i}.stamp_count`}
                                  width={216}
                                  disabled={
                                      !formik.getFieldProps(`gift_list.${i}.is_checked`)
                                          .value ||
                                      formik.getFieldProps(`gift_list.${i}.is_coupon`)
                                          .value ||
                                      disabled
                                  }
                                  flexEnd
                                  style={{ marginLeft: 11 }}
                              />
                          </div>
                      )}
                  </>
                  {/*)}*/}
                  <div
                      className={
                          i < gift_list.length - 1
                              ? 'not-end limit-container'
                              : 'limit-container'
                      }
                  >
                      {['3', '4', '5'].includes(trigger) && (
                          <TextBox
                              formik={formik}
                              placeholder={'00'}
                              preLabel={'ポイント'}
                              suffix={'pt'}
                              listFieldName={`gift_list.${i}.point`}
                              width={292}
                              disabled={
                                  !formik.getFieldProps(`gift_list.${i}.is_checked`).value ||
                                  disabled
                              }
                              flexEnd
                              style={{ marginLeft: 24 }}
                          />
                      )}
                      {lottery_method == '1' ? (
                          <CustomNumberPicker
                              formik={formik}
                              placeholder="00"
                              from={0}
                              to={100}
                              suffix={'%'}
                              listFieldName={`gift_list.${i}.winner_probability`}
                              preLabel="確率"
                              width={290}
                              disabled={
                                  !formik.getFieldProps(`gift_list.${i}.is_checked`).value ||
                                  disabled ||
                                  lottery_disabled
                              }
                              extraOnChange={() =>{
                                  handlerFormikFieldChange(
                                      formik,
                                      'focused_rank',
                                      formik.getFieldProps(`gift_list.${i}.rank`).value
                                  )
                              }}
                              style={{ marginLeft: 24 }}
                          />
                      ) : (
                          <>
                              <TextBox
                                  formik={formik}
                                  placeholder={'00'}
                                  preLabel={'本数'}
                                  suffix={'本'}
                                  listFieldName={`gift_list.${i}.max_winner_count`}
                                  width={292}
                                  disabled={
                                      !formik.getFieldProps(`gift_list.${i}.is_checked`)
                                          .value || disabled ||
                                      lottery_disabled
                                  }
                                  flexEnd
                                  style={{ marginLeft: 24 }}
                              />
                          </>
                      )}
                  </div>
              </div>
          )
        })}
        {gift_list.filter(v=> v.rank == '9').map((v) => {
          return (
            <>
              <div className="lose">
                <div className={loseInputLine}>
                  <span className="pre-label bold">はずれ</span>
                  {!!Number(lottery_method) && (<Input placeholder={loser_probability} disabled />)}
                </div>
                {!!Number(lottery_method) && (<span className="post-label">%</span>)}
              </div>
                <div className="extra-input" style={{ marginTop: 20 }}>
                  <TcRadio
                    formik={formik}
                    isRadio={true}
                    fieldName={`gift_list.${gift_list.length-1}.is_checked`}
                    width={180}
                    disabled={disabled}
                    options={giftOptions}
                      onChange={v =>
                        handlerFormikFieldChange(
                          formik,
                          `gift_list.${gift_list.length-1}.is_checked`,
                          v
                        )
                      }
                  />
                  {['0', '1', '2'].includes(trigger) && (
                      <TextBox
                          formik={formik}
                          placeholder={'00'}
                          preLabel={'スタンプ'}
                          suffix={'個'}
                          listFieldName={`gift_list.${gift_list.length-1}.stamp_count`}
                          width={302}
                          disabled={
                              !formik.getFieldProps(`gift_list.${gift_list.length-1}.is_checked`)
                                  .value ||
                              disabled
                          }
                          flexEnd
                          style={{ marginLeft: 24 }}
                      />
                  )}
                  {['3', '4', '5'].includes(trigger) && (
                      <TextBox
                          formik={formik}
                          placeholder={'00'}
                          preLabel={'ポイント'}
                          suffix={'pt'}
                          listFieldName={`gift_list.${gift_list.length-1}.point`}
                          width={302}
                          disabled={
                              !formik.getFieldProps(`gift_list.${gift_list.length-1}.is_checked`)
                                  .value ||
                              disabled
                          }
                          flexEnd
                          style={{ marginLeft: 24 }}
                      />
                  )}
                </div>
            </>
          );
        })}
      </div>
    </span>
  );
};

export default InputGift;
