import { memo } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { staffManagementActions } from '../../slices/staffManagementSlice';
import { genderOptions3, staffAuthOptions } from '../../constants/options';
import MenuCheckbox from '../tcCheckbox/menuCheckbox';
import ReferenceItem from '../referenceItem';
import ModalTitle from '../modalTitle';
import TcAvatar from '../tcAvatar';
import TcButton from '../tcButton';
import './style.scss';
import { dateFormat } from '../../utils/fnUtil';

const StaffReferenceModal = memo(() => {
  const dispatch = useDispatch();

  const { staffReferenceModalVisible, staffReference } = useSelector(
    state => state.staffManagement
  );

  return (
    <Modal
      className="staff-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      visible={staffReferenceModalVisible}
      destroyOnClose={true}
      onCancel={() => dispatch(staffManagementActions.resetStaffModal())}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'社員参照'} />
        <div className="input-area">
          <h3>所属</h3>
          <ReferenceItem
            label={'ブランド'}
            value={staffReference?.brand?.brand_name}
          />
          <ReferenceItem label={'店舗'} value={staffReference?.shop_name} />
          <h3 className="input-title">社員情報</h3>
          <ReferenceItem label={'社員CD'} value={staffReference?.staff_code} />
          <ReferenceItem label={'社員名'} value={staffReference?.staff_name} />
          <ReferenceItem
            label={'社員名(カナ)'}
            value={staffReference?.staff_name_kana}
          />
          <ReferenceItem
            label={'メールアドレス'}
            value={staffReference?.mail_address}
          />
          <ReferenceItem
            label={'電話番号'}
            multiValue={[
              staffReference?.tel?.number1,
              staffReference?.tel?.number2,
              staffReference?.tel?.number3,
            ]}
          />
          <ReferenceItem
            label={'性別'}
            value={
              genderOptions3.find(
                gender => gender.value === staffReference?.gender
              )?.label
            }
          />
          <ReferenceItem
            label={'身長'}
            value={`${
              staffReference?.height ? `${staffReference?.height} cm` : ''
            }`}
          />
          <ReferenceItem label={'コメント'} value={staffReference?.comment} />
          <ReferenceItem
            label={'利用開始日'}
            value={dateFormat(staffReference?.start_date, 'YYYY/M/D')}
            subValue={`利用終了日 ${dateFormat(
              staffReference?.end_date,
              'YYYY/M/D',
              '-'
            )}`}
          />
          <h3 className="auth-title input-title">
            権限
            <div>
              <span className="auth-tip">
                プログラム内で権限に応じた制御をしているメニューの場合、プログラムでの設定が優先されます。
              </span>
              <span className="auth-tip">
                詳細はユーザーマニュアルをご確認ください
              </span>
            </div>
          </h3>
          <ReferenceItem
            label={'参照元社員'}
            value={staffReference?.referrer_login_id}
          />
          <ReferenceItem
            label={'権限'}
            value={
              staffAuthOptions.find(
                auth => auth.value === staffReference?.staff_auth
              )?.label
            }
          />
          <span className="cus-input-line input-menu">
            <span className="cus-pre-label">利用メニュー</span>
            <MenuCheckbox staffInfo={staffReference} readOnly={true} />
          </span>
          <TcAvatar src={staffReference?.img} readOnly={true} />
          <div className="button-container">
            <TcButton
              text={'閉じる'}
              theme="white"
              onClick={() => dispatch(staffManagementActions.resetStaffModal())}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default StaffReferenceModal;
