import Icons from '../../constants/Icons';
import './style.scss';

const ColumnTitle = ({ title, formik, sortField, onClick }) => {
  const _onClick = () => {
    const desc = `${sortField}_desc`;
    const asc = `${sortField}_asc`;
    const sort =
      formik.values.sort === asc
        ? desc
        : formik.values.sort === desc
        ? asc
        : asc;
    onClick(sort);
    formik.setFieldValue('sort', sort);
  };

  return (
    <div className="tc-table-header">
      <span>{title} </span>
      {sortField && (
        <span className="sort-span" onClick={_onClick}>
          {formik.values.sort === `${sortField}_asc` ? (
            <Icons.IconDown className="sort-icon" />
          ) : (
            <Icons.IconUp className="sort-icon" />
          )}
        </span>
      )}
    </div>
  );
};

export default ColumnTitle;
