import Humberger from '../humberger';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PersonalIcon from '../../components/personalIcon';
import { Popover } from 'antd';
import TcButton from '../tcButton';
import Icon from '@ant-design/icons';
import Icons from '../../constants/Icons';
import { globalActions } from '../../slices/globalSlice';
import {
  logout,
  updatePassword,
  accountActions,
} from '../../slices/accountSlice';
import { staffAuthOptions } from '../../constants/options';
import Modal from 'antd/lib/modal/Modal';
import { useFormik } from 'formik';
import Yup from '../../utils/yupUtil';
import './style.scss';
import { hashPassword } from '../../utils/fnUtil';
import InputContainer from '../inputContainer';
import PasswordBox from '../util/passwordBox';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accountInfo, updatePasswordModalVisible } = useSelector(
    state => state.account
  );

  const formik = useFormik({
    initialValues: {
      current_password: '',
      password: '',
      confirm_password: '',
    },
    initialTouched: {
      current_password: true,
      password: true,
      confirm_password: true,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      current_password: Yup.string().required(),
      password: Yup.string().required().password(),
      confirm_password: Yup.string().required().passwordConfirm(),
    }),
    onSubmit: ({ current_password, password }) =>
      dispatch(
        updatePassword({
          login_type: '1',
          current_password: hashPassword(current_password),
          new_password: hashPassword(password),
        })
      ),
  });

  const onBack = () => {
    dispatch(accountActions.setUpdatePasswordModalVisible(false));
    formik.resetForm();
  };

  return (
    <>
      <header className="header" style={{ padding: 0 }}>
        <Humberger />
        <div className="header">
          <div className="header-left">
            <div className="header-logo">
              <Icon
                component={Icons.LogoTenco}
                className={'logo-image display-icon'}
              />
            </div>
            <div
              className="header-logo"
              style={{ cursor: 'pointer', position: 'absolute', left: '30px' }}
              onClick={() => navigate('/')}
            />
          </div>
          <div className="header-right">
            <PersonalIcon
              imgSrc={accountInfo?.profile_image_url}
              width={50}
              height={50}
              maskTop="8px"
              cursor="default"
            />
            <Popover
              overlayInnerStyle={{ borderRadius: '12px' }}
              content={
                <div className="header-personal-popover">
                  <div className="account-detail">
                    <div className="line">
                      <div>権限　　　</div>
                      <div>
                        {
                          staffAuthOptions?.filter(
                            e => e.value === accountInfo?.staff_auth
                          )?.[0]?.label
                        }
                      </div>
                    </div>
                    <div className="border" />
                    <div className="line">
                      <div>ブランド　</div>
                      <div>{accountInfo?.brand_name}</div>
                    </div>
                    <div className="border" />
                    <div className="line">
                      <div>店舗　　　</div>
                      <div>{accountInfo?.shop_name}</div>
                    </div>
                    <div className="border" />
                  </div>
                  <TcButton
                    text="パスワード変更"
                    style={{
                      width: 200,
                    }}
                    onClick={() => {
                      dispatch(
                        accountActions.setUpdatePasswordModalVisible(true)
                      );
                    }}
                  />
                  <div
                    className="header-logoutButton"
                    onClick={() => {
                      dispatch(
                        globalActions.showSelectModal({
                          title: 'ログアウトしますか',
                          buttons: [
                            {
                              btnAction: logout(),
                            },
                            {},
                          ],
                        })
                      );
                    }}
                  >
                    ログアウト
                  </div>
                </div>
              }
              getPopupContainer={trigger => trigger.parentElement}
            >
              <div className={'header-personal-info'}>
                <span>{accountInfo?.corporation_name}</span>
                <br />
                <span>{accountInfo?.staff_name}</span>
              </div>
            </Popover>
          </div>
          <Modal
            className="changePassword-modal"
            open={updatePasswordModalVisible}
            centered={true}
            footer={null}
            closable={false}
            width={550}
            onCancel={onBack}
          >
            <h3>パスワード変更</h3>
            <InputContainer>
              <PasswordBox
                formik={formik}
                label="現在のパスワード"
                fieldName="current_password"
                placeholder="パスワードを入力してください"
                isBold
              />
              <PasswordBox
                formik={formik}
                label="新しいパスワード"
                fieldName="password"
                placeholder="パスワードを入力してください"
                isBold
              />
              <PasswordBox
                formik={formik}
                label="新しいパスワード(再入力)"
                fieldName="confirm_password"
                placeholder="パスワードを入力してください"
                isBold
              />
            </InputContainer>

            <div className="btn-container">
              <TcButton text={'戻る'} theme="white" onClick={onBack} />
              <TcButton
                text={'変更'}
                disabled={!formik.isValid}
                onClick={formik.handleSubmit}
              />
            </div>
          </Modal>
        </div>
      </header>
    </>
  );
};

export default Header;
