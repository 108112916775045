import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';

const initialSearchParams = {
  qr_code: '',
  qr_name: '',
  brand_code: [],
  shop_code: [],
  deleted_flg: '0',
  offset: 0,
  count: 10,
  sort: 'qr_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  qrList: [],
  total: 0,
  deleteModalVisible: false,
  deleteQr: null,
  editModalVisible: false,
  referenceModalVisible: false,
  currentQr: null,
  referenceQr: null,
};

export const fetchQrList = createAsyncThunk(
  'fetchQrList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('qr/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchQrDetail = createAsyncThunk(
  'fetchQrDetail',
  async params =>
    await axios.get('qr/detail', { params: { qr_code: params.qr_code } })
);

export const fetchQrReference = createAsyncThunk(
  'fetchQrReference',
  async params =>
    await axios.get('qr/detail', { params: { qr_code: params.qr_code } })
);

export const qrCreate = createAsyncThunk(
  'qrCreate',
  async (params, { dispatch }) => {
    await axios.post('qr/create', params);
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const qrUpdate = createAsyncThunk(
  'qrUpdate',
  async (params, { dispatch }) => {
    await axios.post('qr/update', params);
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const qrDelete = createAsyncThunk(
  'qrDelete',
  async params => await axios.post('qr/delete', { qr_code: params })
);

export const qrSlice = createSlice({
  name: 'qrSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      deleteQr: payload,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentQr: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceModalVisible: false,
      referenceQr: null,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchQrList.fulfilled]: (state, { payload }) => {
      state.qrList = payload.item;
      state.total = payload.total_count;
    },
    [fetchQrDetail.fulfilled]: (state, { payload }) => {
      state.editModalVisible = true;
      state.currentQr = payload.item;
    },
    [fetchQrReference.fulfilled]: (state, { payload }) => {
      state.referenceModalVisible = true;
      state.referenceQr = payload.item;
    },
    [qrCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
    }),
    [qrUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
      currentQr: null,
    }),
    [qrDelete.fulfilled]: state => ({
      ...state,
      deleteModalVisible: false,
      searchParams: { ...state.searchParams },
      deleteQr: null,
    }),
  },
});

export const qrActions = qrSlice.actions;

export default qrSlice.reducer;
