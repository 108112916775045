import { useRef, useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import './style.scss';

const Map = ({ data = [], center }) => {
  const [heatmapData, setHeatmapData] = useState({});

  useEffect(() => {
    setHeatmapData({
      positions: data?.map(({ latitude, longitude, user_count }) => ({
        lat: latitude,
        lng: longitude,
        weight: Math.pow(4, Math.min(Math.log(user_count) / Math.log(2), 10)),
      })),
      options: { radius: 40 },
    });
  }, [data]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyCp6dXSnionQVxju_wW8T08EH5C8HeHo_c' }}
      defaultCenter={center}
      defaultZoom={10}
      heatmapLibrary={true}
      heatmap={heatmapData}
    />
  );
};

export default Map;
