import React, { useEffect, useRef, useState } from 'react';
import './style.scss';

const ChartLegend = ({ data, colors, width }) => {
  const items = data.map((v, i) => ({
    label: v[0],
    value: v[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    color: !!colors ? colors[i % colors.length] : null,
  }));

  const itemRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(2);
  const [pages, setPages] = useState([]);

  const visibleItems = pages => {
    const { startIndex, endIndex } = pages[currentPage - 1];
    return items.filter((_, i) => i >= startIndex && i <= endIndex);
  };

  const getPages = (widthList, max) =>
    widthList.reduce(
      (acc, childWidth, i) => {
        const last = acc[acc.length - 1];
        const width = last.totalWidth + childWidth;
        return width > max
          ? [
              ...acc,
              {
                totalWidth: childWidth,
                startIndex: last.endIndex + 1,
                endIndex: last.endIndex + 1,
              },
            ]
          : [
              ...acc.filter((_, i) => i < acc.length - 1),
              {
                ...last,
                totalWidth: width,
                endIndex: i,
              },
            ];
      },
      [{ totalWidth: 0, startIndex: 0, endIndex: 0 }]
    );

  const handleResize = widthList => {
    const maxWidth = itemRef.current.clientWidth;
    const pages = getPages(widthList, maxWidth);

    setCurrentPage(1);
    setPages(pages);
    setPageCount(pages.length);
  };

  const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);
  const nextPage = () =>
    currentPage < pageCount && setCurrentPage(currentPage + 1);

  useEffect(() => {
    const widthList = Array.from(itemRef.current.childNodes)
      .filter(node => node.nodeType === Node.ELEMENT_NODE)
      .map(v => parseFloat(getComputedStyle(v).width));

    handleResize(widthList);

    const windowHandleResize = () => handleResize(widthList);

    window.addEventListener('resize', windowHandleResize);

    return () => {
      window.removeEventListener('resize', windowHandleResize);
    };
  }, []);

  return (
    <div className="legend-container" style={{ width }}>
      <div className="legend-list" ref={itemRef}>
        {(!!pages.length ? visibleItems(pages) : items).map((item, i) => (
          <div className="legend-list-item">
            <div className={`${i > 0 && 'dot'}`}>
              {item.color && (
                <svg width="10" height="10">
                  <circle cx="4" cy="6" r="3" fill={item.color}></circle>
                </svg>
              )}
            </div>

            <div className="item">
              <span>{item.label}</span>
              <span className="left">{item.value}</span>
            </div>
          </div>
        ))}
      </div>

      {pageCount > 1 && (
        <div className="legend-area">
          <svg width="10" height="10">
            <polygon
              points="0,5 10,0 10,10"
              fill="#837F80"
              onClick={prevPage}
            />
          </svg>
          <div className="page-count">
            {currentPage}/{pageCount}
          </div>
          <svg width="10" height="10">
            <polygon points="0,0 10,5 0,10" fill="#837F80" onClick={nextPage} />
          </svg>
        </div>
      )}
    </div>
  );
};

export default ChartLegend;
