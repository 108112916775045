import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { dateFormat, downloadFile } from '../utils/fnUtil';
import moment from 'moment';

const initialSearchParams = {
  coupon_code: '',
  coupon_name: '',
  coupon_type: null,
  from_usage_period: null,
  to_usage_period: null,
  deleted_flg: false,
  offset: 0,
  count: 10,
  sort: 'coupon_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  couponList: [],
  total: 0,
  deleteModalVisible: false,
  deleteCoupon: null,
  editModalVisible: false,
  referenceModalVisible: false,
  currentCoupon: null,
  referenceCoupon: null,
  couponTemplateList: [],
  fileUploadStatus: null,
  couponReserveCount: 0,
};

export const fetchCouponList = createAsyncThunk(
  'fetchCouponList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('coupon/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchCouponTemplateList = createAsyncThunk(
  'fetchCouponTemplateList',
  async () =>
    await axios.get('settings/template/list', {
      params: { template_type: 0 }
    })
);

export const fetchCouponCopy = createAsyncThunk(
  'fetchCouponCopy',
  async params =>
    await axios.get('coupon/detail', {
      params: { coupon_code: params.coupon_code },
    })
);

export const fetchCouponDetail = createAsyncThunk(
  'fetchCouponDetail',
  async params =>
    await axios.get('coupon/detail', {
      params: { coupon_code: params.coupon_code },
    })
);

export const fetchCouponFileCheck = createAsyncThunk(
  'fetchCouponFileCheck',
  async params =>
    await axios.post('coupon/filecheck', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    })
);

export const fetchCouponReference = createAsyncThunk(
  'fetchCouponReference',
  async params =>
    await axios.get('coupon/detail', {
      params: { coupon_code: params.coupon_code },
    })
);

export const couponCreate = createAsyncThunk(
  'couponCreate',
  async (params, { dispatch }) => {
    await axios.post('coupon/create', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const couponUpdate = createAsyncThunk(
  'couponUpdate',
  async (params, { dispatch }) => {
    await axios.post('coupon/update', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const couponDelete = createAsyncThunk(
  'couponDelete',
  async params => await axios.post('coupon/delete', { coupon_code: params })
);

const createFormData = params => {
  const formData = new FormData();
  formData.append('upload_file', params.upload_file);
  formData.append(
    'jsonValue',
    new Blob([JSON.stringify(params)], {
      type: 'application/json',
    })
  );
  return formData;
};

export const reserveCountCoupon = createAsyncThunk(
  'reserveCountCoupon',
  async (params) =>
    await axios.post('/coupon/count', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    })
);

export const couponSlice = createSlice({
  name: 'couponSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      deleteCoupon: payload,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
      deleteCoupon: null,
    }),
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      couponReserveCount: 0,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentCoupon: null,
      fileUploadStatus: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceModalVisible: false,
      referenceCoupon: null,
    }),
    clearFileUploadStatus: state => ({
      ...state,
      fileUploadStatus: null,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchCouponList.fulfilled]: (state, { payload }) => {
      state.couponList = [
        ...payload?.item?.map(e => ({
          ...e,
          usage_period:
            !e.from_usage_period && !e.to_usage_period
              ? ''
              : `${dateFormat(
                  e.from_usage_period,
                  'YYYY/M/D'
                )}\r\n~${dateFormat(e.to_usage_period, 'YYYY/M/D')}`,
        })),
      ];
      state.total = payload.total_count;
    },
    [fetchCouponTemplateList.fulfilled]: (state, { payload }) => {
      state.couponTemplateList = payload.item;
    },
    [fetchCouponCopy.fulfilled]: (state, { payload }) => {
      const { coupon_code, rich_coupon_detail, prefectures_code, ...rest } =
        payload?.item;
      state.editModalVisible = true;
      state.currentCoupon = {
        ...rest,
        delivery_conditions_flag: '0',
        delivery_target: '01',
        from_age: null,
        to_age: null,
        gender: '0',
        birth_month: null,
        prefectures_code: [],
        favorite_shops_code: [],
        target_list_type: '0',
        upload_file: null,
        upload_file_name: null,
        notice_flag: '0',
        rich_coupon_detail: rich_coupon_detail
          ? rich_coupon_detail
              .split('-----separator-----')
              .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
          : [],
      };
    },
    [fetchCouponDetail.fulfilled]: (state, { payload }) => {
      state.editModalVisible = true;
      state.currentCoupon = {
        ...payload.item,
        rich_coupon_detail: payload.item?.rich_coupon_detail
          ? payload.item.rich_coupon_detail
              .split('-----separator-----')
              .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
          : [],
      };
    },
    [fetchCouponReference.fulfilled]: (state, { payload }) => {
      state.referenceModalVisible = true;
      state.referenceCoupon = {
        ...payload.item,
        rich_coupon_detail: payload.item?.rich_coupon_detail
          ? payload.item.rich_coupon_detail
              .split('-----separator-----')
              .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
          : [],
      };
    },
    [fetchCouponFileCheck.fulfilled]: (state, { payload }) => {
      state.fileUploadStatus = {
        all_count: payload.item?.all_count || 0,
        success_count: payload.item?.success_count || 0,
        faild_count: payload.item?.faild_count || 0,
      };
      if (payload.item?.faild_count > 0) {
        const fileType =
          payload.params.target_list_type === '0' ? '端末ID' : 'ユーザーCD';
        downloadFile(
          payload.item.faild_list.join('\r\n') + '\r\n',
          `取込失敗_${fileType}_${moment().format('YYYYMMDDHHmmss')}.txt`
        );
      }
    },
    [couponCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
    }),
    [couponUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
      currentCoupon: null,
    }),
    [couponDelete.fulfilled]: state => ({
      ...state,
      deleteModalVisible: false,
      searchParams: { ...state.searchParams },
      deleteCoupon: null,
    }),
    [reserveCountCoupon.fulfilled]: (state, { payload }) => {
      state.couponReserveCount = payload.item?.delivery_count ?? 0;
    },
  },
});

export const couponActions = couponSlice.actions;

export default couponSlice.reducer;
