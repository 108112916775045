import React, { memo } from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from '../../slices/globalSlice';
import {
  notificationActions,
  updateNotification,
} from '../../slices/notificationSlice';
import { handlerFormikFieldChange, replaceArray } from '../../utils/fnUtil';
import { Image, Modal } from 'antd';
import ModalTitle from '../modalTitle';
import ContentsTooltip from '../contentsTooltip';
import ImagesUploader from '../imagesUploader';
import TcButton from '../tcButton';
import PreviewModal from '../modal/previewModal';
import Yup from '../../utils/yupUtil';
import './style.scss';
import { serialize, toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import Button from '../tcButton';
import Icon from '@ant-design/icons';
import Icons from '../../constants/Icons';
import BaseUpload from '../baseUpload';
import TextBox from '../util/textBox';

const NotificationModal = memo(() => {
  const dispatch = useDispatch();

  const { notificationModalVisible, notificationDetail, geopush, mode } =
    useSelector(state => state.notification);

  const {
    accountInfo: { new_contents_flag },
  } = useSelector(state => state.account);

  const isCreate = geopush
    ? mode === 'add' || mode === 'copy'
    : notificationDetail == null;
  const isCopy = mode === 'copy';

  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      text: '',
      richText: toSlateState(notificationDetail?.richText),
      link: '',
      images: Array(10).fill(''),
      isCreate: true,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      title: Yup.string().required().max(50),
      message: Yup.string().required().max(50),
    }),
    onSubmit: values => {
      const hasDetail =
        values?.richText?.length > 1 ||
        values?.richText.some(r =>
          (r.children ?? []).some(
            c =>
              c.text ||
              (c?.type === 'link' && (c.children ?? []).some(c => c.text))
          )
        );

      dispatch(
        updateNotification({
          ...values,
          isCreate: isCreate,
          document_id: isCreate ? null : values.document_id,
          geopush_flag: isCopy ? false : geopush,
          rich_text:
            new_contents_flag !== 0
              ? values?.richText
                  ?.map(t => serialize(t))
                  ?.map((dom, i) => ({
                    sort_num: `${i + 1}`,
                    dom,
                  }))
                  .map(obj => obj.dom)
                  .join('-----separator-----')
              : '',
          text:
            new_contents_flag !== 0 && hasDetail
              ? '%html%' +
                values?.richText
                  ?.map(t => serialize(t))
                  ?.map((dom, i) => ({
                    sort_num: `${i + 1}`,
                    dom,
                  }))
                  .map(obj => obj.dom)
                  .join('<br />') +
                '%html%'
              : new_contents_flag !== 0
              ? ''
              : values?.text ?? '',
        })
      );
    },
  });

  // console.log(`geopush = ${geopush}`)
  // console.log(`copyFlag = ${copyFlag}`)
  const { text, images } = formik.values;

  // const setDisplayValues = () => {
  //   notificationDetail
  //     ? formik.setValues({
  //       ...notificationDetail,
  //       richText: toSlateState(notificationDetail?.richText),
  //     })
  //     : formik.setValues({ ...formik.initialValues });
  // };

  useUpdateEffect(
    () =>
      notificationDetail &&
      formik
        .setValues({
          ...notificationDetail,
          images: replaceArray(
            Array(10).fill(''),
            0,
            notificationDetail.image_url ?? []
          ),
          isCreate: isCreate,
          richText: toSlateState(notificationDetail?.richText),
        })
        .then(() => formik.validateForm()),
    [notificationDetail]
  );

  useUpdateEffect(() => {
    !notificationModalVisible && formik.resetForm();

    formik.validateForm();
  }, [notificationModalVisible]);

  const onBack = () => {
    dispatch(notificationActions.resetNotificationModal());
    formik.resetForm();
  };

  return (
    <Modal
      className="notification-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      open={notificationModalVisible}
      destroyOnClose={true}
      onCancel={onBack}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={`お知らせ${
            !isCreate ? '編集' : isCopy ? '登録(複製)' : '登録'
          } `}
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          <TextBox
            formik={formik}
            fieldName="title"
            preLabel="タイトル"
            width={'100%'}
            isRequired
          />
          <TextBox
            formik={formik}
            fieldName="message"
            preLabel="メッセージ"
            width={'100%'}
            isRequired
          />
          <TextBox
            formik={formik}
            fieldName="link"
            preLabel="外部リンク"
            width={'100%'}
            style={{
              marginBottom: 20,
            }}
          />
          {new_contents_flag !== 0 && (
            <div className="image-area">
              <span className={'pre-label'}>バナー画像</span>
              <div className="image-select">
                {images[0] && (
                  <Icon
                    component={Icons.IconXCircleWh}
                    className="close-icon"
                    onClick={e => {
                      handlerFormikFieldChange(formik, 'images[0]', null);
                      e.stopPropagation();
                    }}
                  />
                )}
                {images[0] ? (
                  <Image
                    src={images[0]}
                    width={150}
                    height={200}
                    preview={false}
                  />
                ) : (
                  <>
                    <BaseUpload
                      onChange={url =>
                        handlerFormikFieldChange(formik, 'images[0]', url)
                      }
                      onClick={() => formik.setFieldTouched('images[0]', true)}
                    >
                      <Button text="画像を追加" theme="white" />
                    </BaseUpload>
                  </>
                )}
              </div>
            </div>
          )}

          {(!notificationDetail ||
            notificationDetail?.richText?.length ||
            !notificationDetail?.text) &&
          new_contents_flag !== 0 ? (
            <span
              className="input-textarea　input-textarea"
              style={new_contents_flag !== 0 ? { height: 'unset' } : {}}
            >
              <label className="cus-pre-label">本文</label>

              <RichTextEditor
                // articleCode={article_code}
                articleCode={''}
                extraUpload={true}
                contentImageBlock={true}
                editorState={formik.values?.richText}
                // readOnly={!contentEditable}
                readOnly={false}
                error={formik.touched.richText && formik.errors.richText}
                onChange={state => formik.setFieldValue('richText', state)}
                setTouched={() =>
                  !formik.touched.richText &&
                  formik.setFieldTouched('richText', true)
                }
                // userCode={user_code}
                userCode={'user_code'}
              />
            </span>
          ) : (
            <>
              <span
                className="cus-input-line input-textarea"
                style={{
                  height: 'unset',
                }}
              >
                <label className="cus-pre-label">
                  本文
                  <ContentsTooltip notificationTip={true} />
                </label>
                <textarea
                  placeholder="本文を入力してください"
                  spellCheck={false}
                  autoComplete={'false'}
                  value={formik.values.text}
                  onChange={e =>
                    handlerFormikFieldChange(formik, 'text', e.target.value)
                  }
                />
                <TcButton
                  text={'プレビュー'}
                  onClick={() =>
                    dispatch(
                      globalActions.openPreviewModal({
                        text,
                        images,
                      })
                    )
                  }
                />
              </span>
              <ImagesUploader
                label={'画像'}
                formik={formik}
                fieldName={'images'}
              />
            </>
          )}
          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={!isCreate ? '更新' : '登録'}
              disabled={!formik.isValid}
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
      <PreviewModal />
    </Modal>
  );
});

export default NotificationModal;
