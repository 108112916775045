import { memo } from 'react';
import './style.scss';

const ReferenceItem = memo(
  ({ label, value, multiValue, joinString = '-', subValue }) => (
    <div className="reference-row">
      <span className="cus-pre-label">{label}</span>
      <span className="reference-value font-sm">
        {multiValue?.every(v => v) ? multiValue.join(joinString) : value || '-'}
      </span>
      <span className="sub-value font-sm">{subValue}</span>
    </div>
  )
);

export default ReferenceItem;
