import './style.scss';
import { useSelector } from 'react-redux';

/* 削除確認モダール */

const Loading = () => {
  const { loading, multiLoading, primaryLoading } = useSelector(
    state => state.global
  );
  return (
    <>
      {(loading || multiLoading || primaryLoading) && (
        <div className="tc-loading">
          <div>
            <div className="loading-container">
              <div className="loading"></div>
              <div id="loading-text">loading</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
