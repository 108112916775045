import axios from '../utils/axiosUtil';
import moment from 'moment';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createFormData } from '../slices/notificationSlice';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';

const initialSearchParams = {
  title: '',
  message: '',
  send_types: [],
  from_create_at: null,
  to_create_at: null,
  from_send_plan_date: null,
  to_send_plan_date: null,
  send_status: null,
  count: 10,
  sort: 'document_id_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  reserveList: [],
  favoriteItem: [],
  total: 0,
  csvReserve: null,
  reserveModalVisible: false,
  reserveDetail: null,
  reserveReferenceModalVisible: false,
  reserveReference: null,
  fileUploadStatus: null,
};

export const fetchReserveList = createAsyncThunk(
  'fetchreserveList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('/notification/reserve/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchReserveDetail = createAsyncThunk(
  'fetchreserveDetail',
  async params => {
    const detail = await axios.get('/notification/reserve/detail', { params });
    return detail?.item ?? {};
  }
);

export const fetchReserveReferenceDetail = createAsyncThunk(
  'fetchReserveReferenceDetail',
  async params => await axios.get('/notification/reserve/detail', { params })
);

export const updateReserve = createAsyncThunk(
  'updateReserve',
  async (params, { dispatch, getState }) => {
    await axios.post('/notification/reserve/update', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    });

    dispatch(fetchReserveList(getState().reserve.searchParams));

    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchReserveFileCheck = createAsyncThunk(
  'fetchReserveFileCheck',
  async params =>
    await axios.post(
      '/notification/reserve/filecheck',
      createFormData(params),
      {
        headers: { 'content-type': 'multipart/form-data' },
        isFileUpload: true,
      }
    )
);

export const cancelReserve = createAsyncThunk(
  'cancelReserve',
  async (params, { dispatch, getState }) => {
    await axios.post('/notification/reserve/cancel', params);

    dispatch(fetchReserveList(getState().reserve.searchParams));

    dispatch(globalActions.showSingleModal('配信設定をキャンセルしました'));
  }
);

export const fetchReserveCsvFavoriteItem = createAsyncThunk(
  'fetchReserveCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchReserveCsvFavoriteItemCreate = createAsyncThunk(
  'fetchReserveCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response.params;
  }
);

export const fetchReserveCsvDownload = createAsyncThunk(
  'fetchReserveCsvDownload',
  async params => await axios.get('csv/download', { params })
);

export const reserveSlice = createSlice({
  name: 'reserve',
  initialState,
  reducers: {
    openCsvDownloadModal: (state, { payload }) => ({
      ...state,
      csvReserve: payload,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvReserve: null,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setReserveModalVisible: (state, { payload }) => {
      state.reserveModalVisible = payload;
    },
    resetReserveModal: state => {
      state.reserveModalVisible = false;
      state.reserveDetail = null;
      state.reserveReferenceModalVisible = null;
      state.reserveReference = null;
      state.fileUploadStatus = null;
    },
    clearFileUploadStatus: state => ({
      ...state,
      fileUploadStatus: null,
    }),
    reset: () => initialState,
  },
  extraReducers: {
    [fetchReserveList.fulfilled]: (state, { payload }) => {
      state.reserveList = payload.item ?? [];
      state.total = payload.total_count;
    },
    [fetchReserveDetail.fulfilled]: (state, { payload }) => {
      state.reserveDetail = {
        document_id: payload.document_id,
        reserve_id: payload.reserve_id,
        send_type: payload.send_type ?? '1',
        repeat_type: payload.repeat_type ?? '0',
        send_date: payload.send_plan_date,
        send_time_hh: payload.send_reserve_time
          ? moment(payload.send_reserve_time, 'HH:mm').format('HH')
          : null,
        send_time_mm: payload.send_reserve_time
          ? moment(payload.send_reserve_time, 'HH:mm').format('mm')
          : null,
        repeat_send_time_hh: payload.repeat_send_time
          ? moment(payload.repeat_send_time, 'HH:mm').format('HH')
          : null,
        repeat_send_time_mm: payload.repeat_send_time
          ? moment(payload.repeat_send_time, 'HH:mm').format('mm')
          : null,
        repeat_period: payload.repeat_period,
        timing:
          payload.repeat_period === 'day'
            ? payload.timing?.day
            : payload.repeat_period === 'week'
            ? payload.timing?.week
            : payload.repeat_period === 'month'
            ? payload.timing?.month?.month_day
              ? 0
              : 1
            : null,
        repeat_send_time: payload.repeat_send_time,
        repeat_start_date: payload.repeat_start_date ?? '',
        repeat_end_date: payload.repeat_end_date ?? '',
        cond_flag: payload.cond_flag ?? '0',
        from_age: payload.from_age,
        to_age: payload.to_age,
        gender: payload.gender,
        birth_month: payload.birth_month,
        prefectures_list: payload.prefectures_list,
        shop_list: payload.shop_list,
        file_type: payload?.list_type ?? '0',
        upload_file_name: payload.upload_file_name,
        add_user_data_params: payload.add_user_data_params,
        scenario_type: payload.scenario_type,
        scenario_day: payload.scenario_day,
        scenario_day_type: payload.scenario_day_type,
      };
      state.reserveModalVisible = true;
    },
    [fetchReserveReferenceDetail.fulfilled]: (state, { payload }) => {
      state.reserveReference = payload.item ?? {};
      state.reserveReferenceModalVisible = true;
    },
    [fetchReserveFileCheck.fulfilled]: (state, { payload }) => {
      state.fileUploadStatus = {
        all_count: payload.item?.all_count || 0,
        success_count: payload.item?.success_count || 0,
        faild_count: payload.item?.faild_count || 0,
      };
      if (payload.item?.faild_count > 0) {
        const fileType =
          payload.params.target_list_type === '0' ? '端末ID' : 'ユーザーCD';
        downloadFile(
          payload.item.faild_list.join('\r\n') + '\r\n',
          `取込失敗_${fileType}_${moment().format('YYYYMMDDHHmmss')}.txt`
        );
      }
    },
    [updateReserve.fulfilled]: state => {
      state.reserveDetail = null;
      state.reserveModalVisible = false;
    },
    [cancelReserve.fulfilled]: state => {
      state.reserveDetail = null;
      state.reserveModalVisible = false;
    },
    [fetchReserveCsvFavoriteItem.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchReserveCsvFavoriteItemCreate.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchReserveCsvDownload.fulfilled]: (state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `予約・配信済み一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
      ),
  },
});

export const reserveActions = reserveSlice.actions;
export default reserveSlice.reducer;
