import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lotteryApplicantColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import Icons from '../../constants/Icons';
import {
  fetchLotteryApplicantCsvFavoriteItem,
  fetchLotteryApplicantCsvFavoriteItemCreate,
  fetchLotteryApplicantList,
  lotteryApplicantActions,
  fetchLotteryApplicantCsvDownload,
} from '../../slices/lotteryApplicantSlice';
import TableTitle from '../../components/tableTitle';
import TcTable from '../../components/tcTable';
import TcCheckbox from '../../components/tcCheckbox';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import {
  csvDownloadTypes,
  csvFavoriteItemTypes,
  lotteryApplicantFavoriteItemOptions,
} from '../../constants/options';
import './style.scss';
import { useUpdateEffect } from 'react-use';
import InputContainer from '../../components/inputContainer';
import { globalActions } from '../../slices/globalSlice';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';

const LotteryApplicant = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const {
    total,
    searchParams,
    lotteryApplicantList,
    favoriteItem,
    csvDownloadModalVisible,
  } = useSelector(state => state.lotteryApplicant);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => {
      const { ...rest } = values;
      const params = {
        ...rest,
        status: values.status ? values.status?.map(v => Number(v)) : null,
      };

      dispatch(lotteryApplicantActions.saveSearchParams(params));
    },
  });

  useUpdateEffect(() => {
    dispatch(fetchLotteryApplicantList(searchParams));
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      fetchLotteryApplicantCsvFavoriteItem({
        screen_type: csvFavoriteItemTypes['lottery_applicant'],
      })
    ) && dispatch(lotteryApplicantActions.saveSearchParams(null));
    return () => dispatch(lotteryApplicantActions.clear());
  }, [dispatch]);

  const columns = [
    ...lotteryApplicantColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(lotteryApplicantActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle title="応募者・当選者一覧" icon={<Icons.IconLottery />} />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="抽選CD" fieldName="lottery_code" />
          <TextBox formik={formik} label="抽選名" fieldName="lottery_name" />
          <TextBox formik={formik} label="会員番号" fieldName="user_code" />
          <SelectBox
            formik={formik}
            label="ステータス"
            fieldName="status"
            options={
              selectOptionList?.filter(s => s.field_name === 'ステータス')?.[0]
                ?.select_option ?? []
            }
            mode="multiple"
          />
          <TextBox
            formik={formik}
            label="当選景品"
            fieldName="coupon_code"
            placeholder="クーポンCDを入力してください"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['delete_flag']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'delete_flag',
                !!formik.values['delete_flag'] ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickCsvOutput={() => {
          dispatch(lotteryApplicantActions.openCsvDownloadModal());
        }}
        onClickCsv={() =>
          dispatch(
            globalActions.openFileUploadModal(
              csvFavoriteItemTypes['lottery_applicant']
            )
          )
        }
        csvMode={'lotteryApplicant'}
      />
      <TcTable
        columns={columns}
        dataSource={lotteryApplicantList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(lotteryApplicantActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <CsvDownloadModal
        line={3}
        modalVisible={csvDownloadModalVisible}
        options={lotteryApplicantFavoriteItemOptions}
        favoriteItem={favoriteItem}
        width={570}
        onFavoriteItem={checkedList => {
          const initial = lotteryApplicantFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item.value]: '0',
              },
            }),
            {
              screen_type: csvFavoriteItemTypes['lottery_applicant'],
              favorite: {},
            }
          );

          const param = checkedList.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item]: '1',
              },
            }),
            initial
          );

          dispatch(fetchLotteryApplicantCsvFavoriteItemCreate(param));
        }}
        onClose={() =>
          dispatch(lotteryApplicantActions.closeCsvDownloadModal())
        }
        onDownload={checkedList =>
          dispatch(
            fetchLotteryApplicantCsvDownload({
              screen_type: csvDownloadTypes['lottery_applicant'],
              columns: checkedList,
            })
          )
        }
      />
    </div>
  );
};

export default LotteryApplicant;
