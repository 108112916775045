import React, { useEffect, useRef } from 'react';
import PageTitle from '../../components/pageTitle';
import Icons from '../../constants/Icons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import TcButton from '../../components/tcButton';
import TcCheckbox from '../../components/tcCheckbox';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import TableTitle from '../../components/tableTitle';
import DeleteModal from '../../components/modal/deleteModal';
import './style.scss';
import { useUpdateEffect } from 'react-use';
import {
  fetchStampRallyDelete,
  stampRallyActions,
  fetchStampRallyList,
  fetchStampRallyDetail,
  fetchStampRallyReference, fetchStampRallyCopy,
} from '../../slices/stampRallySlice';
import { stampRallyColumns } from '../../constants/columns';
import StampRallyModal from '../../components/stampRallyModal';
import StampRallyReferenceModal from '../../components/stampRallyModal/stampRallyReference';
import InputContainer from '../../components/inputContainer';
import { globalActions } from '../../slices/globalSlice';
import TextBox from '../../components/util/textBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import SelectBox from '../../components/util/selectBox';

const StampRally = () => {
  const dispatch = useDispatch();

  const parent = useRef(null);

  const { searchParams, total, deleteStamp, stampRallyList } = useSelector(
    state => state.stampRally
  );

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => dispatch(stampRallyActions.saveSearchParams(values)),
  });

  useUpdateEffect(
    () => dispatch(fetchStampRallyList(searchParams)),
    [searchParams]
  );

  useEffect(() => {
    dispatch(stampRallyActions.saveSearchParams(null));
    return () => dispatch(stampRallyActions.clear());
  }, [dispatch]);

  const columns = [
    ...stampRallyColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(stampRallyActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current => {
        return current.delete_flag === 0 ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() =>
              dispatch(
                fetchStampRallyDetail({
                  stamp_rally_code: current.stamp_rally_code,
                })
              )
            }
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        );
      },
    },
    {
      title: '複製',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconCopy
          className="tc-action-icon"
          height={'20px'}
          width={'20px'}
          onClick={() => dispatch(fetchStampRallyCopy({
            stamp_rally_code: current.stamp_rally_code
          }))}
        />
      ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        current.delete_flag === 0 ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => {
              dispatch(
                globalActions.showDeleteModal({
                  message: `台紙：  ${current.stamp_rally_code}\nを削除します\nよろしいですか?`,
                  tip: '※削除した内容は復元できません',
                  deleteAction: fetchStampRallyDelete({
                    stamp_rally_code: current.stamp_rally_code,
                  }),
                })
              );
            }}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() =>
              dispatch(
                fetchStampRallyReference({
                  stamp_rally_code: current.stamp_rally_code,
                })
              )
            }
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle title="新規登録・台紙一覧" icon={<Icons.IconStampRally />} isStampRally />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox
            formik={formik}
            label="台紙CD"
            fieldName="stamp_rally_code"
          />
          <TextBox
            formik={formik}
            label="台紙名"
            fieldName="stamp_rally_name"
          />
          <DateTimeRangePicker
            formik={formik}
            label="台紙表示開始日"
            fromFieldName="stamp_rally_start_date"
            toFieldName="stamp_rally_end_date"
          />
          <SelectBox
            formik={formik}
            label="有効期限種別"
            fieldName="valid_days_flag"
            options={
              selectOptionList?.filter(s => s.field_name === '有効期限種別')?.[0]
                ?.select_option ?? []
            }
          />
          <SelectBox
            formik={formik}
            label="交換種別"
            fieldName="exchange_type"
            options={
              selectOptionList?.filter(s => s.field_name === '交換種別')?.[0]
                ?.select_option ?? []
            }
          />
          <TextBox formik={formik} label="クーポンCD" fieldName="coupon_code" />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['deleted_flg']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flg',
                !!formik.values['deleted_flg'] ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(stampRallyActions.openEditModal(true))}
      />
      <TcTable
        columns={columns}
        dataSource={stampRallyList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(stampRallyActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <DeleteModal
        modalVisible={!!deleteStamp}
        onCancel={() => dispatch(stampRallyActions.closeDeleteModal())}
        item={'台紙'}
        itemValue={deleteStamp?.stamp_rally_code}
        onOk={() =>
          dispatch(
            fetchStampRallyDelete({
              stamp_rally_code: deleteStamp?.stamp_rally_code,
            })
          )
        }
      />
      <StampRallyModal />
      <StampRallyReferenceModal />
    </div>
  );
};

export default StampRally;
