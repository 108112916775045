import { memo } from 'react';
import { useFormik } from 'formik';
import { Modal } from 'antd';
import { hashPassword } from '../../utils/fnUtil';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountActions,
  updateProvisionalPassword,
} from '../../slices/accountSlice';
import TcButton from '../tcButton';
import Yup from '../../utils/yupUtil';
import './style.scss';
import InputContainer from '../inputContainer';
import PasswordBox from '../util/passwordBox';

const PasswordChangeModal = memo(() => {
  const dispatch = useDispatch();

  const { updateProvisionalPasswordModalVisible, provisionalAccountInfo } =
    useSelector(state => state.account);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    initialTouched: {
      password: true,
      confirmPassword: true,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      password: Yup.string().required().password(),
      confirmPassword: Yup.string().required().passwordConfirm(),
    }),
    onSubmit: values => {
      dispatch(
        updateProvisionalPassword({
          login_type: 0,
          operator_code: provisionalAccountInfo.staff_code,
          new_password: hashPassword(values.password),
          corporation_code: provisionalAccountInfo.corporation_code,
          token: provisionalAccountInfo.token,
        })
      );
    },
  });

  return (
    <Modal
      className="changePassword-modal"
      open={updateProvisionalPasswordModalVisible}
      centered={true}
      footer={null}
      closable={false}
      width={550}
      onCancel={() =>
        dispatch(accountActions.setUpdateProvisionalPasswordModalVisible(false))
      }
    >
      <h3>パスワードを変更してください</h3>
      <InputContainer>
        <PasswordBox
          formik={formik}
          label="新しいパスワード"
          labelBold={true}
          fieldName="password"
          isPassword
        />
        <PasswordBox
          formik={formik}
          label="新しいパスワード(再入力)"
          labelBold={true}
          fieldName="confirmPassword"
          isPassword
        />
      </InputContainer>
      <div className="btn-container btn-center">
        <TcButton
          text={'変更'}
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
        />
      </div>
    </Modal>
  );
});

export default PasswordChangeModal;
