import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';

const initialSearchParams = {
  user_code: '',
  from_delivery_date: null,
  to_delivery_date: null,
  brands: [],
  shops: [],
  coupon_code: null,
  coupon_name: null,
  couponcard_code: null,
  couponcard_name: null,
  from_used_date: null,
  to_used_date: null,
  from_expire_date: null,
  to_expire_date: null,
  offset: 0,
  count: 10,
  sort: 'user_code_desc',
  csv_coupon_type: '0',
  csv_brands: [''],
  csv_shops: [''],
  csv_from_delivery_date: null,
  csv_to_delivery_date: null,
};

const initialState = {
  searchParams: initialSearchParams,
  couponUserResultList: [],
  showFields: [],
  total: 0,
  csvDownloadModalVisible: false,
  selectColumsVisible: false,
  favoriteItem: [],
};

export const fetchCouponUserResultList = createAsyncThunk(
  'fetchCouponUserResultList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('coupon/usercoupon_list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchCouponUserResultCsvFavoriteItem = createAsyncThunk(
  'fetchCouponUserResultCsvFavoriteItem',
  async () =>
    axios.get('csv/favorite_item/list', { params: { screen_type: '1' } })
);

export const updateCouponUserResultCsvFavoriteItem = createAsyncThunk(
  'updateCouponUserResultCsvFavoriteItem',
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post(
      'csv/favorite_item/create',
      params
    );
    if (updateFavoriteRes?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));
    return params.favorite;
  }
);

export const csvDownloadCouponUserResult = createAsyncThunk(
  'csvDownloadCouponUserResult',
  async (params, { dispatch }) => {
    const result = await axios.get('csv/download', { params });
    if (!!!result) {
      dispatch(globalActions.showCommonErrorModal());
    }
    return result;
  }
);

export const couponUserResultSlice = createSlice({
  name: 'couponUserResultSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      showFields: payload,
    }),
    openCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    openSelectColumsModal: state => ({
      ...state,
      selectColumsVisible: true,
    }),
    closeSelectColumsModal: state => ({
      ...state,
      selectColumsVisible: false,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchCouponUserResultList.fulfilled]: (state, { payload }) => {
      state.couponUserResultList = payload.item.map(e => {
        const brands = e.brands
          ? `${e.brands[0] ?? ''}` +
            (e.brands.length >= 2
              ? `${'\r\n'}他${e.brands.length - 1}ブランド`
              : '')
          : '全件';
        const shops = e.shops
          ? `${e.shops[0] ?? ''}` +
            (e.shops.length >= 2 ? `${'\r\n'}他${e.shops.length - 1}店舗` : '')
          : '全件';
        return {
          ...e,
          brands,
          shops,
          couponcard_code: e?.couponcard_code || '',
          couponcard_name: e?.couponcard_name || '',
          open_date: e?.open_date || '未開封',
          used_date: e?.used_date?.join('\r\n') || '未使用',
        };
      });
      state.total = payload.total_count;
    },
    [fetchCouponUserResultCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload?.item?.favorite ?? {};
    },
    [updateCouponUserResultCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload;
    },
    [csvDownloadCouponUserResult.fulfilled]: (_, { payload }) => {
      if (payload && typeof payload === 'string') {
        downloadFile(
          payload,
          `ユーザー×クーポン単位実績一覧_${moment().format(
            'YYYYMMDDHHmmss'
          )}.csv`
        );
      }
    },
  },
});

export const couponUserResultActions = couponUserResultSlice.actions;

export default couponUserResultSlice.reducer;
