import { memo } from 'react';
import { DatePicker, TimePicker } from 'antd';
import {
  dateFormat,
  compareMemoObject,
  handlerFormikFieldChange,
} from '../../utils/fnUtil';

import Icons from '../../constants/Icons';
import moment from 'moment';
import { get } from 'lodash';
import './style.scss';

const DatePickerBase = memo(
  ({
    formik,
    fieldName,
    format = 'YYYY/M/D',
    uneditable,
    useTimePicker,
    errorDisplay = true,
    disabled = false,
    width,
    usePopContainer,
    disabledDate,
  }) => {
    const onChange = (_, formatString) => {
      handlerFormikFieldChange(formik, fieldName, formatString);
    };

    return (
      <span className="date-picker-wrapper" style={{ width }}>
        {errorDisplay && (
          <span className="tc-input-error date-error">
            {get(formik.touched, fieldName) && get(formik.errors, fieldName)}
          </span>
        )}
        {uneditable ? (
          <span className="input-value font-sm">
            {dateFormat(get(formik.values, fieldName), format)}
          </span>
        ) : useTimePicker ? (
          <TimePicker
            disabled={disabled}
            placeholder="00:00"
            format="HH:mm"
            value={
              get(formik.values, fieldName)
                ? moment(get(formik.values, fieldName), 'HH:mm')
                : null
            }
            suffixIcon={<Icons.IconClock className="picker-icon" />}
            clearIcon={<Icons.IconXCircle className="picker-icon" />}
            onChange={onChange}
          />
        ) : (
          <DatePicker
            disabledDate={disabledDate}
            inputReadOnly={true}
            disabled={disabled}
            onBlur={() => formik.setFieldTouched(fieldName, true)}
            placeholder={'YYYY/M/D'}
            format={format}
            value={
              get(formik.values, fieldName)
                ? moment(get(formik.values, fieldName))
                : null
            }
            suffixIcon={<Icons.IconCalendar className="picker-icon" />}
            clearIcon={<Icons.IconXCircle className="picker-icon" />}
            getPopupContainer={
              usePopContainer ? triggerNode => triggerNode.parentNode : null
            }
            onChange={onChange}
          />
        )}
      </span>
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, ['fieldName']) &&
    pre.disabled === next.disabled &&
    pre.fieldName === next.fieldName &&
    pre.disabledDate === next.disabledDate
);

export default DatePickerBase;
