import { memo } from 'react';
import DatePickerBase from './datePickerBase';
import HourPickerBase from './hourPickerBase';
import './style.scss';

const RangePicker = memo(
  ({ formik, fromField, toField, fromHourField, toHourField, rangeText, preLabelClassName }) => (
    <div className="tc-range-picker">
      <DatePickerBase formik={formik} fieldName={fromField} />
      <HourPickerBase formik={formik} fieldName={fromHourField} style={{ marginLeft: "5px" }} />時
      {toField && (
        <>
          {rangeText ? (
            <span className={preLabelClassName}>{rangeText}</span>
          ) : (
            <span className="range-text">~</span>
          )}
          <DatePickerBase formik={formik} fieldName={toField} />
          <HourPickerBase formik={formik} fieldName={toHourField} style={{ marginLeft: "5px" }} />時
        </>
      )}
    </div>
  )
);

export default RangePicker;
