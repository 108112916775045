import { useEffect, useState } from 'react';
import { Modal, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { csvCheck, csvUpload, globalActions } from '../../slices/globalSlice';
import TcButton from '../tcButton';
import ModalTitle from '../modalTitle';
import Icons from '../../constants/Icons';
import classNames from 'classnames';
import './style.scss';
import { csvFavoriteItemTypes } from '../../constants/options';
import {downloadSampleCsv} from "../../slices/downloadSlice";

const FileUploadModal = () => {
  const dispatch = useDispatch();
  const {
    checkResult,
    registerEnable,
    screenType,
    successCount,
    errorCount,
    fileUploadModalVisible,
  } = useSelector(state => state.global);

  const [fileInfo, setFileInfo] = useState(null);

  const { accountInfo } = useSelector(state => state.account);

  const beforeUpload = file => {
    var format = new RegExp('([^s]+(\\.(csv|txt))$)', 'i');
    const checkFormat = format.test(file.name);

    if (!checkFormat) {
      dispatch(
        globalActions.showErrorModal({
          title: '読み取りエラー',
          message: `${file.name} はcsvまたはtxtファイルではありません`,
        })
      );
      return false;
    }

    const isLt5M = file.size / 1024 / 1024 < 5;

    if (!isLt5M) {
      dispatch(
        globalActions.showErrorModal({
          title: '読み取りエラー',
          message: 'ファイルは5M以下でアップロードしてください',
        })
      );
      return false;
    }

    onChange(file);

    return false;
  };

  const onChange = fileInfo => {
    setFileInfo(fileInfo);
    dispatch(
      csvCheck({ fileInfo, screenType, userCode: accountInfo.staff_code })
    );
  };

  useEffect(() => {}, [dispatch]);

  return (
    <Modal
      visible={fileUploadModalVisible}
      centered
      closable={false}
      width={1000}
      footer={null}
      destroyOnClose={true}
      onCancel={() => dispatch(globalActions.reset())}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title="アップロード"
          rightElement={
            <div
              className="csv-template-download-link"
              onClick={() => {
                dispatch(
                  downloadSampleCsv({
                    screen_type: screenType
                  })
                )
              }}
            >
              フォーマットのダウンロードはこちら
            </div>
          }
        />
        <div className="tc-upload">
          <Upload
            className="upload-btn"
            beforeUpload={beforeUpload}
            showUploadList={false}
            maxCount={1}
            listType="text"
          >
            <TcButton
              text="ファイル選択"
              theme="white"
              icon={<Icons.IconClip className="btn-icon" />}
            />
          </Upload>
          <div className="upload-info">
            <span className="count-info">更新可能件数 : {successCount}</span>
            <span className="count-info">エラー件数 : {errorCount}</span>
          </div>
          <div className="upload-info format-info">
            {screenType === csvFavoriteItemTypes['lottery_applicant'] ?
              <>
                <span style={{color: '#9B233F'}}>1度当選者取込を登録すると変更はできません</span>
                <span className="nf_info_text" style={{color: '#9B233F'}}>当選本数を超えた場合でも登録できます</span>
              </> :
              <span>検証結果が正常なデータのみ更新されます</span>
            }
            <span className="nf_info_text" style={{paddingRight: '2.5px'}}>ファイル形式：csvまたはtxt</span>
          </div>
        </div>
        <div className="tc-file-view">
          <div
            className={classNames('csv-layout', {
              'result-placeholder': checkResult.length === 0,
            })}
          >
            {checkResult.length > 0 ? (
              <table border="1" cellSpacing="0">
                {checkResult.map((result, i) =>
                  i === 0 ? (
                    <thead key={i}>
                      <tr>
                        {(result ?? []).map((column, j) => (
                          <th key={j}>{column}</th>
                        ))}
                      </tr>
                    </thead>
                  ) : (
                    <tbody key={i}>
                      <tr>
                        {(result ?? []).map((column, j) => (
                          <td key={j}>{column}</td>
                        ))}
                      </tr>
                    </tbody>
                  )
                )}
              </table>
            ) : (
              <span className="upload-placeholer">
                ファイルを選択してください
              </span>
            )}
          </div>
        </div>
        <div className="button-container">
          <TcButton
            text="戻る"
            theme="white"
            onClick={() => dispatch(globalActions.reset())}
          />
          <TcButton
            text="登録"
            theme={'black'}
            disabled={screenType === csvFavoriteItemTypes['lottery_applicant'] && errorCount > 0 ? true : !registerEnable}
            onClick={() =>
              dispatch(
                csvUpload({
                  fileInfo,
                  screenType,
                  userCode: accountInfo.staff_code,
                })
              )
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default FileUploadModal;
