import React, { useState, useEffect } from 'react';
import './style.scss';
import axios from 'axios';

const Privacy = () => {
  const [html, setHTML] = useState('');
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_POLICY_URL}?timestamp=${new Date().getTime()}`
      )
      .then(e => setHTML(e.data))
      .catch(() => setHTML('読み込みに失敗しました'));
  }, []);

  return (
    <>
      <div className="management-page privacy-container">
        <div className="inner" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </>
  );
};

export default Privacy;
