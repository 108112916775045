import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import axios from '../utils/axiosUtil';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';

const initialSearchParams = {
  gacha_code: '',
  gacha_name: '',
  trigger: null,
  user_code: '',
  winner_rank: null,
  gift_type: null,
  coupon_code: null,
  from_applicant_date: null,
  to_applicant_date: null,
  gift_default: null,
  stamp_count: null,
  offset: 0,
  count: 10,
  sort: 'gacha_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,
  csvModalVisible: false,
  editModalVisible: false,
  favoriteItem: [],
  applicantList: [],
};

export const fetchGachaApplicantCsvFavoriteItem = createAsyncThunk(
  'fetchGachaApplicantCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchGachaApplicantCsvFavoriteItemCreate = createAsyncThunk(
  'fetchGachaApplicantCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response.params;
  }
);

export const fetchGachaApplicantCsvDownload = createAsyncThunk(
  'fetchGachaApplicantCsvDownload',
  async params => await axios.get('csv/download', { params })
);

export const fetchGachaApplicantList = createAsyncThunk(
  'fetchGachaApplicantList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('gacha/applicants', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const gachaApplicantSlice = createSlice({
  name: 'gachaApplicantSlice',
  initialState,
  reducers: {
    openCsvDownloadModal: (state, { payload }) => ({
      ...state,
      csvModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvModalVisible: false,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchGachaApplicantCsvFavoriteItem.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchGachaApplicantCsvFavoriteItemCreate.fulfilled]: (
      state,
      { payload }
    ) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchGachaApplicantCsvDownload.fulfilled]: (_state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `ガチャ参加者一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
      ),
    [fetchGachaApplicantList.fulfilled]: (state, { payload }) => ({
      ...state,
      total: payload?.total_count ?? 0,
      applicantList: payload?.item?.applicant_list,
    }),
  },
});

export const gachaApplicantActions = gachaApplicantSlice.actions;

export default gachaApplicantSlice.reducer;
