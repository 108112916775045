import { Image, Modal, Empty } from 'antd';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toMoneyString } from '../fnUtil';
import Button from '../button';
import GenernalInput from '../generalInput';
import classNames from 'classnames';
import axios from '../../../utils/axiosUtil';
import noimage from '../../../images/forRichTextEditor/noimage_square2.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import { chunk } from 'lodash';
import './style.scss';

const ProductSearchModal = ({ visible, onOk, onCancel, group = false }) => {
  const batchSize = 50;

  const [offset, setOffset] = useState(0);

  const [hasMore, setHasMore] = useState(false);

  const [productList, setProductList] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState({});

  const { selectedBrand } = useSelector(state => state.product);

  const formik = useFormik({
    initialValues: {
      base_product_code: null,
      product_name: null,
    },
    onSubmit: async values => {
      try {
        const res = await axios.get('product/list', {
          params: {
            ...values,
            brand_code: selectedBrand,
            count: batchSize,
            offset: 0,
          },
        });
        const item = res?.item ?? [];
        if (group) {
          setProductList(groupProduct(item));
        } else {
          setProductList(item);
        }
        setHasMore(true);
      } catch (error) {}
      setOffset(0);
    },
  });

  const afterClose = () => {
    formik.resetForm();
    setProductList([]);
    setSelectedProduct({});
    setOffset(0);
    setHasMore(false);
  };

  const chunkProductList = chunk(productList, 3);

  const loadMore = async () => {
    try {
      const res = await axios.get('product/list', {
        params: {
          ...formik.values,
          brand_code: selectedBrand,
          count: batchSize,
          offset: offset + batchSize,
        },
      });
      if (res?.item?.length > 0) {
        const item = res?.item ?? [];
        if (group) {
          setProductList(groupProduct(productList.concat(item)));
        } else {
          setProductList(productList.concat(item));
        }
        setOffset(offset + batchSize);
      }
    } catch {}
  };

  const groupProduct = productList => {
    const productCodeList = [...new Set(productList.map(a => a.product_code))];

    return productCodeList.map(productCode => {
      const { color_code, color_name, size_code, size_name, ...rest } =
        productList.find(p => p.product_code === productCode) ?? {};
      const targetProductList = productList.filter(
        p => p.product_code === productCode
      );

      const colorCodeList = [
        ...new Set(targetProductList.map(p => p.color_code)),
      ];

      const colors = colorCodeList.map(color => {
        const { color_code: targetColorCode, color_name: targetColorName } =
          targetProductList.find(p => p.color_code === color) ?? {};
        return {
          color_code: targetColorCode,
          color_name: targetColorName,
          value: targetColorCode,
          label: targetColorName,
          sizes: targetProductList
            .filter(p => p.color_code === color)
            .map(p => ({
              ...p,
              value: p.size_code,
              label: p.size_name,
            })),
        };
      });

      return { ...rest, colors };
    });
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      footer={null}
      width={1000}
      style={{ minWidth: 1000 }}
      onCancel={onCancel}
      afterClose={afterClose}
    >
      <div className="product-search-area">
        <span className="search-title">商品検索</span>
        <div className="product-search-content">
          <form onSubmit={formik.handleSubmit}>
            <div className="search-condition">
              <GenernalInput
                label={'品番もしくは\nSKUコード'}
                labelTextAlign={'left'}
                styleType="block-grey"
                placeholder={'品番もしくはSKUコードを入力してください'}
                name={'base_product_code'}
                formik={formik}
              />
              <GenernalInput
                styleType="block-grey"
                label={'商品名'}
                labelTextAlign={'left'}
                name={'product_name'}
                formik={formik}
                placeholder={'商品名を入力してください'}
              />
              <div className="search-button-container">
                <Button text="検索" type="submit" />
              </div>
            </div>
          </form>
          <div className="search-result">
            <span className="result-title">検索結果</span>
            {chunkProductList.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ marginTop: 90 }}
              />
            ) : (
              <InfiniteScroll
                dataLength={chunkProductList.length}
                next={loadMore}
                hasMore={hasMore}
                height={250}
                style={{ overflowX: 'hidden' }}
              >
                {chunkProductList.map((c, j) => (
                  <div key={j} className="chunk-list">
                    {c.map((p, i) => (
                      <div
                        key={i}
                        className={classNames('result-box', {
                          'result-box-selected':
                            selectedProduct.brand_code === p.brand_code &&
                            selectedProduct.product_code === p.product_code &&
                            selectedProduct.color_code === p.color_code &&
                            selectedProduct.size_code === p.size_code,
                        })}
                        onClick={() => setSelectedProduct(p)}
                      >
                        <Image
                          src={p.image_url || noimage}
                          height={140}
                          width={130}
                          preview={false}
                        />
                        <div className="result-info">
                          <span>{p.product_code}</span>
                          <span className="product-name">{p.product_name}</span>
                          {!group && <span>{p.color_name}</span>}
                          {!group && <span>{p.size_name}</span>}
                          <span>¥{toMoneyString(p.selling_price)}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>
        <div className="action-container">
          <Button text="戻る" theme="white" onClick={onCancel} />
          <Button
            text="追加"
            onClick={() => onOk(selectedProduct)}
            disabled={!selectedProduct.product_code}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProductSearchModal;
