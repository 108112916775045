import { memo } from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  brandManagementActions,
  updateBrand,
} from '../../slices/brandManagementSlice';
import {
  fetchCategoryList,
} from '../../slices/categorySlice';
import { CATEGORY_TYPE } from '../../constants/enums';
import { appDisplayOptions } from '../../constants/options';
import { dateFormat } from '../../utils/fnUtil';
import { Modal } from 'antd';
import { mapValues } from 'lodash';
import { globalActions } from '../../slices/globalSlice';
import ModalTitle from '../modalTitle';
import TcAvatar from '../tcAvatar';
import TcButton from '../tcButton';
import TcRadio from '../tcCheckbox/tcRadio';
import Yup from '../../utils/yupUtil';
import moment from 'moment';
import './style.scss';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';
import CustomNumberPicker from '../util/customNumberPicker';
import DatePickerWrapper from '../util/datePickerWrapper';

const BrandModal = memo(() => {
  const dispatch = useDispatch();

  const { brandModalVisible, brandDetail } = useSelector(
    state => state.brandManagement
  );

  const { categoryList } = useSelector(state => state.category);

  const yupObject = {
    brand_code: Yup.string().required(),
    brand_name: Yup.string().required(),
    brand_name_kana: Yup.string().required().kana(),
    use_from_date: Yup.string().required(),
    use_to_date: Yup.string().required(),
  };

  const formik = useFormik({
    initialValues: {
      brand_code: '',
      brand_name: '',
      brand_name_kana: '',
      category_code: [],
      use_from_date: moment().format('YYYY/M/D'),
      use_to_date: '2999/12/31',
      display_order: null,
      app_display_flag: 1,
      brand_image_url: '',
    },
    validateOnMount: true,
    validationSchema: Yup.object(yupObject),
    onSubmit: values => {
      const { use_to_date, use_from_date, ...rest } = values;
      if (!moment(use_to_date).isAfter(moment(use_from_date))) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message: '利用開始日は利用終了日より前の日付を入力してください',
          })
        );
        return;
      }

      dispatch(
        updateBrand({
          ...rest,
          use_to_date: dateFormat(use_to_date, 'YYYY/MM/DD'),
          use_from_date: dateFormat(use_from_date, 'YYYY/MM/DD'),
          isCreate: !brandDetail,
        })
      );
    },
  });

  useUpdateEffect(
    () =>
      brandDetail &&
      formik
        .setValues({
          ...brandDetail,
          category_code: brandDetail.category?.map(c => c.category_code),
        })
        .then(() => formik.setTouched(mapValues(yupObject, () => true)))
        .then(() => formik.validateForm()),
    [brandDetail]
  );

  useUpdateEffect(() => {
    if (brandModalVisible)
      dispatch(fetchCategoryList({ category_type: CATEGORY_TYPE.BRAND }))
    !brandModalVisible && formik.resetForm();
    formik.validateForm();
  }, [brandModalVisible]);

  const onBack = () => {
    dispatch(brandManagementActions.resetBrandModal());
    formik.resetForm();
  };

  return (
    <Modal
      className="brand-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      open={brandModalVisible}
      destroyOnClose={true}
      onCancel={onBack}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={`ブランド${brandDetail ? '編集' : '登録'} `}
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          <TextBox
            formik={formik}
            fieldName="brand_code"
            preLabel="ブランドCD"
            width={'calc(98% - 300px)'}
            uneditable={!!brandDetail}
            isRequired
          />
          <SelectBox
            formik={formik}
            fieldName={'category_code'}
            preLabel={'ブランドカテゴリ'}
            width={'calc(98% - 300px)'}
            options={categoryList.map(d => ({
              label: d.category_name,
              value: d.category_code
            }))}
            mode="multiple"
          />
          <TextBox
            formik={formik}
            fieldName="brand_name"
            preLabel="ブランド名"
            width={'calc(98% - 300px)'}
            isRequired
          />
          <TextBox
            formik={formik}
            fieldName="brand_name_kana"
            preLabel="ブランド名(カナ)"
            width={'calc(98% - 300px)'}
            isRequired
          />
          <CustomNumberPicker
            formik={formik}
            fieldName="display_order"
            preLabel="表示優先順"
            from={1}
            to={1000}
            width={300}
            placeholder={''}
          />
          <span className="cus-input-line input-checkbox">
            <TcRadio
              formik={formik}
              label="アプリ表示"
              fieldName="app_display_flag"
              width={120}
              options={appDisplayOptions}
              cancelable={false}
              isRadio={true}
            />
          </span>
          <div style={{ display: 'flex' }}>
            <DatePickerWrapper
              formik={formik}
              fieldName={'use_from_date'}
              preLabel={'利用開始日'}
              maxWidth={300}
              uneditable={!!brandDetail}
              isRequired
              flexEnd
            />
            <DatePickerWrapper
              formik={formik}
              fieldName={'use_to_date'}
              prefix={'終了日'}
              maxWidth={240}
              uneditable={!!brandDetail}
              isRequired
              style={{ marginLeft: 24 }}
              flexEnd
            />
          </div>
          <TcAvatar formik={formik} fieldName="brand_image_url" />
          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={brandDetail ? '更新' : '登録'}
              disabled={!formik.isValid}
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default BrandModal;
