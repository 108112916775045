export const getImageDetail = (orgText = []) => {
  const images = orgText
    .filter(t => t.type === "image")
    .map(t => t.images)
    .flat();

  const general_images = images
    .filter(i => i.image_url && !i.coordinate_code && !i.product_code)
    .map(i => i.image_url);

  const coordinates = images
    .filter(i => i.coordinate_code)
    .map(i => i.coordinate_code);
  const products = images
    .filter(i => i.product_code)
    .map(i => ({
      brand_code: i.brand_code,
      brand_name: i.brand_name,
      product_code: i.product_code,
      product_name: i.product_name,
      color_code: i.color_code,
      color_name: i.color_name,
      size_code: i.size_code,
      size_name: i.size_name,
      sku_code: i.sku_code,
      category_code: i.category_code,
      category_name: i.category_name,
      tax_included_price: i.tax_included_price,
    }));

  return {
    general_images,
    coordinates,
    products,
  };
};

export const getAvailableCount = (orgText = []) => {
  const countArr = orgText
    .filter(t => t.type === "image" && t.count > 0)
    .map(t => t.count);

  return 9 - [0, ...countArr].reduce((pre, next) => pre + next);
};
