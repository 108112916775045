import React from 'react';
import { Tooltip } from 'antd';
import Icons from '../../constants/Icons';
import './style.scss';

/* コンテンツ詳細本文用ツールチップ */

const ContentsTooltip = ({ notificationTip = false }) => {
  return (
    <Tooltip
      className="tc-tooltip"
      overlayInnerStyle={{ width: '375px' }}
      title={
        <label style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>
          画像埋め込み：%image1% ~ %image10%{'\r\n'}
          {!notificationTip && '\t\t\t ※image1：バナー画像 image2~10：詳細画像'}
          {'\r\n'}
          埋め込みタグ{'\r\n'}
          リンク{'\r\n'}
          例) %html%{'<a href="https://xxxx.jp">リンク</a>'}%/html%{'\r\n'}
          文字色変更{'\r\n'}
          例) %html%{'<p style="color: blue;">青色</p>'}%/html%
        </label>
      }
    >
      <Icons.IconHelpCircle className="icon-help" />
    </Tooltip>
  );
};

export default ContentsTooltip;
