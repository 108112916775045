import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import moment from 'moment';

export const topInitInformation = createAsyncThunk(
  'topInitInformation',
  async params => axios.get('informations/list')
);

export const topInitDownload = createAsyncThunk(
  'topInitDownload',
  async params =>
    axios.get('analysis/download', {
      params,
    })
);

export const fetchActiveUserList = createAsyncThunk(
  'fetchActiveUserList',
  async () => await axios.get('analysis/topactiveuser')
);

const initialState = {
  informationList: [],
  downloadInfo: {
    summary_date: moment(),
    android_count_total: 0,
    ios_count_total: 0,
    day_diff: 0,
  },
  downloadList: [],
  activeUserList: [],
};

export const topSlice = createSlice({
  name: 'topSlice',
  initialState,
  reducers: { clear: () => initialState },
  extraReducers: {
    [topInitInformation.fulfilled]: (state, { payload }) => {
      state.informationList = payload.item;
    },
    [topInitDownload.fulfilled]: (state, { payload }) => {
      state.downloadInfo =
        {
          summary_date: payload?.item?.summary_date || moment(),
          android_count_total: payload?.item?.android_count_total || 0,
          ios_count_total: payload?.item?.ios_count_total || 0,
          day_diff: payload?.item?.day_diff || 0,
        } || {};
      state.downloadList = payload?.item?.send_download_summary_list || [];
    },
    [fetchActiveUserList.fulfilled]: (state, { payload }) => ({
      ...state,
      activeUserList: payload?.item?.send_top_active_summary_list,
    }),
  },
});

export const topActions = topSlice.actions;

export default topSlice.reducer;
