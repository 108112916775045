import React, { useRef } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { generatePassword } from '../../slices/accountSlice';
import Yup from '../../utils/yupUtil';
import Footer from '../../components/footer';
import TcButton from '../../components/tcButton';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';

const PasswordRecovery = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountInfo } = useSelector(state => state.account);

  const formik = useFormik({
    initialValues: { corporation_code: '', mail_address: '' },
    validateOnMount: true,
    initialTouched: {
      corporation_code: true,
      mail_address: true,
    },
    validationSchema: Yup.object({
      corporation_code: Yup.string().required(),
      mail_address: Yup.string().required().max(256).mail().mail_local(),
    }),
    onSubmit: async values => {
      dispatch(generatePassword(values));
    },
  });

  return accountInfo ? (
    <Navigate to="/top" />
  ) : (
    <div className="recovery-page" ref={parent}>
      <div className="recovery-form">
        <h3>パスワードをお忘れの方</h3>
        <InputContainer parent={parent}>
          <TextBox
            formik={formik}
            label="法人CD"
            fieldName="corporation_code"
            isBold
          />
          <TextBox
            formik={formik}
            label="メールアドレス"
            fieldName="mail_address"
            isBold
          />
        </InputContainer>
        <div className="btn-container">
          <TcButton
            text={'ログイン画面へ戻る'}
            onClick={() => navigate('/')}
            theme={'white'}
          />
          <TcButton
            text={'メールを送信'}
            disabled={!formik.isValid}
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordRecovery;
