import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import axios from '../utils/axiosUtil';
import { couponTypes } from '../constants/options';

const initialSearchParams = {
  lottery_code: '',
  lottery_name: '',
  public_start_date: null,
  public_end_date: null,
  entry_public_start_date: null,
  entry_public_end_date: null,
  result_public_start_date: null,
  result_public_end_date: null,
  delete_flag: 0,
  offset: 0,
  count: 10,
  sort: 'lottery_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,
  editModalVisible: false,
  currentLottery: null,
  deleteLottery: null,
  referenceLottery: null,
  templateList: [],
  categoryList: [],
  lotteryList: [],
  couponList: [],
};

export const fetchLotteryTemplateList = createAsyncThunk(
  'fetchLotteryTemplateList',
  async () =>
    await axios.get('settings/template/list', {
      params: { template_type: 5 }
    })
);

export const fetchLotteryCouponList = createAsyncThunk(
  'fetchLotteryCouponList',
  async params =>
    await axios.get('/coupon/list', {
      params: {
        coupon_type: couponTypes['lottery'],
      },
    })
);

export const fetchLotteryList = createAsyncThunk(
  'fetchLotteryList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('lottery/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchLotteryCopy = createAsyncThunk(
  'fetchLotteryCopy',
  async params =>
    await axios.get('lottery/detail', {
      params: { lottery_code: params.lottery_code },
    })
);

export const fetchLotteryDetail = createAsyncThunk(
  'fetchLotteryDetail',
  async params =>
    await axios.get('lottery/detail', {
      params: { lottery_code: params.lottery_code },
    })
);

export const fetchLotteryReference = createAsyncThunk(
  'fetchLotteryReference',
  async params =>
    await axios.get('lottery/detail', {
      params: { lottery_code: params.lottery_code },
    })
);

export const fetchLotteryCreate = createAsyncThunk(
  'fetchLotteryCreate',
  async (params, { dispatch }) => {
    await axios.post('lottery/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchLotteryUpdate = createAsyncThunk(
  'fetchLotteryUpdate',
  async (params, { dispatch }) => {
    await axios.post('lottery/update', { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchLotteryDelete = createAsyncThunk(
  'fetchLotteryDelete',
  async params => await axios.post('lottery/delete', { ...params })
);

export const lotterySlice = createSlice({
  name: 'lotterySlice',
  initialState,
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteLottery: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentLottery: null,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteLottery: null,
    }),
    closeReferenceModal: state =>
      !!!state.chartData
        ? {
            ...state,
            referenceLottery: null,
          }
        : { ...state },
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchLotteryTemplateList.fulfilled]: (state, { payload }) => {
      state.templateList = payload.item;
    },
    [fetchLotteryCouponList.fulfilled]: (state, { payload }) => ({
      ...state,
      couponList: [
        ...payload?.item?.map(coupon => ({
          label: coupon.coupon_name,
          value: coupon.coupon_code,
        })),
      ],
    }),
    [fetchLotteryList.fulfilled]: (state, { payload }) => ({
      ...state,
      total: payload?.total_count ?? 0,
      lotteryList: [
        ...payload?.item?.lottery_list.map(lottery => ({
          ...lottery,
        })),
      ],
    }),
    [fetchLotteryCopy.fulfilled]: (state, { payload }) => {
      const { lottery_code, coupon_list, lottery_rich_content, ...rest } =
        payload?.item;

      return {
        ...state,
        editModalVisible: true,
        currentLottery: {
          ...rest,
          coupon_list: coupon_list.map(v => ({
            coupon_code: null,
            winner_count: v.winner_count,
          })),
          lottery_rich_content: lottery_rich_content
            ? lottery_rich_content
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
      };
    },
    [fetchLotteryDetail.fulfilled]: (state, { payload }) => {
      const { lottery_rich_content, ...rest } = payload?.item;

      return {
        ...state,
        editModalVisible: true,
        currentLottery: {
          ...rest,
          lottery_rich_content: lottery_rich_content
            ? lottery_rich_content
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
      };
    },
    [fetchLotteryReference.fulfilled]: (state, { payload }) => {
      const { lottery_rich_content, ...rest } = payload?.item;
      return {
        ...state,
        referenceLottery: {
          ...rest,
          lottery_rich_content: lottery_rich_content
            ? lottery_rich_content
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
      };
    },
    [fetchLotteryCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
    }),
    [fetchLotteryUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      currentLottery: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchLotteryDelete.fulfilled]: state => ({
      ...state,
      deleteLottery: null,
      searchParams: { ...state.searchParams },
    }),
  },
});

export const lotteryActions = lotterySlice.actions;

export default lotterySlice.reducer;
