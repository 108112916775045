import { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import {
  fetchStampList,
  fetchStampDetail,
  fetchStampReference,
  stampActions,
  deleteStamp,
  fetchStampCopy,
} from '../../slices/stampSlice';
import { stampColumns } from '../../constants/columns';
import { globalActions } from '../../slices/globalSlice';
import Icons from '../../constants/Icons';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import TcCheckbox from '../../components/tcCheckbox';
import ColumnTitle from '../../components/columnTitle';
import StampModal from '../../components/stampModal';
import StampReferenceModal from '../../components/stampModal/stampReference';
import TableTitle from '../../components/tableTitle';
import TcTable from '../../components/tcTable';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';

const Stamp = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountBrandList, accountShopList } = useSelector(
    state => state.account
  );

  const { searchParams, stampList, total } = useSelector(state => state.stamp);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      const { display_start_date, display_end_date, ...rest } = values;
      const params = {
        ...rest,
        display_start_date: dateFormat(display_start_date, 'YYYY/MM/DD'),
        display_end_date: dateFormat(display_end_date, 'YYYY/MM/DD'),
      };

      dispatch(stampActions.saveSearchParams(params));
    },
  });

  useEffect(
    () => dispatch(fetchStampList(searchParams)),
    [searchParams, dispatch]
  );

  useEffect(() => () => dispatch(stampActions.reset()), [dispatch]);

  const { brand_code } = formik.values;

  const columns = [
    ...stampColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => dispatch(stampActions.saveSearchParams({ sort }))}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current => {
        return current.delete_flag === 0 ? (
          <Icons.IconEdit
            className="tc-action-icon table-cell-icon"
            onClick={() =>
              dispatch(fetchStampDetail({ stamp_code: current.stamp_code }))
            }
          />
        ) : (
          <Icons.IconEditGy className="tc-disabled-icon table-cell-icon" />
        );
      },
    },
    {
      title: '複製',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconCopy
          className="tc-action-icon"
          height={'20px'}
          width={'20px'}
          onClick={() =>
            dispatch(fetchStampCopy({ stamp_code: current.stamp_code }))
          }
        />
      ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current => {
        return current.delete_flag === 0 ? (
          <Icons.IconTrash
            className="tc-action-icon table-cell-icon"
            onClick={() =>
              dispatch(
                globalActions.showDeleteModal({
                  message: `台紙：  ${current.stamp_code}\nを削除します\nよろしいですか?`,
                  tip: '※削除した内容は復元できません',
                  deleteAction: deleteStamp({
                    stamp_code: current.stamp_code,
                  }),
                })
              )
            }
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon table-cell-icon"
            onClick={() =>
              dispatch(
                fetchStampReference({
                  stamp_code: current.stamp_code,
                })
              )
            }
          />
        );
      },
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="新規登録・台紙一覧"
        icon={<Icons.IconStamp className="stamp-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1472}>
          <TextBox formik={formik} label="台紙CD" fieldName="stamp_code" />
          <TextBox formik={formik} label="台紙名" fieldName="stamp_name" />
          <DateTimeRangePicker
            formik={formik}
            label="台紙表示開始日"
            fromFieldName="display_start_date"
            toFieldName="display_end_date"
          />
          <SelectBox
            formik={formik}
            label="有効期限種別"
            fieldName="valid_days_flag"
            options={
              selectOptionList?.filter(s => s.field_name === '有効期限種別')?.[0]
                ?.select_option ?? []
            }
          />
          <SelectBox
            formik={formik}
            label="ブランド"
            fieldName="brand_code"
            mode="multiple"
            options={accountBrandList}
            extraOnChange={() => formik.setFieldValue('shop_code', [])}
          />
          <SelectBox
            formik={formik}
            label="店舗"
            fieldName="shop_code"
            mode="multiple"
            options={accountShopList.filter(
              shop =>
                brand_code.length === 0 || brand_code.includes(shop.brand_code)
            )}
          />
          <TextBox formik={formik} label="クーポンCD" fieldName="coupon_code" />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={formik.values.deleted_flg === 1}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flg',
                formik.values.deleted_flg === 1 ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(stampActions.setStampModalVisible(true))}
      />
      <TcTable
        columns={columns}
        dataSource={stampList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(stampActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <StampModal />
      <StampReferenceModal />
    </div>
  );
};

export default Stamp;
