import { useEffect } from 'react';
import { Modal } from 'antd';
import { useFormik } from 'formik';
import Button from '../../tcButton';
import TcRadio from '../../tcCheckbox/tcRadio';
import { linkDisplayOptions } from '../../../constants/options';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import InputSelect from '../../inputSelect';
import Yup from '../../../utils/yupUtil';

const LinkInsertModal = ({ visible, onCancel, onOk, currentValue }) => {
  const formik = useFormik({
    initialValues: {
      link_url: '',
      app_display_flag: '0',
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      link_url: Yup.string().required(),
    }),
    onSubmit: values => {
      onOk(values);
    },
  });

  useEffect(() => {
    handlerFormikFieldChange(formik, 'link_url', currentValue?.link_url ?? '');
    handlerFormikFieldChange(
      formik,
      'app_display_flag',
      currentValue?.app_display_flag ?? '0'
    );
  }, [currentValue]);

  return (
    <Modal visible={visible} footer={null} closable={false} onCancel={onCancel}>
      <div className="link-modal-wrapper">
        <div className="link-modal-header">
          <span className="text-title text-bold">リンク追加 </span>
          <span style={{ color: '#990000', fontSize: '0.75rem' }}>
            *は必須項目です
          </span>
        </div>
        <InputSelect
          formik={formik}
          preLabel="URL"
          fieldName="link_url"
          requiredItem={true}
          styleType="block-grey"
          placeholder="https://"
          width={420}
        />
        <div
          className="check-area"
          style={{ display: 'flex', alignItems: 'center', margin: '30px 0' }}
        >
          <span className={'pre-label label-required'} style={{ width: 130 }}>
            表示方式
          </span>
          <TcRadio
            options={linkDisplayOptions}
            formik={formik}
            width={280}
            fieldName={'app_display_flag'}
            onChange={v =>
              handlerFormikFieldChange(formik, 'app_display_flag', v)
            }
            isRadio={true}
          />
        </div>
        <div
          className="action-container"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button text="戻る" theme="white" onClick={onCancel} />
          <Button
            text="挿入"
            disabled={!formik.isValid}
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LinkInsertModal;
