import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Image } from 'antd';
import { staffManagementcolumns } from '../../constants/columns';
import {
  handlerFormikFieldChange,
  numberFormat,
  spliceArray,
} from '../../utils/fnUtil';
import {
  staffManagementActions,
  fetchStaffList,
  fetchStaffDetail,
  fetchStaffReference,
  deleteStaff,
} from '../../slices/staffManagementSlice';
import { globalActions } from '../../slices/globalSlice';
import { staffAuthOptions } from '../../constants/options';
import TcButton from '../../components/tcButton';
import PageTitle from '../../components/pageTitle';
import TableTitle from '../../components/tableTitle';
import ColumnTitle from '../../components/columnTitle';
import TcCheckbox from '../../components/tcCheckbox';
import StaffModal from '../../components/staffModal';
import StaffReferenceModal from '../../components/staffModal/staffReferenceModal';
import TcTable from '../../components/tcTable';
import Icons from '../../constants/Icons';
import noimage from '../../images/noimage.png';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';

const StaffManagement = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountBrandList, accountShopList, accountInfo } = useSelector(
    state => state.account
  );

  const { searchParams, staffList, total, total_count, active_count } =
    useSelector(state => state.staffManagement);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: async values =>
      dispatch(staffManagementActions.saveSearchParams(values)),
  });

  useEffect(
    () => dispatch(fetchStaffList(searchParams)),
    [dispatch, searchParams]
  );

  useEffect(() => () => dispatch(staffManagementActions.reset()), [dispatch]);

  const columns = spliceArray(
    [
      ...staffManagementcolumns.map(col => ({
        title: (
          <ColumnTitle
            title={col.title}
            formik={formik}
            sortField={col.sortField}
            onClick={sort =>
              dispatch(staffManagementActions.saveSearchParams({ sort }))
            }
          />
        ),
        dataIndex: col.dataIndex ?? col.sortField,
        render: col.render,
      })),
      {
        title: '編集',
        align: 'center',
        width: '80px',
        render: current =>
          current.status === 2 ? (
            <Icons.IconEditGy className="table-cell-icon tc-disabled-icon" />
          ) : (
            <Icons.IconEdit
              className="table-cell-icon tc-action-icon"
              onClick={() =>
                dispatch(fetchStaffDetail({ staff_code: current.staff_code }))
              }
            />
          ),
      },
      {
        title: '削除',
        align: 'center',
        width: '80px',
        render: current =>
          current.status === 2 ? (
            <Icons.IconSearch
              className="table-cell-icon tc-action-icon"
              onClick={() =>
                dispatch(
                  fetchStaffReference({
                    staff_code: current.staff_code,
                  })
                )
              }
            />
          ) : accountInfo.staff_auth >= current.staff_auth ? (
            <Icons.IconTrashGy className="table-cell-icon" />
          ) : (
            <Icons.IconTrash
              className="table-cell-icon tc-action-icon"
              onClick={() =>
                dispatch(
                  globalActions.showDeleteModal({
                    message: `${current.staff_code}  ${current.staff_name}\nを削除します\nよろしいですか?`,
                    tip: '※同一のCDは使用不可となります',
                    deleteAction: deleteStaff({
                      staff_code: current.staff_code,
                    }),
                  })
                )
              }
            />
          ),
      },
    ],
    1,
    {
      title: 'アイコン',
      align: 'center',
      render: staff => (
        <Image
          src={staff.img || noimage}
          className="circle-image"
          preview={false}
        />
      ),
    }
  );

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="社員管理"
        subTitle={[
          `全社員数 ${numberFormat(total_count)}`,
          `利用中社員数 ${numberFormat(active_count)}`,
        ]}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="社員CD" fieldName="staff_code" />
          <TextBox formik={formik} label="社員名" fieldName="staff_name" />
          <SelectBox
            formik={formik}
            label="権限"
            fieldName="staff_auth"
            options={selectOptionList?.filter(s => s.field_name === '権限')?.[0]
              ?.select_option ?? [].filter(
              option => option.value >= accountInfo.staff_auth
            )}
          />
          <SelectBox
            formik={formik}
            label="ブランド"
            fieldName="brand_code"
            options={accountBrandList}
            mode={'multiple'}
          />
          <SelectBox
            formik={formik}
            label="店舗"
            fieldName="shop_code"
            options={accountShopList}
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={formik.values.deleted_flag === 1}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flag',
                formik.values.deleted_flag === 1 ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickCsv={() => dispatch(globalActions.openFileUploadModal(1))}
        onClickAdd={() =>
          dispatch(staffManagementActions.setStaffModalVisible(true))
        }
      />
      <TcTable
        columns={columns}
        dataSource={staffList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(staffManagementActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <StaffModal />
      <StaffReferenceModal />
    </div>
  );
};

export default StaffManagement;
