import { memo } from 'react';
import { Image } from 'antd';
import noimage from '../../images/noimage.png';
import BaseUpload from '../baseUpload';
import classNames from 'classnames';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import './style.scss';

const TcAvatar = memo(({ formik, disabled, fieldName, readOnly, src }) => (
  <div
    className={classNames('file-upload-area', {
      'read-only': readOnly,
    })}
  >
    <BaseUpload
      onChange={url => handlerFormikFieldChange(formik, fieldName, url)}
      disabled={disabled || readOnly}
    >
      <div className="avator">
        <Image
          src={(readOnly ? src : formik.values[fieldName]) || noimage}
          preview={
            !disabled &&
            !readOnly && {
              mask: '画像を選択',
              visible: false,
            }
          }
        />
      </div>
    </BaseUpload>
    {!readOnly && (
      <div className="upload-tip">
        <span>png,gif,jpg,jpeg形式のみアップロード可</span>
        <span>画像は5M以下でアップロードしてください</span>
        <span>推奨画像サイズ:正方形</span>
      </div>
    )}
  </div>
));

export default TcAvatar;
