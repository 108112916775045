import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Modal } from 'antd';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import { flagOptions } from '../../constants/options';
import './style.scss';
import { getOptionLabel } from '../../utils/fnUtil';
import {
  fetchLotteryTemplateList,
  fetchLotteryCouponList,
  lotteryActions,
} from '../../slices/lotterySlice';
import ImagesReference from '../imagesReference';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';

const LotteryReferenceModal = () => {
  const dispatch = useDispatch();

  const { referenceLottery, templateList, couponList } = useSelector(
    state => state.lottery
  );

  const {
    accountInfo: { new_contents_flag },
  } = useSelector(state => state.account);

  const modalVisible = !!referenceLottery;

  const onBack = () => dispatch(lotteryActions.closeReferenceModal());

  const createDateElement = (date, type) => {
    const dateObject = new Date(date);
    let formattedDate = '';

    if (type === 'date') {
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDate();
      formattedDate = `${year}/${month.toString().padStart(2, '0')}/${day
        .toString()
        .padStart(2, '0')}`;
    } else if (type === 'hh') {
      formattedDate = `${dateObject.getHours().toString().padStart(2, '0')}`;
    } else if (type === 'mm') {
      formattedDate = `${dateObject.getMinutes().toString().padStart(2, '0')}`;
    }

    return formattedDate;
  };

  useEffect(
    () =>
      modalVisible &&
      dispatch(fetchLotteryTemplateList()) &&
      dispatch(fetchLotteryCouponList()),
    [modalVisible]
  );

  return (
    <Modal
      className={'lottery-modal tc-modal'}
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'抽選参照'} />

        <div className="input-area lottery-input-area">
          <div className="code-area">
            <span className={'pre-label'}>抽選CD</span>
            {referenceLottery?.lottery_code}
          </div>

          <div className="lottery-image-display-area">
            <div className="image-area lottery-image-area">
              <span className="pre-label">バナー画像</span>
              <div className="image-select">
                <Image
                  src={referenceLottery?.banner_image_url}
                  width={150}
                  height={150}
                  preview={false}
                />
              </div>
            </div>
          </div>

          <div className="code-area">
            <span className={'pre-label'}>抽選名</span>
            {referenceLottery?.lottery_name}
          </div>

          <div className="code-area" style={{ alignItems: 'flex-start' }}>
            <span className={'pre-label'}>抽選説明</span>
            {new_contents_flag !== 0 ? (
              <div className="text-area" style={{ flexGrow: 1 }}>
                <span className="editor-detail">
                  <RichTextEditor
                    readOnly={true}
                    editorState={toSlateState(
                      referenceLottery?.lottery_rich_content
                    )}
                  />
                </span>
              </div>
            ) : (
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {referenceLottery?.lottery_content}
              </span>
            )}
          </div>
          {new_contents_flag !== 0 ? (
            <></>
          ) : (
            <div className="lottery-reference-modal-image">
              <label
                className="text"
                style={{ width: '150px', fontSize: '0.75rem', flexShrink: 0 }}
              >
                画像
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '770px',
                }}
              >
                <ImagesReference
                  image_url={referenceLottery?.image_url_list}
                  from={0}
                  to={2}
                  width={150}
                  height={150}
                />
              </div>
            </div>
          ) }

          <div className="lottery-reference-modal-template-area">
            <span className={'lottery-modal_reference_label'}>
              定型文
            </span>
            <div className="lottery-reference-modal-template-text">
              <span
                className="lottery-reference-modal-text"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {referenceLottery?.lottery_template}
              </span>
            </div>
          </div>

          <div className="date-area">
            <div className={"lottery-modal_update_date"} style={{marginTop: 25}}>
              <label className={"lottery-modal_reference_label"}>
                キャンペーン表示期間
              </label>
              <div className={"lottery-modal_update_date_area"}>
                <span className={"lottery-modal_update_date_text"} style={{minWidth: "75px"}}>{createDateElement(referenceLottery?.public_start_date, 'date')}</span>
                <span className={"lottery-modal_update_date_text"}>{`${createDateElement(referenceLottery?.public_start_date, 'hh')}:${createDateElement(referenceLottery?.public_start_date, 'mm')}`}</span>
                <span className={"lottery-modal_update_date_text"}>{`〜`}</span>
                <span className={"lottery-modal_update_date_text"}>{createDateElement(referenceLottery?.public_end_date, 'date')}</span>
                <span className={"lottery-modal_update_date_text"}>{`${createDateElement(referenceLottery?.public_end_date, 'hh')}:${createDateElement(referenceLottery?.public_end_date, 'mm')}`}</span>
              </div>
            </div>
          </div>

          <div className="date-area">
            <div className={"lottery-modal_update_date"} style={{marginTop: 25}}>
              <label className={"lottery-modal_reference_label"}>
                応募受付期間
              </label>
              <div className={"lottery-modal_update_date_area"}>
                <span className={"lottery-modal_update_date_text"} style={{minWidth: "75px"}}>{createDateElement(referenceLottery?.entry_public_start_date, 'date')}</span>
                <span className={"lottery-modal_update_date_text"}>{`${createDateElement(referenceLottery?.entry_public_start_date, 'hh')}:${createDateElement(referenceLottery?.entry_public_start_date, 'mm')}`}</span>
                <span className={"lottery-modal_update_date_text"}>{`〜`}</span>
                <span className={"lottery-modal_update_date_text"}>{createDateElement(referenceLottery?.entry_public_end_date, 'date')}</span>
                <span className={"lottery-modal_update_date_text"}>{`${createDateElement(referenceLottery?.entry_public_end_date, 'hh')}:${createDateElement(referenceLottery?.entry_public_end_date, 'mm')}`}</span>
              </div>
            </div>
          </div>

          <div className="date-area">
            <div className={"lottery-modal_update_date"} style={{marginTop: 25}}>
              <label className={"lottery-modal_reference_label"}>
                抽選結果公開日
              </label>
              <div className={"lottery-modal_update_date_area"}>
                <span className={"lottery-modal_update_date_text"} style={{minWidth: "75px"}}>{createDateElement(referenceLottery?.result_public_date, 'date')}</span>
                <span className={"lottery-modal_update_date_text"}>{`${createDateElement(referenceLottery?.result_public_date, 'hh')}:${createDateElement(referenceLottery?.result_public_date, 'mm')}`}</span>
              </div>
            </div>
          </div>

          <div className="date-area">

            <div className={"lottery-modal_update_date"} style={{marginTop: 25}}>
              <label className={"lottery-modal_reference_label"}>
                プッシュ通知
              </label>
              <div className={"lottery-modal_update_date_area"}>
                <span className={"lottery-modal_update_date_text"} style={{minWidth: "75px"}}>キャンペーン表示開始</span>
                <span className={"lottery-modal_update_date_text"}>{getOptionLabel(
                  flagOptions,
                  referenceLottery?.public_notice_flag.toString()
                )}</span>

                <span className={"lottery-modal_update_date_text"} style={{minWidth: "75px"}}>抽選表示開始</span>
                <span className={"lottery-modal_update_date_text"}>{getOptionLabel(
                  flagOptions,
                  referenceLottery?.entry_notice_flag.toString()
                )}</span>

                <span className={"lottery-modal_update_date_text"} style={{minWidth: "75px"}}>抽選結果公開</span>
                <span className={"lottery-modal_update_date_text"}>{getOptionLabel(
                  flagOptions,
                  referenceLottery?.result_notice_flag.toString()
                )}</span>
              </div>
            </div>
          </div>

          <div className="lottery-reference-coupone-area">
            <div className="lottery-reference-coupon-text">景品</div>
            <div className="lottery-reference-modal-coupon-list">
              {referenceLottery?.coupon_list?.map((_, i) => (
                <div className="lottery-reference-modal-coupon-border">
                  <div className="lottery-reference-modal-coupon">
                    <span className="lottery-reference-modal-label">
                      クーポン
                    </span>
                    <span className="lottery-reference-modal-text">
                      {referenceLottery?.coupon_list?.[i]?.coupon_code}
                    </span>
                    <span className="lottery-reference-modal-text">
                      {couponList
                        ?.filter(
                          c =>
                            c?.value ===
                            referenceLottery?.coupon_list?.[i]?.coupon_code
                        )
                        .map(coupon => coupon?.label)}
                    </span>
                  </div>
                  <div
                    className="lottery-reference-modal-coupon"
                    style={{ marginTop: '30px' }}
                  >
                    <span className="lottery-reference-modal-label">
                      当選本数
                    </span>
                    <span className="lottery-reference-modal-text">
                      {referenceLottery?.coupon_list?.[
                        i
                      ]?.winner_count?.toLocaleString()}
                    </span>
                    <span className="lottery-reference-modal-text">本</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="button-container">
            <TcButton text="閉じる" theme="white" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LotteryReferenceModal;
