import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';

const initialSearchParams = {
  coupon_code: '',
  coupon_name: '',
  from_delivery_date: null,
  to_delivery_date: null,
  coupon_type: null,
  brands: [],
  shops: [],
  couponcard_code: '',
  couponcard_name: '',
  offset: 0,
  count: 10,
  sort: 'coupon_code_desc',
  csv_coupon_type: '0',
  csv_brands: [''],
  csv_shops: [''],
  csv_from_delivery_date: null,
  csv_to_delivery_date: null,
};

const initialState = {
  searchParams: initialSearchParams,
  couponResultList: [],
  showFields: [],
  total: 0,
  csvDownloadModalVisible: false,
  selectColumsVisible: false,
  favoriteItem: [],
};

export const fetchCouponResultList = createAsyncThunk(
  'fetchCouponResultList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('coupon/achieve_list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchCouponResultCsvFavoriteItem = createAsyncThunk(
  'fetchCouponResultCsvFavoriteItem',
  async () =>
    axios.get('csv/favorite_item/list', { params: { screen_type: '0' } })
);

export const updateCouponResultCsvFavoriteItem = createAsyncThunk(
  'updateCouponResultCsvFavoriteItem',
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post(
      'csv/favorite_item/create',
      params
    );
    if (updateFavoriteRes?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));
    return params.favorite;
  }
);

export const csvDownloadCouponResult = createAsyncThunk(
  'csvDownloadCouponResult',
  async params => await axios.get('csv/download', { params })
);

export const couponResultSlice = createSlice({
  name: 'couponResultSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      showFields: payload,
    }),
    openCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    openSelectColumsModal: state => ({
      ...state,
      selectColumsVisible: true,
    }),
    closeSelectColumsModal: state => ({
      ...state,
      selectColumsVisible: false,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchCouponResultList.fulfilled]: (state, { payload }) => {
      state.couponResultList = payload.item.map(e => {
        const brands = e.brands
          ? `${e.brands[0] ?? ''}` +
            (e.brands.length >= 2
              ? `${'\r\n'}他${e.brands.length - 1}ブランド`
              : '')
          : '全件';
        const shops = e.shops
          ? `${e.shops[0] ?? ''}` +
            (e.shops.length >= 2 ? `${'\r\n'}他${e.shops.length - 1}店舗` : '')
          : '全件';
        return {
          ...e,
          brands,
          shops,
          delivery_date: e?.delivery_date || '',
          couponcard_code: e?.couponcard_code || '',
          couponcard_name: e?.couponcard_name || '',
        };
      });
      state.total = payload.total_count;
    },
    [fetchCouponResultCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload?.item?.favorite || {};
    },
    [updateCouponResultCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload;
    },
    [csvDownloadCouponResult.fulfilled]: (state, { payload }) => {
      if (typeof payload === 'string') {
        downloadFile(
          payload,
          `クーポン単位実績一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
        );
      }
    },
  },
});

export const couponResultActions = couponResultSlice.actions;

export default couponResultSlice.reducer;
