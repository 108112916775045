import { memo } from 'react';
import { Select } from 'antd';
import {
  compareMemoObject,
  compareMemoArray,
  handlerFormikFieldChange,
  getFormikArrayProps,
} from '../../../utils/fnUtil';
import { CheckOutlined } from '@ant-design/icons';
import Icons from '../../../constants/Icons';
import classNames from 'classnames';
import './style.scss';

const SelectBox = memo(
  ({
    className,
    customOnChange,
    disabled = false,
    extraOnChange,
    fieldName,
    flexEnd,
    formik,
    isBold,
    isRequired = false,
    label,
    listFieldName = null,
    mode = null,
    options = null,
    placeholder,
    prefix,
    preLabel,
    suffix,
    uneditable,
    usePopContainer = true,
    displayValue = null,
    width = 350,
    maxWidth,
    style,
    hasLabel = true,
  }) => {
    const wrapperClassName = classNames('tc-select-wrapper', className, {
      'tc-wrapper-uneditable': uneditable,
    });

    const labelClassName = classNames('tc-select-label', {
      'label-bold': isBold,
      'label-disabled': disabled,
      'label-required': isRequired,
    });

    const selectClassName = classNames('tc-select', {
      'select-disabled': disabled,
    });

    const preLabelClassName = classNames('tc-select-preLabel', {
      'label-disabled': disabled,
      'label-required': isRequired && !disabled,
      'label-required-disabled': isRequired && disabled,
    });
    const prefixClassName = classNames('prefix', {
      'label-disabled': disabled,
    });
    const suffixClassName = classNames('suffix', {
      'label-disabled': disabled,
    });
    const labelAreaClassName = classNames('tc-select-label-area', {
      'flex-end': !!preLabel || flexEnd,
    });

    const defaultPlaceHolder = disabled
      ? ''
      : placeholder ?? `${label || preLabel || ''}を選択してください`;

    const formikArrayProps = listFieldName
      ? getFormikArrayProps(formik, listFieldName)
      : null;

    fieldName = listFieldName ? listFieldName : fieldName;
    return (
      <div className={wrapperClassName} style={{ width, maxWidth, ...style }}>
        {hasLabel && (
          <div className={labelAreaClassName}>
            {label && <label className={labelClassName}>{label}</label>}
            <span className="tc-select-error">
              {!!formikArrayProps
                ? formikArrayProps.touched && formikArrayProps.error
                : formik.touched[fieldName] && formik.errors[fieldName]}
            </span>
          </div>
        )}
        <div className="tc-select-line">
          {preLabel && <span className={preLabelClassName}>{preLabel}</span>}
          {prefix && <span className={prefixClassName}>{prefix}</span>}
          {uneditable ? (
            <span className="uneditable">
              {displayValue ??
                options.find(o => o.value === String(formik.values[fieldName]))
                  ?.label}
            </span>
          ) : (
            <Select
              className={selectClassName}
              mode={mode}
              showSearch={true}
              filterOption={(input, option) =>
                `${option.children}`
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onBlur={() => formik.setFieldTouched(fieldName, true)}
              getPopupContainer={
                usePopContainer ? triggerNode => triggerNode.parentNode : null
              }
              maxTagCount="responsive"
              placeholder={defaultPlaceHolder}
              maxTagPlaceholder={'...'}
              value={formik.getFieldProps(fieldName).value}
              defaultActiveFirstOption={false}
              suffixIcon={
                disabled ? (
                  <Icons.IconChevronDownGy className="ant-select-suffix select-icon" />
                ) : (
                  <Icons.IconChevronDown className="ant-select-suffix select-icon" />
                )
              }
              onChange={v => {
                if (customOnChange) {
                  customOnChange(v, fieldName);
                } else {
                  handlerFormikFieldChange(formik, fieldName, v ?? null);
                  extraOnChange?.(fieldName, v);
                }
              }}
              allowClear={true}
              showArrow={true}
              disabled={disabled}
              menuItemSelectedIcon={
                <CheckOutlined style={{ color: 'black' }} />
              }
            >
              {options?.map(o => (
                <Select.Option value={o.value} key={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          )}
          {suffix && <span className={suffixClassName}>{suffix}</span>}
        </div>
      </div>
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, [
      'fieldName',
      'fromFieldName',
      'toFieldName',
    ]) &&
    pre.options === next.options &&
    pre.disabled === next.disabled &&
    pre.fieldName === next.fieldName &&
    (pre.listFieldName ? compareMemoArray(pre, next) : true)
);

export default SelectBox;
