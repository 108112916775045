import { memo } from 'react';
import {
  compareMemoObject,
  compareMemoArray,
  getFormikArrayProps,
  dateFormat,
} from '../../../utils/fnUtil';
import classNames from 'classnames';
import moment from 'moment';
import { get } from 'lodash';
import './style.scss';
import DatePickerWrapper from '../datePickerWrapper';
import TimePickerWrapper from '../timePickerWrapper';

const DateTimeRangePicker = memo(
  ({
    className,
    disabled = false,
    formik,
    fromDisabledDate = null,
    fromFieldName,
    fromHourFieldName,
    fromMinuteFieldName,
    isBold,
    isRequired = false,
    label,
    listFieldName = null,
    prefix,
    preLabel,
    rangeText = '〜',
    suffix,
    toDisabledDate = null,
    toFieldName,
    toHourFieldName,
    toMinuteFieldName,
    fromUneditable,
    toUneditable,
    usePopContainer = true,
    useTimePicker,
    width = 350,
    maxWidth = 160,
    hasLabel = true,
    style,
    minuteStep = 1,
  }) => {
    const wrapperClassName = classNames('tc-range-picker-wrapper', className);
    const labelClassName = classNames('tc-range-picker-label', {
      'label-bold': isBold,
      'label-disabled': disabled,
      'label-required': isRequired,
    });
    const preLabelClassName = classNames('tc-range-picker-preLabel', {
      'label-disabled': disabled,
      'label-required': isRequired && !disabled,
      'label-required-disabled': isRequired && disabled,
    });
    const prefixClassName = classNames('prefix', {
      'label-disabled': disabled,
    });
    const suffixClassName = classNames('suffix', {
      'label-disabled': disabled,
    });
    const rangeTextClassName = classNames('range-text', {
      'label-disabled': disabled,
    });
    const labelAreaClassName = classNames('tc-range-picker-label-area');

    const formikArrayProps = listFieldName
      ? getFormikArrayProps(formik, listFieldName)
      : null;

    // fieldName = listFieldName ? listFieldName : fieldName;

    return (
      <div className={wrapperClassName} style={{ width, ...style }}>
        {hasLabel && (
          <div className={labelAreaClassName}>
            {label && <label className={labelClassName}>{label}</label>}
          </div>
        )}
        <div className="tc-range-picker-line">
          {preLabel && <span className={preLabelClassName}>{preLabel}</span>}
          {prefix && <span className={prefixClassName}>{prefix}</span>}
          {fromUneditable ? (
            <span className="uneditable">
              {`${dateFormat(formik.values[fromFieldName], 'YYYY/M/D')} ${
                formik.values[fromHourFieldName]
              }:${formik.values[fromMinuteFieldName]}`}
            </span>
          ) : (
            <div className="tc-range-picker-container">
              <span className="tc-range-picker-error">
                {formik.touched[fromFieldName] &&
                  formik.touched[fromHourFieldName] &&
                  formik.touched[fromMinuteFieldName] &&
                  formik.errors[fromFieldName]}
              </span>
              <div style={{ display: 'flex' }}>
                <DatePickerWrapper
                  formik={formik}
                  fieldName={fromFieldName}
                  uneditable={fromUneditable}
                  usePopContainer={usePopContainer}
                  disabled={disabled}
                  disabledDate={
                    fromDisabledDate ||
                    (current =>
                      toFieldName &&
                      current > moment(get(formik.values, toFieldName, null)))
                  }
                  hasLabel={false}
                  maxWidth={maxWidth}
                />
                {fromHourFieldName && fromMinuteFieldName && (
                  <TimePickerWrapper
                    formik={formik}
                    hourFieldName={fromHourFieldName}
                    minuteFieldName={fromMinuteFieldName}
                    uneditable={fromUneditable}
                    usePopContainer={usePopContainer}
                    disabled={disabled}
                    style={{ marginLeft: 6, flex: 1 }}
                    hasLabel={false}
                    maxWidth={maxWidth}
                    minuteStep={minuteStep}
                  />
                )}
              </div>
            </div>
          )}
          {toFieldName && (
            <>
              <span className={rangeTextClassName}>{rangeText}</span>

              {toUneditable ? (
                <span className="uneditable">
                  {`${dateFormat(formik.values[toFieldName], 'YYYY/M/D')} ${
                    formik.values[toHourFieldName]
                  }:${formik.values[toMinuteFieldName]}`}
                </span>
              ) : (
                <div className="tc-range-picker-container">
                  <span className="tc-range-picker-error">
                    {formik.touched[toFieldName] &&
                      formik.touched[toHourFieldName] &&
                      formik.touched[toMinuteFieldName] &&
                      formik.errors[toFieldName]}
                  </span>
                  <div style={{ display: 'flex' }}>
                    <DatePickerWrapper
                      disabledDate={
                        toDisabledDate ||
                        (current =>
                          current <
                          moment(get(formik.values, fromFieldName, null)))
                      }
                      formik={formik}
                      disabled={disabled}
                      fieldName={toFieldName}
                      uneditable={toUneditable}
                      useTimePicker={useTimePicker}
                      usePopContainer={usePopContainer}
                      hasLabel={false}
                      maxWidth={maxWidth}
                    />
                    {toHourFieldName && toMinuteFieldName && (
                      <TimePickerWrapper
                        formik={formik}
                        hourFieldName={toHourFieldName}
                        minuteFieldName={toMinuteFieldName}
                        uneditable={toUneditable}
                        usePopContainer={usePopContainer}
                        disabled={disabled}
                        style={{ marginLeft: 6, flex: 1 }}
                        hasLabel={false}
                        maxWidth={maxWidth}
                        minuteStep={minuteStep}
                      />
                    )}
                  </div>
                </div>
              )}

              {/* {rangeText ? (
                        <span className={preLabelClassName}>{rangeText}</span>
                      ) : (
                        <span className="range-text">{rangeText}</span>
                      )} */}
            </>
          )}
          {suffix && <span className={suffixClassName}>{suffix}</span>}
        </div>
      </div>
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, [
      'fromFieldName',
      'fromHourFieldName',
      'fromMinuteFieldName',
      'toFieldName',
      'toHourFieldName',
      'toMinuteFieldName',
    ]) &&
    pre.disabled === next.disabled &&
    (pre.listFieldName ? compareMemoArray(pre, next) : true)
);

export default DateTimeRangePicker;
