import { Upload } from 'antd';
import { getBase64 } from '../../utils/fnUtil';
import store from '../../slices/store';
import { globalActions } from '../../slices/globalSlice';

const BaseUpload = ({ children, onChange, ...rest }) => {
  const beforeUpload = file => {
    const showError = message => {
      store.dispatch(
        globalActions.showErrorModal({
          title: '読み取りエラー',
          message,
        })
      );
    };

    const isFormateValid = [
      'image/png',
      'image/gif',
      'image/jpg',
      'image/jpeg',
    ].includes(file.type);
    if (!isFormateValid) {
      showError('png,gif,jpg,jpeg形式でアップロードしてください');
      return false;
    }

    const isLt5M = file.size / 1024 / 1024 <= 5;

    if (!isLt5M) {
      showError('画像は5M以下でアップロードしてください');
      return false;
    }

    getBase64(file, url => onChange(url));

    return false;
  };

  return (
    <Upload
      accept={'.png,.gif,.jpg,.jpeg'}
      beforeUpload={beforeUpload}
      showUploadList={false}
      {...rest}
    >
      {children}
    </Upload>
  );
};

export default BaseUpload;
