import React, { memo } from 'react';
import { Select } from 'antd';
import {
  compareMemoObject,
  handlerFormikFieldChange,
} from '../../utils/fnUtil';
import { get } from 'lodash';
import './style.scss';

/* 数字選択ピッカー */

const NumberPicker = memo(
  ({
    formik,
    from,
    to,
    style,
    startFormName,
    endFormName,
    formName,
    textAfter,
    disabled = false,
    zeroPadding = false,
    uneditable,
    placeholder = '',
    allowClear = false,
  }) => {
    let picker = [];
    for (let i = from; i <= to; i++) {
      if (zeroPadding) {
        let num = ('00' + i).slice(-2);
        picker.push(<Select.Option value={num}>{num}</Select.Option>);
      } else {
        picker.push(
          <Select.Option
            disabled={
              (startFormName && i <= get(formik.values, startFormName)) ||
              (endFormName && i >= get(formik.values, endFormName))
            }
            value={i}
          >
            {i}
          </Select.Option>
        );
      }
    }

    return (
      <>
        {uneditable ? (
          <div style={style}>{formik.values[formName]}</div>
        ) : (
          <Select
            name={formName}
            style={style}
            className="number-picker"
            value={get(formik.values, formName)}
            onChange={val => {
              handlerFormikFieldChange(formik, formName, val);
            }}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
          >
            {picker}
          </Select>
        )}
        {textAfter && <span className="number-picker-after">{textAfter}</span>}
      </>
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, ['formName']) &&
    pre.disabled === next.disabled &&
    pre.formName === next.formName
);

export default NumberPicker;
