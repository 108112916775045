import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { lineRichmenuColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import Icons from '../../constants/Icons';
import noimage from '../../images/noimage.png';
import {
  fetchLineRichmenuCopy,
  fetchLineRichmenuDelete,
  fetchLineRichmenuDetail,
  fetchLineRichmenuList,
  fetchLineRichmenuReference,
  lineRichmenuActions,
} from '../../slices/lineRichmenuSlice';
import { fetchLineAccountListForSelect } from '../../slices/lineAccountSlice';
import PageTitle from '../../components/pageTitle';
import TableTitle from '../../components/tableTitle';
import TcTable from '../../components/tcTable';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import TcCheckbox from '../../components/tcCheckbox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import TcButton from '../../components/tcButton';
import InputContainer from '../../components/inputContainer';
import LineRichmenuModal from '../../components/lineRichmenuModal';
import LineRichmenuReferenceModal from '../../components/lineRichmenuModal/reference';
import DeleteModal from '../../components/modal/deleteModal';
import { dateFormat, handlerFormikFieldChange, spliceArray } from '../../utils/fnUtil';
import { Image } from 'antd';
import './style.scss';

const LineRichmenu = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { total, deleteLineRichmenu, searchParams, lineRichmenuList } = useSelector(
    state => state.lineRichmenu
  );
  const { lineAccountOptionList } = useSelector(state => state.lineAccount);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => {
      const { from_start_date, to_start_date, from_create_at, to_create_at, ...rest } = values;
      const params = {
        ...rest,
        from_start_date: dateFormat(from_start_date, 'YYYY/MM/DD'),
        to_start_date: dateFormat(to_start_date, 'YYYY/MM/DD'),
        from_create_at: dateFormat(from_create_at, 'YYYY/MM/DD'),
        to_create_at: dateFormat(to_create_at, 'YYYY/MM/DD'),
      };
      dispatch(lineRichmenuActions.saveSearchParams(params));
    },
  });

  useUpdateEffect(() => {
    dispatch(fetchLineRichmenuList(searchParams));
  }, [searchParams]);

  useEffect(() => {
    dispatch(fetchLineAccountListForSelect());
    dispatch(lineRichmenuActions.saveSearchParams(null));
    return () => dispatch(lineRichmenuActions.clear());
  }, [dispatch]);

  const columns = spliceArray(
    [
      ...lineRichmenuColumns.map(col => ({
        title: (
          <ColumnTitle
            title={col.title}
            formik={formik}
            sortField={col.sortField}
            onClick={sort => {
              dispatch(lineRichmenuActions.saveSearchParams({ sort }));
            }}
          />
        ),
        dataIndex: col.sortField,
        render: col.render,
      })),
      {
        title: '編集',
        align: 'center',
        width: '80px',
        render: current =>
          !current.delete_flag ? (
            <Icons.IconEdit
              className="tc-action-icon"
              height={'20px'}
              width={'20px'}
              onClick={() => dispatch(fetchLineRichmenuDetail(current))}
            />
          ) : (
            <Icons.IconEditGy
              className="tc-disabled-icon"
              height={'20px'}
              width={'20px'}
            />
          ),
      },
      {
        title: '複製',
        align: 'center',
        width: '80px',
        render: current => (
          <Icons.IconCopy
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchLineRichmenuCopy(current))}
          />
        ),
      },
      {
        title: '削除',
        align: 'center',
        width: '80px',
        render: current =>
          !current.delete_flag ? (
            <Icons.IconTrash
              className="tc-action-icon"
              height={'20px'}
              width={'20px'}
              onClick={() => {
                dispatch(lineRichmenuActions.openDeleteModal(current));
              }}
            />
          ) : (
            <Icons.IconSearch
              className="tc-action-icon"
              height={'20px'}
              width={'20px'}
              onClick={() => dispatch(fetchLineRichmenuReference(current))}
            />
          ),
      },
    ],
    3,
    {
      title: '画像',
      align: 'center',
      render: richmenu => (
        <Image
          src={richmenu.image_url || noimage}
          preview={false}
        />
      ),
    }
  );

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="新規登録・リッチメニュー一覧"
        icon={<Icons.IconLine />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="メニューCD" fieldName="menu_code" />
          <TextBox formik={formik} label="メニュー名" fieldName="menu_name" />
          <SelectBox
            formik={formik}
            label="公式アカウント名"
            fieldName="account_code"
            options={lineAccountOptionList}
          />
          <DateTimeRangePicker
            formik={formik}
            label="メニュー表示開始日"
            fromFieldName="from_start_date"
            toFieldName="to_start_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="登録日"
            fromFieldName="from_create_at"
            toFieldName="to_create_at"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['delete_flag']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'delete_flag',
                !!formik.values['delete_flag'] ? 0 : 1
              )
            }
          />
        </div>
      </div>

      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(lineRichmenuActions.openEditModal(null))}
      />
      <TcTable
        columns={columns}
        dataSource={lineRichmenuList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(lineRichmenuActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <LineRichmenuModal />
      <LineRichmenuReferenceModal />
      <DeleteModal
        modalVisible={!!deleteLineRichmenu}
        onCancel={() => dispatch(lineRichmenuActions.closeDeleteModal())}
        item={'メニュー'}
        itemValue={deleteLineRichmenu?.menu_code}
        onOk={() =>
          dispatch(
            fetchLineRichmenuDelete({
              menu_code: deleteLineRichmenu?.menu_code,
            })
          )
        }
      />
    </div>
  );
};

export default LineRichmenu;
