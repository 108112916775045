import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueteColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import Icons from '../../constants/Icons';
import {
  enqueteActions,
  fetchEnqueteList,
  fetchEnqueteDetail,
  fetchEnqueteReference,
  fetchEnqueteSummary,
  fetchEnqueteDelete,
  fetchEnqueteCsvFavoriteItem,
  fetchEnqueteCsvFavoriteItemCreate,
  fetchEnqueteCsvDownload,
  fetchEnqueteCopy,
} from '../../slices/enqueteSlice';
import {
  fetchCategoryList,
} from '../../slices/categorySlice';
import { CATEGORY_TYPE } from '../../constants/enums';
import TableTitle from '../../components/tableTitle';
import DeleteModal from '../../components/modal/deleteModal';
import TcTable from '../../components/tcTable';
import TcCheckbox from '../../components/tcCheckbox';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import EnqueteModal from '../../components/enqueteModal';
import EnqueteReferenceModal from '../../components/enqueteModal/reference';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import {
  csvDownloadTypes,
  csvFavoriteItemTypes,
  enqueteFavoriteItemOptions,
} from '../../constants/options';
import './style.scss';
import { useUpdateEffect } from 'react-use';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';

const Enquete = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const {
    searchParams,
    total,
    deleteEnquete,
    favoriteItem,
    csvEnquete,
    enqueteList,
  } = useSelector(state => state.enquete);

  const { categoryList } = useSelector(state => state.category);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => {
      const { public_start_date, public_end_date, ...rest } = values;
      const params = {
        ...rest,
        public_start_date: dateFormat(public_start_date, 'YYYY/MM/DD'),
        public_end_date: dateFormat(public_end_date, 'YYYY/MM/DD'),
      };

      dispatch(enqueteActions.saveSearchParams(params));
    },
  });

  useUpdateEffect(
    () =>
      dispatch(fetchCategoryList({ category_type: CATEGORY_TYPE.ENQUETE})) && dispatch(fetchEnqueteList(searchParams)),
    [searchParams]
  );

  useEffect(() => {
    dispatch(
      fetchEnqueteCsvFavoriteItem({
        screen_type: csvFavoriteItemTypes['enquete'],
      })
    ) && dispatch(enqueteActions.saveSearchParams(null));
    return () => dispatch(enqueteActions.clear());
  }, [dispatch]);

  const columns = [
    ...enqueteColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(enqueteActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      width: col.sortField === 'answer_count' ? '100px' : 'auto',
      render: col.render,
    })),
    {
      title: '結果',
      align: 'center',
      width: '150px',
      render: current => (
        <div className="summary-area">
          {current.answer_count ? (
            <>
              <div className="summary-item-area">
                <TcButton
                  text={'結果表示'}
                  theme={'white'}
                  icon={<Icons.IconResult className="btn-icon" />}
                  onClick={() => dispatch(fetchEnqueteSummary(current))}
                />
              </div>
              <TcButton
                text={'CSV出力'}
                theme={'white'}
                icon={<Icons.IconDownload className="btn-icon" />}
                onClick={() =>
                  dispatch(enqueteActions.openCsvDownloadModal(current))
                }
              />
            </>
          ) : (
            <>
              <div className="summary-item-area">
                <button className="disable-btn">
                  <Icons.IconResultGy className="btn-icon" /> {'結果表示'}
                </button>
              </div>
              <button className="disable-btn">
                <Icons.IconDownloadGy className="btn-icon" /> {'CSV出力'}
              </button>
            </>
          )}
        </div>
      ),
    },
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        !current.deleted_flg ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchEnqueteDetail(current))}
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        ),
    },
    {
      title: '複製',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconCopy
          className="tc-action-icon"
          height={'20px'}
          width={'20px'}
          onClick={() => dispatch(fetchEnqueteCopy(current))}
        />
      ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        !current.deleted_flg ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => {
              dispatch(enqueteActions.openDeleteModal(current));
            }}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchEnqueteReference(current))}
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page enquete" ref={parent}>
      <PageTitle
        title="新規登録・アンケート一覧"
        icon={<Icons.IconEnquete />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={724}>
          <TextBox
            formik={formik}
            label="アンケートCD"
            fieldName="enquete_code"
          />
          <TextBox
            formik={formik}
            label="アンケート名"
            fieldName="enquete_name"
          />
          <SelectBox
            formik={formik}
            label="アンケートカテゴリ"
            fieldName="category_code"
            options={categoryList.map(d => ({
              label: d.category_name,
              value: d.category_code
            }))}
            mode="multiple"
          />
          <DateTimeRangePicker
            formik={formik}
            label="アンケート開始日"
            fromFieldName="public_start_date"
            toFieldName="public_end_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['deleted_flg']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flg',
                !!formik.values['deleted_flg'] ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(enqueteActions.openEditModal(null))}
      />
      <TcTable
        columns={columns}
        dataSource={enqueteList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(enqueteActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <CsvDownloadModal
        line={3}
        modalVisible={!!csvEnquete}
        options={enqueteFavoriteItemOptions}
        favoriteItem={favoriteItem}
        width={570}
        onFavoriteItem={checkedList => {
          const initial = enqueteFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item.value]: '0',
              },
            }),
            {
              screen_type: csvFavoriteItemTypes['enquete'],
              favorite: {},
            }
          );

          const param = checkedList.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item]: '1',
              },
            }),
            initial
          );

          dispatch(fetchEnqueteCsvFavoriteItemCreate(param));
        }}
        onClose={() => dispatch(enqueteActions.closeCsvDownloadModal())}
        onDownload={checkedList =>
          dispatch(
            fetchEnqueteCsvDownload({
              screen_type: csvDownloadTypes['enquete'],
              enquete_code: csvEnquete?.enquete_code,
              columns: checkedList,
            })
          )
        }
      />

      <EnqueteModal />
      <EnqueteReferenceModal />
      <DeleteModal
        modalVisible={!!deleteEnquete}
        onCancel={() => dispatch(enqueteActions.closeDeleteModal())}
        item={'アンケート'}
        itemValue={deleteEnquete?.enquete_code}
        onOk={() =>
          dispatch(
            fetchEnqueteDelete({
              enquete_code: deleteEnquete?.enquete_code,
            })
          )
        }
      />
    </div>
  );
};

export default Enquete;
