import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Yup from '../../utils/yupUtil';
import {
  fetchLotteryTemplateList,
  fetchLotteryCreate,
  fetchLotteryCouponList,
  lotteryActions,
  fetchLotteryUpdate,
} from '../../slices/lotterySlice';
import { useFormik } from 'formik';
import Modal from 'antd/lib/modal/Modal';
import ModalTitle from '../modalTitle';
import InputSelect from '../inputSelect';
import TcButton from '../tcButton';
import { getOptionLabel, handlerFormikFieldChange } from '../../utils/fnUtil';
import BaseUpload from '../baseUpload';
import { Image } from 'antd';
import DatePickerBase from '../rangePicker/datePickerBase';
import NumberPicker from '../numberPicker';
import moment from 'moment';
import { globalActions } from '../../slices/globalSlice';
import TcRadio from '../tcCheckbox/tcRadio';
import { flagOptions } from '../../constants/options';
import Icons from '../../constants/Icons';
import './style.scss';
import { Icon } from '@material-ui/core';
import ImagesUploader from '../imagesUploader';
import ContentsTooltip from '../contentsTooltip';
import CouponList from './coupons';
import { serialize, toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';

const LotteryModal = () => {
  const dispatch = useDispatch();
  const format = (date, format) => (date ? moment(date)?.format(format) : null);

  const { currentLottery, editModalVisible, templateList, couponList } =
    useSelector(state => state.lottery);

  const {
    accountInfo: { new_contents_flag },
  } = useSelector(state => state.account);

  const initialCoupon = {
    coupon_code: null,
    winner_count: null,
  };

  const initialValues = {
    banner_image_url: null,
    lottery_code: null,
    lottery_name: '',
    lottery_content: null,
    lottery_rich_content: toSlateState(currentLottery?.lottery_rich_content),
    image_url_list: Array(10).fill(''),
    lottery_template: null,
    public_start_date: null,
    public_start_hour: null,
    public_start_minute: null,
    public_end_date: null,
    public_end_hour: null,
    public_end_minute: null,
    entry_public_start_date: null,
    entry_public_start_hour: null,
    entry_public_start_minute: null,
    entry_public_end_date: null,
    entry_public_end_hour: null,
    entry_public_end_minute: null,
    result_public_date: null,
    result_public_date_hour: null,
    result_public_date_minute: null,
    public_notice_flag: '0',
    entry_notice_flag: '0',
    result_notice_flag: '0',
    coupon_list: [initialCoupon],
  };

  const isCreate = !currentLottery;
  const isUpdate = !!currentLottery?.lottery_code;

  const yupObject = {
    banner_image_url: Yup.string().nullable().required(),
    lottery_name: Yup.string().required(),
    public_start_date: Yup.string()
      .nullable()
      .test(
        'public_start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.public_start_hour &&
          testContext.parent.public_start_minute
      ),
    public_end_date: Yup.string()
      .nullable()
      .test(
        'public_end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.public_end_hour &&
          testContext.parent.public_end_minute
      ),
    entry_public_start_date: Yup.string()
      .nullable()
      .test(
        'entry_public_start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.entry_public_start_hour &&
          testContext.parent.entry_public_start_minute
      ),
    entry_public_end_date: Yup.string()
      .nullable()
      .test(
        'entry_public_end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.entry_public_end_hour &&
          testContext.parent.entry_public_end_minute
      ),
    result_public_date: Yup.string()
      .nullable()
      .test(
        'result_public_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.result_public_date_hour &&
          testContext.parent.result_public_date_minute
      ),
    coupon_list: Yup.array().of(
      Yup.object({
        coupon_code: Yup.string().nullable().required(),
        winner_count: Yup.string()
          .nullable()
          .test('winner_count', '半角数字で入力してください', value => {
            const pattern = /^[0-9]+$/;
            return pattern.test(value);
          }),
      })
    ),
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object(yupObject),
    onSubmit: async values => {
      const {
        public_start_date,
        public_start_hour,
        public_start_minute,
        public_end_date,
        public_end_hour,
        public_end_minute,
        entry_public_start_date,
        entry_public_start_hour,
        entry_public_start_minute,
        entry_public_end_date,
        entry_public_end_hour,
        entry_public_end_minute,
        result_public_date,
        result_public_date_hour,
        result_public_date_minute,
        lottery_code,
        public_notice_flag,
        entry_notice_flag,
        result_notice_flag,
        coupon_list,
        lottery_content,
        lottery_rich_content,
        ...rest
      } = values;
      const publicStartPeriod = moment(public_start_date)
        .set('h', Number(public_start_hour))
        .set('m', Number(public_start_minute));
      const publicEndPeriod = moment(public_end_date)
        .set('h', Number(public_end_hour))
        .set('m', Number(public_end_minute));
      const entryStartPeriod = moment(entry_public_start_date)
        .set('h', Number(entry_public_start_hour))
        .set('m', Number(entry_public_start_minute));
      const entryEndPeriod = moment(entry_public_end_date)
        .set('h', Number(entry_public_end_hour))
        .set('m', Number(entry_public_end_minute));
      const resultPublicPeriod = moment(result_public_date)
        .set('h', Number(result_public_date_hour))
        .set('m', Number(result_public_date_minute));

      // キャンペーン期間前後チェック
      if (!publicStartPeriod.isBefore(publicEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'キャンペーン表示開始日はキャンペーン表示終了日より前の日付を入力してください',
          })
        );
        return;
      }

      // 応募期間前後チェック
      if (!entryStartPeriod.isBefore(entryEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message: '応募開始日は応募終了日より前の日付を入力してください',
          })
        );
        return;
      }

      // 応募受付開始日チェック
      if (!entryStartPeriod.isSameOrAfter(publicStartPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              '応募開始日はキャンペーン開始日より後の日付を入力してください',
          })
        );
        return;
      }

      // 応募受付終了日チェック
      if (!entryEndPeriod.isSameOrBefore(publicEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              '応募終了日はキャンペーン表示終了日より前の日付を入力してください',
          })
        );
        return;
      }

      // 抽選結果公開日チェック
      if (!resultPublicPeriod.isSameOrBefore(publicEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              '抽選結果公開日はキャンペーン表示終了日より前の日付を入力してください',
          })
        );
        return;
      }

      // 抽選結果公開日チェック
      if (!resultPublicPeriod.isSameOrAfter(entryEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              '抽選結果公開日は応募受付終了日より後の日付を入力してください',
          })
        );
        return;
      }

      const hasDetail =
        values?.lottery_rich_content?.length > 1 ||
        values?.lottery_rich_content.some(r =>
          (r.children ?? []).some(
            c =>
              c.text ||
              (c?.type === 'link' && (c.children ?? []).some(c => c.text))
          )
        );

      const params = {
        ...rest,
        public_start_date: publicStartPeriod.format('YYYY-MM-DD HH:mm:ss'),
        public_end_date: publicEndPeriod.format('YYYY-MM-DD HH:mm:ss'),
        entry_public_start_date: entryStartPeriod.format('YYYY-MM-DD HH:mm:ss'),
        entry_public_end_date: entryEndPeriod.format('YYYY-MM-DD HH:mm:ss'),
        result_public_date: resultPublicPeriod.format('YYYY-MM-DD HH:mm:ss'),
        public_notice_flag: parseInt(public_notice_flag),
        entry_notice_flag: parseInt(entry_notice_flag),
        result_notice_flag: parseInt(result_notice_flag),
        coupon_list: coupon_list?.map(coupon => {
          return {
            coupon_code: coupon.coupon_code,
            winner_count: parseInt(coupon.winner_count),
          };
        }),
        lottery_rich_content:
          new_contents_flag !== 0
            ? lottery_rich_content
                ?.map(t => serialize(t))
                ?.map((dom, i) => ({
                  sort_num: `${i + 1}`,
                  dom,
                }))
                .map(obj => obj.dom)
                .join('-----separator-----')
            : '',
        lottery_content:
          new_contents_flag !== 0 && hasDetail
            ? '%html%' +
              lottery_rich_content
                ?.map(t => serialize(t))
                ?.map((dom, i) => ({
                  sort_num: `${i + 1}`,
                  dom,
                }))
                .map(obj => obj.dom)
                .join('<br />') +
              '%html%'
            : new_contents_flag !== 0
            ? ''
            : lottery_content ?? '',
      };

      return isUpdate
        ? dispatch(
            fetchLotteryUpdate({
              lottery_code: lottery_code,
              ...params,
            })
          )
        : dispatch(fetchLotteryCreate(params));
    },
  });

  const setValues = () => {
    formik.setValues({
      ...currentLottery,
      public_start_date: format(currentLottery?.public_start_date, 'YYYY/M/D'),
      public_start_hour: format(currentLottery?.public_start_date, 'HH'),
      public_start_minute: format(currentLottery?.public_start_date, 'mm'),
      public_end_date: format(currentLottery?.public_end_date, 'YYYY/M/D'),
      public_end_hour: format(currentLottery?.public_end_date, 'HH'),
      public_end_minute: format(currentLottery?.public_end_date, 'mm'),
      entry_public_start_date: format(
        currentLottery?.entry_public_start_date,
        'YYYY/M/D'
      ),
      entry_public_start_hour: format(
        currentLottery?.entry_public_start_date,
        'HH'
      ),
      entry_public_start_minute: format(
        currentLottery?.entry_public_start_date,
        'mm'
      ),
      entry_public_end_date: format(
        currentLottery?.entry_public_end_date,
        'YYYY/M/D'
      ),
      entry_public_end_hour: format(
        currentLottery?.entry_public_end_date,
        'HH'
      ),
      entry_public_end_minute: format(
        currentLottery?.entry_public_end_date,
        'mm'
      ),
      result_public_date: format(
        currentLottery?.result_public_date,
        'YYYY/M/D'
      ),
      result_public_date_hour: format(currentLottery?.result_public_date, 'HH'),
      result_public_date_minute: format(
        currentLottery?.result_public_date,
        'mm'
      ),
      lottery_rich_content: toSlateState(currentLottery?.lottery_rich_content),
      entry_notice_flag: entry_notice_flag.toString(),
      result_notice_flag: result_notice_flag.toString(),
      public_notice_flag: public_notice_flag.toString(),
      template: templateList?.filter(
        e => e?.template_text === currentLottery?.lottery_template
      )?.[0]?.template_code,
    });
  };

  const styleObj = {
    lineHeight: '30px',
    marginTop: '0',
  };

  useEffect(
    () =>
      editModalVisible
        ? currentLottery
          ? setValues()
          : formik.validateForm()
        : formik.resetForm(),
    [editModalVisible, templateList]
  );

  useEffect(
    () =>
      editModalVisible &&
      dispatch(fetchLotteryTemplateList()) &&
      dispatch(fetchLotteryCouponList()),
    [editModalVisible]
  );

  const onBack = () => dispatch(lotteryActions.closeEditModal());

  const {
    banner_image_url,
    public_start_date,
    public_start_hour,
    public_start_minute,
    entry_public_start_date,
    entry_public_start_hour,
    entry_public_start_minute,
    entry_public_end_date,
    entry_public_end_hour,
    entry_public_end_minute,
    result_public_date,
    result_public_date_hour,
    result_public_date_minute,
    public_notice_flag,
    entry_notice_flag,
    result_notice_flag,
  } = formik.values;

  return (
    <Modal
      className={'lottery-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={
            isCreate ? '抽選登録' : isUpdate ? '抽選編集' : '抽選登録(複製)'
          }
          tip={'*は必須項目です'}
        />

        <div className="input-area lottery-input-area">
          {isUpdate && (
            <div className="code-area">
              <span className={'pre-label'}>抽選CD</span>
              {formik.values['lottery_code']}
            </div>
          )}

          <div className="lottery-image-display-area">
            <span className="image-area-error">
              {formik.touched.banner_image_url &&
                formik.errors.banner_image_url}
            </span>
            <div className="image-area lottery-image-area">
              <span className="pre-label label-required">バナー画像</span>
              <div className="image-select">
                {!!banner_image_url ? (
                  <>
                    <Icon
                      component={Icons.IconXCircleWh}
                      className="close-icon"
                      onClick={() => {
                        handlerFormikFieldChange(
                          formik,
                          'banner_image_url',
                          null
                        );
                      }}
                    />
                    <Image
                      src={banner_image_url}
                      width={150}
                      height={150}
                      preview={false}
                    />
                  </>
                ) : (
                  <BaseUpload
                    onChange={url =>
                      handlerFormikFieldChange(formik, 'banner_image_url', url)
                    }
                  >
                    <TcButton text="画像を追加" theme="white" />
                  </BaseUpload>
                )}
              </div>
            </div>
          </div>

          <div className="lottery-modal_new_input_area">
            <TextBox
              isRequired={true}
              formik={formik}
              label="抽選名"
              fieldName="lottery_name"
              placeholder="抽選名を入力してください"
              width={'100%'}
            />
          </div>

          <span
            className="cus-input-line input-textarea"
            style={new_contents_flag !== 0 ? { height: 'unset' } : {}}
          >
            <label className="cus-pre-label" style={{ minWidth: '150px' }}>
              抽選説明
              {new_contents_flag === 0 && (
                <ContentsTooltip notificationTip={true} />
              )}
            </label>
            {new_contents_flag !== 0 ? (
              <div style={{ width: '100%' }}>
                <RichTextEditor
                  editorState={formik.values?.lottery_rich_content}
                  error={
                    formik.touched.lottery_rich_content &&
                    formik.errors.lottery_rich_content
                  }
                  onChange={state =>
                    formik.setFieldValue('lottery_rich_content', state)
                  }
                  setTouched={() =>
                    !formik.touched.lottery_rich_content &&
                    formik.setFieldTouched('lottery_rich_content', true)
                  }
                  placeholder={'抽選説明を入力してください'}
                  disabled={false}
                />
              </div>
            ) : (
              <textarea
                placeholder="抽選説明を入力してください"
                spellCheck={false}
                autoComplete={'false'}
                value={formik.values['lottery_content']}
                onChange={e =>
                  handlerFormikFieldChange(
                    formik,
                    'lottery_content',
                    e.target.value
                  )
                }
              />
            )}
          </span>

          {new_contents_flag !== 0 ? (
            <></>
          ) : (
            <ImagesUploader
              label={'画像'}
              formik={formik}
              fieldName={'image_url_list'}
            />
          )}

          <div className="lottery-modal_new_input_area">
            <SelectBox
              formik={formik}
              label="定型文"
              fieldName={`template`}
              placeholder="テンプレートを選択"
              options={templateList?.map(e => ({
                value: e.template_code,
                label: e.template_title,
              }))}
              width={'100%'}
              extraOnChange={(_, v) =>
                handlerFormikFieldChange(
                  formik,
                  'lottery_template',
                  templateList?.filter(e => e.template_code === v)?.[0]
                    ?.template_text
                )
              }
            />
          </div>

          <span
            className="cus-input-line input-textarea input-fixed-phrase"
            style={{ marginTop: '10px' }}
          >
            <label className="cus-pre-label" style={{ minWidth: '150px' }} />
            <textarea
              placeholder=""
              spellCheck={false}
              autoComplete={'false'}
              value={formik.values.lottery_template}
              onChange={e =>
                handlerFormikFieldChange(
                  formik,
                  'lottery_template',
                  e.target.value
                )
              }
            />
          </span>

          <div className="date-area">
            {!isUpdate ? (
              <DateTimeRangePicker
                formik={formik}
                preLabel={'キャンペーン表示期間'}
                fromFieldName={'public_start_date'}
                fromHourFieldName={'public_start_hour'}
                fromMinuteFieldName={'public_start_minute'}
                toFieldName={'public_end_date'}
                toHourFieldName={'public_end_hour'}
                toMinuteFieldName={'public_end_minute'}
                isRequired
                width={'100%'}
                className={'lottery-modal_date-area'}
              />
            ) : (
              <div className={'lottery-modal_update_date'}>
                <label className={'lottery-modal_update_date_label'}>
                  キャンペーン表示期間
                </label>
                <div className={'lottery-modal_update_date_area'}>
                  <span
                    className={'lottery-modal_update_date_text'}
                    style={{ minWidth: '75px' }}
                  >
                    {public_start_date}
                  </span>
                  <span
                    className={'lottery-modal_update_date_text'}
                  >{`${public_start_hour}:${public_start_minute}`}</span>
                  <span
                    className={'lottery-modal_update_date_text'}
                  >{`〜`}</span>
                  <div className={'lottery-modal_update_date_to'}>
                    <DateTimeRangePicker
                      formik={formik}
                      fromFieldName={'public_end_date'}
                      fromHourFieldName={'public_end_hour'}
                      fromMinuteFieldName={'public_end_minute'}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="date-area">
            {!isUpdate ? (
              <DateTimeRangePicker
                formik={formik}
                preLabel={'応募受付期間'}
                fromFieldName={'entry_public_start_date'}
                fromHourFieldName={'entry_public_start_hour'}
                fromMinuteFieldName={'entry_public_start_minute'}
                toFieldName={'entry_public_end_date'}
                toHourFieldName={'entry_public_end_hour'}
                toMinuteFieldName={'entry_public_end_minute'}
                isRequired
                width={'100%'}
                className={'lottery-modal_date-area'}
              />
            ) : (
              <div
                className={'lottery-modal_update_date'}
                style={{ marginTop: 25 }}
              >
                <label className={'lottery-modal_update_date_label'}>
                  応募受付期間
                </label>
                <div className={'lottery-modal_update_date_area'}>
                  <span
                    className={'lottery-modal_update_date_text'}
                    style={{ minWidth: '75px' }}
                  >
                    {entry_public_start_date}
                  </span>
                  <span
                    className={'lottery-modal_update_date_text'}
                  >{`${entry_public_start_hour}:${entry_public_start_minute}`}</span>
                  <span
                    className={'lottery-modal_update_date_text'}
                  >{`〜`}</span>
                  <span className={'lottery-modal_update_date_text'}>
                    {entry_public_end_date}
                  </span>
                  <span
                    className={'lottery-modal_update_date_text'}
                  >{`${entry_public_end_hour}:${entry_public_end_minute}`}</span>
                </div>
              </div>
            )}
          </div>

          <div className="date-area">
            {!isUpdate ? (
              <DateTimeRangePicker
                formik={formik}
                preLabel={'抽選結果公開日'}
                fromFieldName={'result_public_date'}
                fromHourFieldName={'result_public_date_hour'}
                fromMinuteFieldName={'result_public_date_minute'}
                isRequired
                width={'100%'}
                className={'lottery-modal_date-area'}
              />
            ) : (
              <div
                className={'lottery-modal_update_date'}
                style={{ marginTop: 25 }}
              >
                <label className={'lottery-modal_update_date_label'}>
                  抽選結果公開日
                </label>
                <div className={'lottery-modal_update_date_area'}>
                  <span
                    className={'lottery-modal_update_date_text'}
                    style={{ minWidth: '75px' }}
                  >
                    {result_public_date}
                  </span>
                  <span
                    className={'lottery-modal_update_date_text'}
                  >{`${result_public_date_hour}:${result_public_date_minute}`}</span>
                </div>
              </div>
            )}
          </div>

          {isUpdate ? (
            <div className="lottery-push-area-display">
              <span className="lottery-push-area-display-label">
                プッシュ通知
              </span>
              <div className="lottery-push-btn-area-display">
                <div className="push-area-display">
                  <span className="push-area-display-label">
                    キャンペーン表示開始
                  </span>
                  <span className="push-area-display-text">
                    {getOptionLabel(flagOptions, public_notice_flag.toString())}
                  </span>
                </div>
                <div className="push-area-display">
                  <span className="push-area-display-label">応募受付開始</span>
                  <span className="push-area-display-text">
                    {getOptionLabel(flagOptions, entry_notice_flag.toString())}
                  </span>
                </div>
                <div className="push-area-display">
                  <span className="push-area-display-label">抽選結果公開</span>
                  <span className="push-area-display-text">
                    {getOptionLabel(flagOptions, result_notice_flag.toString())}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="lottery-push-area">
              <span className="pre-label label-required">プッシュ通知</span>
              <div className="lottery-push-btn-area">
                <span className="cus-input-line input-checkbox">
                  <TcRadio
                    formik={formik}
                    label={'キャンペーン表示開始'}
                    isRadio={true}
                    fieldName="public_notice_flag"
                    width={150}
                    options={flagOptions}
                    onChange={v =>
                      handlerFormikFieldChange(formik, 'public_notice_flag', v)
                    }
                  />
                </span>
                <span className="cus-input-line input-checkbox">
                  <TcRadio
                    formik={formik}
                    label={'応募受付開始'}
                    isRadio={true}
                    fieldName="entry_notice_flag"
                    width={150}
                    options={flagOptions}
                    onChange={v =>
                      handlerFormikFieldChange(formik, 'entry_notice_flag', v)
                    }
                  />
                </span>
                <span className="cus-input-line input-checkbox">
                  <TcRadio
                    formik={formik}
                    label={'抽選結果公開'}
                    isRadio={true}
                    fieldName="result_notice_flag"
                    width={150}
                    options={flagOptions}
                    onChange={v =>
                      handlerFormikFieldChange(formik, 'result_notice_flag', v)
                    }
                  />
                </span>
              </div>
            </div>
          )}

          <div className="lottery-coupon-area">
            <div className="lottery-coupon-label">景品</div>
            <CouponList
              formik={formik}
              initial={initialCoupon}
              isUpdate={isUpdate}
              couponList={couponList}
            />
          </div>

          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={isUpdate ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LotteryModal;
