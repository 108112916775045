import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TcButton from '../tcButton';
import { enqueteActions } from '../../slices/enqueteSlice';
import './style.scss';
import ChartFactory from '../chartFactory';

const ChartModal = () => {
  const dispatch = useDispatch();
  const { chartData } = useSelector(state => state.enquete);
  const [isOpen, setIsOpen] = useState(false);
  const [centerX, setCenterX] = useState(0);
  const [centerY, setCenterY] = useState(0);

  const r = useRef(null);
  const wrapperRef = useRef(null);

  useLayoutEffect(() => {
    if (chartData == null) return;

    setIsOpen(true);

    setCenterX((chartData.x - r.current.getBoundingClientRect().width) / 2);
    setCenterY(
      chartData.scrollTop +
        window.innerHeight / 2 -
        chartData.y -
        r.current.getBoundingClientRect().height / 2
    );
    const handleClickOutside = event => {
      wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        onBack();
    };

    window.addEventListener('mouseup', handleClickOutside, false);

    return () => {
      window.removeEventListener('mouseup', handleClickOutside, false);
    };
  }, [chartData]);

  const onBack = () => {
    setIsOpen(false);
    setTimeout(() => {
      dispatch(enqueteActions.closeChartModal());
    }, 200);
  };

  return (
    chartData && (
      <div className="chart-modal" ref={wrapperRef}>
        <div
          className={`modal-content ${
            isOpen ? 'fade-in-center' : 'fade-out-center'
          }`}
          ref={r}
          style={{
            top: centerY,
            left: centerX,
          }}
        >
          <div className="chart-area">
            <ChartFactory
              type={chartData.graph_type}
              data={chartData?.data}
              columnChartWidth={700}
            />
          </div>
          <div className="button-container">
            <TcButton text="閉じる" theme="white" onClick={onBack} />
          </div>
        </div>
      </div>
    )
  );
};

export default ChartModal;
