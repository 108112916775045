import React, { useState } from 'react';
import { Image, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../slices/notificationSlice';
import ModalTitle from '../modalTitle';
import Button from '../../components/tcButton';
import ImagesReference from '../imagesReference';
import { useUpdateEffect } from 'react-use';
import { serialize, toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import ReferenceItem from '../referenceItem';
import './style.scss';

/* 記事参照モーダル */

const DocumentReferenceModal = () => {
  const dispatch = useDispatch();

  const { referenceModalVisible, referenceDocument } = useSelector(
    state => state.notification
  );

  const {
    accountInfo: { new_contents_flag },
  } = useSelector(state => state.account);

  const [html, setHtml] = useState([]);

  const initialValues = {
    title: '',
    message: '',
    text: '',
    link: '',
    image_url: Array(10).fill(''),
  };

  const { title, message, text, link, image_url, rich_text } =
    referenceDocument || initialValues;

  const onBack = () => {
    dispatch(notificationActions.closeReferenceModal());
  };

  const imageAdd = (data, index) => {
    if (data === `%image${index}%` && image_url[index - 1]) {
      return imageElement(image_url[index - 1]);
    }
  };

  const imageElement = image => {
    return (
      <Image
        src={image}
        width={'100%'}
        height={'100%'}
        preview={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    );
  };

  return (
    <Modal
      className="notification-modal tc-modal"
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'記事参照'} />
        <div className="input-area">
          <div className="reference-area">
            <span className={'pre-label'}>タイトル</span>
            <span className="font-sm">{title}</span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>メッセージ</span>
            <span className="font-sm">{message}</span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>外部リンク</span>
            <span className="font-sm">{link}</span>
          </div>
          {(!referenceDocument ||
            referenceDocument?.document_category === '0') && (
            <>
              {new_contents_flag !== 0 ? (
                <div className="text-area" style={{ display: 'flex' }}>
                  <span className="pre-label editor-detail-title">本文</span>
                  <span className="editor-detail">
                    <RichTextEditor
                      readOnly={true}
                      editorState={toSlateState(
                        rich_text
                          ? rich_text
                              .split('-----separator-----')
                              .map((str, idx) => ({
                                sort_num: idx + 1,
                                dom: str,
                              }))
                          : []
                      )}
                    />
                  </span>
                </div>
              ) : (
                <ReferenceItem label="本文" value={text} />
              )}

              <div className="image-area">
                <label className="pre-label text-required">画像</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '80%',
                  }}
                >
                  <ImagesReference
                    image_url={image_url}
                    from={0}
                    to={4}
                    width={120}
                    height={120}
                  />
                  <ImagesReference
                    image_url={image_url}
                    from={5}
                    to={9}
                    width={120}
                    height={120}
                  />
                </div>
              </div>
            </>
          )}
          <div className="button-container">
            <Button
              text="閉じる"
              theme="white"
              onClick={() =>
                dispatch(notificationActions.closeReferenceModal())
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentReferenceModal;
