import { memo } from 'react';
import { Select } from 'antd';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import { DownOutlined, CheckOutlined } from '@ant-design/icons';
import { hourOptions } from '../../constants/options';
import './style.scss';

const HourPickerBase = memo(({ formik, fieldName, style }) => {
  return (
    <Select
      // className={"tc-select"}
      mode={null}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onBlur={() => formik.setFieldTouched(fieldName, true)}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      maxTagCount="responsive"
      placeholder={'00'}
      maxTagPlaceholder={'...'}
      value={formik.values[fieldName]}
      defaultActiveFirstOption={false}
      // size={selectSize}
      suffixIcon={<DownOutlined className="ant-select-suffix" />}
      onChange={v => {
        console.log(formik, fieldName, v);
        handlerFormikFieldChange(formik, fieldName, v ?? null);
      }}
      allowClear={true}
      showArrow={true}
      menuItemSelectedIcon={<CheckOutlined style={{ color: 'black' }} />}
      style={style}
      key={fieldName}
    >
      {hourOptions.map(o => (
        <Select.Option value={o.value} key={o.value}>
          {o.label}
        </Select.Option>
      ))}
    </Select>
  );
});

export default HourPickerBase;
