import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { login } from '../../slices/accountSlice';
import { hashPassword } from '../../utils/fnUtil';
import PasswordChangeModal from '../../components/passwordChangeModal';
import TcButton from '../../components/tcButton';
import Footer from '../../components/footer';
import Icons from '../../constants/Icons';
import Yup from '../../utils/yupUtil';
import './style.scss';
import TextBox from '../../components/util/textBox';
import InputContainer from '../../components/inputContainer';
import PasswordBox from '../../components/util/passwordBox';

const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountInfo } = useSelector(state => state.account);

  useEffect(() => {
    navigate(accountInfo ? 'top' : '/');
  }, [accountInfo, navigate]);

  const formik = useFormik({
    initialValues: { corporation_code: '', mail_address: '', password: '' },
    validateOnMount: true,
    initialTouched: {
      corporation_code: true,
      mail_address: true,
      password: true,
    },
    validationSchema: Yup.object({
      corporation_code: Yup.string().required(),
      mail_address: Yup.string().required().max(256).mail().mail_local(),
      password: Yup.string().required(),
    }),
    onSubmit: async values => {
      dispatch(
        login({
          ...values,
          password: hashPassword(values.password),
        })
      );
    },
  });

  return (
    <div className="login-page" ref={parent}>
      <div className="login-form">
        <div className="logo-icon">
          <Icons.LogoTenco />
        </div>
        <InputContainer parent={parent}>
          <TextBox
            formik={formik}
            label="法人CD"
            fieldName="corporation_code"
            isBold
          />
          <TextBox
            formik={formik}
            label="ログインID"
            fieldName="mail_address"
            isBold
          />
          <PasswordBox
            formik={formik}
            label="パスワード"
            fieldName="password"
            isBold
          />
        </InputContainer>
        <div className="recovery-link">
          <Link to="/passwordRecovery">パスワードをお忘れの方はこちら</Link>
        </div>
        <TcButton
          text={'ログイン'}
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
        />
      </div>
      <PasswordChangeModal />
      <Footer />
    </div>
  );
};

export default Login;
