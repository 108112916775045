import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  downloadStampedResult,
  fetchStampedDetailList,
  fetchStampedResultCsvFavoriteItem,
  stampedDetailActions,
  updateStampedResultCsvFavoriteItem,
} from '../../slices/stampedDetailSlice';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import { stampedDetailFavoriteItemOptions } from '../../constants/options';
import { stampedDetailColumns } from '../../constants/columns';
import Icons from '../../constants/Icons';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import TcTable from '../../components/tcTable';
import ColumnTitle from '../../components/columnTitle';
import TableTitle from '../../components/tableTitle';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import SelectColumsModal from '../../components/modal/selectCloumsModal';
import moment from 'moment';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';

const StampedDetail = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountBrandList, accountShopList } = useSelector(
    state => state.account
  );

  const {
    searchParams,
    stampedDetailList,
    total,
    csvDownloadModalVisible,
    selectColumsVisible,
    favoriteItem,
    showFields,
  } = useSelector(state => state.stampedDetail);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,

    onSubmit: values => {
      const { from_create_at, to_create_at, ...rest } = values;
      const params = {
        ...rest,
        from_create_at: dateFormat(from_create_at, 'YYYY/MM/DD'),
        to_create_at: dateFormat(to_create_at, 'YYYY/MM/DD'),
      };

      dispatch(stampedDetailActions.saveSearchParams(params));
    },
  });

  const {
    brand_code,
    csv_brand_code,
    csv_shop_code,
    csv_from_create_at,
    csv_to_create_at,
  } = formik.values;

  const filterCsvShopList = accountShopList.filter(
    s =>
      csv_brand_code.filter(b => b).length === 0 ||
      csv_brand_code.includes(s.brand_code)
  );

  useEffect(() => {
    dispatch(fetchStampedResultCsvFavoriteItem());
    dispatch(stampedDetailActions.saveSearchParams(null));
    dispatch(
      stampedDetailActions.saveShowFields(
        columns.filter(
          c =>
            !stampedDetailColumns.filter(d => c.key === d.sortField)[0]
              .defaultHiddenFiled
        )
      )
    );
    return () => dispatch(stampedDetailActions.clear());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStampedDetailList(searchParams));
  }, [searchParams, dispatch]);

  const columns = [
    ...stampedDetailColumns.map(col => ({
      key: col.sortField,
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort =>
            dispatch(stampedDetailActions.saveSearchParams({ sort }))
          }
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
  ];

  const displayColumnOptions = [
    ...stampedDetailColumns.map(e => ({
      label: e.title,
      value: e.sortField,
    })),
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="押印実績明細一覧"
        icon={<Icons.IconStamp className="stamped-icon" />}
      />
      <div className="search-form stamped-page-search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="会員番号" fieldName="user_code" />
          <TextBox formik={formik} label="台紙CD" fieldName="stamp_code" />
          <TextBox formik={formik} label="台紙名" fieldName="stamp_name" />
          <DateTimeRangePicker
            formik={formik}
            label="押印日"
            fromFieldName="from_create_at"
            toFieldName="to_create_at"
          />
          <SelectBox
            formik={formik}
            label="ブランド"
            fieldName="brand_code"
            mode="multiple"
            options={accountBrandList}
            extraOnChange={() => formik.setFieldValue('shop_code', [])}
          />
          <SelectBox
            formik={formik}
            label="店舗"
            fieldName="shop_code"
            mode="multiple"
            options={accountShopList.filter(
              shop =>
                brand_code.length === 0 || brand_code.includes(shop.brand_code)
            )}
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickCsvOutput={() => {
          handlerFormikFieldChange(
            formik,
            'csv_from_create_at',
            csv_from_create_at || moment().format('YYYY/M/1')
          );
          handlerFormikFieldChange(
            formik,
            'csv_to_create_at',
            csv_to_create_at || moment().format('YYYY/M/D')
          );
          dispatch(stampedDetailActions.openCsvDownloadModal());
        }}
        onClickDisplayChange={() =>
          dispatch(stampedDetailActions.openSelectColumsModal())
        }
      />
      <TcTable
        columns={columns.filter(e =>
          showFields.map(s => s.dataIndex).includes(e.dataIndex)
        )}
        dataSource={stampedDetailList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(stampedDetailActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <CsvDownloadModal
        line={5}
        width={850}
        requiredText={null}
        modalVisible={csvDownloadModalVisible}
        options={stampedDetailFavoriteItemOptions}
        favoriteItem={favoriteItem}
        disabled={!(csv_from_create_at && csv_to_create_at)}
        searchArea={
          <>
            <div style={{ display: 'flex' }}>
              <SelectBox
                formik={formik}
                label={'ブランド'}
                fieldName={'csv_brand_code'}
                mode={'multiple'}
                options={[{ label: 'すべて', value: '' }, ...accountBrandList]}
                width={300}
                customOnChange={(v, fieldName) => {
                  const isNoSelect = v.filter(value => value).length === 0;
                  const isLastEmpty = v[v.length - 1] === '';
                  const value =
                    isNoSelect || isLastEmpty ? [''] : v.filter(value => value);
                  handlerFormikFieldChange(formik, fieldName, value);
                  handlerFormikFieldChange(formik, 'csv_shop_code', ['']);
                }}
                isRequired
              />
              <SelectBox
                formik={formik}
                label={'店舗'}
                fieldName={'csv_shop_code'}
                mode={'multiple'}
                options={[{ label: 'すべて', value: '' }, ...filterCsvShopList]}
                width={300}
                customOnChange={(v, fieldName) => {
                  const isNoSelect = v.filter(value => value).length === 0;
                  const isLastEmpty = v[v.length - 1] === '';
                  const value =
                    isNoSelect || isLastEmpty ? [''] : v.filter(value => value);
                  handlerFormikFieldChange(formik, fieldName, value);
                }}
                style={{ marginLeft: 24 }}
                isRequired
              />
            </div>
            <div className="csv-modal-warning">
              ※ブランド/店舗で「すべて」を選択された場合、ダウンロードに時間がかかる場合があります。
            </div>
            <DateTimeRangePicker
              label={'押印日'}
              formik={formik}
              fromFieldName={'csv_from_create_at'}
              toFieldName={'csv_to_create_at'}
              isRequired
            />
          </>
        }
        onFavoriteItem={checkedList => {
          const param = {
            screen_type: '3',
            favorite: {},
          };
          stampedDetailFavoriteItemOptions
            .filter(item => item)
            .forEach(item => (param.favorite[item.value] = '0'));
          checkedList
            .filter(item => item)
            .forEach(item => (param.favorite[item] = '1'));
          dispatch(updateStampedResultCsvFavoriteItem(param));
        }}
        onClose={() => dispatch(stampedDetailActions.closeCsvDownloadModal())}
        onDownload={checkedList => {
          dispatch(
            downloadStampedResult({
              brands: csv_brand_code.filter(v => v),
              shops: csv_shop_code.filter(v => v),
              from_create_at: dateFormat(csv_from_create_at, 'YYYY/MM/DD'),
              to_create_at: dateFormat(csv_to_create_at, 'YYYY/MM/DD'),
              screen_type: '5',
              columns: checkedList,
            })
          );
        }}
      />
      <SelectColumsModal
        title="表示する項目を選択してください"
        modalVisible={selectColumsVisible}
        closeModal={() =>
          dispatch(stampedDetailActions.closeSelectColumsModal())
        }
        options={displayColumnOptions}
        onDisplay={checkedList => {
          dispatch(
            stampedDetailActions.saveShowFields(
              columns.filter(col =>
                checkedList.some(checkedCol => checkedCol === col.key)
              )
            )
          );
        }}
        chekedValues={stampedDetailColumns
          .filter(e => !e.defaultHiddenFiled)
          .map(e => e?.sortField)}
        initialize={false}
      />
    </div>
  );
};

export default StampedDetail;
