import { memo } from 'react';
import './style.scss';

const ModalTitle = memo(({ title, tip, rightElement = null }) => (
  <div className="modal-title">
    <div>
      <span className="title-text">{title}</span>
      <span className="title-tip">{tip}</span>
    </div>
    {rightElement && rightElement}
  </div>
));

export default ModalTitle;
