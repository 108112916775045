import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { qrActions } from '../../slices/qrSlice';
import ModalTitle from '../modalTitle';
import Button from '../tcButton';
import './style.scss';

/* QR参照モーダル */

const QRReferenceModal = () => {
  const dispatch = useDispatch();

  const { referenceModalVisible, referenceQr } = useSelector(state => state.qr);

  const initialValues = {
    qr_code: null,
    qr_name: null,
    stamp_count: 1,
    brand_name: null,
    shop_name: null,
  };

  const { qr_code, qr_name, stamp_count, brand_name, shop_name } =
    referenceQr || initialValues;

  const onBack = () => {
    dispatch(qrActions.closeReferenceModal());
  };

  return (
    <Modal
      className={'qr-modal'}
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
    >
      <div className="qr-modal-content">
        <ModalTitle title={'QR参照'} />
        <div>
          <div className="text-area">
            <label>QR名</label>
            <span>{qr_name}</span>
          </div>
          <div className="text-area">
            <label>QRコード</label>
            <span>{qr_code}</span>
          </div>
          <div className="text-area">
            <label>スタンプ押印数</label>
            <span>{`${stamp_count} 個`}</span>
          </div>
          <div className="text-area">
            <label>ブランド</label>
            <div style={{ whiteSpace: 'pre-wrap' }}>{brand_name}</div>
          </div>
          <div className="text-area">
            <label>店舗</label>
            <div style={{ whiteSpace: 'pre-wrap' }}>{shop_name}</div>
          </div>
          <div className="button-container">
            <Button text="閉じる" theme="white" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QRReferenceModal;
