import { memo } from 'react';
import classNames from 'classnames';
import Icons from '../../constants/Icons';
import './style.scss';

const TcCheckbox = memo(
  ({
    checked,
    className,
    disabled,
    label,
    onChange,
    wrapperStyle,
    readOnly,
    isRadio,
    bold = false,
  }) => {
    return readOnly ? (
      checked ? (
        <div className="read-only-label">{label}</div>
      ) : (
        <></>
      )
    ) : (
      <div
        className={classNames('tc-checkbox-wrapper', className, {
          'tc-checkbox-disabled': disabled,
        })}
        style={wrapperStyle}
      >
        <div>
          <label
            className={
              isRadio
                ? classNames('tc-radio-label', {
                    'tc-radio-active': checked,
                  })
                : classNames('tc-checkbox-label', {
                    'tc-checkbox-active': checked,
                  })
            }
          >
            {isRadio ? (
              <div className="circle">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <circle
                    cx={8}
                    cy={8}
                    r={7}
                    stroke="silver"
                    strokeWidth="1"
                    fill="#ffffff"
                  ></circle>
                  {checked && (
                    <circle cx={8} cy={8} r={4.5} fill="#9b233f"></circle>
                  )}
                </svg>
              </div>
            ) : (
              checked && <Icons.IconCheck className="check-icon" />
            )}

            <input
              className="tc-checkbox"
              type="checkbox"
              disabled={disabled}
              checked={checked}
              onChange={onChange}
            />

            {bold ? <b>{label}</b> : `${label}`}
          </label>
        </div>
      </div>
    );
  }
);

export default TcCheckbox;
