import { memo } from 'react';
import { Tooltip } from 'antd';
import Icons from '../../constants/Icons';
import TcButton from '../tcButton';
import { numberFormat } from '../../utils/fnUtil';
import './style.scss';

const TableTitle = memo(
  ({
    title = '検索結果一覧',
    onClickCsv,
    onClickCsvOutput,
    onClickAdd,
    onClickDisplayChange,
    message,
    disabled = false,
    searchCount = null,
    csvMode,
  }) => (
    <div className="table-title">
      <div className="table-title-left">
        <Icons.IconList className="icon-list" />
        <div className="title">{title}</div>
        {/* <Icons.IconList />
        <h3>{title}</h3> */}
        {(!!searchCount || searchCount === 0) && (
          <div className="search-count">
            検索結果件数：{numberFormat(searchCount)}件
          </div>
        )}
      </div>
      <div className="table-title-right">
        <span className="message">{message}</span>
        {onClickDisplayChange && (
          <TcButton
            onClick={onClickDisplayChange}
            text={'表示切替'}
            theme={'white'}
            icon={<Icons.IconCheckList className="btn-icon" />}
            disabled={disabled}
          />
        )}
        {csvMode === 'lotteryApplicant' ? (
          <>
            <TcButton
              onClick={onClickCsvOutput}
              text={'CSV出力'}
              theme={'white'}
              icon={<Icons.IconUpload className="btn-icon" />}
              disabled={disabled}
            />
            <TcButton
              onClick={onClickCsv}
              text={'当選者取込'}
              theme={'white'}
              icon={<Icons.IconDownload className="btn-icon" />}
              disabled={disabled}
            />
          </>
        ) : (
          <>
            {onClickCsv && (
              <TcButton
                onClick={onClickCsv}
                text={'CSV取込'}
                theme={'white'}
                icon={<Icons.IconDownload className="btn-icon" />}
                disabled={disabled}
              />
            )}
            {onClickCsvOutput && (
              <TcButton
                onClick={onClickCsvOutput}
                text={'CSV出力'}
                theme={'white'}
                icon={<Icons.IconUpload className="btn-icon" />}
                disabled={disabled}
              />
            )}
          </>
        )}

        {onClickAdd && (
          <Tooltip
            title="新規追加"
            color="#C4A6A9"
            overlayClassName="add-tooltip"
          >
            <Icons.IconPlus className="plus-icon" onClick={onClickAdd} />
          </Tooltip>
        )}
      </div>
    </div>
  )
);

export default TableTitle;
