import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';

const initialSearchParams = {
  user_code: '',
  stamp_rally_code: '',
  stamp_rally_name: '',
  trigger: null,
  offset: 0,
  count: 10,
  sort: 'user_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,
  csvModalVisible: false,
  selectColumsModalVisible: false,
  favoriteItem: [],
  stampRallyResultList: [],
  showFields: [],
};

export const fetchStampRallyResultList = createAsyncThunk(
  'fetchStampRallyResultList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const stampRallyResultList = await axios.get('/stamp_rally/result', {
      params,
    });

    dispatch(globalActions.setPrimaryLoading(false));

    return stampRallyResultList;
  }
);

export const fetchStampRallyResultCsvFavoriteItem = createAsyncThunk(
  'fetchStampRallyResultCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchStampRallyResultCsvFavoriteItemCreate = createAsyncThunk(
  'fetchStampRallyResultCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response.params;
  }
);

export const fetchStampRallyResultCsvDownload = createAsyncThunk(
  'fetchStampRallyResultCsvDownload',
  async (params, { dispatch }) => {
    const result = await axios.get('csv/download', { params });
    if (!!!result) {
      dispatch(globalActions.showCommonErrorModal());
    }
    return result;
  }
);

export const stampRallyResultSlice = createSlice({
  name: 'stampRallyResultSlice',
  initialState,
  reducers: {
    openCsvDownloadModal: state => ({
      ...state,
      csvModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvModalVisible: false,
    }),
    openSelectColumsModal: state => ({
      ...state,
      selectColumsModalVisible: true,
    }),
    closeSelectColumsModal: state => ({
      ...state,
      selectColumsModalVisible: false,
    }),
    saveShowFields: (state, { payload }) => ({
      ...state,
      showFields: payload,
      selectColumsModalVisible: false,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchStampRallyResultList.fulfilled]: (state, { payload }) => {
      state.stampRallyResultList = payload.item ?? [];
      state.total = payload.total_count;
    },
    [fetchStampRallyResultCsvFavoriteItem.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchStampRallyResultCsvFavoriteItemCreate.fulfilled]: (
      state,
      { payload }
    ) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchStampRallyResultCsvDownload.fulfilled]: (state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `押印実績明細一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
      ),
  },
});

export const stampRallyResultActions = stampRallyResultSlice.actions;

export default stampRallyResultSlice.reducer;
