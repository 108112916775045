import React, { memo, useEffect, useState } from 'react';
import { compareMemoArray, compareMemoObject } from '../../../utils/fnUtil';
import './style.scss';
import SelectBox from '../selectBox';

const CustomNumberPicker = memo(
  ({
    from,
    to,
    fieldName,
    disabled = false,
    zeroPadding = false,
    ...restProps
  }) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
      let options = [];
      for (let i = from; i <= to; i++) {
        const num = zeroPadding ? ('00' + i).slice(-2) : `${i}`;
        options.push({ label: num, value: num });
      }

      setOptions(options);
    }, [from, to]);

    return (
      <SelectBox
        fieldName={fieldName}
        options={options}
        placeholder={'00'}
        width={100}
        disabled={disabled}
        {...restProps}
      />
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, ['fieldName']) &&
    pre.from === next.from &&
    pre.to === next.to &&
    pre.disabled === next.disabled &&
    pre.fieldName === next.fieldName &&
    (pre.listFieldName ? compareMemoArray(pre, next) : true)
);

export default CustomNumberPicker;
