import { Modal } from 'antd';
import Button from '../../tcButton';
import './style.scss';

/* アクション削除確認モーダル */

const DeleteModal = ({ open, onCancel, onOk }) => {
  return (
    <Modal
      className={'tc-modal-delete'}
      open={open}
      centered
      footer={null}
      closable={false}
      width={450}
      onCancel={onCancel}
    >
      <div className="modal-content">
        <div className="delete-title">削除確認</div>
        <div className="delete-info">
          <div>設定済みのアクションはリセットされます</div>
          <div>よろしいですか？</div>
        </div>
        <div className="delete-button-area">
          <Button text="削除する" theme="red" onClick={onOk} />
          <Button text="戻る" theme="white" onClick={onCancel} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
