import { Transforms, Editor } from "slate";
import { ReactEditor } from "slate-react";

export const focusToEnd = editor => {
  if (!editor.selection) {
    Transforms.select(editor, editor.blurSelection ?? Editor.end(editor, []));
  }

  ReactEditor.focus(editor);
};
