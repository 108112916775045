import { Image, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalTitle from '../modalTitle';
import {
  fetchGachaCreate,
  fetchGachaUpdate,
  fetchGachaTemplateList,
  gachaActions,
} from '../../slices/gachaSlice';
import { useFormik } from 'formik';
import Yup from '../../utils/yupUtil';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import TcRadio from '../tcCheckbox/tcRadio';
import {
  checkinRuleOptions,
  flagOptions,
  winningOptions,
} from '../../constants/options';
import TcButton from '../tcButton';
import Icon from '@ant-design/icons/lib/components/Icon';
import Icons from '../../constants/Icons';
import BaseUpload from '../baseUpload';
import ImagesUploader from '../imagesUploader';
import ContentsTooltip from '../contentsTooltip';
import InputGift from './inputGift';
import { globalActions } from '../../slices/globalSlice';
import moment from 'moment';
import { getOptionLabel } from '../../utils/fnUtil';
import SelectBox from '../util/selectBox';
import CustomNumberPicker from '../util/customNumberPicker';
import TextBox from '../util/textBox';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import { serialize, toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import DualListBox from '../../components/dualListBox';
import classNames from 'classnames';
import GeoMap from '../googleMap/geoMap';
import TcTable from '../tcTable';
import { Tooltip } from 'antd';
import './style.scss';

const GachaModal = () => {
  const dispatch = useDispatch();

  const [center, setCenter] = useState({ lat: 35.69575, lng: 139.77521 });

  const format = (date, format) => (date ? moment(date)?.format(format) : null);
  const {
    editModalVisible,
    currentGacha,
    templateList,
    maxNumber,
    geoOffset,
    mode,
  } = useSelector(state => state.gacha);
  const isUpdate = !!currentGacha;
  const isCopy = mode === 'copy';

  const {
    accountInfo: { new_contents_flag },
    accountBrandList,
    accountShopList,
  } = useSelector(state => state.account);

  const { selectOptionList } = useSelector(state => state.name);

  const initialValues = {
    gacha_code: null,
    gacha_name: null,
    trigger_temp: null,
    trigger: null,
    locations: [],
    locations_edit: [],
    locations_temp: [],
    consecutive_startup_days: null,
    banner_image_url: null,
    gacha_content: null,
    gacha_rich_content: toSlateState(currentGacha?.gacha_rich_content),
    image_url: Array(10).fill(''),
    fixed_phrase: null,
    campaign_start_date: null,
    campaign_start_hour: null,
    campaign_start_minute: null,
    campaign_end_date: null,
    campaign_end_hour: null,
    campaign_end_minute: null,
    gacha_start_date: null,
    gacha_start_hour: null,
    gacha_start_minute: null,
    gacha_end_date: null,
    gacha_end_hour: null,
    gacha_end_minute: null,
    campaign_notice_flag: '1',
    gacha_notice_flag: '1',
    lottery_method: '0',
    focused_rank: null,
    gift_list: [
      {
        rank: '1',
        coupon_code: null,
        stamp_count: null,
        point: null,
        max_winner_count: null,
        winner_probability: null,
        is_checked: true,
      },
      {
        rank: '2',
        coupon_code: null,
        stamp_count: null,
        point: null,
        max_winner_count: null,
        winner_probability: null,
        is_checked: false,
      },
      {
        rank: '3',
        coupon_code: null,
        stamp_count: null,
        point: null,
        max_winner_count: null,
        winner_probability: null,
        is_checked: false,
      },
      {
        rank: '9',
        coupon_code: null,
        stamp_count: null,
        point: null,
        max_winner_count: null,
        winner_probability: 0,
        is_checked: false,
      },
    ],
    radius: 300,
    longitude: 0,
    latitude: 0,
    loser_probability: '',
    check_in_rule: '1',
  };

  const shopSchema = {
    location_name: Yup.string().nullable().required(),
    longitude: Yup.string()
      .nullable()
      .required()
      .matches(/^[0-9.]+$/, '数値で入力してください')
      .test(
        'is-valid-range-longitude',
        '経度は-180から180の範囲内である必要があります',
        value => {
          const numValue = parseFloat(value);
          return numValue >= -180 && numValue <= 180;
        }
      ),
    latitude: Yup.string()
      .nullable()
      .required()
      .matches(/^[0-9.]+$/, '数値で入力してください')
      .test(
        'is-valid-range-latitude',
        '緯度は-90から90の範囲内である必要があります',
        value => {
          const numValue = parseFloat(value);
          return numValue >= -90 && numValue <= 90;
        }
      ),
    radius: Yup.string()
      .nullable()
      .required()
      .matches(
        /^[3-9][0-9][0-9]$|^[1-9][0-9][0-9][0-9]$|10000/,
        '300~10000の数値を入力してください'
      ),
  };

  // Yupバリデーションオブジェクトの定義開始
  const yupObject = {
    // triggerフィールドは文字列であり、null許容だが必須
    trigger: Yup.string().nullable().required(),

    // locationsフィールドはオブジェクトの配列で、各オブジェクトはshopSchemaに基づいて検証される
    locations: Yup.array().when('trigger', (trigger, schema) => {
      return ['2', '5'].includes(trigger)
        ? schema.of(Yup.object(shopSchema))
        : schema.strip();
    }),
    locations_edit: Yup.array().when('trigger', (trigger, schema) => {
      return ['2', '5'].includes(trigger)
        ? schema.test(
            'at-least-one-reflect-true',
            '少なくとも1つのロケーション登録が必要です',
            locations_edit => {
              // reflectがtrueであるオブジェクトが少なくとも1つ存在するかチェック
              const hasReflectTrue = locations_edit?.some(
                location => location.reflect === true
              );
              return hasReflectTrue; // trueならバリデーションOK、falseならエラー
            }
          )
        : schema.strip();
    }),
    // manual_shopフィールドはshopSchemaに基づいて検証されるオブジェクト
    // manual_shop: Yup.object().when('trigger', (trigger, schema) => {
    //   return ['2', '5'].includes(trigger) ? Yup.object(shopSchema) : schema.strip();
    // }),
    // consecutive_startup_daysフィールドは、triggerの値に基づいて条件付きで必須または除去される
    consecutive_startup_days: Yup.string()
      .nullable()
      .when('trigger', (trigger, schema) =>
        ['1', '4'].includes(trigger) ? schema.required() : schema.strip()
      ),

    // banner_image_urlフィールドは文字列であり、null許容だが必須
    banner_image_url: Yup.string().nullable().required(),

    // gacha_nameフィールドは文字列であり、null許容だが必須
    gacha_name: Yup.string().nullable().required(),

    // gacha_contentフィールドは特定の条件下で検証される。ガチャの説明が必要
    gacha_content: Yup.string()
      .nullable()
      .test(
        'gacha_content',
        'ガチャ説明を入力してください',
        (value, testContext) => {
          const richContent = testContext.parent.gacha_rich_content;
          return (
            (new_contents_flag !== 0 &&
              (richContent?.length > 1 ||
                richContent.some(r => (r.children ?? []).some(c => c.text)))) ||
            value
          );
        }
      ),

    // campaign_start_dateフィールドは特定の条件下での日時選択を検証
    campaign_start_date: Yup.string()
      .nullable()
      .test(
        'campaign_start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.campaign_start_hour &&
          testContext.parent.campaign_start_minute
      ),

    // campaign_end_dateフィールドは特定の条件下での日時選択を検証
    campaign_end_date: Yup.string()
      .nullable()
      .test(
        'campaign_end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.campaign_end_hour &&
          testContext.parent.campaign_end_minute
      ),

    // gacha_start_dateフィールドは特定の条件下での日時選択を検証
    gacha_start_date: Yup.string()
      .nullable()
      .test(
        'gacha_start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.gacha_start_hour &&
          testContext.parent.gacha_start_minute
      ),

    // gacha_end_dateフィールドは特定の条件下での日時選択を検証
    gacha_end_date: Yup.string()
      .nullable()
      .test(
        'gacha_end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.gacha_end_hour &&
          testContext.parent.gacha_end_minute
      ),

    // lottery_methodフィールドは文字列として定義される
    lottery_method: Yup.string(),

    // gift_listフィールドはオブジェクトの配列で、特定の条件下で検証される
    gift_list: Yup.array().of(
      Yup.object({
        stamp_count: Yup.string()
          .nullable()
          .when(['is_checked'], (is_checked, schema) =>
            !is_checked
              ? schema.strip()
              : schema
                  .test('stamp_count', '必須', (value, testContext) =>
                    ['0', '1', '2'].includes(testContext.from[1].value.trigger)
                      ? !!value
                      : schema.strip()
                  )
                  .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります')
          ),
        point: Yup.string()
          .nullable()
          .when('is_checked', (is_checked, schema) =>
            !is_checked
              ? schema.strip()
              : schema
                  .test('point', '必須', (value, testContext) =>
                    ['3', '4', '5'].includes(testContext.from[1].value.trigger)
                      ? !!value
                      : schema.strip()
                  )
                  .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります')
          ),
        max_winner_count: Yup.string()
          .nullable()
          .when(['is_checked', 'rank'], (is_checked, rank, schema) =>
            !is_checked
              ? schema.strip()
              : schema
                  .test('max_winner_count', '必須', (value, testContext) =>
                    testContext.from[1].value.lottery_method === '0' &&
                    rank != '9'
                      ? !!value
                      : schema.strip()
                  )
                  .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります')
          ),
        winner_probability: Yup.string()
          .nullable()
          .when(['is_checked', 'rank'], (is_checked, rank, schema) =>
            !is_checked
              ? schema.strip()
              : schema
                  .test('winner_probability', '必須', (value, testContext) =>
                    testContext.from[1].value.lottery_method !== '1' &&
                    rank != '9'
                      ? schema.strip()
                      : !!value
                  )
                  .test(
                    'winner_probability',
                    '確率の合計が100%になるよう設定してください',
                    (_, testContext) => {
                      const { lottery_method, focused_rank, gift_list } =
                        testContext.from[1].value;
                      if (
                        lottery_method !== '1' ||
                        focused_rank !== testContext.parent.rank
                      ) {
                        schema.strip();
                        return true;
                      }
                      return (
                        gift_list
                          .filter(v => v?.is_checked)
                          .reduce(
                            (acc, gift) =>
                              acc + (Number(gift?.winner_probability) ?? 0),
                            0
                          ) <= 100
                      );
                    }
                  )
          ),
      })
    ),
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object(yupObject),
    onSubmit: async values => {
      const {
        campaign_start_date,
        campaign_start_hour,
        campaign_start_minute,
        campaign_end_date,
        campaign_end_hour,
        campaign_end_minute,
        gacha_start_date,
        gacha_start_hour,
        gacha_start_minute,
        gacha_end_date,
        gacha_end_hour,
        gacha_end_minute,
        gacha_code,
        lottery_method,
        focused_rank,
        consecutive_startup_days,
        gift_list,
        gacha_content,
        gacha_rich_content,
        ...rest
      } = values;
      const campaignStartPeriod = moment(campaign_start_date)
        .set('h', Number(campaign_start_hour))
        .set('m', Number(campaign_start_minute));
      const campaignEndPeriod = moment(campaign_end_date)
        .set('h', Number(campaign_end_hour))
        .set('m', Number(campaign_end_minute));
      const gachaStartPeriod = moment(gacha_start_date)
        .set('h', Number(gacha_start_hour))
        .set('m', Number(gacha_start_minute));
      const gachaEndPeriod = moment(gacha_end_date)
        .set('h', Number(gacha_end_hour))
        .set('m', Number(gacha_end_minute));

      // キャンペーン期間前後チェック
      if (!campaignStartPeriod.isBefore(campaignEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'キャンペーン表示開始日はキャンペーン表示終了日より前の日付を入力してください',
          })
        );
        return;
      }

      // ガチャ実施期間前後チェック
      if (!gachaStartPeriod.isBefore(gachaEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'ガチャ実施開始日はガチャ実施終了日より前の日付を入力してください',
          })
        );
        return;
      }

      // キャンペーン表示開始日/ガチャ実施開始日前後チェック
      if (!campaignStartPeriod.isSameOrBefore(gachaStartPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'キャンペーン表示開始日はガチャ実施開始日と同じか前の日付を入力してください',
          })
        );
        return;
      }

      // ガチャ実施終了日/キャンペーン表示終了日前後チェック
      if (!gachaEndPeriod.isSameOrBefore(campaignEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'ガチャ実施終了日はキャンペーン表示終了日と同じか前の日付を入力してください',
          })
        );
        return;
      }

      const winnerProp = !!Number(lottery_method)
        ? 'winner_probability'
        : 'max_winner_count';
      const giftList = gift_list
        .filter(v => v?.is_checked)
        .map(v => {
          const giftProps = ['0', '1', '2'].includes(trigger)
            ? 'stamp_count'
            : 'point';
          return {
            rank: v?.rank,
            [giftProps]: v[giftProps],
            [winnerProp]: v[winnerProp],
          };
        });

      const hasDetail =
        values?.gacha_rich_content?.length > 1 ||
        values?.gacha_rich_content.some(r =>
          (r.children ?? []).some(
            c =>
              c.text ||
              (c?.type === 'link' && (c.children ?? []).some(c => c.text))
          )
        );

      const locationList = locations?.map((l, i) => {
        const identifier = isUpdate ? maxNumber + i : 1 + i;
        return {
          ...l,
          identifier:
            l.identifier == null
              ? identifier.toString().padStart(5, '0')
              : l.identifier,
        };
      });
      const paramsBase = {
        ...rest,
        gift_list: giftList,
        lottery_method: lottery_method,
        locations: locationList,
        campaign_start_date: campaignStartPeriod.format('YYYY-MM-DD HH:mm:ss'),
        campaign_end_date: campaignEndPeriod.format('YYYY-MM-DD HH:mm:ss'),
        gacha_start_date: gachaStartPeriod.format('YYYY-MM-DD HH:mm:ss'),
        gacha_end_date: gachaEndPeriod.format('YYYY-MM-DD HH:mm:ss'),
        gacha_rich_content:
          new_contents_flag !== 0
            ? gacha_rich_content
                ?.map(t => serialize(t))
                ?.map((dom, i) => ({
                  sort_num: `${i + 1}`,
                  dom,
                }))
                .map(obj => obj?.dom)
                .join('-----separator-----')
            : '',
        gacha_content:
          new_contents_flag !== 0 && hasDetail
            ? '%html%' +
              gacha_rich_content
                ?.map(t => serialize(t))
                ?.map((dom, i) => ({
                  sort_num: `${i + 1}`,
                  dom,
                }))
                .map(obj => obj.dom)
                .join('<br />') +
              '%html%'
            : new_contents_flag !== 0
            ? ''
            : gacha_content ?? '',
      };

      const params = !!Number(rest.trigger)
        ? {
            ...paramsBase,
            consecutive_startup_days: consecutive_startup_days,
          }
        : paramsBase;

      return isCopy
        ? dispatch(fetchGachaCreate(params))
        : isUpdate
        ? dispatch(fetchGachaUpdate({ gacha_code: gacha_code, ...params }))
        : dispatch(fetchGachaCreate(params));
    },
  });

  const columns = [
    {
      title: '名称',
      dataIndex: 'location_name',
      width: '230px',
      render: (shopName, current, i) => {
        return current?.reflect ? (
          shopName
        ) : (
          <TextBox
            formik={formik}
            listFieldName={`locations.${i + geoOffset}.location_name`}
            placeholder="名称を入力してください"
            width={'none'}
          />
        );
      },
    },
    {
      title: '緯度',
      dataIndex: 'latitude',
      render: (latitude, current, i) => {
        return current.reflect ? (
          latitude
        ) : (
          <TextBox
            formik={formik}
            listFieldName={`locations.${i + geoOffset}.latitude`}
            placeholder=""
            width={'none'}
          />
        );
      },
    },
    {
      title: '経度',
      dataIndex: 'longitude',
      render: (longitude, current, i) => {
        return current.reflect ? (
          longitude
        ) : (
          <TextBox
            formik={formik}
            listFieldName={`locations.${i + geoOffset}.longitude`}
            placeholder=""
            width={'none'}
          />
        );
      },
    },
    {
      title: '半径',
      dataIndex: 'radius',
      render: (radius, current, i) => {
        return !current.reflect ? (
          <TextBox
            formik={formik}
            listFieldName={`locations.${i + geoOffset}.radius`}
            placeholder=""
            width={'none'}
            suffix="m"
          />
        ) : radius ? (
          `${radius}m`
        ) : (
          ''
        );
      },
    },
    {
      title: '中央に表示',
      align: 'center',
      onCell: current => {
        return { colSpan: current.reflect ? 1 : 2 };
      },
      render: (_, current, i) => {
        return current.reflect ? (
          <TcButton
            icon={
              <Icons.IconCheckIn
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 2,
                }}
              />
            }
            text="中央に表示"
            theme={'white'}
            onClick={() => {
              setCenter({
                lat: current.latitude,
                lng: current.longitude,
              });
            }}
            style={{
              width: 100,
              margin: 0,
            }}
          />
        ) : (
          <TcButton
            text="反映"
            theme="black"
            disabled={formik.errors?.locations?.[i + geoOffset]}
            style={{
              width: 160,
              margin: 0,
            }}
            onClick={() => {
              let updateList = [...locations];
              updateList[i + geoOffset].reflect = true;
              handlerFormikFieldChange(formik, `locations`, updateList);
              handlerFormikFieldChange(formik, `locations_edit`, updateList);
            }}
          />
        );
      },
    },
    {
      title: '編集',
      align: 'center',
      width: '80px',
      onCell: current => {
        return { colSpan: current.reflect ? 1 : 0 };
      },
      render: (_, current, i) =>
        current.reflect && (
          <Icons.IconEdit
            className="table-cell-icon tc-action-icon"
            onClick={() => {
              let updateList = [...locations];
              const updatedItem = {
                ...updateList[i + geoOffset],
                reflect: false,
              };
              updateList[i + geoOffset] = updatedItem;
              handlerFormikFieldChange(formik, 'locations', updateList);
              handlerFormikFieldChange(formik, `locations_edit`, updateList);
            }}
          />
        ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: (_, current, i) => (
        <Icons.IconTrash
          className="table-cell-icon tc-action-icon"
          onClick={() => {
            let updateList = [...locations];
            updateList.splice(i + geoOffset, 1);
            handlerFormikFieldChange(formik, 'locations', updateList);
            handlerFormikFieldChange(formik, `locations_edit`, updateList);
          }}
        />
      ),
    },
  ];

  const breakDownDate = value => ({
    date: format(value, 'YYYY/MM/DD'),
    hour: format(value, 'HH'),
    minute: format(value, 'mm'),
  });

  const setValues = () => {
    const {
      date: campaign_start_date,
      hour: campaign_start_hour,
      minute: campaign_start_minute,
    } = breakDownDate(currentGacha?.campaign_start_date);

    const {
      date: campaign_end_date,
      hour: campaign_end_hour,
      minute: campaign_end_minute,
    } = breakDownDate(currentGacha?.campaign_end_date);

    const {
      date: gacha_start_date,
      hour: gacha_start_hour,
      minute: gacha_start_minute,
    } = breakDownDate(currentGacha?.gacha_start_date);

    const {
      date: gacha_end_date,
      hour: gacha_end_hour,
      minute: gacha_end_minute,
    } = breakDownDate(currentGacha?.gacha_end_date);

    const lottery_method = currentGacha.lottery_method === 0 ? '0' : '1';

    const gift_list = currentGacha.gift_list;

    const mergedGiftList = isCopy
      ? (() => {
          const giftMap = new Map();

          initialValues.gift_list.forEach(item => giftMap.set(item.rank, item));

          gift_list?.forEach(item => giftMap.set(item.rank, item));

          return Array.from(giftMap.values());
        })()
      : gift_list;

    const values = {
      ...currentGacha,
      gift_list: mergedGiftList,
      lottery_method: lottery_method,
      campaign_start_date: campaign_start_date,
      campaign_start_hour: campaign_start_hour,
      campaign_start_minute: campaign_start_minute,
      campaign_end_date: campaign_end_date,
      campaign_end_hour: campaign_end_hour,
      campaign_end_minute: campaign_end_minute,
      gacha_start_date: gacha_start_date,
      gacha_start_hour: gacha_start_hour,
      gacha_start_minute: gacha_start_minute,
      gacha_end_date: gacha_end_date,
      gacha_end_hour: gacha_end_hour,
      gacha_end_minute: gacha_end_minute,
      gacha_rich_content: toSlateState(currentGacha?.gacha_rich_content),
      template: templateList?.filter(
        e => e?.template_text === currentGacha?.fixed_phrase
      )?.[0]?.template_code,
    };

    formik.setValues(values);
  };

  useEffect(
    () =>
      editModalVisible
        ? currentGacha
          ? setValues()
          : formik.validateForm()
        : formik.resetForm(),
    [editModalVisible, templateList]
  );

  useEffect(() => {
    dispatch(fetchGachaTemplateList());
  }, [editModalVisible]);

  const onBack = () => dispatch(gachaActions.closeEditModal());

  const [availableShops, setAvailableShops] = useState([]);

  const {
    gacha_code,
    banner_image_url,
    trigger_temp,
    trigger,
    campaign_notice_flag,
    gacha_notice_flag,
    lottery_method,
    locations,
    locations_temp,
    gift_list,
  } = formik.values;

  const makers = locations?.filter(s => s?.reflect);

  const otherInputDisabled = trigger === null;

  const triggerElement = type => {
    return isUpdate ? (
      <div style={{ marginRight: 20 }}>
        {`${
          selectOptionList
            ?.filter(s => s.field_name === 'トリガー')?.[0]
            ?.select_option?.find(v => v.value === trigger)?.label
        }`}
      </div>
    ) : (
      <div className="trigger-box">
        <SelectBox
          formik={formik}
          fieldName={'trigger_temp'}
          options={
            selectOptionList?.filter(s => s.field_name === 'トリガー')?.[0]
              ?.select_option ?? []
          }
          uneditable={isUpdate}
          placeholder="トリガーを選択してください"
        />
        <TcButton
          text="反映"
          style={{
            margin: 0,
            marginLeft: 5,
            marginRight: 20,
            width: 50,
          }}
          disabled={!trigger_temp}
          onClick={() => {
            // formik.resetForm();

            formik.setValues({
              ...initialValues,
              trigger_temp,
              trigger: trigger_temp,
            });
            // handlerFormikFieldChange(formik, 'trigger', trigger_temp);
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      className={'gacha-modal tc-modal shop-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={
            isCopy ? 'ガチャ登録(複製)' : isUpdate ? 'ガチャ編集' : 'ガチャ登録'
          }
          tip={'*は必須項目です'}
          rightElement={triggerElement()}
        />

        <div className="input-area">
          {!isCopy && isUpdate && (
            <div className="show-area">
              <span className="pre-label">ガチャCD</span>
              <span className="font-sm">{gacha_code}</span>
            </div>
          )}
          {['1', '4'].includes(trigger) && (
            <CustomNumberPicker
              formik={formik}
              preLabel={'連続起動日数'}
              fieldName={'consecutive_startup_days'}
              placeholder={'00'}
              width={!isCopy && isUpdate ? 140 : 300}
              from={1}
              to={31}
              uneditable={!isCopy && isUpdate}
              suffix={'日'}
              isRequired
            />
          )}
          {['2', '5'].includes(trigger) && (
            <span className="gacha-type-area">
              <TcRadio
                formik={formik}
                label={'チェックインルール'}
                fieldName="check_in_rule"
                width={280}
                options={checkinRuleOptions}
                required={true}
                isRadio={true}
              />
            </span>
          )}
          {['2', '5'].includes(trigger) && (
            <>
              <div
                className="check-area"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 30,
                }}
              >
                <span className="pre-label label-required">対象店舗</span>
                <DualListBox
                  selectBoxId="stamp_shop_select_box"
                  brandList={accountBrandList}
                  shopList={accountShopList}
                  selectShops={locations_temp}
                  setSelectShops={v =>
                    handlerFormikFieldChange(formik, 'locations_temp', v)
                  }
                  availableShops={availableShops}
                  setAvailableShops={setAvailableShops}
                  disabled={false}
                  onReflect={() => {
                    const locationData = [
                      ...locations_temp.map((s, i) => ({
                        ...s,
                        location_name: s?.location_name
                          ? s?.location_name
                          : s?.shop_name,
                        latitude: s?.latitude
                          ? s?.latitude
                          : s?.location
                          ? s.location[0]
                          : '',
                        longitude: s?.longitude
                          ? s?.longitude
                          : s?.location
                          ? s.location[1]
                          : '',
                        reflect: [
                          s?.location_name,
                          s?.latitude,
                          s?.longitude,
                          s?.radius,
                        ].every(e => e),
                      })),
                    ];

                    handlerFormikFieldChange(formik, 'locations', locationData);
                    handlerFormikFieldChange(
                      formik,
                      'locations_edit',
                      locationData
                    );
                  }}
                />
              </div>
              {makers.length > 0 && (
                <GeoMap
                  center={center}
                  makers={makers.map(m => ({
                    ...m,
                    lat: Number(m.latitude),
                    lng: Number(m.longitude),
                  }))}
                />
              )}
              {locations.length > 0 && (
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <TcTable
                    showPaging={false}
                    columns={columns}
                    dataSource={locations}
                    offset={geoOffset}
                    total={locations.length}
                    onChange={offset => {
                      dispatch(gachaActions.saveGeoOffset(offset));
                    }}
                  />
                  <Tooltip
                    title="新規追加"
                    color="#C4A6A9"
                    overlayClassName="add-tooltip"
                    className="plus-tooltip"
                  >
                    <Icons.IconPlusWh
                      className="plus-icon"
                      onClick={() => {
                        const locationData = [
                          ...locations.map((s, i) => ({
                            ...s,
                            reflect: [
                              s?.location_name,
                              s?.latitude,
                              s?.longitude,
                              s?.radius,
                            ].every(e => e),
                          })),
                          {
                            identifier: null,
                            location_name: null,
                            latitude: null,
                            longitude: null,
                            radius: null,
                          },
                        ];
                        handlerFormikFieldChange(
                          formik,
                          'locations',
                          locationData
                        );
                        handlerFormikFieldChange(
                          formik,
                          'locations_edit',
                          locationData
                        );
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </>
          )}
          <div className="image-area">
            <span
              className={classNames('pre-label label-required', {
                'label-disabled': otherInputDisabled,
              })}
            >
              バナー画像
            </span>
            <div className="image-select">
              {!!banner_image_url ? (
                <>
                  <Icon
                    component={Icons.IconXCircleWh}
                    className="close-icon"
                    onClick={() => {
                      handlerFormikFieldChange(
                        formik,
                        'banner_image_url',
                        null
                      );
                      // e.stopPropagation();
                    }}
                  />
                  <Image
                    src={banner_image_url}
                    width={150}
                    height={150}
                    preview={false}
                  />
                </>
              ) : (
                <BaseUpload
                  disabled={otherInputDisabled}
                  onChange={url =>
                    handlerFormikFieldChange(formik, 'banner_image_url', url)
                  }
                >
                  <TcButton
                    text="画像を追加"
                    theme="white"
                    disabled={otherInputDisabled}
                  />
                </BaseUpload>
              )}
            </div>
          </div>
          <TextBox
            formik={formik}
            preLabel="ガチャ名"
            fieldName="gacha_name"
            width={'100%'}
            isRequired
            disabled={otherInputDisabled}
          />
          <div className="gacha-content">
            <span className="error">
              {formik.touched.gacha_content && formik.errors.gacha_content}
            </span>
            <span
              className="cus-input-line input-textarea"
              style={new_contents_flag !== 0 ? { height: 'unset' } : {}}
            >
              <span className="pre-label">
                <span
                  className={classNames('label-required', {
                    'label-disabled': otherInputDisabled,
                  })}
                >
                  ガチャ説明
                </span>
                {new_contents_flag === 0 && (
                  <ContentsTooltip notificationTip={true} />
                )}
              </span>
              {new_contents_flag !== 0 ? (
                <div style={{ width: '100%' }}>
                  <RichTextEditor
                    readOnly={otherInputDisabled}
                    editorState={formik.values.gacha_rich_content}
                    error={
                      formik.touched.gacha_rich_content &&
                      formik.errors.gacha_rich_content
                    }
                    onChange={state =>
                      formik.setFieldValue('gacha_rich_content', state)
                    }
                    setTouched={() =>
                      !formik.touched.gacha_rich_content &&
                      formik.setFieldTouched('gacha_rich_content', true)
                    }
                    placeholder={'ガチャ説明を入力してください'}
                  />
                </div>
              ) : (
                <textarea
                  placeholder="ガチャ説明を入力してください"
                  spellCheck={false}
                  autoComplete={'false'}
                  disabled={otherInputDisabled}
                  style={{ height: 100 }}
                  {...formik.getFieldProps('gacha_content')}
                />
              )}
            </span>
          </div>
          {new_contents_flag === 0 && (
            <ImagesUploader
              label={'画像'}
              formik={formik}
              fieldName={'image_url'}
              isSpanLabel={true}
              disabled={otherInputDisabled}
            />
          )}
          <SelectBox
            formik={formik}
            preLabel="定型文"
            fieldName="template"
            placeholder="テンプレートを選択"
            options={templateList}
            disabled={otherInputDisabled}
            width={400}
            extraOnChange={(_, v) =>
              handlerFormikFieldChange(
                formik,
                'fixed_phrase',
                templateList.find(e => e.value === v)?.template_text
              )
            }
          />
          <span
            className="cus-input-line input-textarea"
            style={{ marginTop: '5px' }}
          >
            <label />
            <textarea
              placeholder=""
              spellCheck={false}
              autoComplete={'false'}
              value={formik.values.fixed_phrase}
              disabled={otherInputDisabled}
              onChange={e =>
                handlerFormikFieldChange(formik, 'fixed_phrase', e.target.value)
              }
            />
          </span>
          <DateTimeRangePicker
            formik={formik}
            preLabel={'キャンペーン表示期間'}
            fromFieldName={'campaign_start_date'}
            fromHourFieldName={'campaign_start_hour'}
            fromMinuteFieldName={'campaign_start_minute'}
            toFieldName={'campaign_end_date'}
            toHourFieldName={'campaign_end_hour'}
            toMinuteFieldName={'campaign_end_minute'}
            fromUneditable={isCopy ? !isCopy : isUpdate}
            isRequired
            width={'100%'}
            disabled={otherInputDisabled}
          />
          <DateTimeRangePicker
            formik={formik}
            preLabel={'ガチャ実施期間'}
            fromFieldName={'gacha_start_date'}
            fromHourFieldName={'gacha_start_hour'}
            fromMinuteFieldName={'gacha_start_minute'}
            toFieldName={'gacha_end_date'}
            toHourFieldName={'gacha_end_hour'}
            toMinuteFieldName={'gacha_end_minute'}
            fromUneditable={isCopy ? !isCopy : isUpdate}
            toUneditable={isCopy ? !isCopy : isUpdate}
            isRequired
            width={'100%'}
            disabled={otherInputDisabled}
          />
          <span className="push-area">
            <span
              className={classNames('pre-label label-required', {
                'label-disabled': otherInputDisabled,
              })}
            >
              プッシュ通知
            </span>
            {!isCopy && isUpdate ? (
              <div className="push-list">
                <div className="radio-area">
                  <span
                    className={classNames('pre-label', {
                      'label-disabled': otherInputDisabled,
                    })}
                  >
                    キャンペーン表示開始
                  </span>
                  <span className="font-sm">
                    {getOptionLabel(flagOptions, campaign_notice_flag)}
                  </span>
                </div>
                <div className="radio-area end">
                  <span
                    className={classNames('pre-label', {
                      'label-disabled': otherInputDisabled,
                    })}
                  >
                    ガチャ実施開始
                  </span>
                  <span className="font-sm">
                    {getOptionLabel(flagOptions, gacha_notice_flag)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="push-list edit">
                <div className="radio-area">
                  <TcRadio
                    formik={formik}
                    label={'キャンペーン表示開始'}
                    isRadio={true}
                    fieldName="campaign_notice_flag"
                    width={150}
                    options={flagOptions}
                    disabled={otherInputDisabled}
                    labelDisabled={otherInputDisabled}
                    onChange={v =>
                      handlerFormikFieldChange(
                        formik,
                        'campaign_notice_flag',
                        v
                      )
                    }
                  />
                </div>
                <div className="radio-area end">
                  <TcRadio
                    disabled={otherInputDisabled}
                    labelDisabled={otherInputDisabled}
                    formik={formik}
                    label={'ガチャ実施開始'}
                    isRadio={true}
                    fieldName="gacha_notice_flag"
                    width={150}
                    options={flagOptions}
                    onChange={v =>
                      handlerFormikFieldChange(formik, 'gacha_notice_flag', v)
                    }
                  />
                </div>
              </div>
            )}
          </span>

          {!isCopy && isUpdate ? (
            <div className="show-area type">
              <span
                className={classNames('pre-label label-required', {
                  'label-disabled': otherInputDisabled,
                })}
              >
                当選形式
              </span>
              <span className="font-sm">
                {getOptionLabel(winningOptions, lottery_method)}
              </span>
            </div>
          ) : (
            <span className="gacha-type-area">
              <TcRadio
                disabled={otherInputDisabled}
                labelDisabled={otherInputDisabled}
                formik={formik}
                label={'当選形式'}
                isRadio={true}
                fieldName="lottery_method"
                width={150}
                required={true}
                options={winningOptions}
                onChange={v =>
                  handlerFormikFieldChange(formik, 'lottery_method', v)
                }
              />
            </span>
          )}

          <InputGift
            formik={formik}
            isUpdate={!isCopy && isUpdate}
            disabled={otherInputDisabled}
            lottery_disabled={lottery_method == 0 && !isCopy && isUpdate}
            loser_display_number={gift_list.length - 2}
          />

          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={!isCopy && isUpdate ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GachaModal;
