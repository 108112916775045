import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { couponTypes } from '../constants/options';
import moment from 'moment';

const initialSearchParams = {
  stamp_rally_code: '',
  stamp_rally_name: '',
  stamp_rally_start_date: null,
  stamp_rally_end_date: null,
  expire_type: null,
  exchange_type: null,
  coupon_code: null,
  deleted_flg: 0,
  offset: 0,
  count: 10,
  sort: 'stamp_rally_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,
  editModalVisible: false,
  deleteStamp: false,
  referenceStamp: null,
  stampRallyList: [],
  mode: "",
  referenceModalVisible: false,
  currentstamp: null,
  stampRallyCouponList: [],
  stampRallyTemplateList: [],
  stampDetail: null,
};

export const fetchStampRallyTemplateList = createAsyncThunk(
  'fetchStampRallyTemplateList',
  async () => await axios.get('stamp_rally/template')
);

export const fetchStampRallyCouponList = createAsyncThunk(
  'fetchStampRallyCouponList',
  async params =>
    await axios.get('/coupon/list', {
      params: {
        coupon_type: couponTypes['stamp'],
      },
    })
);
export const fetchStampRallyExtraInfo = createAsyncThunk(
  'fetchStampRallyExtraInfo',
  async () => {
    const [stampRallyCouponRes, stampRallyTemplateRes] =
      await Promise.all([
        axios.get('/coupon/list', {
          params: {
            coupon_type: couponTypes['stamp'],
          },
        }),
        axios.get('/settings/template/list', {
          params: {
            template_type: 3,
          },
        }),
      ]);

    return {
      stampRallyCouponList: (stampRallyCouponRes.item ?? []).map(t => ({
        ...t,
        label: t.coupon_name,
        value: t.coupon_code,
      })),
      stampRallyTemplateList: (stampRallyTemplateRes.item ?? []).map(t => ({
        ...t,
        label: t.template_title,
        value: t.template_code,
        template_text: t.template_text,
      })),
    };
  }
);

export const fetchStampRallyList = createAsyncThunk(
  'fetchStampRallyList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('stamp_rally/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchStampRallyDetail = createAsyncThunk(
  'fetchStampRallyDetail',
  async params =>
    await axios.get('stamp_rally/detail', {
      params: { stamp_rally_code: params.stamp_rally_code },
    })
);

export const fetchStampRallyCopy = createAsyncThunk(
  'fetchStampRallyCopy',
  async params =>
    await axios.get('stamp_rally/detail', {
      params: { stamp_rally_code: params.stamp_rally_code },
    })
);

export const fetchStampRallyReference = createAsyncThunk(
  'fetchStampRallyReference',
  async params =>
    await axios.get('stamp_rally/detail', {
      params: { stamp_rally_code: params.stamp_rally_code },
    })
);

export const fetchStampRallyCreate = createAsyncThunk(
  'fetchStampRallyCreate',
  async (params, { dispatch }) => {
    await axios.post('stamp_rally/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchStampRallyUpdate = createAsyncThunk(
  'fetchStampRallyUpdate',
  async (params, { dispatch, getState }) => {
    await axios.post(
      `/stamp_rally/${params.isCreate ? 'create' : 'update'}`,
      params
    );
    //await axios.post('stamp_rally/update', { ...params });
    dispatch(fetchStampRallyList(getState().stampRally.searchParams));
    dispatch(
      globalActions.showSingleModal(
        `${params.isCreate ? '登録' : '更新'}が完了しました`
      )
    );
  }
);

export const fetchStampRallyDelete = createAsyncThunk(
  'fetchStampRallyDelete',
  async (params, { dispatch, getState }) => {
    await axios.post('stamp_rally/delete', { ...params });
    dispatch(fetchStampRallyList(getState().stampRally.searchParams));
  }
);

export const stampRallySlice = createSlice({
  name: 'stampRallySlice',
  initialState,
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteStamp: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentStamp: null,
      stampDetail: null,
      mode: ""
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteStamp: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceStamp: null,
      referenceModalVisible: false,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchStampRallyList.fulfilled]: (state, { payload }) => {
      state.stampRallyList =
        payload.item.map(e => ({
          ...e,
        })) ?? [];
      state.total = payload.total_count;
    },
    [fetchStampRallyDetail.fulfilled]: (state, { payload }) => {
      const detail = adjustDetailItem(payload.item);
      state.editModalVisible = true;
      state.stampDetail = detail;
    },
    [fetchStampRallyCopy.fulfilled]: (state, { payload }) => {
      const detail = adjustDetailItem(payload.item , true);
      state.editModalVisible = true;
      state.stampDetail = detail;
      state.mode = "copy";
    },
    [fetchStampRallyUpdate.fulfilled]: state => {
      state.editModalVisible = false;
      state.stampDetail = null
        state.mode = "";
    },
    [fetchStampRallyReference.fulfilled]: (state, { payload }) => {
      const detail = adjustDetailItem(payload.item);
      state.referenceModalVisible = true;
      state.referenceStamp = detail;
    },

    [fetchStampRallyExtraInfo.fulfilled]: (state, { payload }) => {
      state.stampRallyCouponList = payload.stampRallyCouponList.filter(
        e => e.stamp_linkage_flg === '0' && e.extracted_flag !== '1'
      );
      state.stampRallyTemplateList = payload.stampRallyTemplateList;
    },
    [fetchStampRallyCouponList.fulfilled]: (state, { payload }) => {
      state.stampRallyCouponList = payload.item;
    },
  },
});

const adjustDetailItem = (item, copy= false) => {
  const {
    valid_days_flag,
    display_start_date,
    display_end_date,
    valid_days,
    stamp_rally_rich_text,
    coupon_code,
    coupon_name,
    ...rest
  } = item ?? {};

  const startDate = moment(display_start_date);

  const endDate = moment(display_end_date);

  return {
    ...rest,
    valid_days_flag,
    valid_days,
    coupon_code: copy ? null : coupon_code,
    coupon_name: copy ? null : coupon_name,
    valid_days1: valid_days_flag === '0' ? valid_days : null,
    valid_days2: valid_days_flag === '1' ? valid_days : null,
    use_date_from: startDate.format('YYYY/MM/DD'),
    use_hour_from: startDate.format('HH'),
    use_minute_from: startDate.format('mm'),
    use_date_to: endDate.format('YYYY/MM/DD'),
    use_hour_to: endDate.format('HH'),
    use_minute_to: endDate.format('mm'),
    stamp_rally_rich_text: stamp_rally_rich_text
      ? stamp_rally_rich_text
          .split('-----separator-----')
          .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
      : [],
  };
};

export const stampRallyActions = stampRallySlice.actions;

export default stampRallySlice.reducer;
