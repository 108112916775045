import classNames from "classnames";
import "./style.scss";

/* 汎用ボタン */

const Button = ({
  width,
  theme = "black",
  type = "button",
  fontSize,
  text,
  style = {},
  onClick,
  disabled,
  ...restProps
}) => {
  const className = classNames("nf-btn", {
    [`nf-btn-${width}`]: width,
    [`nf-btn-${theme}`]: theme,
    [`nf-btn-${fontSize}`]: fontSize,
    [`nf-btn-disabled`]: disabled,
  });
  return (
    <button
      disabled={disabled}
      contentEditable={false}
      className={className}
      type={type}
      style={{...style, paddingTop: '2px'}}
      onClick={onClick}
      {...restProps}
    >
      {text}
    </button>
  );
};

export default Button;
