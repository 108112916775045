import { menuOptions } from '../../constants/options';
import TcCheckbox from '.';
import classNames from 'classnames';
import './style.scss';

const MenuCheckbox = ({ formik, disabled, readOnly, staffInfo }) => {
  return (
    <div className="tc-menu-area">
      {menuOptions.map((m, i) => (
        <div key={i} className="menu-line">
          <TcCheckbox
            readOnly={readOnly}
            label={m.label}
            disabled={
              disabled ||
              readOnly ||
              (m.name === 'top_flag' && formik.values['top_flag'] === 1)
            }
            checked={
              (readOnly ? staffInfo?.[m.name] : formik.values[m.name]) === 1
            }
            onChange={() => {
              const checked = formik.values[m.name] === 1;
              formik.setFieldValue(m.name, checked ? 0 : 1);
              (m.subMenu ?? []).forEach(sub =>
                formik.setFieldValue(sub.name, checked ? 0 : 1)
              );
            }}
          />
          <div className="sub-menu">
            {(m.subMenu ?? []).map(sub => (
              <TcCheckbox
                readOnly={readOnly}
                disabled={disabled}
                className={classNames({
                  'wrap-option': m.name === 'stamp_flag',
                })}
                label={sub.label}
                checked={
                  (readOnly
                    ? staffInfo?.[sub.name]
                    : formik.values[sub.name]) === 1
                }
                onChange={() => {
                  const subMenuChecked = formik.values[sub.name] === 1;
                  formik.setFieldValue(sub.name, subMenuChecked ? 0 : 1);
                  !subMenuChecked && formik.setFieldValue(m.name, 1);
                }}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuCheckbox;
