import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Yup from '../../utils/yupUtil';
import { fetchNameUpdate, nameActions } from '../../slices/nameSlice';
import { useUpdateEffect } from 'react-use';
import { Modal } from 'antd';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import React from 'react';
import './style.scss';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';

const NameModal = () => {
  const dispatch = useDispatch();

  const { accountInfo } = useSelector(state => state.account);

  const { nameModalVisible, initialValues, currentName, selectOptionList } =
    useSelector(state => state.name);

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      update_name: Yup.string().nullable(),
      display_order: Yup.string().nullable().required(),
    }),
    onSubmit: async values => {
      const params = {
        operator_code: accountInfo.staff_code,
        ...values,
      };
      dispatch(fetchNameUpdate(params));
    },
  });

  const onBack = () => dispatch(nameActions.resetNameModal());

  const createDisplayOrderSelectOptions = () => {
    if (selectOptionList.length === 0) {
      return [];
    }

    const maxSelectOption = selectOptionList
      ?.filter(
        selectOption => selectOption.field_code === formik.values.field_code
      )
      .reduce((max, current) => {
        return current.select_option.length > max.select_option.length
          ? current
          : max;
      }, selectOptionList[0]);

    return Array.from(
      { length: maxSelectOption.select_option.length },
      (v, i) => ({
        label: (i + 1).toString(),
        value: (i + 1).toString(),
      })
    );
  };

  useUpdateEffect(() => {
    nameModalVisible
      ? formik.setValues({ ...currentName })
      : formik.resetForm();
  }, [nameModalVisible]);

  return (
    <Modal
      className={'name-modal tc-modal'}
      open={nameModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'名称編集'} tip={'*は必須項目です'} />
        <div className="input-area name-modal-input-area">
          <div className="text-area">
            <label>名称CD</label>
            <span>{currentName?.select_option_code}</span>
          </div>
          <div className="text-area">
            <label>フィールド名</label>
            <span>{currentName?.field_name ?? '-'}</span>
          </div>
          <div className="text-area">
            <label>ラベル</label>
            <span>{currentName?.label ?? '-'}</span>
          </div>
          <div className="text-area">
            <label>使用画面名</label>
            <span>
              {currentName?.used_screen_name
                ?.map(
                  (v, i) =>
                    `${v}${
                      i < currentName.used_screen_name.length - 1 ? ', ' : ''
                    }`
                )
                .join('') ?? '-'}
            </span>
          </div>
          <TextBox
            formik={formik}
            label="名称"
            fieldName="update_label"
            width={'100%'}
          />
          <SelectBox
            formik={formik}
            label="表示優先順"
            fieldName="display_order"
            options={createDisplayOrderSelectOptions()}
            isRequired
          />
          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={'更新'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NameModal;
