import moment from 'moment';
import * as Yup from 'yup';
import axios from '../utils/axiosUtil';
import { isAllNullOrAllNotNull } from './fnUtil';

Yup.setLocale({
  mixed: {
    required: '必須',
  },
  string: {
    min: ({ min }) => `${min}文字以上入力してください`,
    max: ({ max }) => `${max}文字以内で入力して下さい`,
  },
  array: {
    min: ({ min }) => `${min}個以上登録必須です`,
    max: ({ max }) => `最大${max}個までです`,
  },
});

Yup.addMethod(Yup.string, 'id', function () {
  return this.test(
    'id',
    '形式が異なります',
    value => !value || /^[a-zA-Z0-9]+$/.test(value)
  );
});
Yup.addMethod(Yup.string, 'name', function () {
  return this.test(
    'name',
    '形式が異なります',
    value => !value || /^[ぁ-んァ-ヶー一-龠ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰ｡｢｣､]+$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'selected', function (msg) {
  return this.test('selected', '必須', value => !!value);
});

Yup.addMethod(Yup.string, 'password', function () {
  return this.test(
    'password',
    '英数混在6桁以上で入力してください',
    value => !value || /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{6,}$/i.test(value)
  );
});

Yup.addMethod(Yup.string, 'passwordConfirm', function () {
  return this.test(
    'passwordConfirm',
    'パスワードが一致しません',
    (value, context) => value === context.parent.password
  );
});
Yup.addMethod(Yup.string, 'mail_local', function () {
  return this.test(
    'mail_local',
    '@より前は64文字以内で入力してください',
    value =>
      !value ||
      /(^[a-zA-Z.0-9-._/#?!%&~+]{1,64})+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
        value
      )
  );
});
Yup.addMethod(Yup.string, 'mail', function () {
  return this.test(
    'mail',
    '形式が異なります',
    value =>
      !value ||
      /^[a-zA-Z.0-9-._/#?!%&~+]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'url', function () {
  return this.test(
    'url',
    '形式が異なります',
    value =>
      !value || /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/.test(value)
  );
});

Yup.addMethod(Yup.string, 'kana', function () {
  return this.test(
    'kana',
    '形式が異なります',
    value =>
      !value || /^[ア-ヴ゛゜ァ-ォャ-ョー「」、ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰ｡｢｣・､　 ]+$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'halfWidthAlphanumeric', function () {
  return this.test('halfWidthAlphanumeric', '形式が異なります', value =>
    /^[0-9a-zA-Z]*$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'userExist', function () {
  return this.test('userExist', '既に存在したユーザーです', async value => {
    const res = await axios.get('staff/detail', {
      params: { user_code: value },
    });
    return !(res.code === '1' && !!res.item);
  });
});

Yup.addMethod(Yup.string, 'date_required', function () {
  return this.test('date_required', '必須', value => value);
});

Yup.addMethod(Yup.string, 'shop_date_valid', function () {
  return this.test(
    'shop_date_valid',
    '日付が無効です',
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent.opening_date,
        context.parent.closed_date,
      ]) && context?.parent?.opening_date > context?.parent?.closed_date
  );
});

Yup.addMethod(Yup.string, 'tel', function () {
  return this.test(
    'tel',
    '形式が異なります',
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent.tel1,
        context.parent.tel2,
        context.parent.tel3,
      ]) &&
      /^(\s*|\d+)$/.test(
        `${context.parent.tel1 ?? ''}${context.parent.tel2 ?? ''}${
          context.parent.tel3 ?? ''
        }`
      )
  );
});

Yup.addMethod(Yup.string, 'zip1', function () {
  return this.test('zip1', '3桁の数値で入力してください', (_, context) =>
    context.parent.zip2
      ? /^([0-9]{3})$/.test(context.parent.zip1)
      : !context.parent.zip1 || /^([0-9]{3})$/.test(context.parent.zip1)
  );
});
Yup.addMethod(Yup.string, 'zip2', function () {
  return this.test('zip2', '4桁の数値で入力してください', (_, context) =>
    context.parent.zip1
      ? /^([0-9]{4})$/.test(context.parent.zip2)
      : !context.parent.zip2 || /^([0-9]{4})$/.test(context.parent.zip2)
  );
});
Yup.addMethod(Yup.string, 'fax', function () {
  return this.test(
    'fax',
    '形式が異なります',
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent.fax1,
        context.parent.fax2,
        context.parent.fax3,
      ]) &&
      /^(\s*|\d+)$/.test(
        `${context.parent.fax1 ?? ''}${context.parent.fax2 ?? ''}${
          context.parent.fax3 ?? ''
        }`
      )
  );
});

Yup.addMethod(Yup.string, 'tel1', function () {
  return this.test('tel1', '形式不正', (_, context) =>
    context.parent.tel2 || context.parent.tel3
      ? /^\d{1,}$/.test(context.parent.tel1)
      : !context.parent.tel1 || /^\d{1,}$/.test(context.parent.tel1)
  );
});
Yup.addMethod(Yup.string, 'tel2', function () {
  return this.test('tel2', '形式不正', (_, context) =>
    context.parent.tel1 || context.parent.tel3
      ? /^\d{1,}$/.test(context.parent.tel2)
      : !context.parent.tel2 || /^\d{1,}$/.test(context.parent.tel2)
  );
});
Yup.addMethod(Yup.string, 'tel3', function () {
  return this.test('tel3', '形式不正', (_, context) =>
    context.parent.tel1 || context.parent.tel2
      ? /^\d{1,}$/.test(context.parent.tel3)
      : !context.parent.tel3 || /^\d{1,}$/.test(context.parent.tel3)
  );
});

Yup.addMethod(Yup.string, 'fax1', function () {
  return this.test('fax1', '形式不正', (_, context) =>
    context.parent.fax2 || context.parent.fax3
      ? /^\d{1,}$/.test(context.parent.fax1)
      : !context.parent.fax1 || /^\d{1,}$/.test(context.parent.fax1)
  );
});
Yup.addMethod(Yup.string, 'fax2', function () {
  return this.test('fax2', '形式不正', (_, context) =>
    context.parent.fax1 || context.parent.fax3
      ? /^\d{1,}$/.test(context.parent.fax2)
      : !context.parent.fax2 || /^\d{1,}$/.test(context.parent.fax2)
  );
});
Yup.addMethod(Yup.string, 'fax3', function () {
  return this.test('fax3', '形式不正', (_, context) =>
    context.parent.fax1 || context.parent.fax2
      ? /^\d{1,}$/.test(context.parent.fax3)
      : !context.parent.fax3 || /^\d{1,}$/.test(context.parent.fax3)
  );
});

Yup.addMethod(Yup.string, 'time', function () {
  return this.test(
    'time',
    '形式不正',
    value => !value || moment(value, 'HH:mm', true).isValid()
  );
});

Yup.addMethod(Yup.string, 'longitude', function () {
  return this.test(
    'longitude',
    '有効な範囲で入力してください',
    function (value) {
      if (value == null || value === '') return true;
      if (parseFloat(value) >= 0 && parseFloat(value) <= 90) return true;
    }
  );
});
Yup.addMethod(Yup.string, 'latitude', function () {
  return this.test(
    'latitude',
    '有効な範囲で入力してください',
    function (value) {
      if (value == null || value === '') return true;
      if (parseInt(value) >= 0 && parseInt(value) <= 180) return true;
    }
  );
});

Yup.addMethod(Yup.array, 'editorTextRequired', function () {
  return this.test('editorTextRequired', '必須項目です', function (value) {
    return (
      value?.length >= 1 &&
      value.some(
        v =>
          v.type !== 'content' ||
          (v.children ?? []).some(c => c.text || c.type === 'link')
      )
    );
  });
});

export default Yup;
