import { Modal } from 'antd';
import Icons from '../../../constants/Icons';
import Button from '../../tcButton';
import ModalTitle from '../../modalTitle';
import ReferenceItem from '../../referenceItem';
import { globalActions } from '../../../slices/globalSlice';
import { useUpdateEffect } from 'react-use';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

/* アクセス集計結果モダール */

const PageAccessResultModal = ({
  type,
  modalVisible,
  onClose,
  width = 800,
  tip = '',
  searchArea,
  disabled = false,
}) => {
  const buttonStyle = {
    width: 140,
    height: 30,
    fontSize: 14,
    marginTop: 40,
  };

  const dispatch = useDispatch();

  const { pageAccessResult } = useSelector(
    state => state[type === '0' ? 'topImage' : 'contents']
  );

  const initialValues = {
    mode: '0',
  };

  useUpdateEffect(() => {
  }, [modalVisible]);

  return (
    <>
      <Modal
        open={modalVisible}
        centered
        footer={null}
        closable={false}
        onCancel={onClose}
        width={width}
      >
        <div className="resultModal-content">
          <ModalTitle title={(type === '0' ? 'トップ画像' : 'コンテンツ') + '結果'} tip={tip} />
          {!!searchArea && <div className="border-line" />}
          <div className="contents-area">
            <ReferenceItem
              label={'閲覧回数'}
              value={`詳細ページ閲覧回数：${pageAccessResult?.detail_page_read_count}　外部リンク先閲覧回数：${pageAccessResult?.link_read_count}`}
            />
            <ReferenceItem
              label={'表示結果'}
              value={`詳細ページ閲覧者数：${pageAccessResult?.detail_page_reader_count}　外部リンク先閲覧者数：${pageAccessResult?.link_reader_count}`}
            />
          </div>
          <div className="button-area">
            <Button
              text="閉じる"
              onClick={onClose}
              style={buttonStyle}
              theme={'white'}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PageAccessResultModal;
