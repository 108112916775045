import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import axios from '../utils/axiosUtil';
import moment from 'moment';

const initialSearchParams = {
  menu_code: '',
  menu_name: '',
  account_code: [],
  from_menu_start_date: null,
  to_menu_start_date: null,
  from_create_at: null,
  to_create_at: null,
  delete_flag: 0,
  offset: 0,
  count: 10,
  sort: 'menu_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,
  editModalVisible: false,
  referenceModalVisible: false,
  currentLineRichmenu: null,
  deleteLineRichmenu: null,
  referenceLineRichmenu: null,
  lineRichmenuList: [],
  crop: [],
};

export const fetchLineRichmenuList = createAsyncThunk(
  'fetchLineRichmenuList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('line/richmenu/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchLineRichmenuCopy = createAsyncThunk(
  'fetchLineRichmenuCopy',
  async params =>
    await axios.get('line/richmenu/detail', {
      params: { menu_code: params.menu_code },
    })
);

export const fetchLineRichmenuDetail = createAsyncThunk(
  'fetchLineRichmenuDetail',
  async params =>
    await axios.get('line/richmenu/detail', {
      params: { menu_code: params.menu_code },
    })
);

export const fetchLineRichmenuReference = createAsyncThunk(
  'fetchLineRichmenuReference',
  async params =>
    await axios.get('line/richmenu/detail', {
      params: { menu_code: params.menu_code },
    })
);

export const fetchLineRichmenuCreate = createAsyncThunk(
  'fetchLineRichmenuCreate',
  async (params, { dispatch }) => {
    await axios.post('line/richmenu/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchLineRichmenuUpdate = createAsyncThunk(
  'fetchLineRichmenuUpdate',
  async (params, { dispatch }) => {
    await axios.post('line/richmenu/update', { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchLineRichmenuDelete = createAsyncThunk(
  'fetchLineRichmenuDelete',
  async params => await axios.post('line/richmenu/delete', { ...params })
);

export const lineRichmenuSlice = createSlice({
  name: 'lineRichmenuSlice',
  initialState,
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteLineRichmenu: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentLineRichmenu: null,
      crop: [],
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteLineRichmenu: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceModalVisible: false,
      currentLineRichmenu: null,
      crop: [],
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
    addCrop: (state, { payload }) => {
      const targetCrop = state.crop.find(item => item.menu_number === payload.menuNumber);
      if (targetCrop) {
        targetCrop.bounds.push(payload.newCrop);
      } else {
        state.crop.push({ menu_number: payload.menuNumber, bounds: [payload.newCrop] });
      }
    },
    removeCrop: (state, { payload }) => {
      state.crop.forEach(item => {
        if (item.menu_number === payload.menuNumber) {
          item.bounds.splice(payload.index, 1);
        }
      });
    },
  },
  extraReducers: {
    [fetchLineRichmenuList.fulfilled]: (state, { payload }) => ({
      ...state,
      total_count: payload?.total_count ?? 0,
      total: payload?.search_count ?? 0,
      lineRichmenuList: [
        ...payload?.item?.list.map(lineRichmenu => ({
          ...lineRichmenu,
        })),
      ],
    }),
    [fetchLineRichmenuCopy.fulfilled]: (state, { payload }) => {
      const currentLineRichmenu = adjustCopyItem(payload?.item);
      return {
        ...state,
        editModalVisible: true,
        currentLineRichmenu: currentLineRichmenu,
        crop: adjustCrop(payload?.item),
      }
    },
    [fetchLineRichmenuDetail.fulfilled]: (state, { payload }) => {
      const currentLineRichmenu = adjustDetailItem(payload?.item);
      return {
        ...state,
        editModalVisible: true,
        currentLineRichmenu: addEmptyRichmenu(currentLineRichmenu),
        crop: adjustCrop(payload?.item),
      }
    },
    [fetchLineRichmenuReference.fulfilled]: (state, { payload }) => ({
      ...state,
      referenceModalVisible: true,
      referenceLineRichmenu: payload?.item,
      crop: adjustCrop(payload?.item),
    }),
    [fetchLineRichmenuCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
      crop: [],
    }),
    [fetchLineRichmenuUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      currentLineRichmenu: null,
      searchParams: { ...state.searchParams },
      crop: [],
    }),
    [fetchLineRichmenuDelete.fulfilled]: state => ({
      ...state,
      deleteLineRichmenu: null,
      searchParams: { ...state.searchParams },
    }),
  },
});

const adjustDetailItem = item => {
  const {
    start_date,
    end_date,
    richmenus,
    ...rest
  } = item ?? {};

  const startDate = moment(start_date);
  const endDate = moment(end_date);

  const adjustedRichmenus = richmenus.map(menu => {
    const chatBarTextFlag = menu.chat_bar_text === 'メニュー' ? 0 : 1;
    const modifiedAreas = menu.areas.map(area => {
      if (area.action.type === "url") {
        const url = new URL(area.action.uri);
        area.action.display_mode = url.searchParams.has('openExternalBrowser') ? 0 : 1;
        url.searchParams.delete('openExternalBrowser');
        area.action.uri = url.toString();
      }
      return area;
    });
    return {
      ...menu,
      chat_bar_text_flag: chatBarTextFlag,
      chat_bar_text: chatBarTextFlag === 1 ? menu.chat_bar_text : '',
      areas: modifiedAreas,
    };
  });

  if (adjustedRichmenus.length === 1) {
    adjustedRichmenus.push({
      menu_number: 2,
      chat_bar_text_flag: 0,
      chat_bar_text: '',
      image_url: null,
      size: null,
      areas: [],
    });
  }

  return {
    ...rest,
    start_date: startDate.format('YYYY/M/D'),
    start_hour: startDate.format('HH'),
    start_minute: startDate.format('mm'),
    end_date: endDate.format('YYYY/M/D'),
    end_hour: endDate.format('HH'),
    end_minute: endDate.format('mm'),
    richmenus: adjustedRichmenus,
  };
};

const adjustCopyItem = item => {
  const {
    richmenus,
    ...rest
  } = item ?? {};

  delete rest.menu_code;
  delete rest.create_at;
  delete rest.create_user;
  delete rest.update_at;
  delete rest.update_user;

  const adjustedRichmenus = richmenus.map(menu => {
    const chatBarTextFlag = menu.chat_bar_text === 'メニュー' ? 0 : 1;
    const modifiedAreas = menu.areas.map(area => {
      if (area.action.type === "url") {
        const url = new URL(area.action.uri);
        area.action.display_mode = url.searchParams.has('openExternalBrowser') ? 0 : 1;
        url.searchParams.delete('openExternalBrowser');
        area.action.uri = url.toString();
      }
      return area;
    });
    return {
      ...menu,
      chat_bar_text_flag: chatBarTextFlag,
      chat_bar_text: chatBarTextFlag === 1 ? menu.chat_bar_text : '',
      areas: modifiedAreas,
    };
  });

  if (adjustedRichmenus.length === 1) {
    adjustedRichmenus.push({
      menu_number: 2,
      chat_bar_text_flag: 0,
      chat_bar_text: '',
      image_url: null,
      size: null,
      areas: [],
    });
  }

  return {
    ...rest,
    start_date: null,
    start_hour: null,
    start_minute: null,
    end_date: '2099/12/31',
    end_hour: '00',
    end_minute: '00',
    richmenus: adjustedRichmenus,
  };
};

const addEmptyRichmenu = item => {
  const {
    richmenus,
    ...rest
  } = item ?? {};

  if (richmenus.length === 1) {
    richmenus.push({
      menu_number: 2,
      chat_bar_text_flag: 0,
      chat_bar_text: '',
      image_url: null,
      size: null,
      areas: [],
    });
  }

  return {
    ...rest,
    richmenus: richmenus,
  };
};

const adjustCrop = item => {
  const { richmenus } = item ?? {};

  const crop = richmenus.map(menu => {
    const { menu_number, size, areas } = menu;
    const bounds = areas.map(area => {
      return {
        x: (area.bounds.x / size.width) * 100,
        y: (area.bounds.y / size.height) * 100,
        width: (area.bounds.width / size.width) * 100,
        height: (area.bounds.height / size.height) * 100
      };
    });

    return {
      menu_number,
      bounds
    };
  });

  return crop;
};

export const lineRichmenuActions = lineRichmenuSlice.actions;

export default lineRichmenuSlice.reducer;
