import sha256 from 'crypto-js/sha256';
import moment from 'moment';
import { get } from 'lodash';

export const handlerFormikFieldChange = (formik, field, value) => {
  formik.setFieldValue(field, value);
  setTimeout(() => formik.setFieldTouched(field, true));
};

export const isAllNullOrAllNotNull = (fileds = []) =>
  fileds.every(f => f) || fileds.every(f => !f);

export const compareMemoObject = (pre, next, fields = []) =>
  fields.every(
    field =>
      get(pre.formik.values, pre[field]) ===
        get(next.formik.values, next[field]) &&
      get(pre.formik.errors, pre[field]) ===
        get(next.formik.errors, next[field]) &&
      get(pre.formik.touched, pre[field]) ===
        get(next.formik.touched, next[field])
  );

export const getFormikArrayProps = (formik, listField) => {
  let fields = listField?.split('.');
  let touched = formik.touched[fields[0]];
  let error = formik.errors[fields[0]];
  fields = [...fields.filter((_, i) => i > 0)];

  while (fields.length > 0) {
    if (!isNaN(Number(fields[0]))) {
      touched = touched?.[fields[0]]?.[fields[1]];
      error = error?.[fields[0]]?.[fields[1]];
      fields = [...fields.filter((_, i) => i > 1)];
    } else {
      touched = touched?.[fields[0]];
      error = error?.[fields[0]];
      fields = [...fields.filter((_, i) => i > 0)];
    }
  }

  return { touched, error };
};

export const getOptionLabel = (options, value) =>
  options.find(e => e.value === value)?.label;

export const compareMemoArray = (pre, next) => {
  const preArrayProps = getFormikArrayProps(pre.formik, pre.listFieldName);
  const nextArrayProps = getFormikArrayProps(next.formik, next.listFieldName);

  return (
    pre.formik.getFieldProps(pre.listFieldName).value ===
      next.formik.getFieldProps(pre.listFieldName).value &&
    preArrayProps.touched === nextArrayProps.touched &&
    preArrayProps.error === nextArrayProps.touched
  );
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const thousandsSeparator = v =>
  v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const spliceArray = (orgArray, targetIndex, target, deleteCount = 0) => {
  const temp = [...orgArray];

  temp.splice(targetIndex, deleteCount, target);

  return temp;
};

export const spliceImageArray = (
  orgArray,
  targetIndex,
  target,
  clearCurrent
) => {
  const temp = [...orgArray];

  temp.splice(
    targetIndex,
    1,
    clearCurrent
      ? target
      : {
          ...temp[targetIndex],
          ...target,
        }
  );

  return temp;
};

export const replaceArray = (orgArray, from, target = []) =>
  orgArray.splice(from, target.length, ...target) && orgArray;

export const createNumberOptions = (length = 151, from = 100, suffix = '') =>
  Array.from({ length }, (_, k) => ({
    label: from + k + suffix,
    value: from + k,
  }));

export const createTimeOptions = (length, from = 0) =>
  Array.from({ length }, (_, k) => ({
    label: `${k + from}`.padStart(2, 0),
    value: `${k + from}`.padStart(2, 0),
  }));

export const downloadFile = (content, fileName) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display:none';
  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), content], {
    type: 'text/csv',
  });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.parentNode.removeChild(a);
};

export const numberFormat = str =>
  str || str === 0 ? new Intl.NumberFormat().format(str) : '';

export const dateFormat = (value, format, nullVal = null) =>
  !!value ? moment(value).format(format) : nullVal;

export const hashPassword = password => sha256(password).toString();

export const getMomentTagertWeekOfMonthNum = targetMoment => {
  const target = moment(targetMoment);
  const startOfMonthOfWeekNum = moment(
    moment(target).clone().startOf('month')
  ).format('w');
  const targetOfYearOfWeekNum = target.clone().format('w');
  return targetOfYearOfWeekNum - startOfMonthOfWeekNum + 1;
};

export const getFileTxt = (file, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsText(file, 'UTF-8');
};
export const isCorrectExtension = name => {
  const format = new RegExp('([^s]+(\\.(csv|txt))$)', 'i');
  return format.test(name);
};

export const getYouTubeVideoId = url => {
  const match = url.match(/[?&]v=([a-zA-Z0-9_-]{11})/);
  if (match) {
    return match[1];
  } else {
    const matchEmbed = url.match(/\/embed\/([a-zA-Z0-9_-]{11})/);
    const matchShort = url.match(/youtu\.be\/([a-zA-Z0-9_-]{11})/);

    if (matchEmbed) {
      return matchEmbed[1];
    } else if (matchShort) {
      return matchShort[1];
    } else {
      return null;
    }
  }
};
