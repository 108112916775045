import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import classNames from 'classnames';
import {
  topActions,
  topInitInformation,
  topInitDownload,
  fetchActiveUserList,
} from '../../slices/topSlice';
import Icon from '@ant-design/icons';
import Icons from '../../constants/Icons';
import { Chart } from 'react-google-charts';
import CircleTitle from '../../components/circleTitle';
import DataNone from '../../components/dataNone';
import InformationModal from '../../components/modal/informationModal';
import moment from 'moment';
import { numberFormat } from '../../utils/fnUtil';
import './style.scss';

const Top = () => {
  const dispatch = useDispatch();
  const initialActiveUserWidth = window.innerWidth <= 1200 ? 0 : 415;

  const downloadRef = useRef(null);
  const refActiveUser = useRef(null);

  const [activeUserAreaWidth, setActiveUserAreaWidth] = useState(
    initialActiveUserWidth
  );
  const [graphAreaWidth, setGraphAreaWidth] = useState(0);
  const [graph, setGraph] = useState(null);
  const [infomationModalVisible, setInfomationModalVisible] = useState(false);
  const [infomationModalItem, setInfomationModalItem] = useState({});

  const [toDisplayPeriod, setToDisplayPeriod] = useState(
    moment().subtract(1, 'day')
  );

  const { informationList, downloadInfo, downloadList, activeUserList } =
    useSelector(state => state.top);

  const format = (date, format) => (date ? moment(date)?.format(format) : null);

  const createInformation = (e, i) => {
    return (
      <>
        {i !== 0 && <div className="information-border" />}
        <div
          className="information-line"
          onClick={() => {
            setInfomationModalItem({ ...e });
            setInfomationModalVisible(true);
          }}
        >
          <span className="infomation-date">{e?.date}</span>
          <span className="infomation-title">{e?.information_title}</span>
        </div>
      </>
    );
  };

  const createTooltip = (label, date, value) => {
    return `
      <div className="graph-tooltip-date">${date}</div>
      ${label}：${numberFormat(value)}
    `;
  };

  const getDateSpan = date => {
    const start = moment(date).startOf('month').format('YYYY/M/D');
    const end =
      moment(date).endOf('month').diff(moment()) > 0
        ? moment().subtract(1, 'day').format('YYYY/M/D')
        : moment(date).endOf('month').format('YYYY/M/D');
    return `(${start} ~ ${end})`;
  };

  const thousandsSeparator = v =>
    v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const upDownVectorFactory = data =>
    data > 0 ? (
      <Icons.IconVectorUp className="icon-vector" />
    ) : data < 0 ? (
      <Icons.IconVectorDown className="icon-vector" />
    ) : (
      <Icons.IconVectorStay className="icon-vector" />
    );

  const getDiff = value => (
    <>
      {(value > 0 ? '+' : '') + thousandsSeparator(value)}
      {upDownVectorFactory(value)}
    </>
  );

  const handleResize = () => {
    const downloadRefRect = downloadRef.current.getBoundingClientRect();
    const activeUserRefRect = refActiveUser.current.getBoundingClientRect();

    setGraphAreaWidth(Math.ceil(downloadRefRect.width) + 60);
    setActiveUserAreaWidth(
      window.innerWidth <= 1200 ? 0 : activeUserRefRect.width + 15
    );
  };

  useUpdateEffect(() => {
    const dateFormat = downloadInfo.day_diff >= 180 ? 'YYYY/M' : 'YYYY/M/D';
    let ios = 0;
    let android = 0;
    setGraph(
      downloadList.map(e => {
        const date = moment(e.summary_date).format(dateFormat);
        ios += e.ios_count;
        android += e.android_count;
        const total = ios + android;
        return [
          date,
          ios,
          createTooltip('iOS', date, ios),
          android,
          createTooltip('Android', date, android),
          total,
          createTooltip('合計', date, total),
        ];
      })
    );
  }, [downloadList]);

  useEffect(() => {
    handleResize();
  }, [activeUserList]);

  useEffect(() => {
    const to_display_period = moment().subtract(1, 'day');
    setToDisplayPeriod(to_display_period);
    dispatch(topInitInformation());
    dispatch(
      topInitDownload({
        to_display_period: toDisplayPeriod.format('YYYY/MM/DD'),
      })
    );
    dispatch(fetchActiveUserList());

    handleResize();
    const windowHandleResize = () => handleResize();
    window.addEventListener('resize', windowHandleResize);
    return () => {
      window.removeEventListener('resize', windowHandleResize);
      dispatch(topActions.clear());
    };
  }, [dispatch]);

  return (
    <div className="content-body management-page">
      <InformationModal
        modalVisible={infomationModalVisible}
        setModalVisible={setInfomationModalVisible}
        item={infomationModalItem}
      />
      <div className="top-container">
        <div className="information-area">
          <div className="information-header">
            <Icon component={Icons.IconInfo} className={'information-icon'} />
            <div>お知らせ</div>
          </div>
          <div className="information-body">
            {informationList?.length > 0 ? (
              informationList?.map(createInformation)
            ) : (
              <DataNone />
            )}
          </div>
        </div>
        <div className="bottom-area">
          <div
            className="download-area"
            style={{ width: `calc(100% - ${activeUserAreaWidth}px)` }}
          >
            <div className="download-title">
              <Icon component={Icons.IconChart} className={'download-icon'} />
              <div>総ダウンロード数</div>
            </div>
            <div className="download-bottom">
              <div className="summary" ref={downloadRef}>
                <div className="date">
                  <div className="bold">
                    {`${toDisplayPeriod.format('YYYY/M')}`}
                  </div>
                  <div className="sub grey">
                    {`（${toDisplayPeriod.format('YYYY/M/D')} 迄）`}
                  </div>
                </div>

                <div className="count">
                  <CircleTitle title="iOS" color="#14789c" />
                  <div className="sub blue">
                    {thousandsSeparator(downloadInfo?.ios_count_total)}
                  </div>
                </div>
                <div className="count">
                  <CircleTitle title="Android" color="#149c75" />
                  <div className="sub green">
                    {thousandsSeparator(downloadInfo?.android_count_total)}
                  </div>
                </div>
                <div className="count">
                  <CircleTitle title="合計" color="#9b233f" />
                  <div className="sub red">
                    {thousandsSeparator(
                      (downloadInfo?.ios_count_total || 0) +
                        (downloadInfo?.android_count_total || 0)
                    )}
                  </div>
                </div>
              </div>
              <div
                className="graph-area"
                style={{ width: `calc(100% - ${graphAreaWidth}px)` }}
              >
                <div
                  className={classNames({
                    'graph-wrapper': true,
                    'graph-none': !(graph && graph.length > 0),
                  })}
                >
                  <div className="graph graph-area">
                    {graph && graph.length > 0 ? (
                      <Chart
                        chartType="LineChart"
                        data={[
                          [
                            '',
                            'iOS',
                            {
                              type: 'string',
                              role: 'tooltip',
                              p: { html: true },
                            },
                            'Android',
                            {
                              type: 'string',
                              role: 'tooltip',
                              p: { html: true },
                            },
                            '合計',
                            {
                              type: 'string',
                              role: 'tooltip',
                              p: { html: true },
                            },
                          ],
                          ...graph,
                        ]}
                        options={{
                          colors: ['#14789C', '#149C75', '#9B233F'],
                          legend: { position: 'none' },
                          tooltip: { isHtml: true },
                          chartArea: {
                            height: '70%',
                            top: '5%',
                            bottom: '18%',
                            left: '10%',
                            right: '0',
                          },
                        }}
                        height={'450px'}
                        style={{ fontSize: '10px' }}
                      />
                    ) : (
                      <DataNone />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="active-user-area" ref={refActiveUser}>
            <div className="active-user-title">
              <Icon
                component={Icons.IconActiveUser}
                className={'download-icon'}
              />
              <div>マンスリーアクティブユーザー数</div>
            </div>
            <div
              className={classNames('scroll-area', {
                'data-none': !activeUserList?.length,
              })}
            >
              {!!activeUserList?.length ? (
                [...activeUserList]
                  .sort(
                    (v1, v2) =>
                      moment(v1.summary_date) + moment(v2.summary_date)
                  )
                  .reverse()
                  .map(v => (
                    <div className="scroll-item">
                      <div className="record-area">
                        <div className="record-area-item">
                          <div className="bold date">
                            {format(v.summary_date, 'YYYY/M')}
                          </div>
                          <div className="sub grey">
                            {getDateSpan(v.summary_date)}
                          </div>
                          <div className="count-area">
                            <div className="total-user">
                              <span className="bold red count-max">
                                {thousandsSeparator(
                                  v.ios_count + v.android_count
                                )}
                              </span>
                              <span className="unit grey">人</span>
                            </div>
                            <div className="diff grey">
                              {getDiff(v.ios_count_diff + v.android_count_diff)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="space"></div>
                    </div>
                  ))
              ) : (
                <DataNone />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top;
