import React, { useState, useEffect, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Chart } from 'react-google-charts';
import {
  fetchDownloadList,
  downloadCountCsv,
  downloadActions,
} from '../../slices/downloadSlice';
import Icons from '../../constants/Icons';
import CircleTitle from '../../components/circleTitle';
import TableTitle from '../../components/tableTitle';
import TcButton from '../../components/tcButton';
import DataNone from '../../components/dataNone';
import PageTitle from '../../components/pageTitle';
import Yup from '../../utils/yupUtil';
import moment from 'moment';
import { dateFormat, numberFormat } from '../../utils/fnUtil';
import { globalActions } from '../../slices/globalSlice';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';

const Download = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const [graph, setGraph] = useState(null);
  const { searchParams, downloadList, errorMessage, resultList } = useSelector(
    state => state.download
  );

  const androidCount = resultList?.android_count_total ?? 0;
  const iosCount = resultList?.ios_count_total ?? 0;

  const formik = useFormik({
    initialValues: searchParams,
    validationSchema: Yup.object({
      from_display_period: Yup.string().nullable().required(),
      to_display_period: Yup.string().nullable().required(),
    }),

    onSubmit: values => {
      const { from_display_period, to_display_period, ...rest } = values;
      const params = {
        ...rest,
        from_display_period: dateFormat(from_display_period, 'YYYY/MM/DD'),
        to_display_period: dateFormat(to_display_period, 'YYYY/MM/DD'),
      };

      dispatch(downloadActions.saveSearchParams(params));
    },
  });

  useEffect(() => {
    return () => dispatch(downloadActions.clear());
  }, [dispatch]);

  useUpdateEffect(
    () => dispatch(fetchDownloadList(searchParams)),
    [searchParams]
  );

  useUpdateEffect(() => {
    if (errorMessage) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: errorMessage,
        })
      );
      dispatch(downloadActions.hiddenErrorMessage());
    }
  }, [errorMessage]);

  useUpdateEffect(() => {
    const dateFormat = 'M/D HH時';
    setGraph(
      downloadList.map(e => {
        const date = moment(e.summary_date).format(dateFormat);
        const ios = e.ios_count;
        const android = e.android_count;
        return [
          date,
          ios,
          createTooltip('iOS', date, ios),
          android,
          createTooltip('Android', date, android),
        ];
      })
    );
  }, [downloadList]);

  const createTooltip = (label, date, value) => {
    return `
      <div className="graph-tooltip-date"><b>${date}</b></div>
      <b>${label}：${numberFormat(value)}</b>
    `;
  };

  const { from_display_period, to_display_period } = formik.values;

  return (
    <div className="content-body management-page download-page" ref={parent}>
      <PageTitle
        title="ダウンロード数"
        icon={<Icons.IconBarChart className="download-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent}>
          <DateTimeRangePicker
            formik={formik}
            label="表示範囲"
            fromFieldName="from_display_period"
            toFieldName="to_display_period"
            isRequired
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton
            text={'検索'}
            disabled={!from_display_period || !to_display_period}
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
      <TableTitle
        title={
          <div className="download-title-area">
            <div className="download-title-name">ダウンロード推移</div>
            <div className="download-count-area">
              <span>集計期間のダウンロード数</span>
              <span className="download-count">{(androidCount + iosCount).toLocaleString()}</span>
              <span>(Android+iOS)</span>
            </div>
          </div>
        }
        disabled={!from_display_period || !to_display_period}
        onClickCsvOutput={() =>
          dispatch(
            downloadCountCsv({
              start_date: from_display_period,
              end_date: to_display_period,
              screen_type: '0',
              columns: ['device_id', 'download_date'],
            })
          )
        }
      />
      <div className="graph-area">
        {graph && graph.length > 0 ? (
          <>
            <div className="legend">
              <CircleTitle title="Android" color="#149c75" />
              <CircleTitle title="iOS" color="#14789c" />
            </div>
            <div className="graph-wrapper">
              <div className="graph">
                <Chart
                  chartType="ColumnChart"
                  data={[
                    [
                      '',
                      'iOS',
                      { type: 'string', role: 'tooltip', p: { html: true } },
                      'Android',
                      { type: 'string', role: 'tooltip', p: { html: true } },
                    ],
                    ...graph,
                  ]}
                  options={{
                    legend: { position: 'none' },
                    isStacked: true,
                    bar: { groupWidth: '65%' },
                    series: [{ color: '#14789C' }, { color: '#149C75' }],
                    tooltip: { isHtml: true },
                  }}
                  height={300}
                  style={{
                    fontSize: '10px',
                    borderRadius: '8px',
                    margin: '0 30px 50px 30px',
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <DataNone />
        )}
      </div>
    </div>
  );
};

export default Download;
