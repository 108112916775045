import { memo } from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchZip,
  shopManagementActions,
  updateShop,
} from '../../slices/shopManagementSlice';
import {
  fetchCategoryList,
} from '../../slices/categorySlice';
import {
  createNumberOptions,
  dateFormat,
  handlerFormikFieldChange,
} from '../../utils/fnUtil';
import { appDisplayOptions, prefectures } from '../../constants/options';
import { mapValues } from 'lodash';
import { Modal } from 'antd';
import { globalActions } from '../../slices/globalSlice';
import { CATEGORY_TYPE } from '../../constants/enums';
import ModalTitle from '../modalTitle';
import TcAvatar from '../tcAvatar';
import TcButton from '../tcButton';
import WeekCheckbox from '../tcCheckbox/weekCheckbox';
import TcRadio from '../tcCheckbox/tcRadio';
import TcCheckbox from '../tcCheckbox';
import Yup from '../../utils/yupUtil';
import moment from 'moment';
import GoogleMapSelect from '../googleMap';
import './style.scss';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';
import CustomNumberPicker from '../util/customNumberPicker';
import TimePickerWrapper from '../util/timePickerWrapper';
import DatePickerWrapper from '../util/datePickerWrapper';
import DateTimeRangePicker from '../util/dateTimeRangePicker';

const ShopModal = memo(() => {
  const dispatch = useDispatch();

  const { accountBrandList, accountInfo } = useSelector(state => state.account);

  const { categoryList } = useSelector(state => state.category);

  const { shopModalVisible, shopDetail } = useSelector(
    state => state.shopManagement
  );

  const { selectOptionList } = useSelector(state => state.name);

  const yupObject = {
    shop_code: Yup.string().required(),
    brand_code: Yup.string().nullable().selected(),
    shop_name: Yup.string().required(),
    shop_name_kana: Yup.string().required().kana(),
    opening_date: Yup.string().required(),
    closed_date: Yup.string().required(),
    zip1: Yup.string().zip1(),
    zip2: Yup.string().zip2(),
    tel1: Yup.string().tel1(),
    tel2: Yup.string().tel2(),
    tel3: Yup.string().tel3(),
    fax1: Yup.string().fax1(),
    fax2: Yup.string().fax2(),
    fax3: Yup.string().fax3(),
    opening_time1: Yup.string().time(),
    closing_time1: Yup.string().time(),
    opening_time2: Yup.string().time(),
    closing_time2: Yup.string().time(),
    check_in_flag: Yup.string().nullable().required(),
    location1: Yup.string()
      .nullable()
      .matches(/^[0-9.]+$/, '数値で入力してください')
      .longitude(),
    location2: Yup.string()
      .nullable()
      .matches(/^[0-9.]+$/, '数値で入力してください')
      .latitude(),
    radius: Yup.string()
      .nullable()
      .matches(
        /^[3-9][0-9][0-9]$|^[1-9][0-9][0-9][0-9]$|10000/,
        '300~10000の数値を入力してください'
      ),
  };

  const formik = useFormik({
    initialValues: {
      shop_code: '',
      shop_image_url: '',
      brand_code: null,
      category_code: [],
      shop_name: '',
      shop_name_kana: '',
      display_order: null,
      app_display_flag: 1,
      zip1: '',
      zip2: '',
      prefectures_code: null,
      address1: '',
      address2: '',
      address3: '',
      tel1: '',
      tel2: '',
      tel3: '',
      fax1: '',
      fax2: '',
      fax3: '',
      opening_time1: '',
      closing_time1: '',
      sales_time_remark1: '',
      opening_time2: '',
      closing_time2: '',
      sales_time_remark2: '',
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      irregular_holiday: '',
      link: '',
      appeal: '',
      opening_date: moment().format('YYYY/M/D'),
      closed_date: '2999/12/31',
      check_in_flag: 1,
      check_in_start_date: '',
      check_in_end_date: '',
      stamp_rally_count: accountInfo.default_stamp_rally_count ?? null,
      radius: 300,
      location1: null,
      location2: null,
    },
    validateOnMount: true,
    validationSchema: Yup.object(yupObject),
    onSubmit: values => {
      const {
        zip1,
        zip2,
        location1,
        location2,
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        opening_date,
        closed_date,
        check_in_start_date,
        check_in_end_date,
        stamp_rally_count,
        ...rest
      } = values;

      if (!moment(closed_date).isAfter(moment(opening_date))) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message: '開店日は閉店日より前の日付を入力してください',
          })
        );
        return;
      }

      dispatch(
        updateShop({
          ...rest,
          isCreate: !shopDetail,
          zip_code: zip1 && zip2 ? `${zip1}${zip2}` : '',
          location: [location1, location2],
          opening_date: dateFormat(opening_date, 'YYYY/MM/DD'),
          closed_date: dateFormat(closed_date, 'YYYY/MM/DD'),
          check_in_flag: "1",
          check_in_start_date: dateFormat(check_in_start_date, 'YYYY/MM/DD'),
          check_in_end_date: dateFormat(check_in_end_date, 'YYYY/MM/DD'),
          latitude: location1,
          longitude: location2,
          stamp_rally_count: Number(stamp_rally_count),
          regular_holiday: {
            sunday,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
          },
        })
      );
    },
  });

  useUpdateEffect(() => {
    shopDetail &&
      formik
        .setValues({
          ...shopDetail,
        })
        .then(() => formik.setTouched(mapValues(yupObject, () => true)))
        .then(() => formik.validateForm());
    shopDetail && dispatch(shopManagementActions.setRadius(shopDetail.radius));
    shopDetail &&
      dispatch(shopManagementActions.setLatitude(shopDetail.location1));
    shopDetail &&
      dispatch(shopManagementActions.setLongitude(shopDetail.location2));
  }, [shopDetail]);

  useUpdateEffect(() => {
    if (shopModalVisible)
      dispatch(fetchCategoryList({ category_type: CATEGORY_TYPE.SHOP }))
    !shopModalVisible && formik.resetForm();

    formik.validateForm();
  }, [shopModalVisible]);
  const { check_in_flag } = formik.values;
  const onBack = () => {
    dispatch(shopManagementActions.resetShopModal());
    formik.resetForm();
  };

  const onSearchZip = async () => {
    formik.setFieldTouched('zip1', true, true);
    formik.setFieldTouched('zip2', true, true);

    const zipcode = `${formik.values.zip1}${formik.values.zip2}`;
    try {
      const addressRes =
        /^\d{7}$/.test(zipcode) &&
        (await searchZip({
          zipcode,
        }));
      const address = addressRes?.results?.[0];
      if (address) {
        formik.setFieldValue(
          'prefectures_code',
          prefectures.find(p => p.label === address.address1)?.value
        );
        formik.setFieldValue('address1', address.address2);
        formik.setFieldValue('address2', address.address3);
      }
    } catch (error) { }
  };

  const onInputTel = () => {
    formik.setFieldTouched('tel1', true, true);
    formik.setFieldTouched('tel2', true, true);
    formik.setFieldTouched('tel3', true, true);
  };

  const onInputFax = () => {
    formik.setFieldTouched('fax1', true, true);
    formik.setFieldTouched('fax2', true, true);
    formik.setFieldTouched('fax3', true, true);
  };
  const { radius } = formik.values;
  const { radiusR } = useSelector(state => state.shopManagement);
  const onClickRadius = () => {
    dispatch(shopManagementActions.setRadius(radius));
    dispatch(shopManagementActions.setLatitude(formik.values.location1));
    dispatch(shopManagementActions.setLongitude(formik.values.location2));
    //handlerFormikFieldChange(formik, 'radius', radiusR);
  };

  return (
    <Modal
      className="shop-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      open={shopModalVisible}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={`店舗${shopDetail ? '編集' : '登録'}`}
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          <TextBox
            formik={formik}
            fieldName={'shop_code'}
            preLabel={'店舗CD'}
            width={'calc(95% - 300px)'}
            uneditable={!!shopDetail}
            isRequired
          />
          <SelectBox
            formik={formik}
            fieldName={'brand_code'}
            preLabel={'ブランド'}
            width={'calc(95% - 300px)'}
            options={accountBrandList}
            isRequired
          />
          <SelectBox
            formik={formik}
            fieldName={'category_code'}
            preLabel={'店舗カテゴリ'}
            width={'calc(95% - 300px)'}
            options={categoryList.map(d => ({
              label: d.category_name,
              value: d.category_code
            }))}
            mode="multiple"
          />
          <TextBox
            formik={formik}
            fieldName={'shop_name'}
            preLabel={'店舗名'}
            width={'calc(95% - 300px)'}
            isRequired
          />
          <TextBox
            formik={formik}
            fieldName={'shop_name_kana'}
            preLabel={'店舗名(カナ)'}
            width={'calc(95% - 300px)'}
            isRequired
          />
          <CustomNumberPicker
            formik={formik}
            fieldName={'display_order'}
            preLabel={'表示優先順'}
            width={300}
            from={1}
            to={1000}
            options={createNumberOptions(1000, 1)}
            placeholder={''}
          />
          <span className="cus-input-line input-checkbox">
            <TcRadio
              formik={formik}
              label="アプリ表示"
              fieldName="app_display_flag"
              width={120}
              options={appDisplayOptions}
              cancelable={false}
              isRadio={true}
            />
          </span>
          <span className="cus-input-line input-zip">
            <TextBox
              formik={formik}
              fieldName="zip1"
              preLabel="郵便番号"
              width={300}
              placeholder="123"
              suffix={'ー'}
              onKeyUp={onSearchZip}
            />
            <TextBox
              formik={formik}
              fieldName="zip2"
              width={180}
              placeholder="4567"
              flexEnd
              onKeyUp={onSearchZip}
            />
          </span>
          <SelectBox
            formik={formik}
            fieldName="prefectures_code"
            preLabel="都道府県"
            width={480}
            options={
              selectOptionList?.filter(s => s.field_name === '都道府県')?.[0]
                ?.select_option ?? []
            }
          />
          <TextBox
            formik={formik}
            fieldName="address1"
            preLabel="市区町村"
            width={'100%'}
          />
          <TextBox
            formik={formik}
            fieldName="address2"
            preLabel="丁目番地"
            width={'100%'}
          />
          <TextBox
            formik={formik}
            fieldName="address3"
            preLabel="館情報"
            width={'100%'}
          />
          <span className="cus-input-line input-tel-fax">
            <TextBox
              formik={formik}
              fieldName="tel1"
              preLabel="電話番号"
              width={300}
              placeholder="03"
              suffix={'ー'}
              onKeyUp={onInputTel}
              flexEnd
            />
            <TextBox
              formik={formik}
              fieldName="tel2"
              width={170}
              suffix={'ー'}
              placeholder="0000"
              onKeyUp={onInputTel}
              flexEnd
            />
            <TextBox
              formik={formik}
              fieldName="tel3"
              width={150}
              placeholder="0000"
              onKeyUp={onInputTel}
              flexEnd
            />
          </span>
          <span className="cus-input-line input-tel-fax">
            <TextBox
              formik={formik}
              fieldName="fax1"
              preLabel="FAX"
              width={300}
              placeholder="03"
              suffix={'ー'}
              onKeyUp={onInputFax}
              flexEnd
            />
            <TextBox
              formik={formik}
              fieldName="fax2"
              width={170}
              placeholder="0000"
              suffix={'ー'}
              flexEnd
              onKeyUp={onInputFax}
            />
            <TextBox
              formik={formik}
              fieldName="fax3"
              width={150}
              placeholder="0000"
              onKeyUp={onInputFax}
              flexEnd
            />
          </span>
          <span style={{ display: 'flex' }}>
            <TimePickerWrapper
              formik={formik}
              fieldName={'opening_time1'}
              preLabel={'営業時間1'}
              width={300}
              placeholder={'00:00'}
              suffix={'ー'}
            />
            <TimePickerWrapper
              formik={formik}
              fieldName={'closing_time1'}
              width={140}
              placeholder={'00:00'}
            />
            <TextBox
              placeholder={'備考'}
              formik={formik}
              fieldName={'sales_time_remark1'}
              style={{ marginLeft: 30 }}
              width={'calc(100% - 450px)'}
            />
          </span>
          <span style={{ display: 'flex' }}>
            <TimePickerWrapper
              formik={formik}
              fieldName={'opening_time2'}
              preLabel={'営業時間2'}
              width={300}
              placeholder={'00:00'}
              suffix={'ー'}
            />
            <TimePickerWrapper
              formik={formik}
              fieldName={'closing_time2'}
              width={140}
              placeholder={'00:00'}
            />
            <TextBox
              placeholder={'備考'}
              formik={formik}
              fieldName={'sales_time_remark2'}
              style={{ marginLeft: 30 }}
              width={'calc(100% - 450px)'}
            />
          </span>
          <span className="cus-input-line input-checkbox">
            <WeekCheckbox formik={formik} />
          </span>
          <TextBox
            formik={formik}
            fieldName={'irregular_holiday'}
            preLabel={'不定休日'}
            width={'100%'}
          />
          <TextBox
            formik={formik}
            fieldName={'link'}
            preLabel={'外部リンク'}
            width={'100%'}
            requiredItem={false}
          />
          <span className="cus-input-line input-textarea">
            <label className="cus-pre-label">{'店舗紹介文'}</label>
            <textarea
              placeholder="店舗紹介文を入力してください"
              spellCheck={false}
              autoComplete={'false'}
              value={formik.values.appeal}
              onChange={e =>
                handlerFormikFieldChange(formik, 'appeal', e.target.value)
              }
            />
          </span>
          <div style={{ display: 'flex' }}>
            <DatePickerWrapper
              formik={formik}
              fieldName={'opening_date'}
              preLabel="開店日"
              maxWidth={300}
              isRequired
              flexEnd
            />
            <DatePickerWrapper
              formik={formik}
              fieldName={'closed_date'}
              prefix={'閉店日'}
              maxWidth={240}
              isRequired
              style={{ marginLeft: 24 }}
              flexEnd
            />
          </div>
          {Number(check_in_flag) === 1 && (
            <>
              <div style={{ display: 'flex' }}>
                <TextBox
                  formik={formik}
                  fieldName="location1"
                  preLabel="位置情報"
                  prefix="緯度"
                  placeholder=""
                  width={540}
                />
                <TextBox
                  formik={formik}
                  fieldName="location2"
                  prefix="経度"
                  placeholder=""
                  width={400}
                  style={{ marginLeft: 24 }}
                  flexEnd
                />
              </div>
              <span className="cus-input-line input-radius">
                <TextBox
                  formik={formik}
                  preLabel="半径"
                  fieldName="radius"
                  placeholder="00"
                  width={240}
                  suffix={'m'}
                />
                <span className="radius-btn">
                  <TcButton
                    text="反映"
                    onClick={onClickRadius}
                    width={80}
                    disabled={false}
                  />
                </span>
              </span>
              <GoogleMapSelect
                formik={formik}
                fieldName1={'location1'}
                fieldName2={'location2'}
                fieldNameRadius={'radius'}
              />
            </>
          )}
          <TcAvatar formik={formik} fieldName="shop_image_url" />
          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={shopDetail ? '更新' : '登録'}
              disabled={!formik.isValid}
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ShopModal;
