import { memo } from 'react';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import TcCheckbox from '.';
import './style.scss';

export const weekCheckboxOptions = [
  {
    label: '月',
    field: 'monday',
  },
  {
    label: '火',
    field: 'tuesday',
  },
  {
    label: '水',
    field: 'wednesday',
  },
  {
    label: '木',
    field: 'thursday',
  },
  {
    label: '金',
    field: 'friday',
  },
  {
    label: '土',
    field: 'saturday',
  },
  {
    label: '日',
    field: 'sunday',
  },
];

const WeekCheckbox = memo(({ formik }) => (
  <>
    <span className="cus-pre-label">{'定休日'}</span>
    <span className="checkbox-list">
      {weekCheckboxOptions.map((o, i) => (
        <TcCheckbox
          key={i}
          label={o.label}
          checked={formik.values[o.field] === 1}
          onChange={() =>
            handlerFormikFieldChange(
              formik,
              o.field,
              formik.values[o.field] === 1 ? 0 : 1
            )
          }
        />
      ))}
    </span>
  </>
));

export default WeekCheckbox;
