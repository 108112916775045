import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { nameColumns } from '../../constants/columns';
import TcButton from '../../components/tcButton';
import PageTitle from '../../components/pageTitle';
import TableTitle from '../../components/tableTitle';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import Icons from '../../constants/Icons';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import {
  fetchNameDetail,
  fetchNameList,
  nameActions,
  fetchNameReset,
} from '../../slices/nameSlice';
import NameModal from '../../components/nameModal';
import DeleteModal from '../../components/modal/deleteModal';

const Name = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountInfo } = useSelector(state => state.account);

  const { searchParams, nameList, total, deleteName, selectOptionList } =
    useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: async values => dispatch(nameActions.saveSearchParams(values)),
  });

  const {
    values: { field_name },
    setFieldValue,
  } = formik;

  const createFieldSelectOptions = () => {
    return (
      selectOptionList?.map(selectOption => ({
        label: selectOption?.field_name,
        value: selectOption?.field_code,
      })) ?? []
    );
  };

  useEffect(() => {
    dispatch(fetchNameList(searchParams));
  }, [searchParams]);

  useEffect(() => {
    dispatch(nameActions.reset());
  }, [dispatch]);

  const columns = [
    ...nameColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => dispatch(nameActions.saveSearchParams({ sort }))}
        />
      ),
      dataIndex: col.dataIndex ?? col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconEdit
          className="table-cell-icon tc-action-icon"
          onClick={() => {
            dispatch(
              fetchNameDetail({
                operator_code: accountInfo.staff_code,
                select_option_code: current.select_option_code,
              })
            );
          }}
        />
      ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconTrash
          className="table-cell-icon tc-action-icon"
          onClick={() => dispatch(nameActions.openDeleteModal(current))}
        />
      ),
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle title="名称設定" />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox
            formik={formik}
            label="名称CD"
            fieldName="select_option_code"
          />
          <SelectBox
            formik={formik}
            label="フィールド名"
            fieldName="field_name"
            options={createFieldSelectOptions()}
            customOnChange={v => {
                const newValue = v === undefined ? null : v;

                if (newValue !== field_name || !newValue) {
                    setFieldValue('label', null);
                }
                setFieldValue('field_name', newValue);
            }}
          />
          <SelectBox
            formik={formik}
            label="ラベル"
            fieldName="label"
            options={
              selectOptionList?.filter(
                selectOption =>
                  selectOption?.field_code === formik.values.field_name
              )?.[0]?.select_option ?? []
            }
            disabled={!formik.values.field_name}
          />
          <TextBox formik={formik} label="名称" fieldName="update_label" />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>
      <TableTitle searchCount={total} />
      <TcTable
        columns={columns}
        dataSource={nameList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(nameActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <NameModal />
      <DeleteModal
        modalVisible={!!deleteName}
        onCancel={() => dispatch(nameActions.openDeleteModal())}
        item={'名称'}
        itemValue={deleteName?.select_option_code}
        onOk={() =>
          dispatch(
            fetchNameReset({
              operator_code: accountInfo.staff_code,
              select_option_code: deleteName?.select_option_code,
            })
          )
        }
      />
    </div>
  );
};

export default Name;
