import { QRCodeCanvas } from 'qrcode.react';
import pica from 'pica';
import './style.scss';

/* QRコード表示部品 */

const QRImage = ({ src, style, qrName }) => (
  <QRCodeCanvas
    id={src}
    style={{ ...style, cursor: 'pointer' }}
    value={src}
    onClick={() => {
      const canvas = document?.getElementById(src);

      const toCanvas = document.createElement('canvas');

      toCanvas.height = 128;

      toCanvas.width = 128;

      pica()
        .resize(canvas, toCanvas)
        .then(result => {
          result.toBlob(blob => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.download = `${qrName}.png`;
            a.href = url;
            a.click();
            a.remove();
            setTimeout(() => {
              URL.revokeObjectURL(url);
            }, 1e4);
          }, 'image/png');
        });
    }}
  />
);

export default QRImage;
