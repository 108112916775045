import { memo } from 'react';
import { TimePicker } from 'antd';
import {
  compareMemoObject,
  dateFormat,
  getFormikArrayProps,
  handlerFormikFieldChange,
} from '../../../utils/fnUtil';
import Icons from '../../../constants/Icons';
import moment from 'moment';
import { get } from 'lodash';
import './style.scss';
import classNames from 'classnames';

const TimePickerWrapper = memo(
  ({
    className,
    disabled = false,
    flexEnd,
    format = 'HH:mm',
    formik,
    hasLabel = true,
    hourFieldName,
    isBold,
    isRequired = false,
    label,
    listFieldName = null,
    listHourFieldName = null,
    listMinuteFieldName = null,
    maxWidth,
    minuteFieldName,
    prefix,
    preLabel,
    style,
    suffix,
    uneditable,
    usePopContainer,
    width,
    fieldName,
    minuteStep = 1,
  }) => {
    const wrapperClassName = classNames('tc-time-picker-wrapper', className);
    const labelClassName = classNames('tc-time-picker-label', {
      'label-bold': isBold,
      'label-disabled': disabled,
      'label-required': isRequired,
    });
    const preLabelClassName = classNames('tc-time-picker-preLabel', {
      'label-disabled': disabled,
      'label-required': isRequired && !disabled,
      'label-required-disabled': isRequired && disabled,
    });
    const prefixClassName = classNames('prefix', {
      'label-disabled': disabled,
    });
    const suffixClassName = classNames('suffix', {
      'label-disabled': disabled,
    });

    const labelAreaClassName = classNames('tc-time-picker-label-area', {
      'flex-end': !!preLabel || flexEnd,
    });

    const onValueChanged = value => {
      if (fieldName) {
        handlerFormikFieldChange(formik, fieldName, value);
        return;
      }
      const [hour, minute] = value.split(':');

      handlerFormikFieldChange(formik, hourFieldName, hour);
      handlerFormikFieldChange(formik, minuteFieldName, minute);
    };

    const onChange = (_, value) => onValueChanged(value);
    const onSelect = v => onValueChanged(dateFormat(v, 'HH:mm'));

    const onBlur = () => {
      if (fieldName) {
        formik.setFieldTouched(fieldName, true);
        return;
      }
      formik.setFieldTouched(hourFieldName, true);
      formik.setFieldTouched(minuteFieldName, true);
    };

    const getValue = () => {
      if (fieldName) {
        const tmp = get(formik.values, fieldName);
        if (!tmp) return null;
        return moment(`${tmp}`, 'HH:mm');
      }
      const hour = get(formik.values, hourFieldName);
      const minute = get(formik.values, minuteFieldName);

      if (!hour || !minute) return null;

      return moment(`${hour}:${minute}`, 'HH:mm');
    };

    const formikArrayProps = listFieldName
      ? getFormikArrayProps(formik, listFieldName)
      : null;

    const formikHourArrayProps = listHourFieldName
      ? getFormikArrayProps(formik, listHourFieldName)
      : null;

    const formikMinuteArrayProps = listMinuteFieldName
      ? getFormikArrayProps(formik, listMinuteFieldName)
      : null;

    fieldName = listFieldName ?? fieldName;
    hourFieldName = listHourFieldName ?? hourFieldName;
    minuteFieldName = listMinuteFieldName ?? minuteFieldName;

    return (
      <div className={wrapperClassName} style={{ width, maxWidth, ...style }}>
        {hasLabel && (
          <div className={labelAreaClassName}>
            {label && <label className={labelClassName}>{label}</label>}
            <span className="tc-time-picker-error">
              {!!formikArrayProps
                ? formikArrayProps.touched && formikArrayProps.error
                : (formik.touched[fieldName] && formik.errors[fieldName]) ||
                  (!!formikHourArrayProps
                    ? formikHourArrayProps.touched && formikHourArrayProps.error
                    : formik.touched[hourFieldName] &&
                      formik.errors[hourFieldName]) ||
                  (!!formikMinuteArrayProps
                    ? formikMinuteArrayProps.touched &&
                      formikMinuteArrayProps.error
                    : formik.touched[minuteFieldName] &&
                      formik.errors[minuteFieldName])}
            </span>
          </div>
        )}
        <div className="tc-time-picker-line">
          {preLabel && <span className={preLabelClassName}>{preLabel}</span>}
          {prefix && <span className={prefixClassName}>{prefix}</span>}
          {uneditable ? (
            <span className="uneditable">
              {/* {displayValue ?? formik.values[fieldName]} */}
            </span>
          ) : (
            <TimePicker
              inputReadOnly={true}
              disabled={disabled}
              onBlur={onBlur}
              placeholder={disabled ? '' : '00:00'}
              format={format}
              value={getValue()}
              changeOnBlur={true}
              suffixIcon={
                disabled ? (
                  <Icons.IconClockGy className="picker-icon" />
                ) : (
                  <Icons.IconClock className="picker-icon" />
                )
              }
              clearIcon={<Icons.IconXCircle className="picker-icon" />}
              getPopupContainer={
                usePopContainer ? triggerNode => triggerNode.parentNode : null
              }
              onSelect={onSelect}
              onChange={onChange}
              minuteStep={minuteStep}
            />
          )}
          {suffix && <span className={suffixClassName}>{suffix}</span>}
        </div>
      </div>
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, [
      'fieldName',
      'hourFieldName',
      'minuteFieldName',
    ]) &&
    pre.disabled === next.disabled &&
    pre.fieldName === next.fieldName &&
    pre.hourFieldName === next.hourFieldName &&
    pre.minuteFieldName === next.minuteFieldName
);

export default TimePickerWrapper;
