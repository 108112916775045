import { memo } from 'react';
import { Input } from 'antd';
import {
  compareMemoObject,
  compareMemoArray,
  getFormikArrayProps,
} from '../../../utils/fnUtil';
import classNames from 'classnames';
import './style.scss';
import { get } from 'lodash';

const TextBox = memo(
  ({
    className,
    customStyle = {},
    disabled = false,
    fieldName,
    flexEnd,
    formik,
    inputType,
    isBold,
    isRequired = false,
    label,
    listFieldName = null,
    placeholder,
    prefix,
    preLabel,
    suffix,
    width = 350,
    hasLabel = true,
    style,
    uneditable,
    displayValue,
    ...restProps
  }) => {
    const wrapperClassName = classNames('tc-input-wrapper', className);
    const labelClassName = classNames('tc-input-label', {
      'label-bold': isBold,
      'label-disabled': disabled,
      'label-required': isRequired,
    });
    const preLabelClassName = classNames('tc-input-preLabel', {
      'label-disabled': disabled,
      'label-required': isRequired && !disabled,
      'label-required-disabled': isRequired && disabled,
    });
    const prefixClassName = classNames('prefix', {
      'label-disabled': disabled,
    });
    const suffixClassName = classNames('suffix', {
      'label-disabled': disabled,
    });
    const labelAreaClassName = classNames('tc-input-label-area', {
      'flex-end': !!preLabel || flexEnd,
    });

    const defultPlaceHolder = disabled
      ? ''
      : placeholder ?? `${label || preLabel || ''}を入力してください`;

    const formikArrayProps = listFieldName
      ? getFormikArrayProps(formik, listFieldName)
      : null;

    fieldName = listFieldName ? listFieldName : fieldName;

    return (
      <div className={wrapperClassName} style={{ width, ...style }}>
        {hasLabel && (
          <div className={labelAreaClassName}>
            {label && (
              <label for={'input'} className={labelClassName}>
                {label}
              </label>
            )}
            <span className="tc-input-error">
              {!!formikArrayProps
                ? formikArrayProps.touched && formikArrayProps.error
                : get(formik.touched, fieldName) &&
                  get(formik.errors, fieldName)}
            </span>
          </div>
        )}
        <div className="tc-input-line">
          {preLabel && <div className={preLabelClassName}>{preLabel}</div>}
          {prefix && <span className={prefixClassName}>{prefix}</span>}
          {uneditable ? (
            <span className="uneditable">
              {displayValue ?? formik.values[fieldName]}
            </span>
          ) : (
            <Input
              id={'input'}
              autoComplete="off"
              type={inputType}
              placeholder={defultPlaceHolder}
              style={customStyle}
              disabled={disabled}
              {...formik.getFieldProps(fieldName)}
              {...restProps}
            />
          )}

          {suffix && <span className={suffixClassName}>{suffix}</span>}
        </div>
      </div>
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, [
      'fieldName',
      'fromFieldName',
      'toFieldName',
    ]) &&
    pre.disabled === next.disabled &&
    pre.fieldName === next.fieldName &&
    (pre.listFieldName ? compareMemoArray(pre, next) : true)
);

export default TextBox;
