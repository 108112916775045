import escapeHtml from 'escape-html';
import { Text } from 'slate';
import { layoutMap } from '../../forRichTextEditor/layoutModal';
import { toMoneyString } from '../../forRichTextEditor/fnUtil';
import { parse } from 'node-html-parser';
import styleParser from 'inline-style-parser';

export const toSlateState = (text, products, coordinates) =>
  text && text.length > 0
    ? text
        .map(t =>
          deserialize(
            parse(`${t.dom ?? ''}`),
            products ?? [],
            coordinates ?? []
          )
        )
        .flat()
        .filter(el => el.type !== 'title')
    : [
        {
          type: 'content',
          children: [{ text: '' }],
        },
      ];

export const serialize = node => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.underline) {
      string = `<span style="text-decoration: underline;">${string}</span>`;
    }
    if (node.italic) {
      string = `<span style="font-style: oblique;">${string}</span>`;
    }
    if (node.fontSize) {
      string = `<span class="ss-font-size-${node.fontSize}">${string}</span>`;
    }
    if (node.color) {
      string = `<span style="color: ${node.color};">${string}</span>`;
    }
    if (node.strikethrough) {
      string = `<span style="text-decoration: line-through;">${string}</span>`;
    }
    return string;
  }

  const children = node.children.map(n => serialize(n)).join('');

  switch (node.type) {
    case 'title':
      return `<div class='ss-content-area content-area-title'><h2>${children}</h2></div>`;
    case 'align-left':
      return `<div class="ss-content-area content-area-description" style="text-align:left;"><p>${children}</p></div>`;
    case 'align-center':
      return `<div class="ss-content-area content-area-description" style="text-align:center;"><p>${children}</p></div>`;
    case 'align-right':
      return `<div class="ss-content-area content-area-description" style="text-align:right;"><p>${children}</p></div>`;
    case 'bulleted-list':
      return `<div class="ss-content-area content-area-description"><p><ul class="ss-itemization-list">${children}</ul></p></div>`;
    case 'list-item':
      return `<li class="ss-itemization">${children}</li>`;
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<div class="ss-content-area content-area-description"><p>${children}</p></div>`;
    case 'image':
      return `<div class="ss-article-box boxtype-${node.boxtype ?? ''}">
      <ul class="ss-boxtype-list boxtype-${
        node.boxtype ?? ''
      }-list" style="list-style-type: none">
          ${node.images
            .map((image, i) => {
              let content;

              if (image.coordinate_code) {
                content = `<div class="ss-content-area content-area-coordinate" coordinate_code="${
                  image.coordinate_code
                }" >
                <div class="ss-content-coordinate-img">
                    <a href="${image.coordinate_url ?? ''}"><img src="${
                  image.image_url ?? ''
                }"></a>
                </div>
                <div class="ss-content-coordinate-info">
                    <div class="ss-content-user-info">
                        <ul class="ss-content-user-info-list">
                            <li class="ss-content-user-image">
                               <img src="${image.user_image_url ?? ''}"></a>
                            </li>
                            <li>
                                <div class="ss-content-user-meta">
                                    <ul class="ss-content-user-meta-list">
                                        <li><p class="ss-content-user-brand">${
                                          image.brand_name ?? ''
                                        }</p></li>
                                        <li><p class="ss-content-user-shop">${
                                          image.shop_name ?? ''
                                        }</p></li>
                                        <li><p class="ss-content-user-name">${
                                          image.user_name ?? ''
                                        }</p></li>
                                        <li><p class="ss-content-user-height">${
                                          image.user_height
                                            ? `${image.user_height}cm`
                                            : ''
                                        }</p></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>`;
              } else if (image.product_code) {
                content = `<div class="ss-content-area content-area-product" identification="${
                  image.brand_code
                }-${image.sku_code}" ><div class="ss-content-product-img">
                    <a href="${image.product_url ?? ''}"><img src="${
                  image.image_url ?? ''
                }"></a>
                </div>
                <div class="ss-content-product-info">
                    <p class="ss-product-brand">${image.brand_name ?? ''}</p>
                    <p class="ss-product-name">${image.product_name ?? ''}</p>
                    <p class="ss-product-price">¥${toMoneyString(
                      image.tax_included_price
                    )}</p>
                </div>
            </div>`;
              } else {
                content = `<div class="ss-content-area content-area-image">${
                  image.link_url
                    ? `<a class="linktype-${image.app_display_flag}" href="${image.link_url}">`
                    : ''
                }${
                  image.image_url ? `<img src="${image.image_url ?? ''}">` : ''
                }
                ${image.link_url ? '</a>' : ''}
                ${
                  image.movie_url
                    ? `<video src="${image.movie_url}"></video>`
                    : ''
                }${
                  image.youtobe_url
                    ? `<iframe width="560" height="315" src="${image.youtobe_url}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
                    : ''
                }
                </div>`;
              }
              return `<li class="position-${i + 1}" >${content}</li>`;
            })
            .join('')}
      </ul>
  </div>`;
    case 'link':
      return `<a class="linktype-${
        node.appDisplayFlag ? node.appDisplayFlag : 0
      }" class="content-area-link" href="${node.url}">${children}</a>`;
    case 'content':
      return `<div class="ss-content-area content-area-description"><p>${children}</p></div>`;
    case 'div':
      return `<div ${node.class ? `"class=${node.class}"` : ''} ${
        node.cssText ? `"style=${node.cssText}"` : ''
      }  >${children}</div>`;
    default:
      return children;
  }
};

export const parseTextNode = node => {
  const text = { text: node.text };

  const parseParentNode = targetNode => {
    if (!targetNode.parentNode) return;

    parseParentNode(targetNode.parentNode);

    const inlineStyle = targetNode.parentNode.attrs?.style ?? '';

    const parsedInlineStyle = styleParser(inlineStyle) ?? [];

    switch (targetNode.parentNode.tagName) {
      case 'STRONG':
        text.bold = true;
        break;
      case 'SPAN':
        const textDecorationStyle = parsedInlineStyle.find(
          css => css.property === 'text-decoration'
        )?.value;
        if (textDecorationStyle === 'line-through') {
          text.strikethrough = true;
          break;
        }
        if (textDecorationStyle === 'underline') {
          text.underline = true;
          break;
        }
        const fontStyle = parsedInlineStyle.find(
          css => css.property === 'font-style'
        )?.value;
        if (fontStyle === 'oblique') {
          text.italic = true;
          break;
        }
        const color = (parsedInlineStyle ?? []).find(
          s => s.property === 'color'
        )?.value;
        if (color) {
          text.color = color;
          break;
        }
        const { fontSize } =
          targetNode.parentNode.attrs.class?.match(
            /ss-font-size-(?<fontSize>.{2})/
          )?.groups ?? {};
        if (fontSize) {
          text.fontSize = fontSize;
          break;
        }
        break;
      default:
        break;
    }
  };

  parseParentNode(node);

  return text;
};

export const deserialize = (node, products, coordinates) => {
  if (node.nodeType === 3) {
    return parseTextNode(node);
  }
  if (!node.childNodes || node.childNodes.length === 0) {
    return { text: '' };
  }

  const { boxtype } =
    node.attrs.class?.match(/ss-article-box boxtype-(?<boxtype>.{1})/)
      ?.groups ?? {};

  if (node.tagName === 'DIV') {
    if (boxtype) {
      return parseImageBlock(node, boxtype, products, coordinates);
    }
    if (/ss-content-area content-area-title/.test(node.attrs.class)) {
      return {
        type: 'title',
        children: [{ text: '' }],
      };
    }
    const ulNode = node.querySelector('ul');

    if (ulNode) {
      return deserialize(ulNode, products, coordinates);
    }
  }

  let children = node.childNodes
    .map(n => deserialize(n, products, coordinates))
    .flat();

  if (children.filter(child => child).length === 0) {
    children = [{ text: '', type: 'content' }];
  }

  const inlineStyle = node.attrs?.style ?? '';

  const parsedInlineStyle = styleParser(inlineStyle) ?? [];

  switch (node.tagName) {
    case 'UL':
      return { children, type: 'bulleted-list' };
    case 'LI':
      return { children, type: 'list-item' };
    case 'A':
      return parseLink(node, children);
    case 'DIV':
      const textAlign = (parsedInlineStyle ?? []).find(
        s => s.property === 'text-align'
      )?.value;
      if (textAlign) {
        return { children, type: `align-${textAlign}` };
      }
      if (/ss-content-area content-area-description/.test(node.attrs.class)) {
        return { children, type: 'content' };
      }
      return children;
    default:
      return children;
  }
};

const parseProductBlock = (root, products = []) => {
  const disabled = /pointer-events: none/.test(root.attrs?.style);

  const product_url = root.querySelector('.ss-content-product-img a')?.attrs
    ?.href;
  const image_url = root.querySelector('.ss-content-product-img img')?.attrs
    ?.src;

  const identificationCode = root.attrs?.identification;

  const target =
    products.find(
      p => `${p.brand_code}-${p.sku_code}` === identificationCode
    ) ?? {};

  return {
    ...target,
    product_url,
    image_url,
    disabled,
  };
};

const parseCoordinateBlock = root => {
  const coordinate_code = root.attrs?.coordinate_code;

  const disabled = /pointer-events: none/.test(root.attrs?.style);

  const coordinate_url = root.querySelector('.ss-content-coordinate-img a')
    ?.attrs?.href;
  const image_url = root.querySelector('.ss-content-coordinate-img img')?.attrs
    ?.src;

  const user_image_url = root.querySelector('.ss-content-user-image img')?.attrs
    ?.src;

  const brand_name = root.querySelector('.ss-content-user-brand')?.text;
  const shop_name = root.querySelector('.ss-content-user-shop')?.text;
  const user_name = root.querySelector('.ss-content-user-name')?.text;
  const user_height = root
    .querySelector('.ss-content-user-height')
    ?.text?.replaceAll('cm', '');

  return {
    coordinate_code,
    brand_name,
    shop_name,
    user_name,
    user_height,
    coordinate_url,
    user_image_url,
    image_url,
    disabled,
  };
};

const parseGeneralBlock = root => {
  const image_url = root.querySelector('img')?.attrs?.src;

  const linkElement = root.querySelector('a');

  const movieElement = root.querySelector('video');

  const iframeElement = root.querySelector('iframe');

  const { app_display_flag } =
    linkElement?.attrs?.class?.match(/linktype-(?<app_display_flag>.{1})/)
      ?.groups ?? {};
  return {
    image_url,
    app_display_flag,
    link_url: linkElement?.attrs?.href,
    movie_url: movieElement?.attrs.src,
    youtobe_url: iframeElement?.attrs.src,
  };
};

const parseImageBlock = (root, boxtype, products, coordinates) => {
  const metaData = (
    root.querySelectorAll('.ss-boxtype-list > li > div.ss-content-area') ?? []
  ).map(content => {
    if (/ss-content-area content-area-coordinate/.test(content.classNames)) {
      return parseCoordinateBlock(content, coordinates);
    } else if (
      /ss-content-area content-area-product/.test(content.classNames)
    ) {
      return parseProductBlock(content, products);
    } else if (/ss-content-area content-area-image/.test(content.classNames)) {
      return parseGeneralBlock(content);
    } else {
      return '';
    }
  });

  const { maxCol, count } =
    layoutMap.find(layout => layout.boxtype === boxtype) ?? {};
  return {
    type: 'image',
    boxtype,
    maxCol,
    count,
    deviceMode: false,
    images: metaData.map(data =>
      [data.image_url, data.link_url, data.movie_url, data.youtobe_url].some(
        d => d
      )
        ? data
        : ''
    ),
    children: [{ text: '' }],
  };
};

const parseLink = (root, children) => {
  const { appDisplayFlag } =
    root?.attrs?.class?.match(/linktype-(?<appDisplayFlag>.{1})/)?.groups ?? {};
  return {
    type: 'link',
    url: root.attrs.href,
    appDisplayFlag,
    children,
  };
};
