import { memo } from 'react';
import { Input, InputNumber, Select } from 'antd';
import {
  compareMemoObject,
  compareMemoArray,
  handlerFormikFieldChange,
  getFormikArrayProps,
} from '../../utils/fnUtil';
import { CheckOutlined } from '@ant-design/icons';
import RangePicker from '../rangePicker';
import RangeDateTimePicker from '../rangePicker/dateHour';
import Icons from '../../constants/Icons';
import classNames from 'classnames';
import './style.scss';

const InputSelect = memo(
  ({
    label,
    preLabel,
    postLabel,
    subLabel,
    labelBold,
    customOnChange,
    displayValue,
    extraOnChange,
    requiredItem = true,
    styleType,
    formik,
    fieldName,
    disabled = false,
    width = 350,
    rangePickerWidth,
    inputWidth = 'middle',
    selectSize = 'middle',
    mode = null,
    placeholder,
    options = null,
    customStyle = {},
    uneditable,
    dateUneditable,
    inputType,
    className,
    isPassword,
    fromFieldName,
    toFieldName,
    useTimePicker,
    fromFieldHourName,
    toFieldHourName,
    rangeText,
    visibilityToggle = true,
    usePopContainer = true,
    suffix = null,
    onClick,
    fromDisabledDate = null,
    toDisabledDate = null,
    listFieldName = null,
    useNumberPicker = false,
    startNumber,
    endNumber,
    isNumber,
    flexEnd,
    hasTheme,
    ...restProps
  }) => {
    const wrapperClassName = classNames('tc-input-wrapper', className, {
      [`tc-input-wrapper-${selectSize}`]: selectSize,
      'tc-wrapper-uneditable': uneditable,
      'pre-label-exist': !!preLabel,
      'tc-input-disabled': hasTheme && disabled,
    });

    const labelClassName = classNames('tc-input-label', {
      'label-bold': labelBold,
      'label-disabled': disabled,
    });

    const selectClassName = classNames('tc-select', {
      [`select-${styleType}`]: styleType,
      'select-disabled': hasTheme && disabled,
    });

    const preLabelClassName = classNames('pre-label', {
      'label-required': requiredItem,
      'label-disabled': disabled,
    });

    const inputInfoClassName = classNames('tc-input-info', {
      'flex-end': !!preLabel || flexEnd,
    });

    const postLabelClassName = classNames('post-label', {
      'label-disabled': hasTheme && disabled,
    });

    const defultPlaceHolder =
      placeholder ??
      `${label || preLabel || ''}を${options ? '選択' : '入力'}してください`;

    const formikArrayProps = listFieldName
      ? getFormikArrayProps(formik, listFieldName)
      : null;

    fieldName = listFieldName ? listFieldName : fieldName;

    return (
      <div className={wrapperClassName} style={{ width }}>
        <div className="tc-wrapper-post-label">
          <div className="tc-container-post-label">
            <div className={inputInfoClassName}>
              {label && <label className={labelClassName}>{label}</label>}
              <span className="tc-input-error">
                {!!formikArrayProps
                  ? formikArrayProps.touched && formikArrayProps.error
                  : formik.touched[fieldName] && formik.errors[fieldName]}
              </span>
            </div>
            <div className="tc-input-line" onClick={onClick}>
              {preLabel && (
                <span className={preLabelClassName}>{preLabel}</span>
              )}
              {subLabel && <span className="sub-label">{subLabel}</span>}
              {options ? (
                uneditable ? (
                  <span className="input-value font-sm">
                    {
                      options.find(o => o.value === formik.values[fieldName])
                        ?.label
                    }
                  </span>
                ) : (
                  <>
                    <Select
                      className={selectClassName}
                      mode={mode}
                      showSearch={true}
                      filterOption={(input, option) =>
                        `${option.children}`
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onBlur={() => formik.setFieldTouched(fieldName, true)}
                      getPopupContainer={
                        usePopContainer
                          ? triggerNode => triggerNode.parentNode
                          : null
                      }
                      maxTagCount="responsive"
                      placeholder={defultPlaceHolder}
                      maxTagPlaceholder={'...'}
                      value={formik.getFieldProps(fieldName).value}
                      defaultActiveFirstOption={false}
                      size={selectSize}
                      suffixIcon={
                        hasTheme && disabled ? (
                          <Icons.IconChevronDownGy className="ant-select-suffix select-icon" />
                        ) : (
                          <Icons.IconChevronDown className="ant-select-suffix select-icon" />
                        )
                      }
                      onChange={v => {
                        if (customOnChange) {
                          customOnChange(v, fieldName);
                        } else {
                          handlerFormikFieldChange(
                            formik,
                            fieldName,
                            v ?? null
                          );
                          extraOnChange?.(fieldName, v);
                        }
                      }}
                      allowClear={true}
                      showArrow={true}
                      disabled={disabled}
                      menuItemSelectedIcon={
                        <CheckOutlined style={{ color: 'black' }} />
                      }
                    >
                      {options.map(o => (
                        <Select.Option value={o.value} key={o.value}>
                          {o.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                )
              ) : uneditable ? (
                <span className="input-value font-sm">
                  {displayValue ?? formik.values[fieldName]}
                </span>
              ) : (
                <>
                  {isPassword ? (
                    <Input.Password
                      placeholder={defultPlaceHolder}
                      {...formik.getFieldProps(fieldName)}
                      visibilityToggle={visibilityToggle}
                      autoComplete="new-password"
                      iconRender={visible =>
                        visible ? <Icons.IconEye /> : <Icons.IconEyeOff />
                      }
                    />
                  ) : fromFieldHourName ? (
                    <RangeDateTimePicker
                      formik={formik}
                      fromField={fromFieldName}
                      toField={toFieldName}
                      fromHourField={fromFieldHourName}
                      toHourField={toFieldHourName}
                      preLabelClassName={preLabelClassName}
                      rangeText={rangeText}
                    />
                  ) : fromFieldName ? (
                    <RangePicker
                      useNumberPicker={useNumberPicker}
                      startNumber={startNumber}
                      endNumber={endNumber}
                      disabled={disabled}
                      useTimePicker={useTimePicker}
                      formik={formik}
                      fromField={fromFieldName}
                      toField={toFieldName}
                      uneditable={dateUneditable}
                      preLabelClassName={preLabelClassName}
                      rangeText={rangeText}
                      usePopContainer={usePopContainer}
                      fromDisabledDate={fromDisabledDate}
                      toDisabledDate={toDisabledDate}
                      width={rangePickerWidth}
                    />
                  ) : isNumber ? (
                    <Input
                      size={selectSize}
                      autoComplete="off"
                      placeholder={defultPlaceHolder}
                      style={customStyle}
                      disabled={disabled}
                      {...restProps}
                      {...formik.getFieldProps(fieldName)}
                      value={formik
                        .getFieldProps(fieldName)
                        .value?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      onChange={e => {
                        const { value } = e.target;

                        console.log(value);

                        handlerFormikFieldChange(
                          formik,
                          fieldName,
                          value ? Number(value.replace(/[^0-9]/g, '')) : ''
                        );
                      }}
                    />
                  ) : (
                    <Input
                      size={selectSize}
                      autoComplete="off"
                      type={inputType}
                      suffix={suffix}
                      placeholder={defultPlaceHolder}
                      style={customStyle}
                      disabled={disabled}
                      {...formik.getFieldProps(fieldName)}
                      {...restProps}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {postLabel && <span className={postLabelClassName}>{postLabel}</span>}
        </div>
      </div>
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, [
      'fieldName',
      'fromFieldName',
      'toFieldName',
    ]) &&
    pre.options === next.options &&
    pre.disabled === next.disabled &&
    pre.fieldName === next.fieldName &&
    (pre.listFieldName ? compareMemoArray(pre, next) : true)
);

export default InputSelect;
