import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import {
  csvDownloadTypes,
  csvFavoriteItemTypes,
  gachaApplicantFavoriteItemOptions,
} from '../../constants/options';
import PageTitle from '../../components/pageTitle';
import Icons from '../../constants/Icons';
import TableTitle from '../../components/tableTitle';
import TcButton from '../../components/tcButton';
import { dateFormat } from '../../utils/fnUtil';
import { gachaApplicantColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import './style.scss';
import TcTable from '../../components/tcTable';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import {
  fetchGachaApplicantCsvDownload,
  fetchGachaApplicantCsvFavoriteItem,
  fetchGachaApplicantCsvFavoriteItemCreate,
  fetchGachaApplicantList,
  gachaApplicantActions,
} from '../../slices/gachaApplicantSlice';
import InputContainer from '../../components/inputContainer';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';

const GachaApplicant = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { searchParams, total, applicantList, favoriteItem, csvModalVisible } =
    useSelector(state => state.gachaApplicant);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => {
      const {
        from_applicant_date,
        to_applicant_date,
        gift_type,
        gift_default,
        coupon_code,
        stamp_count,
        point,
        ...rest
      } = values;
      const params = {
        ...rest,
        from_applicant_date: dateFormat(from_applicant_date, 'YYYY/MM/DD'),
        to_applicant_date: dateFormat(to_applicant_date, 'YYYY/MM/DD'),
        gift_type,
      };
      switch (gift_type) {
        case '1':
          params.gift = coupon_code;
          break;
        case '2':
          params.gift = stamp_count;
          break;
        case '3':
          params.gift = point;
          break;
        default:
          params.gift = gift_default;
          break;
      }

      console.log(params);
      dispatch(gachaApplicantActions.saveSearchParams(params));
    },
  });

  useUpdateEffect(
    () => dispatch(fetchGachaApplicantList(searchParams)),
    [searchParams]
  );

  useEffect(() => {
    dispatch(
      fetchGachaApplicantCsvFavoriteItem({
        screen_type: csvFavoriteItemTypes['gacha_applicant'],
      })
    ) && dispatch(gachaApplicantActions.saveSearchParams(null));
    return () => dispatch(gachaApplicantActions.clear());
  }, [dispatch]);

  const columns = [
    ...gachaApplicantColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(gachaApplicantActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      width: col.width,
      render: col.render,
    })),
  ];

  const { gift_type } = formik.values;

  const extraOnChange = (_, v) => {
    switch (gift_type) {
      case '1':
        formik.setFieldValue('coupon_code', null);
        break;
      case '2':
        formik.setFieldValue('stamp_count', null);
        break;
      case '3':
        formik.setFieldValue('point', null);
        break;
      default:
        break;
    }
  };

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle title="ガチャ参加者一覧" icon={<Icons.IconGatya />} />
      <div className="search-form">
        <InputContainer parent={parent} max={1472}>
          <TextBox formik={formik} label="ガチャCD" fieldName="gacha_code" />
          <TextBox formik={formik} label="ガチャ名" fieldName="gacha_name" />
          <SelectBox
            formik={formik}
            label="トリガー"
            options={
              selectOptionList?.filter(s => s.field_name === 'トリガー')?.[0]
                ?.select_option ?? []
            }
            fieldName="trigger"
          />
          <TextBox formik={formik} label="会員番号" fieldName="user_code" />
          <SelectBox
            formik={formik}
            label="ステータス"
            options={
              selectOptionList?.filter(s => s.field_name === 'ステータス')?.[0]
                ?.select_option ?? []
            }
            fieldName="winner_rank"
          />
          <SelectBox
            formik={formik}
            label="当選景品種別"
            options={
              selectOptionList?.filter(
                s => s.field_name === '当選景品種別'
              )?.[0]?.select_option ?? []
            }
            fieldName="gift_type"
            extraOnChange={extraOnChange}
          />
          {gift_type == null ? (
            <TextBox
              key={'gift_default'}
              formik={formik}
              label="当選景品"
              fieldName="gift_default"
              disabled
            />
          ) : gift_type === '3' ? (
            <TextBox
              key={'point'}
              formik={formik}
              label="当選景品"
              placeholder={'ポイント数を入力してください'}
              fieldName="point"
            />
          ) : (
            <TextBox
              key={'gift_stamp'}
              formik={formik}
              label="当選景品"
              placeholder={'スタンプ数を入力してください'}
              fieldName="stamp_count"
            />
          )}
          <DateTimeRangePicker
            formik={formik}
            label="参加日"
            fromFieldName="from_applicant_date"
            toFieldName="to_applicant_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>

      <TableTitle
        searchCount={total}
        onClickCsvOutput={() =>
          dispatch(gachaApplicantActions.openCsvDownloadModal())
        }
      />
      <TcTable
        columns={columns}
        dataSource={applicantList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(gachaApplicantActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <CsvDownloadModal
        line={3}
        modalVisible={csvModalVisible}
        options={gachaApplicantFavoriteItemOptions}
        favoriteItem={favoriteItem}
        width={570}
        onFavoriteItem={checkedList => {
          const initial = gachaApplicantFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: { ...acc.favorite, [item.value]: '0' },
            }),
            {
              screen_type: csvFavoriteItemTypes['gacha_applicant'],
              favorite: {},
            }
          );

          const param = checkedList.reduce(
            (acc, item) => ({
              ...acc,
              favorite: { ...acc.favorite, [item]: '1' },
            }),
            initial
          );

          dispatch(fetchGachaApplicantCsvFavoriteItemCreate(param));
        }}
        onClose={() => dispatch(gachaApplicantActions.closeCsvDownloadModal())}
        onDownload={checkedList =>
          dispatch(
            fetchGachaApplicantCsvDownload({
              screen_type: csvDownloadTypes['gacha_applicant'],
              columns: checkedList,
            })
          )
        }
      />
    </div>
  );
};

export default GachaApplicant;
