import { Modal } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Yup from '../../utils/yupUtil';
import {
  categoryActions,
  fetchCategoryCreate,
  fetchCategoryUpdate,
} from '../../slices/categorySlice';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';
import {
  categoryTypeOptions,
} from '../../constants/options';
import CustomNumberPicker from '../util/customNumberPicker';
import './style.scss';

const CategoryModal = () => {
  const dispatch = useDispatch();

  const { editModalVisible, currentCategory } = useSelector(
    state => state.category
  );

  const isUpdate = !!currentCategory;
  const initialValues = {
    category_code: null,
    category_type: null,
    category_name: null,
    display_order: null,
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object({
      category_type: Yup.string().nullable().selected(),
      category_name: Yup.string().nullable().required(),
    }),
    onSubmit: values =>
      isUpdate
        ? dispatch(
          fetchCategoryUpdate({
            category_code: values['category_code'],
            category_type: values['category_type'],
            category_name: values['category_name'],
            display_order: values['display_order'],
          })
        )
        : dispatch(
          fetchCategoryCreate({
            category_type: values['category_type'],
            category_name: values['category_name'],
            display_order: values['display_order'],
          })
        ),
  });

  const setValues = async () => {
    const values = currentCategory;
    formik.setValues(values);
    const errors = await formik.validateForm(values);

    formik.setErrors(errors);
  };

  useEffect(
    () =>
      editModalVisible
        ? currentCategory
          ? setValues()
          : formik.validateForm()
        : formik.resetForm(),
    [editModalVisible]
  );

  const onBack = () => dispatch(categoryActions.closeEditModal());

  console.log(`modal formik = ${JSON.stringify(formik.values)}`)
  return (
    <Modal
      className={'category-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={isUpdate ? 'カテゴリ編集' : 'カテゴリ登録'}
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          {isUpdate && (
            <>
              <div className="check-area">
                <span className={'pre-label'}>カテゴリCD</span>
                <span className="font-sm">{formik.values['category_code']}</span>
              </div>
              <div className="check-area">
                <span className={'pre-label'}>カテゴリ種別</span>
                <span className="font-sm">{categoryTypeOptions.find(opt => opt.value === formik.values['category_type'])?.label}</span>
              </div>
            </>
          )}
          {!isUpdate && (
            <SelectBox
              formik={formik}
              preLabel={"カテゴリ種別"}
              fieldName={"category_type"}
              options={categoryTypeOptions}
              width={'100%'}
              isRequired
            />
          )}
          <TextBox
            formik={formik}
            preLabel={'カテゴリ名'}
            fieldName={'category_name'}
            width={'100%'}
            isRequired
          />
          <CustomNumberPicker
            formik={formik}
            fieldName="display_order"
            preLabel="表示優先順"
            from={1}
            to={1000}
            width={300}
            placeholder={''}
          />
          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={isUpdate ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CategoryModal;
