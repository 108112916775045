import axios from '../utils/axiosUtil';
import moment from 'moment';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createFormData } from './notificationSlice';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';

const initialSearchParams = {
  title: '',
  message: '',
  shop_code: null,
  from_create_at: null,
  to_create_at: null,
  show_delete_flag: 0,
  geopush_flag: 1,
  count: 10,
  sort: 'document_id_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  geopushList: [],
  total: 0,
  geopushModalVisible: false,
  geopushDetail: null,
  geopushReferenceModalVisible: false,
  geopushReference: null,
  fileUploadStatus: null,
};

export const fetchGeopushList = createAsyncThunk(
  'fetchGeopushList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('/notification/document/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchGeopushDetail = createAsyncThunk(
  'fetchGeopushDetail',
  async params => {
    const detail = await axios.get('/notification/geopush/detail', { params });
    return detail?.item ?? {};
  }
);

export const fetchGeopushReferenceDetail = createAsyncThunk(
  'fetchGeopushReferenceDetail',
  async params => await axios.get('/notification/geopush/detail', { params })
);

export const updateGeopush = createAsyncThunk(
  'updateGeopush',
  async (params, { dispatch, getState }) => {
    await axios.post('/notification/geopush/update', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    });

    dispatch(fetchGeopushList(getState().geopush.searchParams));

    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchGeopushFileCheck = createAsyncThunk(
  'fetchGeopushFileCheck',
  async params =>
    await axios.post(
      '/notification/geopush/filecheck',
      createFormData(params),
      {
        headers: { 'content-type': 'multipart/form-data' },
        isFileUpload: true,
      }
    )
);

export const cancelGeopush = createAsyncThunk(
  'cancelGeopush',
  async (params, { dispatch, getState }) => {
    await axios.post('/notification/geopush/cancel', params);

    dispatch(fetchGeopushList(getState().geopush.searchParams));

    dispatch(globalActions.showSingleModal('配信設定をキャンセルしました'));
  }
);

export const geopushSlice = createSlice({
  name: 'geopush',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setGeopushModalVisible: (state, { payload }) => {
      state.geopushModalVisible = payload;
    },
    resetGeopushModal: state => {
      state.geopushModalVisible = false;
      state.geopushDetail = null;
      state.geopushReferenceModalVisible = null;
      state.geopushReference = null;
      state.fileUploadStatus = null;
    },
    clearFileUploadStatus: state => ({
      ...state,
      fileUploadStatus: null,
    }),
    reset: () => initialState,
  },
  extraReducers: {
    [fetchGeopushList.fulfilled]: (state, { payload }) => {
      state.geopushList = (payload.item ?? []).map(data => ({
        ...data,
        shop_name_list: !!data.shop_name_list?.length
          ? data.shop_name_list?.length > 1
            ? `${data.shop_name_list[0]}\r\n他${data.shop_name_list.length - 1
            }店舗`
            : `${data.shop_name_list[0]}`
          : '-',
      }));
      state.total = payload.search_count;
    },
    [fetchGeopushDetail.fulfilled]: (state, { payload }) => {
      state.geopushDetail = {
        document_id: payload.document_id,
        reserve_id: payload.reserve_id,
        send_type: payload.send_type ?? '1',
        repeat_type: payload.repeat_type ?? '0',
        send_date: payload.send_plan_date,
        send_time_hh: payload.send_reserve_time
          ? moment(payload.send_reserve_time, 'HH:mm').format('HH')
          : null,
        send_time_mm: payload.send_reserve_time
          ? moment(payload.send_reserve_time, 'HH:mm').format('mm')
          : null,
        repeat_send_time_hh: payload.repeat_send_time
          ? moment(payload.repeat_send_time, 'HH:mm').format('HH')
          : null,
        repeat_send_time_mm: payload.repeat_send_time
          ? moment(payload.repeat_send_time, 'HH:mm').format('mm')
          : null,
        repeat_period: payload.repeat_period,
        timing:
          payload.repeat_period === 'day'
            ? payload.timing?.day
            : payload.repeat_period === 'week'
              ? payload.timing?.week
              : payload.repeat_period === 'month'
                ? payload.timing?.month?.month_day
                  ? 0
                  : 1
                : null,
        repeat_send_time: payload.repeat_send_time,
        repeat_start_date: payload.repeat_start_date ?? '',
        repeat_end_date: payload.repeat_end_date ?? '',
        cond_flag: payload.cond_flag ?? '0',
        from_age: payload.from_age,
        to_age: payload.to_age,
        gender: payload.gender,
        birth_month: payload.birth_month,
        prefectures_list: payload.prefectures_list,
        shop_list: payload.shop_list,
        file_type: payload.list_type,
        upload_file_name: payload.upload_file_name,
        add_user_data_params: payload.add_user_data_params,
      };
      state.geopushModalVisible = true;
    },
    [fetchGeopushReferenceDetail.fulfilled]: (state, { payload }) => {
      state.geopushReference = payload.item ?? {};
      state.geopushReferenceModalVisible = true;
    },
    [fetchGeopushFileCheck.fulfilled]: (state, { payload }) => {
      state.fileUploadStatus = {
        all_count: payload.item?.all_count || 0,
        success_count: payload.item?.success_count || 0,
        faild_count: payload.item?.faild_count || 0,
      };
      if (payload.item?.faild_count > 0) {
        const fileType =
          payload.params.target_list_type === '0' ? '端末ID' : 'ユーザーCD';
        downloadFile(
          payload.item.faild_list.join('\r\n') + '\r\n',
          `取込失敗_${fileType}_${moment().format('YYYYMMDDHHmmss')}.txt`
        );
      }
    },
    [updateGeopush.fulfilled]: state => {
      state.geopushDetail = null;
      state.geopushModalVisible = false;
    },
    [cancelGeopush.fulfilled]: state => {
      state.geopushDetail = null;
      state.geopushModalVisible = false;
    },
  },
});

export const geopushActions = geopushSlice.actions;
export default geopushSlice.reducer;
