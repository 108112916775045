import { Upload, message } from 'antd';
import { getBase64 } from '../../utils/fnUtil';

const VideoUpload = ({ children, onChange, ...rest }) => {
  const beforeUpload = file => {
    const isFormateValid = ['video/mp4', 'video/quicktime'].includes(file.type);
    if (!isFormateValid) {
      message.error('mp4形式またはmov形式でアップロードしてください');
      return false;
    }

    const isLt1_5G = file.size / 1024 / 1024 / 1024 <= 1.5;

    if (!isLt1_5G) {
      message.error('動画は1.5GB以下でアップロードしてください');
      return false;
    }
    getBase64(file, url => onChange(url));

    return false;
  };

  return (
    <Upload
      accept={'.mp4,.mov'}
      beforeUpload={beforeUpload}
      showUploadList={false}
      {...rest}
    >
      {children}
    </Upload>
  );
};

export default VideoUpload;
