import { memo } from 'react';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import classNames from 'classnames';
import TcCheckbox from '.';
import { get } from 'lodash';
import './style.scss';

const TcRadio = memo(
  ({
    formik,
    label,
    options,
    fieldName,
    isRadio,
    width = 220,
    onChange = null,
    cancelable = true,
    required = false,
    onChecked = null,
    disabled,
    labelDisabled,
  }) => (
    <>
      {label && (
        <span
          className={classNames('cus-pre-label tc-radio-pre-label', {
            'label-required': required,
            'label-disabled': labelDisabled,
          })}
        >
          {label}
        </span>
      )}
      <span className="checkbox-list" style={{ width }}>
        {options.map((o, i) => (
          <TcCheckbox
            key={i}
            label={o.label}
            disabled={disabled}
            isRadio={isRadio}
            checked={
              onChecked
                ? onChecked(o)
                : get(formik.values, fieldName) === o.value
            }
            onChange={() => {
              if (onChange) {
                onChange(o.value);
              } else {
                handlerFormikFieldChange(
                  formik,
                  fieldName,
                  formik.values[fieldName] === o.value
                    ? !cancelable
                      ? options.length === 2
                        ? options.find(e => e.value !== o.value)?.value
                        : o.value
                      : null
                    : o.value
                );
              }
            }}
          />
        ))}
      </span>
    </>
  )
);

export default TcRadio;
