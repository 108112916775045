import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';

const initialSearchParams = {
  from_display_period: null,
  to_display_period: null,
};

const initialState = {
  searchParams: initialSearchParams,
  downloadList: [],
  errorMessage: null,
};

export const fetchDownloadList = createAsyncThunk(
  'fetchDownloadList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('analysis/download', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const downloadCountCsv = createAsyncThunk(
  'downloadCountCsv',
  async params => await axios.get('csv/download', { params })
);

export const downloadSampleCsv = createAsyncThunk(
  'downloadSampleCsv',
  async params => await axios.get('csv/download/sample', { params })
);

export const downloadSlice = createSlice({
  name: 'downloadSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
      },
    }),
    showErrorMessage: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
    }),
    hiddenErrorMessage: (state, { payload }) => ({
      ...state,
      errorMessage: null,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchDownloadList.fulfilled]: (state, { payload }) => {
      state.resultList = payload.item;
      state.downloadList = payload?.item?.send_download_summary_list || [];
    },
    [downloadCountCsv.fulfilled]: (state, { payload }) => {
      if (payload.code === '-1') {
        state.errorMessage = payload.display_message;
      } else if (typeof payload === 'string') {
        downloadFile(
          payload,
          `ダウンロード数_${moment().format('YYYYMMDDHHmmss')}.csv`
        );
      }
    },
    [downloadSampleCsv.fulfilled]: (state, { payload }) => {
      if (payload.code === '-1') {
        state.errorMessage = payload.display_message;
      } else if (typeof payload === 'string') {
        downloadFile(
          payload,
          `サンプルフォーマット_${moment().format('YYYYMMDDHHmmss')}.csv`
        );
      }
    },
  },
});

export const downloadActions = downloadSlice.actions;

export default downloadSlice.reducer;
