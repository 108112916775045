import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoryActions } from '../../slices/categorySlice';
import Modal from 'antd/lib/modal/Modal';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import './style.scss';

const CategoryReferenceModal = () => {
  const dispatch = useDispatch();
  const { referenceCategory } = useSelector(
    state => state.category
  );

  const onBack = () => dispatch(categoryActions.closeReferenceModal());

  return (
    <Modal
      className={'category-modal tc-modal'}
      open={!!referenceCategory}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title="カテゴリ参照" tip={''} />
        <div className="input-area">
          <div className="check-area">
            <span className="pre-label">カテゴリCD</span>
            <div className="font-sm">
              {referenceCategory?.category_code}
            </div>
          </div>
          <div className="check-area">
            <span className="pre-label">カテゴリ種別</span>
            <div className="font-sm">
              {referenceCategory?.category_type}
            </div>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>カテゴリ名</span>
            <div className="font-sm">
              {referenceCategory?.category_name}
            </div>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>表示優先順</span>
            <div className="font-sm">
              {referenceCategory?.display_order}
            </div>
          </div>
          <div className="button-container">
            <TcButton text="閉じる" theme="white" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CategoryReferenceModal;
