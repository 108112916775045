import { memo } from 'react';
import { DatePicker } from 'antd';
import {
  compareMemoObject,
  getFormikArrayProps,
  handlerFormikFieldChange,
} from '../../../utils/fnUtil';
import Icons from '../../../constants/Icons';
import moment from 'moment';
import { get } from 'lodash';
import classNames from 'classnames';
import './style.scss';

const DatePickerWrapper = memo(
  ({
    className,
    disabled = false,
    disabledDate,
    fieldName,
    format = 'YYYY/M/D',
    formik,
    hasLabel = true,
    isBold,
    isRequired = false,
    label,
    listFieldName = null,
    prefix,
    flexEnd,
    preLabel,
    suffix,
    uneditable,
    usePopContainer,
    width,
    maxWidth,
    prefixWidth = 100,
    displayValue,
    style,
  }) => {
    const wrapperClassName = classNames('tc-date-picker-wrapper', className);
    const labelClassName = classNames('tc-date-picker-label', {
      'label-bold': isBold,
      'label-disabled': disabled,
      'label-required': isRequired,
    });
    const preLabelClassName = classNames('tc-date-picker-preLabel', {
      'label-disabled': disabled,
      'label-required': isRequired && !disabled,
      'label-required-disabled': isRequired && disabled,
    });
    const prefixClassName = classNames('prefix', {
      'label-disabled': disabled,
      'label-required': isRequired,
    });
    const suffixClassName = classNames('suffix', {
      'label-disabled': disabled,
    });

    const labelAreaClassName = classNames('tc-date-picker-label-area', {
      'flex-end': !!preLabel || flexEnd,
    });

    const onChange = (_, formatString) => {
      handlerFormikFieldChange(formik, fieldName, formatString);
    };

    const formikArrayProps = listFieldName
      ? getFormikArrayProps(formik, listFieldName)
      : null;

    fieldName = listFieldName ? listFieldName : fieldName;

    return (
      <div
        className={wrapperClassName}
        style={{ width, maxWidth, flex: 1, ...style }}
      >
        {hasLabel && (
          <div className={labelAreaClassName}>
            {label && <label className={labelClassName}>{label}</label>}
            <span className="tc-date-picker-error">
              {!!formikArrayProps
                ? formikArrayProps.touched && formikArrayProps.error
                : formik.touched[fieldName] && formik.errors[fieldName]}
            </span>
          </div>
        )}
        <div className="tc-date-picker-line">
          {preLabel && <span className={preLabelClassName}>{preLabel}</span>}
          {prefix && (
            <span className={prefixClassName} style={{ width: prefixWidth }}>
              {prefix}
            </span>
          )}
          {uneditable ? (
            <span className="uneditable">
              {displayValue ?? formik.values[fieldName]}
            </span>
          ) : (
            <DatePicker
              disabledDate={disabledDate}
              inputReadOnly={true}
              disabled={disabled}
              onBlur={() => formik.setFieldTouched(fieldName, true)}
              placeholder={disabled ? '' : 'YYYY/M/D'}
              format={format}
              value={
                get(formik.values, fieldName)
                  ? moment(get(formik.values, fieldName))
                  : null
              }
              suffixIcon={
                disabled ? (
                  <Icons.IconCalendarGy className="picker-icon" />
                ) : (
                  <Icons.IconCalendar className="picker-icon" />
                )
              }
              clearIcon={<Icons.IconXCircle className="picker-icon" />}
              getPopupContainer={
                usePopContainer ? triggerNode => triggerNode.parentNode : null
              }
              onChange={onChange}
            />
          )}
          {suffix && <span className={suffixClassName}>{suffix}</span>}
        </div>
      </div>
    );
  },
  (pre, next) =>
    compareMemoObject(pre, next, ['fieldName']) &&
    pre.disabled === next.disabled &&
    pre.fieldName === next.fieldName &&
    pre.disabledDate === next.disabledDate
);

export default DatePickerWrapper;
