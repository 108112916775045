import React, { useEffect, useRef } from 'react';
import PageTitle from '../../components/pageTitle';
import Icons from '../../constants/Icons';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import TcButton from '../../components/tcButton';
import TcCheckbox from '../../components/tcCheckbox';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import TableTitle from '../../components/tableTitle';
import TcTable from '../../components/tcTable';
import DeleteModal from '../../components/modal/deleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { templateColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import SelectBox from '../../components/util/selectBox';
import './style.scss';
import {
  fetchTemplateDelete,
  fetchTemplateDetail,
  fetchTemplateList,
  fetchTemplateReference,
  templateActions,
} from '../../slices/templateSlice';
import TemplateModal from '../../components/templateModal';
import TemplateReferenceModal from '../../components/templateModal/reference';

const Template = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { total, deleteTemplate, searchParams, templateList } = useSelector(
    state => state.template
  );

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => {
      console.log(values);
      dispatch(templateActions.saveSearchParams(values));
    },
  });

  useEffect(() => {
    dispatch(fetchTemplateList(searchParams));
  }, [searchParams]);

  useEffect(() => {
    dispatch(templateActions.saveSearchParams(null));
    return () => dispatch(templateActions.clear());
  }, [dispatch]);

  const columns = [
    ...templateColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(templateActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        current.deleted_flag === '0' ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchTemplateDetail(current))}
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        current.deleted_flag === '0' ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => {
              dispatch(templateActions.openDeleteModal(current));
            }}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchTemplateReference(current))}
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle title="定型文テンプレート管理" icon={<Icons.IconTemplate />} />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} fieldName="template_code" label="定型文CD" />
          <SelectBox
            formik={formik}
            label="定型文種別"
            fieldName="template_type"
            mode="multiple"
            placeholder={'定型文種別を選択してください'}
            options={
            selectOptionList?.filter(s => s.field_name === '定型文種別')?.[0]
              ?.select_option ?? []
            }
            extraOnChange={(_, v) => formik.setFieldValue('template_type', v)}
          />
          <TextBox
            formik={formik}
            fieldName="template_title"
            label="定型文タイトル"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['deleted_flg']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flg',
                !!formik.values['deleted_flg'] ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(templateActions.openEditModal(null))}
      />
      <TcTable
        columns={columns}
        dataSource={templateList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(templateActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <TemplateModal />
      <TemplateReferenceModal />
      <DeleteModal
        modalVisible={!!deleteTemplate}
        onCancel={() => dispatch(templateActions.closeDeleteModal())}
        item={'定型文'}
        itemValue={deleteTemplate?.template_code}
        onOk={() =>
          dispatch(
            fetchTemplateDelete({
              template_code: deleteTemplate?.template_code,
            })
          )
        }
      />
    </div>
  );
};

export default Template;
