import './style.scss';
import React, { useState, useEffect } from 'react';
import {
  CircleF,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import { useDispatch, useSelector } from 'react-redux';
import { shopManagementActions } from '../../slices/shopManagementSlice';

const containerStyle = {
  width: '100%',
  height: '350px',
  borderRadius: '3px',
};

const center = {
  lat: 35.69575,
  lng: 139.77521,
};

const circleOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  //zIndex: 10000,
};

const GoogleMapSelect = ({
  formik,
  fieldName1,
  fieldName2,
  fieldNameRadius,
  geopush = false,
}) => {
  //緯度
  fieldName1 = fieldName1;
  //経度
  fieldName2 = fieldName2;
  fieldNameRadius = fieldNameRadius;

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCp6dXSnionQVxju_wW8T08EH5C8HeHo_c',
    language: 'ja',
    // ...otherOptions
  });

  const [markers, setMarkers] = useState([]);
  const { radius, latitude, longitude } = useSelector(
    state => state.shopManagement
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setMarkers([
      {
        lat: Number(formik.values.location1),
        lng: Number(formik.values.location2),
      },
    ]);
  }, []);
  useEffect(() => {
    setMarkers([
      {
        lat: Number(latitude),
        lng: Number(longitude),
      },
    ]);
  }, [latitude, longitude]);

  const onClick = e => {
    if (!geopush) {
      setMarkers([{ lat: e.latLng.lat(), lng: e.latLng.lng() }]);
      //緯度
      handlerFormikFieldChange(formik, fieldName1, e.latLng.lat() ?? null);
      //経度
      handlerFormikFieldChange(formik, fieldName2, e.latLng.lng() ?? null);
    }
  };

  return (
    <div className="map-wrapper">
      <div>
        {/* 半径
        <input type="text" value={radius} /> */}
        <div className="map">
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={geopush ? {
                lat: Number(latitude ?? 35.69575),
                lng: Number(longitude ?? 139.77521),
              } : center}
              zoom={10}
              onClick={onClick}
            // onIdle={() => console.log('Idle now')}
            >
              {!!markers?.length &&
                markers.map((v, i) => (
                  <MarkerF
                    position={v}
                    draggable
                    onDrag={e => {
                      setMarkers([
                        ...markers.map((v, index) =>
                          index === i
                            ? { lat: e.latLng.lat(), lng: e.latLng.lng() }
                            : v
                        ),
                      ]);
                      //緯度
                      handlerFormikFieldChange(
                        formik,
                        fieldName1,
                        e.latLng.lat() ?? null
                      );
                      //経度
                      handlerFormikFieldChange(
                        formik,
                        fieldName2,
                        e.latLng.lng() ?? null
                      );
                      //console.log(radius);
                    }}
                  >
                    <CircleF
                      center={v}
                      radius={Number(radius) ?? 0}
                      options={circleOptions}
                    />
                  </MarkerF>
                ))}
            </GoogleMap>
          ) : (
            <div>Now Loading</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleMapSelect;
