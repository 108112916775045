import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { qrActions, qrCreate, qrUpdate } from '../../slices/qrSlice';
import { mapValues } from 'lodash';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import ModalTitle from '../modalTitle';
import Button from '../tcButton';
import Yup from '../../utils/yupUtil';
import './style.scss';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';
import CustomNumberPicker from '../util/customNumberPicker';

/* QR登録/更新モーダル */

const QRModal = () => {
  const dispatch = useDispatch();

  const { accountBrandList: brandList, accountShopList: shopList } =
    useSelector(state => state.account);

  const { editModalVisible, currentQr } = useSelector(state => state.qr);

  const isUpdate = currentQr;

  const initialValues = {
    qr_code: null,
    qr_name: null,
    stamp_count: 1,
    brand: null,
    shop: null,
  };

  const yupObject = Yup.object({
    qr_name: Yup.string().nullable().required().max(30),
    brand: Yup.string().nullable().required(),
    shop: Yup.string().nullable().required(),
    stamp_count: Yup.string().nullable().required(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: yupObject,
    onSubmit: async values =>
      dispatch(isUpdate ? qrUpdate(values) : qrCreate(values)),
  });

  const { qr_code, brand, brand_name, shop_name } = formik.values;

  useUpdateEffect(() => {
    currentQr &&
      formik
        .setValues({
          ...currentQr,
          brand: currentQr.brand_code,
          shop: currentQr.shop_code,
          brand_name: currentQr.brand_name,
          shop_name: currentQr.shop_name,
        })
        .then(() => formik.setTouched(mapValues(yupObject, () => true)))
        .then(() => formik.validateForm());
  }, [currentQr]);

  useUpdateEffect(() => {
    !editModalVisible && formik.resetForm();
    formik.validateForm();
  }, [editModalVisible]);

  const onBack = () => {
    dispatch(qrActions.closeEditModal());
    formik.resetForm();
  };

  return (
    <Modal
      className={'qr-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={isUpdate ? 'QR編集' : 'QR登録'}
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          <TextBox
            formik={formik}
            preLabel="QR名"
            fieldName="qr_name"
            mode={null}
            width={'100%'}
            isRequired
          />
          {isUpdate && (
            <div className="text-area">
              <label>QRコード</label>
              <span>{qr_code}</span>
            </div>
          )}
          <CustomNumberPicker
            preLabel={'スタンプ押印数'}
            formik={formik}
            from={1}
            to={100}
            fieldName={'stamp_count'}
            suffix={'個'}
            width={300}
            isRequired
          />
          <SelectBox
            preLabel={'ブランド'}
            formik={formik}
            fieldName="brand"
            options={brandList}
            width={400}
            extraOnChange={() => handlerFormikFieldChange(formik, 'shop', null)}
            uneditable={isUpdate}
            isRequired
          />
          <SelectBox
            preLabel={'店舗'}
            formik={formik}
            fieldName="shop"
            options={shopList.filter(e => e.brand_code === brand)}
            width={400}
            uneditable={isUpdate}
            isRequired
          />
          <div className="button-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            <Button
              text={isUpdate ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QRModal;
