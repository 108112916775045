import React, { useState } from 'react';
import { Image } from 'antd';
import { focusToEnd } from '../utils/other';
import { spliceImageArray, getYouTubeVideoId } from '../../../utils/fnUtil';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from '../../../slices/articlesSlice';
import { useFocused, useSelected, useSlate, useReadOnly } from 'slate-react';
import { Editor, Transforms, Element as SlateElement } from 'slate';
import Icon from '@ant-design/icons';
import Icons from '../../../constants/Icons';
import Button from '../../forRichTextEditor/button';
import noimage from '../../../images/forRichTextEditor/noimage_square2.png';
import BaseUpload from '../../baseUpload';
import VideoUpload from '../../baseUpload/videoUpload';
import ProductInfoCard from '../../forRichTextEditor/productInfoCard';
import ProductSearchModal from '../../forRichTextEditor/productSearchModal';
import CoordinateSearchModal from '../../forRichTextEditor/coordinateSearchModal';
import classNames from 'classnames';
import LinkInsertModal from '../../forRichTextEditor/linkInsertModal';
import { globalActions } from '../../../slices/globalSlice';
import '../style.scss';

const ImageBlock = ({
  element,
  children,
  attributes,
  contentImageBlock,
  extraUpload,
}) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);
  const [coordinateSearchModalVisible, setCoordinateSearchModalVisible] =
    useState(false);
  const [linkInsertModalVisible, setLinkInsertModalVisible] = useState(false);

  const { images, maxCol, boxtype, deviceMode, articleCode, userCode } =
    element;

  const {
    accountInfo: { add_article_data },
  } = useSelector(state => state.account);

  const isPlus = i => boxtype === '9' && i === 0;

  const changeImage = (imageObj, index, clearCurrent) => {
    Transforms.setNodes(
      editor,
      {
        images: spliceImageArray(images, index, imageObj, clearCurrent),
      },
      {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'image',
      }
    );
  };

  const readCipboardText = async () => {
    try {
      return await navigator.clipboard.readText();
    } catch (err) {}
  };

  return (
    <>
      <div {...attributes} className={classNames('image-block-container')}>
        <div
          className={classNames('image-block-inner', {
            'image-block-active': selected && focused,
            'image-block-disable': readOnly,
          })}
          contentEditable={false}
          style={{
            maxWidth: maxCol * (deviceMode ? 0.57 * 250 : 250),
            width: maxCol * (deviceMode ? 0.57 * 205 : 205),
          }}
          onMouseDown={e => {
            e.preventDefault();
            focusToEnd(editor);
          }}
        >
          {images.map((i, index) => (
            <div
              key={index}
              className={classNames('block-action-container', {
                'block-action-container-plus': isPlus(index),
                'block-action-container-device': deviceMode,
              })}
            >
              {i && !readOnly && (
                <>
                  {contentImageBlock && i.image_url && (
                    <Icon
                      className="edit-icon"
                      component={Icons.IconEditS}
                      onClick={() => {
                        setLinkInsertModalVisible(true);
                        setActiveIndex(index);
                      }}
                    />
                  )}
                  <Icon
                    component={Icons.IconXCircleS}
                    className="close-icon"
                    onClick={() =>
                      setTimeout(() => changeImage('', index, true))
                    }
                  />
                </>
              )}
              {i.image_url ? (
                i.product_code ? (
                  <ProductInfoCard
                    productInfo={i}
                    size={isPlus(index) ? 'plus' : 'normal'}
                    deviceMode={deviceMode}
                    displayNoImage={i.disabled}
                  />
                ) : (
                  <Image
                    preview={false}
                    className="block-img"
                    src={
                      i.coordinate_code && i.disabled
                        ? noimage
                        : i.image_url || noimage
                    }
                  />
                )
              ) : i.movie_url ? (
                <video controls>
                  <source src={i.movie_url} />
                </video>
              ) : i.youtobe_url ? (
                <iframe
                  src={i.youtobe_url}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {/* {contentImageBlock && !i.link_url && (
                    <Button
                      text="リンクを追加"
                      theme="white"
                      fontSize="small"
                      disabled={readOnly}
                      onClick={() => {
                        setLinkInsertModalVisible(true);
                        setActiveIndex(index);
                      }}
                    />
                  )} */}
                  {add_article_data?.movie_flag === 1 && extraUpload && (
                    <VideoUpload
                      disabled={readOnly}
                      onChange={image_url => {
                        dispatch(
                          uploadImage({
                            base64_images: [image_url],
                            operator_code: userCode,
                          })
                        ).then(
                          ({ payload }) =>
                            (payload?.code === '0' || payload?.code === null) &&
                            changeImage(
                              { movie_url: payload.item[0] },
                              index,
                              true
                            )
                        );
                      }}
                    >
                      <Button
                        text="動画を追加"
                        theme="white"
                        fontSize="small"
                        disabled={readOnly}
                      />
                    </VideoUpload>
                  )}
                  {add_article_data?.youtube_flag === 1 && extraUpload && (
                    <Button
                      text="youtobeを追加"
                      theme="white"
                      fontSize="small"
                      disabled={readOnly}
                      onClick={async () => {
                        const text = await readCipboardText();

                        const videoId = getYouTubeVideoId(text);

                        if (videoId) {
                          changeImage(
                            {
                              youtobe_url: `https://www.youtube.com/embed/${videoId}`,
                            },
                            index,
                            true
                          );
                        } else {
                          dispatch(
                            globalActions.showErrorModal({
                              title: '',
                              message:
                                'youtubeのシェアURLを事前にコピーした後に「youtubeを追加」ボタンを押下してください',
                            })
                          );
                        }
                      }}
                    />
                  )}
                  <BaseUpload
                    disabled={readOnly}
                    onChange={image_url => {
                      dispatch(
                        uploadImage({
                          base64_images: [image_url],
                          operator_code: userCode,
                        })
                      ).then(
                        ({ payload }) =>
                          (payload?.code === '0' || payload?.code === null) &&
                          changeImage({ image_url: payload.item[0] }, index)
                      );
                    }}
                  >
                    <Button
                      text="画像を追加"
                      theme="white"
                      fontSize="small"
                      disabled={readOnly}
                    />
                  </BaseUpload>
                  {/*<Button*/}
                  {/*  text="商品を追加"*/}
                  {/*  theme="white"*/}
                  {/*  disabled={readOnly}*/}
                  {/*  fontSize="small"*/}
                  {/*  onClick={() => {*/}
                  {/*    setProductSearchModalVisible(true);*/}
                  {/*    setActiveIndex(index);*/}
                  {/*  }}*/}
                  {/*/>*/}
                  {/*<Button*/}
                  {/*  text="コーディネートを追加"*/}
                  {/*  fontSize="small"*/}
                  {/*  theme="white"*/}
                  {/*  disabled={readOnly}*/}
                  {/*  onClick={() => {*/}
                  {/*    setCoordinateSearchModalVisible(true);*/}
                  {/*    setActiveIndex(index);*/}
                  {/*  }}*/}
                  {/*/>*/}
                </div>
              )}
            </div>
          ))}
        </div>
        <CoordinateSearchModal
          visible={coordinateSearchModalVisible}
          onOk={seletedCoordinate => {
            focusToEnd(editor);
            changeImage(seletedCoordinate, activeIndex);
            setCoordinateSearchModalVisible(false);
          }}
          onCancel={() => {
            setCoordinateSearchModalVisible(false);
            focusToEnd(editor);
          }}
        />
        <ProductSearchModal
          visible={productSearchModalVisible}
          onCancel={() => {
            setProductSearchModalVisible(false);
            focusToEnd(editor);
          }}
          onOk={selectedProduct => {
            focusToEnd(editor);
            changeImage(selectedProduct, activeIndex);
            setProductSearchModalVisible(false);
          }}
        />
        <LinkInsertModal
          visible={linkInsertModalVisible}
          onCancel={() => {
            setLinkInsertModalVisible(false);
            focusToEnd(editor);
          }}
          currentValue={images[activeIndex]}
          onOk={selectedLink => {
            focusToEnd(editor);
            changeImage(selectedLink, activeIndex);
            setLinkInsertModalVisible(false);
          }}
        />
        {children}
      </div>
    </>
  );
};

export default ImageBlock;
