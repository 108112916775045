import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';

const initialSearchParams = {
  template_code: '',
  template_type: [],
  template_title: '',
  deleted_flg: 0,
  offset: 0,
  count: 10,
  sort: 'template_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  templateList: [],
  total: 0,
  deleteTemplate: null,
  editModalVisible: false,
  currentTemplate: null,
  referenceTemplate: null,
};

export const fetchTemplateList = createAsyncThunk(
  'fetchTemplateList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('settings/template/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchTemplateDetail = createAsyncThunk(
  'fetchTemplateDetail',
  async params =>
    await axios.get('settings/template/detail', {
      params: { template_code: params.template_code },
    })
);

export const fetchTemplateReference = createAsyncThunk(
  'fetchTemplateReference',
  async params =>
    await axios.get('settings/template/detail', {
      params: { template_code: params.template_code },
    })
);

export const fetchTemplateCreate = createAsyncThunk(
  'fetchTemplateCreate',
  async (params, { dispatch }) => {
    await axios.post('settings/template/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchTemplateUpdate = createAsyncThunk(
  'fetchTemplateUpdate',
  async (params, { dispatch }) => {
    console.log(`params = ${JSON.stringify(params)}`)
    await axios.post('settings/template/update', { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchTemplateDelete = createAsyncThunk(
  'fetchTemplateDelete',
  async params => await axios.post('settings/template/delete', { ...params })
);

export const templateSlice = createSlice({
  name: 'templateSlice',
  initialState,
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteTemplate: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentTemplate: null,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteTemplate: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceTemplate: null,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchTemplateList.fulfilled]: (state, { payload }) => ({
      ...state,
      total: payload?.total_count ?? 0,
      templateList: [...payload?.item],
    }),
    [fetchTemplateDetail.fulfilled]: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      currentTemplate: {
        template_code: payload?.item?.template_code,
        template_type: payload?.item?.template_type,
        template_title: payload?.item?.template_title,
        template_text: payload?.item?.template_text,
      },
    }),
    [fetchTemplateReference.fulfilled]: (state, { payload }) => ({
      ...state,
      referenceTemplate: {
        template_code: payload?.item?.template_code,
        template_type: payload?.item?.template_type,
        template_title: payload?.item?.template_title,
        template_text: payload?.item?.template_text,
      },
    }),
    [fetchTemplateCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
    }),
    [fetchTemplateUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      currentCategory: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchTemplateDelete.fulfilled]: state => ({
      ...state,
      deleteTemplate: null,
      searchParams: { ...state.searchParams },
    }),
  },
});

export const templateActions = templateSlice.actions;

export default templateSlice.reducer;
