import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';

const initialSearchParams = {
  search_date: null,
  start_time: '00',
  end_time: '23',
};

const initialState = {
  searchParams: initialSearchParams,
  resultList: null,
  center: {},
  errorMessage: null,
};

export const fetchHeatmapList = createAsyncThunk(
  'fetchHeatmapList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('analysis/geoactive', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const heatmapSlice = createSlice({
  name: 'heatmapSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
      },
    }),
    showErrorMessage: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
    }),
    hiddenErrorMessage: (state, { payload }) => ({
      ...state,
      errorMessage: null,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchHeatmapList.fulfilled]: (state, { payload }) => {
      const screen_list = payload?.item || [];
      state.resultList = screen_list;

      // データがない場合
      if (screen_list.length === 0) {
        state.errorMessage = '表示するデータがありません';
        return;
      }

      // 地図の初期表示位置を取得
      const summary = screen_list.filter(
        e => e.location_summary_list.length > 0
      )?.[0].location_summary_list?.[0];
      state.center = { lat: summary?.latitude, lng: summary?.longitude };
    },
  },
});

export const heatmapActions = heatmapSlice.actions;

export default heatmapSlice.reducer;
