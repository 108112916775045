import { memo } from 'react';
import { Table } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import Icons from '../../constants/Icons';

const TcTable = memo(({ columns, dataSource, onChange, offset, total, pagenationFlag = true }) => (
  <Table
    rowKey="name"
    columns={columns}
    dataSource={dataSource}
    onChange={pagination => {
      const offset =
        pagination.current === 1
          ? 0
          : pagination.pageSize * (pagination.current - 1);
      onChange(offset);
    }}
    pagination={pagenationFlag ? {
      total,
      pageSize: 10,
      current: (offset || 0) / 10 + 1,
      position: ['bottomCenter'],
      showQuickJumper: false,
      itemRender: (page, type) => {
        switch (type) {
          case 'prev':
            return <Icons.IconChevronLeft className="paging-icon" />;
          case 'page':
            return <span>{page}</span>;
          case 'jump-prev':
            return <EllipsisOutlined />;
          case 'jump-next':
            return <EllipsisOutlined />;
          case 'next':
            return <Icons.IconChevronRight className="paging-icon" />;
        }
      },
    } : false}
    sticky={{ offsetHeader: 60 }}
  />
));

export default TcTable;
