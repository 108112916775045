import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { reserveColumns } from '../../constants/columns';
import {
  fetchReserveList,
  reserveActions,
  fetchReserveDetail,
  fetchReserveReferenceDetail,
  fetchReserveCsvFavoriteItem,
  fetchReserveCsvFavoriteItemCreate,
  fetchReserveCsvDownload,
} from '../../slices/reserveSlice';
import { fetchGeopushDetail } from '../../slices/geopushInfoSlice';
import {
  canUpdateSendStatus,
  csvFavoriteItemTypes,
  reserveFavoriteItemOptions,
  targetIsOpenOptions,
  csvDownloadTypes,
} from '../../constants/options';
import { fetchScenarioType } from '../../slices/notificationSlice';
import ReserveReferenceModal from '../../components/reserveModal/reserveReferenceModal';
import ReserveModal from '../../components/reserveModal';
import GeopushModal from '../../components/geopushModal';
import TcButton from '../../components/tcButton';
import PageTitle from '../../components/pageTitle';
import TableTitle from '../../components/tableTitle';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import Icons from '../../constants/Icons';
import './style.scss';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import SelectBox from '../../components/util/selectBox';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import TcRadio from '../../components/tcCheckbox/tcRadio';

const Reserve = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { searchParams, reserveList, csvReserve, favoriteItem, total } =
    useSelector(state => state.reserve);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      const {
        from_create_at,
        to_create_at,
        from_send_plan_date,
        to_send_plan_date,
        ...rest
      } = values;
      const params = {
        ...rest,
        from_create_at: dateFormat(from_create_at, 'YYYY/MM/DD'),
        to_create_at: dateFormat(to_create_at, 'YYYY/MM/DD'),
        from_send_plan_date: dateFormat(from_send_plan_date, 'YYYY/MM/DD'),
        to_send_plan_date: dateFormat(to_send_plan_date, 'YYYY/MM/DD'),
      };

      dispatch(reserveActions.saveSearchParams(params));
    },
  });

  const csvFormik = useFormik({
    initialValues: { read_flag: '1', checkedList: [] },
    onSubmit: values =>
      dispatch(
        fetchReserveCsvDownload({
          reserve_id: csvReserve.reserve_id,
          screen_type: csvDownloadTypes['reserve'],
          columns: values.checkedList,
          read_flag: Number(values.read_flag),
        })
      ),
  });

  useUpdateEffect(
    () => dispatch(fetchReserveList(searchParams)),
    [searchParams]
  );

  useEffect(() => {
    dispatch(
      fetchReserveCsvFavoriteItem({
        screen_type: csvFavoriteItemTypes['reserve'],
      })
    ) && dispatch(reserveActions.saveSearchParams(null));

    return () => dispatch(reserveActions.reset());
  }, [dispatch]);

  const columns = [
    ...reserveColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => dispatch(reserveActions.saveSearchParams({ sort }))}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '結果',
      align: 'center',
      width: '150px',
      render: current =>
        canUpdateSendStatus.includes(current.send_status) &&
          current.document_category === '0' ? (
          <div className="summary-area">
            <div className="summary-item-area">
              <button className="disable-btn">
                <Icons.IconResultGy className="btn-icon" /> {'結果表示'}
              </button>
            </div>
            <button className="disable-btn">
              <Icons.IconDownloadGy className="btn-icon" /> {'CSV出力'}
            </button>
          </div>
        ) : (
          <div className="summary-area">
            <div className="summary-item-area">
              <TcButton
                text={'結果表示'}
                theme={'white'}
                icon={<Icons.IconResult className="btn-icon" />}
                onClick={() =>
                  dispatch(
                    fetchReserveReferenceDetail({
                      reserve_id: current.reserve_id,
                    })
                  )
                }
              />
            </div>
            <TcButton
              text={'CSV出力'}
              theme={'white'}
              icon={<Icons.IconDownload className="btn-icon" />}
              onClick={() =>
                dispatch(reserveActions.openCsvDownloadModal(current))
              }
            />
          </div>
        ),
    },
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current => {
        dispatch(fetchScenarioType())
        if (canUpdateSendStatus.includes(current.send_status) && current.document_category === '0') {
          const fetchFunction = current.geopush_flag !== '1' ?
            () => dispatch(fetchReserveDetail({ reserve_id: current.reserve_id })) :
            () => dispatch(fetchGeopushDetail({ document_id: current.document_id }));
          return (
            <Icons.IconEdit
              className="table-cell-icon tc-action-icon"
              onClick={fetchFunction}
            />
          )
        } else {
          return (
            <Icons.IconEditGy className="table-cell-icon" />
          )
        }
      }
    },
  ];

  return (
    <div className="content-body management-page reserve" ref={parent}>
      <PageTitle
        title="予約・配信済み一覧"
        icon={<Icons.IconBell className="page-title-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <SelectBox
            formik={formik}
            label="種別"
            fieldName="send_types"
            options={selectOptionList?.filter(s => s.field_name === '種別')?.[0]
              ?.select_option ?? []
          }
            mode="multiple"
          />
          <TextBox formik={formik} label="タイトル" fieldName="title" />
          <TextBox formik={formik} label="メッセージ" fieldName="message" />
          <DateTimeRangePicker
            formik={formik}
            label="登録日"
            fromFieldName="from_create_at"
            toFieldName="to_create_at"
          />
          <DateTimeRangePicker
            formik={formik}
            label="配信日"
            fromFieldName="from_send_plan_date"
            toFieldName="to_send_plan_date"
          />
          <SelectBox
            formik={formik}
            label="ステータス"
            fieldName="send_status"
            options={selectOptionList?.filter(s => s.field_name === 'ステータス')?.[0]
              ?.select_option ?? []
          }
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>
      <TableTitle searchCount={total} />
      <TcTable
        columns={columns}
        dataSource={reserveList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(reserveActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <CsvDownloadModal
        line={2}
        modalVisible={!!csvReserve}
        requiredText={null}
        options={reserveFavoriteItemOptions}
        favoriteItem={favoriteItem}
        width={1000}
        searchArea={
          <div className="csv-modal-date-container">
            <TcRadio
              formik={csvFormik}
              isRadio={true}
              fieldName="read_flag"
              options={targetIsOpenOptions}
              width={240}
              onChange={v =>
                handlerFormikFieldChange(csvFormik, 'read_flag', v)
              }
            />
          </div>
        }
        onFavoriteItem={checkedList => {
          const initial = reserveFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: { ...acc.favorite, [item.value]: '0' },
            }),
            { screen_type: csvFavoriteItemTypes['reserve'], favorite: {} }
          );

          const param = checkedList.reduce(
            (acc, item) => ({
              ...acc,
              favorite: { ...acc.favorite, [item]: '1' },
            }),
            initial
          );

          dispatch(fetchReserveCsvFavoriteItemCreate(param));
        }}
        onClose={() => dispatch(reserveActions.closeCsvDownloadModal())}
        onDownload={checkedList => {
          csvFormik.setValues({ ...csvFormik.values, checkedList });
          csvFormik.handleSubmit();
        }}
      />
      <ReserveModal />
      <GeopushModal />
      <ReserveReferenceModal />
    </div>
  );
};

export default Reserve;
