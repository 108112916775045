import './style.scss';
import React from 'react';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div>Copyright © CORIN. All Rights Reserved.</div>
      </footer>
    </>
  );
};

export default Footer;
