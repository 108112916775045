import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lineRichmenuActions } from '../../slices/lineRichmenuSlice';
import { fetchLineAccountListForSelect } from '../../slices/lineAccountSlice';
import { Modal, Image as AntImage, Divider, Layout } from 'antd';
import ModalTitle from '../modalTitle';
import PreviewSVG from './previewSVG';
import Button from '../tcButton';
import ReferenceItem from '../referenceItem';
import { richmenuDisplayOptions, richmenuTabOptions, richmenuActionTypeOptions } from '../../constants/options';
import './style.scss';

const { Sider, Content } = Layout;

/* LINEリッチメニュー参照モーダル */

const LineRichmenuReferenceModal = () => {
  const dispatch = useDispatch();

  const { referenceModalVisible, referenceLineRichmenu, crop } = useSelector(state => state.lineRichmenu);
  const { lineAccountOptionList } = useSelector(state => state.lineAccount);

  useEffect(
    () => referenceModalVisible && dispatch(fetchLineAccountListForSelect()),
    [referenceModalVisible, dispatch]
  );

  const onBack = () => {
    dispatch(lineRichmenuActions.closeReferenceModal());
  };

  return (
    <Modal
      className={'line-richmenu-reference-modal'}
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
    >
      <div className="line-richmenu-reference-modal-content">
        <ModalTitle title={'リッチメニュー参照'} />
        <div>
          <h3>基本設定</h3>
          <ReferenceItem
            label="公式アカウント名"
            value={
              lineAccountOptionList.find(v => v.value === referenceLineRichmenu?.account_code)?.label
            }
          />
          <ReferenceItem
            label="メニューCD"
            value={referenceLineRichmenu?.menu_code}
          />
          <ReferenceItem
            label="メニュー名"
            value={referenceLineRichmenu?.menu_name}
          />
          <ReferenceItem
            label="表示期間"
            multiValue={[
              referenceLineRichmenu?.start_date,
              `　〜　`,
              referenceLineRichmenu?.end_date,
            ]}
            joinString=""
          />
          <ReferenceItem
            label="メニューバーのデフォルト表示"
            value={
              richmenuDisplayOptions.find(r => r.value === referenceLineRichmenu?.selected)?.label
            }
          />
          <Divider />
          <h3>コンテンツ設定</h3>
          <ReferenceItem
            label="タブ数"
            value={
              richmenuTabOptions.find(r => r.value === referenceLineRichmenu?.tab_count)?.label
            }
          />

          {referenceLineRichmenu?.richmenus.map((richmenu, index) => (
            index < referenceLineRichmenu?.tab_count && (
              <RichmenuTab
                key={index}
                richmenu={richmenu}
                maskBounds={crop}
              />
            )
          ))}
          <div className="button-container">
            <Button text="閉じる" theme="white" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const RichmenuTab = ({ richmenu, maskBounds }) => {
  const bounds = (maskBounds.find(item => item.menu_number === richmenu.menu_number) || {}).bounds || [];

  return (
    <>
      <Divider />
      <h3>タブ{richmenu.menu_number}</h3>
      <ReferenceItem
        label="メニューバーのテキスト"
        value={richmenu?.chat_bar_text}
      />

      <div style={{ marginTop: '16px' }}></div>
      <label className="image-preview-label">
        プレビュー
      </label>
      <Layout>
        <Sider width={280}>
          <PreviewSVG
            imageHeight={richmenu.size?.height}
            richmenuImage={richmenu.image_url}
            maskBounds={bounds}
            chatBarText={richmenu.chat_bar_text}
          />
        </Sider>
        <Content>
          <div className="image-area">
            <label className="pre-label">
              画像
            </label>
            <div className="richmenu-file-upload-area">
              <AntImage
                src={richmenu.image_url}
                width={180}
                height={160}
                preview={false}
              />
            </div>
          </div>
          <div className="image-area">
            <label className="pre-label action-label">
              アクション
            </label>
            <ActionList
              areas={richmenu.areas}
            />
          </div>
        </Content>
      </Layout>
    </>
  );
}

const ActionList = ({ areas }) => {

  return (
    <div>
      {areas.map((area, index) => {
        const displayValues = [
          String.fromCharCode(65 + index),
          `：`,
          richmenuActionTypeOptions.find(r => r.value === area?.action.type)?.label,
        ];
        if (area?.action.type === 'url' || area?.action.type === 'tel') {
          displayValues.push(' - ' + area?.action.uri);
        } else if (area?.action.type === 'message') {
          displayValues.push(' - ' + area?.action.text);
        }

        return (
          <ReferenceItem
            value={displayValues.join('')}
          />
        );
      })}
    </div>

  );
};

export default LineRichmenuReferenceModal;
