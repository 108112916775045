import { useState } from 'react';
import { Modal } from 'antd';
import ModalTitle from '../../modalTitle';
import Button from '../../tcButton';
import './style.scss';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const BoundsSelectModal = ({ open, onCancel, onOk, orgSrc, bounds }) => {

  const [crop, setCrop] = useState(null);
  const [percentCrop, setPercentCrop] = useState(null);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);

  return (
    <Modal
      open={open}
      footer={null}
      closable={false}
      width={980}
      style={{ minWidth: 980 }}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      <div className="bounds-select-modal">
        <ModalTitle
          title={'領域設定'}
        />
        <div className="bounds-select-container">
          <div className="bounds-select-area">
            <ReactCrop
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={(crop, percentCrop) => {
                setCrop(crop);
                setPercentCrop(percentCrop);
              }}
            >
              <img
                src={orgSrc}
                alt=""
                onLoad={(event) => {
                  setImgWidth(event.target.offsetWidth);
                  setImgHeight(event.target.offsetHeight);
                }}
              />
              {bounds.map((maskBound, index) => (
                <MaskOverlay
                  key={index}
                  {...maskBound}
                  baseWidth={imgWidth}
                  baseHeight={imgHeight}
                  actionIndex={index}
                />
              ))}
            </ReactCrop>
          </div>

          <div className="deselection" >
            <button
              className="deselection-button"
              onClick={() => {
                crop && setCrop(null);
                percentCrop && setPercentCrop(null);
              }}
            >
              選択解除
            </button>
          </div>
        </div>

        <div className="buttons-row">
          <Button
            text="戻る"
            theme="white"
            onClick={() => {
              crop && setCrop(null);
              percentCrop && setPercentCrop(null);
              onCancel();
            }}
          />
          <Button
            text="適用"
            style={{ border: 'none' }}
            onClick={() => {
              onOk(percentCrop);
              crop && setCrop(null);
              percentCrop && setPercentCrop(null);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

const MaskOverlay = ({ x, y, width, height, baseWidth, baseHeight, actionIndex }) => {

  const overlayX = (baseWidth * x / 100);
  const overlayY = (baseHeight * y / 100);
  const overlayWidth = baseWidth * width / 100;
  const overlayHeight = baseHeight * height / 100;
  const centerX = (overlayWidth / 2);
  const centerY = (overlayHeight / 2);

  return (
    <svg style={{ position: 'absolute', left: overlayX, top: overlayY, width: overlayWidth, height: overlayHeight }}>
      <rect
        x={0}
        y={0}
        width={overlayWidth}
        height={overlayHeight}
        fill="black"
        fillOpacity="0.4"
        stroke="#9B233F"
        strokeWidth={4}
      />
      <text x={centerX} y={centerY} textAnchor="middle" alignmentBaseline="middle" fontSize="x-large" fill="white">
        {String.fromCharCode(65 + actionIndex)}
      </text>
    </svg>
  );
};

export default BoundsSelectModal;
