import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';

const initialSearchParams = {
  category_code: '',
  category_type: null,
  category_name: '',
  deleted_flg: 0,
  offset: 0,
  count: 10,
  sort: 'category_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  categoryList: [],
  total: 0,
  deleteCategory: null,
  editModalVisible: false,
  currentCategory: null,
  referenceCategory: null,
};

export const fetchCategoryList = createAsyncThunk(
  'fetchCategoryList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('settings/category/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchCategoryDetail = createAsyncThunk(
  'fetchCategoryDetail',
  async params =>
    await axios.get('settings/category/detail', {
      params: { category_code: params.category_code },
    })
);

export const fetchCategoryReference = createAsyncThunk(
  'fetchCategoryReference',
  async params =>
    await axios.get('settings/category/detail', {
      params: { category_code: params.category_code },
    })
);

export const fetchCategoryCreate = createAsyncThunk(
  'fetchCategoryCreate',
  async (params, { dispatch }) => {
    await axios.post('settings/category/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchCategoryUpdate = createAsyncThunk(
  'fetchCategoryUpdate',
  async (params, { dispatch }) => {
    console.log(`params = ${JSON.stringify(params)}`)
    await axios.post('settings/category/update', { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchCategoryDelete = createAsyncThunk(
  'fetchCategoryDelete',
  async params => await axios.post('settings/category/delete', { ...params })
);

export const categorySlice = createSlice({
  name: 'categorySlice',
  initialState,
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteCategory: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentCategory: null,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteCategory: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceCategory: null,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchCategoryList.fulfilled]: (state, { payload }) => ({
      ...state,
      total: payload?.total_count ?? 0,
      categoryList: [...payload?.item?.category_list],
    }),
    [fetchCategoryDetail.fulfilled]: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      currentCategory: {
        category_code: payload?.item?.category.category_code,
        category_type: payload?.item?.category.category_type,
        category_name: payload?.item?.category.category_name,
        display_order: payload?.item?.category.display_order,
      },
    }),
    [fetchCategoryReference.fulfilled]: (state, { payload }) => ({
      ...state,
      referenceCategory: {
        category_code: payload?.item?.category.category_code,
        category_type: payload?.item?.category.category_type,
        category_name: payload?.item?.category.category_name,
        display_order: payload?.item?.category.display_order,
      },
    }),
    [fetchCategoryCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
    }),
    [fetchCategoryUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      currentCategory: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchCategoryDelete.fulfilled]: state => ({
      ...state,
      deleteCategory: null,
      searchParams: { ...state.searchParams },
    }),
  },
});

export const categoryActions = categorySlice.actions;

export default categorySlice.reducer;
