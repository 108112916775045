import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { lineRichmenuActions, fetchLineRichmenuCreate, fetchLineRichmenuUpdate } from '../../slices/lineRichmenuSlice';
import { fetchLineAccountListForSelect } from '../../slices/lineAccountSlice';
import { globalActions } from '../../slices/globalSlice';
import { Modal, Image as AntImage, Divider, Layout } from 'antd';
import { mapValues } from 'lodash';
import moment from 'moment';
import ModalTitle from '../modalTitle';
import Button from '../tcButton';
import Yup from '../../utils/yupUtil';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import TcRadio from '../tcCheckbox/tcRadio';
import TcCheckbox from '../../components/tcCheckbox';
import { richmenuDisplayOptions, richmenuTabOptions } from '../../constants/options';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import RichmenuImageUpload from './richmenuImageUpload';
import PreviewSVG from './previewSVG';
import ActionList from './actionList';
import Icons from '../../constants/Icons';
import Icon from '@ant-design/icons';
import './style.scss';

const { Sider, Content } = Layout;

/* LINEリッチメニュー登録/更新/複製モーダル */

const LineRichmenuModal = () => {
  const dispatch = useDispatch();

  const { editModalVisible, currentLineRichmenu, crop } = useSelector(state => state.lineRichmenu);
  const { lineAccountOptionList } = useSelector(state => state.lineAccount);

  const isCreate = !currentLineRichmenu;
  const isUpdate = !!currentLineRichmenu?.menu_code;

  const initialRichmenu = {
    menu_number: 1,
    chat_bar_text_flag: 0,
    chat_bar_text: '',
    image_url: null,
    size: null,
    areas: [],
  };
  const initialValues = {
    menu_code: null,
    menu_name: null,
    account_code: null,
    start_date: null,
    start_hour: null,
    start_minute: null,
    end_date: '2099/12/31',
    end_hour: '00',
    end_minute: '00',
    selected: 1,
    tab_count: 1,
    richmenus: Array.from({ length: 2 }, (_, i) => ({
      ...initialRichmenu,
      menu_number: i + 1,
    })),
  };

  // リッチメニューのバリデーションスキーマ
  const richmenuSchema = Yup.object().shape({
    chat_bar_text: Yup.string().when('chat_bar_text_flag', {
      is: 1,
      then: Yup.string().required().max(14),
      otherwise: Yup.string().nullable(),
    }),
    image_url: Yup.string().nullable().required(),
    size: Yup.object().nullable().shape({
      width: Yup.number().oneOf([2500]).required(),
      height: Yup.number().oneOf([1686, 843]).required(),
    }),
    areas: Yup.array().min(1).max(20).of(
      Yup.object().shape({
        bounds: Yup.object().shape({
          x: Yup.number().required(),
          y: Yup.number().required(),
          width: Yup.number().required(),
          height: Yup.number().required(),
        }),
        action: Yup.object().shape({
          type: Yup.string().required(),
          uri: Yup.string()
            .when('type', {
              is: 'url',
              then: Yup.string().nullable().required().url(),
              otherwise: Yup.string().nullable(),
            })
            .when('type', {
              is: 'tel',
              then: Yup.string().nullable().required().matches(/^[0-9]+$/, '数値で入力してください'),
              otherwise: Yup.string().nullable(),
            }),
          text: Yup.string().when('type', {
            is: 'message',
            then: Yup.string().nullable().required(),
            otherwise: Yup.string().nullable(),
          }),
          display_mode: Yup.string().when('type', {
            is: 'url',
            then: Yup.string().nullable().required(),
            otherwise: Yup.string().nullable(),
          }),
        }),
      })
    ),
  });

  const yupObject = Yup.object({
    menu_name: Yup.string().nullable().required().max(30),
    account_code: Yup.string().nullable().required(),
    start_date: Yup.string()
      .nullable()
      .test(
        'start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.start_hour &&
          testContext.parent.start_minute
      ),
    end_date: Yup.string()
      .nullable()
      .test(
        'end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.end_hour &&
          testContext.parent.end_minute
      ),
    selected: Yup.string().nullable().required(),
    tab_count: Yup.number().oneOf([1, 2]).required(),
    // richmenus: Yup.array().of(richmenuSchema),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate: values => {
      let errors = {};
      const objectsToValidate = values.richmenus.slice(0, values.tab_count);
      const richmenusErrors = objectsToValidate.map((obj, index) => {
        try {
          richmenuSchema.validateSync(obj, { abortEarly: false });
        } catch (err) {
          return err.inner.reduce((acc, curr) => {
            acc[curr.path] = curr.message;
            return acc;
          }, { index });
        }
        return undefined;
      }).filter(Boolean);

      if (richmenusErrors.length > 0) {
        errors.richmenus = richmenusErrors;
      }
      return errors;
    },
    validationSchema: yupObject,
    onSubmit: async values => {
      const {
        start_date,
        start_hour,
        start_minute,
        end_date,
        end_hour,
        end_minute,
        ...rest
      } = values;
      const startPeriod = moment(start_date)
        .set('h', Number(start_hour))
        .set('m', Number(start_minute));
      const endPeriod = moment(end_date)
        .set('h', Number(end_hour))
        .set('m', Number(end_minute));

      // 表示期間前後チェック
      if (!startPeriod.isBefore(endPeriod) || startPeriod.isSame(endPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              '表示開始日時は表示終了日時より前の日時を入力してください',
          })
        );
        return;
      }

      // 新規登録時、過去日時チェック
      const now = moment();
      if (!isUpdate && (startPeriod.isBefore(now) || endPeriod.isBefore(now))) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              '未来日時で登録してください',
          })
        );
        return;
      }

      // タブ数が1の場合、タブ切り替えは選択できない
      let isTabSwitch = false;
      if (rest.tab_count === 1) {
        values.richmenus.slice(0, values.tab_count).forEach(richmenu => {
          richmenu.areas.forEach(area => {
            if (area.action.type === 'richmenuswitch') {
              isTabSwitch = true;
            }
          });
        });
      }
      if (isTabSwitch) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'タブ数が1の場合、タブ切り替えは選択できません',
          })
        );
        return;
      }

      const params = {
        ...rest,
        start_date: startPeriod.format('YYYY-MM-DD HH:mm:ss'),
        end_date: endPeriod.format('YYYY-MM-DD HH:mm:ss'),
        richmenus: values.richmenus.slice(0, values.tab_count).map(richmenu => {
          const modifiedAreas = richmenu.areas.map(area => {
            if (area.action.type === "url" && area.action.display_mode === 0) {
              const url = new URL(area.action.uri);
              url.searchParams.append('openExternalBrowser', '1');
              return { ...area, action: { ...area.action, uri: url.toString() } };
            }
            return area;
          });
          return {
            ...richmenu,
            chat_bar_text: richmenu.chat_bar_text_flag === 1 ? richmenu.chat_bar_text : 'メニュー',
            areas: modifiedAreas,
          };
        }),
      };
      dispatch(isUpdate ? fetchLineRichmenuUpdate(params) : fetchLineRichmenuCreate(params))
    },
  });

  const { menu_code, tab_count, richmenus } = formik.values;

  useUpdateEffect(() => {
    currentLineRichmenu &&
      formik
        .setValues({
          ...currentLineRichmenu,
        })
        .then(() => formik.setTouched(mapValues(yupObject, () => true)))
        .then(() => formik.validateForm());
  }, [currentLineRichmenu]);

  useUpdateEffect(() => {
    !editModalVisible && formik.resetForm();
    formik.validateForm();
  }, [editModalVisible]);

  useEffect(
    () => editModalVisible && dispatch(fetchLineAccountListForSelect()),
    [editModalVisible, dispatch]
  );

  // for debug
  // useEffect(() => {
  //   console.log('formik.values >>>>>', formik.values)
  //   console.log('formik.errors >>>>>', formik.errors);
  //   console.log('formik.isValid >>>>>', formik.isValid);
  // }, [formik.errors, formik.isValid, formik.values]);

  const onBack = () => {
    dispatch(lineRichmenuActions.closeEditModal());
    formik.resetForm();
  };

  return (
    <Modal
      className={'line-richmenu-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={
            isCreate
              ? 'リッチメニュー登録'
              : isUpdate
                ? 'リッチメニュー編集'
                : 'リッチメニュー登録(複製)'
          }
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          <h3>基本設定</h3>
          {isUpdate && (
            <div className="text-area">
              <label>メニューCD</label>
              <span>{menu_code}</span>
            </div>
          )}
          <TextBox
            formik={formik}
            preLabel="メニュー名"
            fieldName="menu_name"
            width={'100%'}
            isRequired
          />
          <SelectBox
            formik={formik}
            fieldName="account_code"
            preLabel="公式アカウント名"
            width={'100%'}
            options={lineAccountOptionList}
            isRequired
            disabled={isUpdate}
          />
          <DateTimeRangePicker
            formik={formik}
            preLabel={'表示期間'}
            fromFieldName={'start_date'}
            fromHourFieldName={'start_hour'}
            fromMinuteFieldName={'start_minute'}
            toFieldName={'end_date'}
            toHourFieldName={'end_hour'}
            toMinuteFieldName={'end_minute'}
            isRequired
            width={'100%'}
            minuteStep={30}
          />
          <span className="cus-input-line input-checkbox">
            <TcRadio
              formik={formik}
              label={'メニューのデフォルト表示'}
              isRadio={true}
              fieldName="selected"
              width={170}
              options={richmenuDisplayOptions}
              required={true}
              onChange={v =>
                handlerFormikFieldChange(formik, 'selected', v)
              }
            />
          </span>

          <Divider />

          <h3 className="contents-setting-row input-title">
            <div className="contents-setting-title">
              コンテンツ設定
            </div>
            <div className="contents-setting-tip">
              <span>
                2タブの場合、タブ切り替えの設定が必要になります
              </span>
              <span>
                各タブの領域を設定しアクションでタブ切り替え/設定しないを選択ください
              </span>
            </div>
          </h3>
          <span className="cus-input-line input-checkbox">
            <TcRadio
              formik={formik}
              label={'タブ数'}
              isRadio={true}
              fieldName="tab_count"
              width={170}
              options={richmenuTabOptions}
              required={true}
              onChange={v => {
                handlerFormikFieldChange(formik, 'tab_count', v);
              }}
            />
          </span>

          {richmenus.map((_, index) => (
            index < tab_count && (
              <RichmenuTab
                key={index}
                formik={formik}
                richmenuIndex={index}
                maskBounds={crop}
              />
            )
          ))}

          <div className="button-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            <Button
              text={isUpdate ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal >
  );
};

const RichmenuTab = ({ formik, richmenuIndex, maskBounds }) => {
  const richmenu = formik.values.richmenus[richmenuIndex];
  const richmenuFieldName = `richmenus[${richmenuIndex}]`;
  const bounds = (maskBounds.find(item => item.menu_number === richmenu.menu_number) || {}).bounds || [];

  const dispatch = useDispatch();

  const showError = message => {
    dispatch(
      globalActions.showErrorModal({
        title: '読み取りエラー',
        message,
      })
    );
  };

  return (
    <>
      <Divider />
      <h3>タブ{richmenu.menu_number}</h3>
      <div className="check-area">
        <span
          className={'pre-label label-required'}
        >
          メニューバーのテキスト
        </span>
        <div className="menubar-container">
          <div className="input-line">
            <TcCheckbox
              label={'メニュー'}
              checked={richmenu.chat_bar_text_flag === 0}
              onChange={() =>
                handlerFormikFieldChange(formik, `${richmenuFieldName}.chat_bar_text_flag`, 0)
              }
              isRadio={true}
            />
          </div>
          <div className="input-line">
            <TcCheckbox
              label={'その他のテキスト'}
              checked={richmenu.chat_bar_text_flag === 1}
              onChange={() =>
                handlerFormikFieldChange(formik, `${richmenuFieldName}.chat_bar_text_flag`, 1)
              }
              isRadio={true}
            />
            <TextBox
              formik={formik}
              fieldName={`${richmenuFieldName}.chat_bar_text`}
              placeholder="テキストを入力してください"
              width={240}
              hasLabel={false}
              style={{ marginLeft: '10px' }}
              disabled={richmenu.chat_bar_text_flag === 0}
            />
            <span className="tc-select-error">
              {formik.touched?.richmenus?.[richmenuIndex]?.chat_bar_text &&
                formik.errors?.richmenus?.[richmenuIndex]?.chat_bar_text}
            </span>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '16px' }}></div>
      <label className="image-preview-label">
        プレビュー
      </label>
      <Layout>
        <Sider width={280}>
          <PreviewSVG
            imageHeight={richmenu.size?.height}
            richmenuImage={richmenu.image_url}
            maskBounds={bounds}
            chatBarText={richmenu.chat_bar_text_flag === 1 ? richmenu.chat_bar_text : 'メニュー'}
          />
        </Sider>
        <Content>
          <div className="image-area">
            <label className="pre-label label-required">
              画像
            </label>
            <div className="richmenu-file-upload-area">
              {richmenu.image_url ? (
                <>
                  <Icon
                    component={Icons.IconXCircleWh}
                    className="close-icon"
                    onClick={() => {
                      handlerFormikFieldChange(formik, `${richmenuFieldName}.image_url`, null);
                    }}
                  />
                  <AntImage
                    src={richmenu.image_url}
                    width={180}
                    height={160}
                    preview={false}
                  />
                </>
              ) : (
                <>
                  <div className="upload-tip">
                    <div className="upload-format">
                      <span>png,jpg形式のみアップロード可</span>
                      <span>画像は1M以下でアップロードしてください</span>
                    </div>
                    <div className="upload-size">
                      <span>アップロード画像サイズ</span>
                      <span>大　横:2,500px 縦:1,686px</span>
                      <span>小　横:2,500px 縦:843px</span>
                    </div>
                  </div>
                  <RichmenuImageUpload
                    onChange={(imageUrl) => {
                      const img = new Image();
                      img.src = imageUrl;
                      img.onload = function () {
                        const width = this.width;
                        const height = this.height;
                        const isSizeValid = (height === 1686 && width === 2500) || (height === 843 && width === 2500);
                        if (!isSizeValid) {
                          showError('横：2,500px 縦：1,686px または 横：2,500px 縦：843px の画像をアップロードしてください');
                        } else {
                          const size = {
                            width: width,
                            height: height,
                          }
                          handlerFormikFieldChange(formik, `${richmenuFieldName}.image_url`, imageUrl)
                          handlerFormikFieldChange(formik, `${richmenuFieldName}.size`, size)
                        }
                      };
                    }}
                  >
                    <Button text="画像を追加" theme="white" />
                  </RichmenuImageUpload>
                </>
              )}
            </div>
          </div>
          <div className="image-area">
            <label className="pre-label label-required action-label">
              アクション
            </label>
            <ActionList
              formik={formik}
              menuNumber={richmenu.menu_number}
            />
          </div>
        </Content>
      </Layout>
    </>
  );
}

export default LineRichmenuModal;
