import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import { lineRichmenuActions } from '../../../slices/lineRichmenuSlice';
import Button from '../../tcButton';
import TextBox from '../../util/textBox';
import TcRadio from '../../tcCheckbox/tcRadio';
import SelectBox from '../../util/selectBox';
import BoundsSelectModal from '../boundsSelectModal';
import DeleteModal from '../deleteModal';
import { richmenuActionTypeOptions } from '../../../constants/options';
import Icons from '../../../constants/Icons';
import { Card, Input } from 'antd';
import './style.scss';

const { TextArea } = Input;

/* LINEリッチメニュー領域アクション設定リスト */
const ActionList = ({ formik, menuNumber }) => {
  const [boundsSelectModalVisible, setBoundsSelectModalVisible] = useState(false);
  const [deleteActionIndex, setDeleteActionIndex] = useState(null);
  const { crop } = useSelector(state => state.lineRichmenu);
  const dispatch = useDispatch();

  const richmenu = formik.values?.richmenus?.find(menu => menu.menu_number === menuNumber);
  const areas = richmenu?.areas || [];
  const richmenuIndex = menuNumber - 1;

  const initialAction = {
    type: 'url',
    uri: null,
    text: null,
  };

  const updateAreaBounds = percentCrop => {
    if (!percentCrop) return;
    const bounds = {
      x: richmenu.size.width * (percentCrop.x / 100),
      y: richmenu.size.height * (percentCrop.y / 100),
      width: richmenu.size.width * (percentCrop.width / 100),
      height: richmenu.size.height * (percentCrop.height / 100),
    };
    handlerFormikFieldChange(formik, `richmenus.${richmenuIndex}.areas`, [
      ...areas,
      { bounds: bounds, action: initialAction },
    ]);
  };

  return (
    <>
      <div className="action-list-area">
        {areas.map((_, i) => (
          <React.Fragment key={i}>
            <Action
              formik={formik}
              title={String.fromCharCode(65 + i)}
              richmenuIndex={richmenuIndex}
              areaIndex={i}
              onRemove={() => {
                setDeleteActionIndex(i);
              }}
            />
          </React.Fragment>
        ))}
        <div className="tc-input-error-wrapper">
          <span className="tc-input-error">
            {(() => {
              const touched = formik.touched.richmenus?.[richmenuIndex]?.areas;
              const richmenuErrors = formik.errors.richmenus?.find(item => item.index === richmenuIndex);
              const error = richmenuErrors ? richmenuErrors[`areas`] : undefined;
              return touched && error;
            })()}
          </span>
        </div>
        <div className="add-btn-area">
          <Button
            text="領域を追加"
            theme="white"
            onClick={() => setBoundsSelectModalVisible(true)}
            disabled={!richmenu.image_url}
          />
        </div>
      </div>
      <BoundsSelectModal
        open={boundsSelectModalVisible}
        onCancel={() => setBoundsSelectModalVisible(false)}
        onOk={(percentCrop) => {
          setBoundsSelectModalVisible(false);
          updateAreaBounds(percentCrop);
          dispatch(lineRichmenuActions.addCrop({ menuNumber: menuNumber, newCrop: percentCrop, }));
        }}
        orgSrc={richmenu.image_url}
        bounds={crop.find(c => c.menu_number === menuNumber)?.bounds || []}
      />
      <DeleteModal
        open={deleteActionIndex != null}
        onCancel={() => {
          setDeleteActionIndex(null);
        }}
        onOk={() => {
          handlerFormikFieldChange(formik, `richmenus.${richmenuIndex}.areas`, areas.filter((_, index) => index !== deleteActionIndex));
          const payload = {
            menuNumber: menuNumber,
            index: deleteActionIndex,
          };
          dispatch(lineRichmenuActions.removeCrop(payload));
          setDeleteActionIndex(null);
        }
        }
      />
    </>
  );
};

/* LINEリッチメニュー領域アクション設定 */
const Action = ({ formik, title, richmenuIndex, areaIndex, onRemove }) => {
  const action = formik.values.richmenus?.[richmenuIndex].areas?.[areaIndex]?.action;
  const actionFieldName = `richmenus.${richmenuIndex}.areas.${areaIndex}.action`;

  return (
    <div className="action-area">
      <Card
        id={`action-areaIndex-${areaIndex}`}
        title={title}
        size="small"
        headStyle={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}
        extra={
          <Icons.IconXCircleWh
            className='icon-x'
            onClick={onRemove}
          />
        }
      >
        <TcRadio
          formik={formik}
          isRadio={true}
          fieldName={`${actionFieldName}.type`}
          width="auto"
          options={richmenuActionTypeOptions}
          required={true}
          cancelable={false}
          onChange={v => {
            handlerFormikFieldChange(formik, `${actionFieldName}.type`, v);
            if (action.type !== v) {
              handlerFormikFieldChange(formik, `${actionFieldName}.uri`, null);
              handlerFormikFieldChange(formik, `${actionFieldName}.text`, null);
            }
          }}
        />

        {action.type === 'url' && (
          <>
            <div className="tc-input-error-wrapper">
              <span className="tc-input-error">
                {(() => {
                  const touched = formik.touched.richmenus?.[richmenuIndex]?.areas?.[areaIndex]?.action.display_mode;
                  const richmenuErrors = formik.errors.richmenus?.find(item => item.index === richmenuIndex);
                  const error = richmenuErrors ? richmenuErrors[`areas[${areaIndex}].action.display_mode`] : undefined;
                  return touched && error;
                })()}
              </span>
            </div>
            <SelectBox
              formik={formik}
              fieldName={`${actionFieldName}.display_mode`}
              preLabel="表示方法"
              width={'100%'}
              options={[
                { label: 'LIFFブラウザ', value: 1 },
                { label: '外部ブラウザ', value: 0 },
              ]}
              hasLabel={false}
            />
            <div className="tc-input-error-wrapper">
              <span className="tc-input-error">
                {(() => {
                  const touched = formik.touched.richmenus?.[richmenuIndex]?.areas?.[areaIndex]?.action.uri;
                  const richmenuErrors = formik.errors.richmenus?.find(item => item.index === richmenuIndex);
                  const error = richmenuErrors ? richmenuErrors[`areas[${areaIndex}].action.uri`] : undefined;
                  return touched && error;
                })()}
              </span>
            </div>
            <TextBox
              formik={formik}
              preLabel="URL"
              fieldName={`${actionFieldName}.uri`}
              width={'100%'}
              hasLabel={false}
              placeholder={`URLを入力してください`}
            />
          </>
        )}
        {action.type === 'tel' && (
          <>
            <div className="tc-input-error-wrapper">
              <span className="tc-input-error">
                {(() => {
                  const touched = formik.touched.richmenus?.[richmenuIndex]?.areas?.[areaIndex]?.action.uri;
                  const richmenuErrors = formik.errors.richmenus?.find(item => item.index === richmenuIndex);
                  const error = richmenuErrors ? richmenuErrors[`areas[${areaIndex}].action.uri`] : undefined;
                  return touched && error;
                })()}
              </span>
            </div>
            <TextBox
              formik={formik}
              fieldName={`${actionFieldName}.uri`}
              width={'100%'}
              hasLabel={false}
              placeholder={`0312345678(ハイフンなし)`}
            />
          </>
        )}
        {action.type === 'message' && (
          <>
            <div>
              <div className="tc-input-error-wrapper">
                <span className="tc-input-error">
                  {(() => {
                    const touched = formik.touched.richmenus?.[richmenuIndex]?.areas?.[areaIndex]?.action.text;
                    const richmenuErrors = formik.errors.richmenus?.find(item => item.index === richmenuIndex);
                    const error = richmenuErrors ? richmenuErrors[`areas[${areaIndex}].action.text`] : undefined;
                    return touched && error;
                  })()}
                </span>
              </div>
              <TextArea
                showCount
                maxLength={50}
                style={{
                  height: 50,
                  resize: 'none',
                }}
                value={action.text || ''}
                onChange={e =>
                  handlerFormikFieldChange(formik, `${actionFieldName}.text`, e.target.value)
                }
                placeholder="入力してください"
              />
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default ActionList;
