import axios from '../utils/axiosUtil';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import moment from 'moment';

const initialSearchParams = {
  stamp_code: '',
  stamp_name: '',
  display_start_date: null,
  display_end_date: null,
  valid_days_flag: null,
  brand_code: [],
  shop_code: [],
  coupon_code: '',
  deleted_flg: 0,
  offset: 0,
  count: 10,
  sort: 'stamp_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  stampList: [],
  total: 0,
  deleteModalVisible: false,
  deletestamp: null,
  stampModalVisible: false,
  referenceModalVisible: false,
  currentstamp: null,
  stampCouponList: [],
  stampTemplateList: [],
};

export const fetchStampList = createAsyncThunk(
  'fetchStampList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('/stamp/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchStampCopy = createAsyncThunk(
  'fetchStampCopy',
  async params => await axios.get('/stamp/detail', { params })
);

export const fetchStampDetail = createAsyncThunk(
  'fetchStampDetail',
  async params => await axios.get('/stamp/detail', { params })
);

export const updateStamp = createAsyncThunk(
  'updateStamp',
  async (params, { dispatch, getState }) => {
    await axios.post(`/stamp/${params.isCreate ? 'create' : 'update'}`, params);

    dispatch(fetchStampList(getState().stamp.searchParams));

    dispatch(
      globalActions.showSingleModal(
        `${params.isCreate ? '登録' : '更新'}が完了しました`
      )
    );
  }
);

export const deleteStamp = createAsyncThunk(
  'deleteStamp',
  async (params, { dispatch, getState }) => {
    await axios.post('/stamp/delete', params);

    dispatch(fetchStampList(getState().stamp.searchParams));
  }
);

export const fetchStampReference = createAsyncThunk(
  'fetchStampReference',
  async params => await axios.get('/stamp/detail', { params })
);

export const fetchStampExtraInfo = createAsyncThunk(
  'fetchStampExtraInfo',
  async () => {
    const [stampCouponRes, stampTemplateRes] = await Promise.all([
      axios.get('/coupon/list', {
        params: {
          coupon_type: '1',
        },
      }),
      axios.get('/settings/template/list', {
        params: {
          template_type: 2,
        },
      }),
    ]);

    console.log(stampTemplateRes)

    return {
      stampCouponList: (stampCouponRes.item ?? []).map(t => ({
        ...t,
        label: t.coupon_name,
        value: t.coupon_code,
      })),
      stampTemplateList: (stampTemplateRes.item ?? []).map(t => ({
        ...t,
        label: t.template_title,
        value: t.template_code,
        template_text: t.template_text,
      })),
    };
  }
);

export const stampSlice = createSlice({
  name: 'stampSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    setStampModalVisible: (state, { payload }) => {
      state.stampModalVisible = payload;
    },
    resetStampModal: state => {
      state.stampModalVisible = false;
      state.stampDetail = null;
      state.stampReferenceModalVisible = false;
      state.stampReference = null;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [fetchStampList.fulfilled]: (state, { payload }) => {
      state.stampList =
        payload.item.map(e => ({
          ...e,
          brands: Array.from(
            new Map(e.brands.map(brand => [brand.brand_code, brand])).values()
          ),
        })) ?? [];
      state.total = payload.total_count;
    },
    [fetchStampCopy.fulfilled]: (state, { payload }) => {
      const detail = adjustCopyItem(payload.item);
      state.stampModalVisible = true;
      state.stampDetail = detail;
    },
    [fetchStampDetail.fulfilled]: (state, { payload }) => {
      const detail = adjustDetailItem(payload.item);
      state.stampModalVisible = true;
      state.stampDetail = detail;
    },
    [updateStamp.fulfilled]: state => {
      state.stampModalVisible = false;
    },
    [fetchStampReference.fulfilled]: (state, { payload }) => {
      const detail = adjustDetailItem(payload.item);
      state.stampReferenceModalVisible = true;
      state.stampReference = detail;
    },

    [fetchStampExtraInfo.fulfilled]: (state, { payload }) => {
      state.stampCouponList = payload.stampCouponList.filter(
        e => e.stamp_linkage_flg === '0' && e.extracted_flag !== '1'
      );
      state.stampTemplateList = payload.stampTemplateList;
    },
  },
});

const adjustCopyItem = item => {
  const {
    stamp_code,
    coupon_code,
    coupon_name,
    brands,
    shops,
    valid_days_flag,
    display_start_date,
    display_end_date,
    valid_days,
    stamp_rich_text,
    ...rest
  } = item ?? {};

  const startDate = moment(display_start_date);

  const endDate = moment(display_end_date);

  return {
    ...rest,
    shop_list: shops ?? [],
    brand_list: brands ?? [],
    valid_days_flag,
    valid_days,
    valid_days1: valid_days_flag === '0' ? valid_days : null,
    valid_days2: valid_days_flag === '1' ? valid_days : null,
    use_date_from: startDate.format('YYYY/M/D'),
    use_hour_from: startDate.format('HH'),
    use_minute_from: startDate.format('mm'),
    use_date_to: endDate.format('YYYY/M/D'),
    use_hour_to: endDate.format('HH'),
    use_minute_to: endDate.format('mm'),
    stamp_rich_text: stamp_rich_text
      ? stamp_rich_text
          .split('-----separator-----')
          .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
      : [],
  };
};

const adjustDetailItem = item => {
  const {
    brands,
    shops,
    valid_days_flag,
    display_start_date,
    display_end_date,
    valid_days,
    stamp_rich_text,
    ...rest
  } = item ?? {};

  const startDate = moment(display_start_date);

  const endDate = moment(display_end_date);

  return {
    ...rest,
    shop_list: shops ?? [],
    brand_list: brands ?? [],
    valid_days_flag,
    valid_days,
    valid_days1: valid_days_flag === '0' ? valid_days : null,
    valid_days2: valid_days_flag === '1' ? valid_days : null,
    use_date_from: startDate.format('YYYY/M/D'),
    use_hour_from: startDate.format('HH'),
    use_minute_from: startDate.format('mm'),
    use_date_to: endDate.format('YYYY/M/D'),
    use_hour_to: endDate.format('HH'),
    use_minute_to: endDate.format('mm'),
    stamp_rich_text: stamp_rich_text
      ? stamp_rich_text
          .split('-----separator-----')
          .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
      : [],
  };
};

export const stampActions = stampSlice.actions;

export default stampSlice.reducer;
