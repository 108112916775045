import { memo } from 'react';
import classNames from 'classnames';
import { isWindows, isChrome } from 'react-device-detect';
import './style.scss';

/* 汎用ボタン */

const TcButton = memo(
  ({
    width,
    theme = 'black',
    type = 'button',
    fontSize,
    text,
    style = {},
    onClick,
    disabled,
    icon,
    ...restProps
  }) => {
    const className = classNames('tc-btn', {
      [`tc-btn-${width}`]: width,
      [`tc-btn-${theme}`]: theme,
      [`tc-btn-${fontSize}`]: fontSize,
      [`tc-btn-disabled`]: disabled,
    });
    return (
      <button
        disabled={disabled}
        contentEditable={false}
        className={className}
        type={type}
        style={style}
        onClick={onClick}
        {...restProps}
      >
        {icon}
        <span
          className={classNames({
            'ad-pad': isWindows && isChrome,
          })}
        >
          {text}
        </span>
      </button>
    );
  },
  (pre, next) =>
    pre.disabled === next.disabled &&
    pre.text === next.text &&
    pre.onClick === next.onClick
);

export default TcButton;
