import axios from '../utils/axiosUtil';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';

const initialSearchParams = {
  select_option_code: '',
  update_label: '',
  field_name: null,
  label: null,
  display_order: null,
  sort: 'select_option_code_desc',
  count: 10,
  offset: 0,
};

const initialValues = {
  update_label: null,
  display_order: null,
};

const initialState = {
  searchParams: initialSearchParams,
  initialValues,
  selectOptionList: [],
  nameList: [],
  total: 0,
  currentName: null,
  nameModalVisible: false,
  deleteName: null,
};

export const fetchNameList = createAsyncThunk('fetchNameList', async params => {
  const [nameRes, selectOptionRes] = await Promise.all([
    axios.get('settings/name/list', { params }),
    axios.get('settings/select-option/list'),
  ]);

  return {
    nameList: nameRes?.item ?? [],
    total_count: nameRes?.total_count,
    selectOptionList: selectOptionRes?.item ?? [],
  };
});

export const fetchNameDetail = createAsyncThunk(
  'fetchNameDetail',
  async params => await axios.get('settings/name/detail', { params })
);

export const fetchNameUpdate = createAsyncThunk(
  'fetchNameUpdate',
  async (params, { dispatch }) => {
    await axios.post(`settings/name/update`, { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchNameReset = createAsyncThunk(
  'fetchNameDelete',
  async params => await axios.post('settings/name/reset', { ...params })
);

export const fetchSelectOption = createAsyncThunk(
  'fetchSelectOption',
  async params => await axios.get('settings/select-option/list', {params})
);

export const nameSlice = createSlice({
  name: 'name',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    openEditModal: state => ({
      ...state,
      nameModalVisible: true,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteName: payload,
    }),
    resetNameModal: state => ({
      ...state,
      nameModalVisible: false,
      currentName: null,
      deleteName: null,
    }),
    reset: () => initialState,
  },
  extraReducers: {
    [fetchNameList.fulfilled]: (state, { payload }) => {
      state.nameList = payload.nameList;
      state.total = payload.total_count;
      state.selectOptionList = payload.selectOptionList;
    },
    [fetchNameDetail.fulfilled]: (state, { payload }) => {
      const { ...rest } = payload.item ?? {};
      state.currentName = {
        ...rest,
      };
      state.nameModalVisible = true;
    },
    [fetchNameUpdate.fulfilled]: state => ({
      ...state,
      nameModalVisible: false,
      currentName: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchNameReset.fulfilled]: state => ({
      ...state,
      deleteName: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchSelectOption.fulfilled]: (state, { payload }) => {
      state.selectOptionList = payload.item ?? [];
    },
  },
});

export const nameActions = nameSlice.actions;
export default nameSlice.reducer;
