import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { Modal, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from '../../../slices/globalSlice';
import './style.scss';

/* 配信内容プレビューモーダル */

const PreviewModal = () => {
  const dispatch = useDispatch();

  const [html, setHtml] = useState([]);

  const { previewModalVisible, previewModalContents } = useSelector(
    state => state.global
  );

  useUpdateEffect(() => {
    const contentsText = [];
    let linkFlg = false;
    let contentsReplace = previewModalContents.text.replace(/<br>/g, '<br />');
    contentsReplace = contentsReplace.replace(/\r\n/g, '<br />');
    contentsReplace = contentsReplace.replace(/\n/g, '<br />');
    contentsReplace = contentsReplace.replace(
      new RegExp(`%image%`, 'g'),
      `<br />%image1%<br />`
    );
    for (let i = 1; i <= 10; i++) {
      contentsReplace = contentsReplace.replace(
        new RegExp(`%image${i}%`, 'g'),
        `<br />%image${i}%<br />`
      );
    }
    contentsReplace = contentsReplace.replace(/%html%/g, '<br />%html%<br />');
    contentsReplace = contentsReplace.replace(
      /%\/html%/g,
      '<br />%/html%<br />'
    );
    contentsReplace = contentsReplace.replace(/<br \/><br \/>%/g, '<br />%');
    contentsReplace = contentsReplace.replace(/%<br \/><br \/>/g, '%<br />');

    const convertContents = contentsReplace.split('<br />');
    convertContents?.forEach(data => {
      if (data === '%image%' && previewModalContents?.images?.[0]) {
        contentsText.push(imageElement(previewModalContents?.images?.[0]));
      } else if (data.indexOf('%image') === 0) {
        for (let i = 1; i <= 10; i++) {
          const image = imageAdd(data, i);
          image && contentsText.push(image);
        }
      } else if (data === '%html%') {
        linkFlg = true;
      } else if (data === '%/html%') {
        linkFlg = false;
      } else {
        if (linkFlg) {
          if (data.indexOf('<a') === 0) {
            data = data.replace(/<a/, '<a target="_brank" ');
          }
          contentsText.push(<div dangerouslySetInnerHTML={{ __html: data }} />);
        } else {
          contentsText.push(<div>{data}</div>);
        }
      }
    });
    setHtml(contentsText);
  }, [previewModalContents]);

  const imageAdd = (data, index) => {
    if (
      data === `%image${index}%` &&
      previewModalContents?.images?.[index - 1]
    ) {
      return imageElement(previewModalContents?.images?.[index - 1]);
    }
  };

  const imageElement = image => {
    return (
      <Image
        src={image}
        width={'100%'}
        height={'100%'}
        preview={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    );
  };

  const onBack = () => {
    dispatch(globalActions.closePreviewModal());
  };

  return (
    <Modal
      className={'preview-modal'}
      visible={previewModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={375}
      style={{ minWidth: 375 }}
      onCancel={onBack}
    >
      <div className="preview-modal-content">{html?.map(e => e)}</div>
    </Modal>
  );
};

export default PreviewModal;
