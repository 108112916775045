import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import axios from '../utils/axiosUtil';

const initialSearchParams = {
  account_code: '',
  account_name: '',
  provider_id: '',
  channel_id: '',
  channel_access_token: '',
  delete_flag: 0,
  offset: 0,
  count: 10,
  sort: 'account_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,
  editModalVisible: false,
  referenceModalVisible: false,
  currentLineAccount: null,
  deleteLineAccount: null,
  referenceLineAccount: null,
  lineAccountList: [],
  lineAccountOptionList: [],
};

export const fetchLineAccountList = createAsyncThunk(
  'fetchLineAccountList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('line/account/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchLineAccountListForSelect = createAsyncThunk(
  'fetchLineAccountListForSelect',
  async () => await axios.get('line/account/list')
);

export const fetchLineAccountDetail = createAsyncThunk(
  'fetchLineAccountDetail',
  async params =>
    await axios.get('line/account/detail', {
      params: { account_code: params.account_code },
    })
);

export const fetchLineAccountReference = createAsyncThunk(
  'fetchLineAccountReference',
  async params =>
    await axios.get('line/account/detail', {
      params: { account_code: params.account_code },
    })
);

export const fetchLineAccountCreate = createAsyncThunk(
  'fetchLineAccountCreate',
  async (params, { dispatch }) => {
    await axios.post('line/account/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchLineAccountUpdate = createAsyncThunk(
  'fetchLineAccountUpdate',
  async (params, { dispatch }) => {
    await axios.post('line/account/update', { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchLineAccountDelete = createAsyncThunk(
  'fetchLineAccountDelete',
  async params => await axios.post('line/account/delete', { ...params })
);

export const lineAccountSlice = createSlice({
  name: 'lineAccountSlice',
  initialState,
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteLineAccount: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentLineAccount: null,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteLineAccount: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceModalVisible: false,
      currentLineAccount: null,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchLineAccountList.fulfilled]: (state, { payload }) => ({
      ...state,
      total_count: payload?.total_count ?? 0,
      total: payload?.search_count ?? 0,
      lineAccountList: [
        ...payload?.item?.list.map(lineAccount => ({
          ...lineAccount,
        })),
      ],
    }),
    [fetchLineAccountDetail.fulfilled]: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      currentLineAccount: payload?.item,
    }),
    [fetchLineAccountReference.fulfilled]: (state, { payload }) => ({
      ...state,
      referenceModalVisible: true,
      referenceLineAccount: payload?.item,
    }),
    [fetchLineAccountCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
    }),
    [fetchLineAccountUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      currentLineAccount: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchLineAccountDelete.fulfilled]: state => ({
      ...state,
      deleteLineAccount: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchLineAccountListForSelect.fulfilled]: (state, { payload }) => ({
      ...state,
      lineAccountOptionList: [
        ...payload?.item?.list.map(lineAccount => ({
          label: lineAccount.account_name,
          value: lineAccount.account_code,
        })),
      ],
    }),
  },
});

export const lineAccountActions = lineAccountSlice.actions;

export default lineAccountSlice.reducer;
