import { Input, InputNumber, Select } from "antd";
import { handlerFormikFieldChange } from "../fnUtil";
import { DownOutlined, CheckOutlined } from "@ant-design/icons";
import classNames from "classnames";
import "./style.scss";

const GenernalInput = ({
  label,
  labelTextAlign = "center",
  labelWidth,
  customOnChange,
  displayValue,
  extraOnChange,
  requiredItem,
  styleType,
  formik,
  name,
  arrayName,
  arrayIndex,
  disabled = false,
  inputWidth = "middle",
  selectSize = "middle",
  mode = "multiple",
  placeholder,
  options = null,
  customStyle = {},
  textAfter,
  uneditable,
  inputType,
  className,
  min = 0,
  max = 100,
  step = 1,
  ...restProps
}) => {
  const wrapperClassName = classNames("nf-input-wrapper", className, {
    [`nf-input-wrapper-${selectSize}`]: selectSize,
    "nf-wrapper-uneditable": uneditable,
  });
  const labelClassName = classNames("nf-input-label", {
    [`text-align-${labelTextAlign}`]: labelTextAlign,
    [`label-width-${labelWidth}`]: labelWidth,
    "label-required": requiredItem,
  });
  const inputClassName = classNames("nf-input", {
    [`input-${styleType}`]: styleType,
    [`input-width-${inputWidth}`]: inputWidth,
  });

  const selectClassName = classNames("nf-select", {
    [`select-${styleType}`]: styleType,
  });

  const getValue = () => {
    return arrayName ? formik?.values?.[arrayName]?.[arrayIndex]?.[name] : formik?.values?.[name];
  };

  const getName = () => {
    return arrayName ? `${arrayName}[${arrayIndex}].${name}` : name;
  };

  const getError = () => {
    return arrayName ? formik?.errors?.[arrayName]?.[arrayIndex]?.[name] : formik?.errors?.[name];
  };

  const getTouch = () => {
    return arrayName ? formik?.touched?.[arrayName]?.[arrayIndex]?.[name] : formik?.touched?.[name];
  };

  return (
    <div className={wrapperClassName}>
      {label && <label className={labelClassName}>{label}</label>}
      {options === "step" 
        ? uneditable ? (
            <span className="input-value">
              {displayValue ?? formik.values[name]}
              {textAfter}
            </span>
          ) : (
            <>
              <InputNumber
                className={inputClassName}
                size={selectSize}
                autoComplete="off"
                min={min}
                max={max}
                step={step}
                placeholder={placeholder}
                style={customStyle} 
                disabled={disabled}
                onChange={customOnChange}
                value={formik?.values[name]}
              />
              {textAfter && <span className="nf-text-after">{textAfter}</span>}
            </>
          )
        : options ? (
        uneditable ? (
          <span className="input-value">
            {options.find(o => o.value === getValue())?.label}{" "}
            {textAfter}
          </span>
        ) : (
          <>
            <Select
              mode={mode}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onBlur={() => formik?.setFieldTouched(getName(), true)}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              maxTagCount="responsive"
              placeholder={placeholder}
              maxTagPlaceholder={"..."}
              className={selectClassName}
              value={getValue()}
              defaultActiveFirstOption={false}
              size={selectSize}
              suffixIcon={<DownOutlined className="ant-select-suffix" />}
              onChange={v => {
                if (customOnChange) {
                  customOnChange(v);
                } else {
                  handlerFormikFieldChange(formik, getName(), v ?? null);
                  extraOnChange?.(getName(), v);
                }
              }}
              allowClear={true}
              style={customStyle}
              showArrow={true}
              disabled={disabled}
              menuItemSelectedIcon={
                <CheckOutlined style={{ color: "black" }} />
              }
            >
              {options.map(o => (
                <Select.Option value={o.value} key={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
            {textAfter && <span className="nf-text-after">{textAfter}</span>}
          </>
        )
      ) : uneditable ? (
        <span className="input-value">
          {displayValue ?? getValue()}
          {textAfter}
        </span>
      ) : (
        <>
          <Input
            className={inputClassName}
            size={selectSize}
            autoComplete="off"
            type={inputType}
            placeholder={placeholder}
            style={customStyle}
            disabled={disabled}
            {...formik?.getFieldProps(getName())}
            {...restProps}
          />
          {textAfter && <span className="nf-text-after">{textAfter}</span>}
        </>
      )}
      {getError() && getTouch() && (
        <span className="nf-input-error">{getError()}</span>
      )}
    </div>
  );
};

export default GenernalInput;
