import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  messages: [],
  type: null,
  btnText: "閉じる",
  onCloseAction: null,
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    reset: () => initialState,
  },
});

export const actions = messageSlice.actions;

export default messageSlice.reducer;
