import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ja_JP from 'antd/es/locale/ja_JP';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/ja';
moment.locale('ja');

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ja_JP}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
