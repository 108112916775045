import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { lineAccountActions } from '../../slices/lineAccountSlice';
import ModalTitle from '../modalTitle';
import Button from '../tcButton';
import './style.scss';

/* LINE公式アカウント参照モーダル */

const LineAccountReferenceModal = () => {
  const dispatch = useDispatch();

  const { referenceModalVisible, referenceLineAccount } = useSelector(state => state.lineAccount);

  const initialValues = {
    account_code: null,
    account_name: null,
    provider_id: null,
    channel_id: null,
    channel_access_token: null,
  };

  const { account_code, account_name, provider_id, channel_id, channel_access_token } =
    referenceLineAccount || initialValues;

  const onBack = () => {
    dispatch(lineAccountActions.closeReferenceModal());
  };

  return (
    <Modal
      className={'line-account-modal'}
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
    >
      <div className="line-account-modal-content">
        <ModalTitle title={'LINE公式アカウント参照'} />
        <div>
          <div className="text-area">
            <label>公式アカウントCD</label>
            <span>{account_code}</span>
          </div>
          <div className="text-area">
            <label>公式アカウント名</label>
            <span>{account_name}</span>
          </div>
          <div className="text-area">
            <label>プロバイダID</label>
            <span>{provider_id}</span>
          </div>
          <div className="text-area">
            <label>チャネルID</label>
            <span>{channel_id}</span>
          </div>
          <div className="text-area">
            <label>チャネルアクセストークン</label>
            <span className="line-break-anywhere">{channel_access_token}</span>
          </div>
          <div className="button-container">
            <Button text="閉じる" theme="white" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LineAccountReferenceModal;
