import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { Table } from 'antd';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import {
  fetchQrList,
  fetchQrDetail,
  fetchQrReference,
  qrDelete,
  qrActions,
} from '../../slices/qrSlice';
import { qrColumns } from '../../constants/columns';
import Icons from '../../constants/Icons';
import TcButton from '../../components/tcButton';
import TcCheckbox from '../../components/tcCheckbox';
import ColumnTitle from '../../components/columnTitle';
import QRModal from '../../components/qrModal';
import QRReferenceModal from '../../components/qrModal/reference';
import DeleteModal from '../../components/modal/deleteModal';
import TableTitle from '../../components/tableTitle';
import QRImage from '../../components/qrImage';
import PageTitle from '../../components/pageTitle';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';

const QR = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const [displayQrCode, setDisplayQrCode] = useState([]);

  const { accountBrandList: brandList, accountShopList: shopList } =
    useSelector(state => state.account);

  const { searchParams, qrList, total, deleteModalVisible, deleteQr } =
    useSelector(state => state.qr);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: async values => dispatch(qrActions.saveSearchParams(values)),
  });

  useUpdateEffect(() => {
    setDisplayQrCode([]);
    dispatch(fetchQrList(searchParams));
  }, [searchParams]);

  useEffect(() => {
    dispatch(qrActions.saveSearchParams(null));
    return () => dispatch(qrActions.clear());
  }, [dispatch]);

  const columns = [
    ...qrColumns.map(col => {
      return col.sortField === 'qr_button'
        ? {
            title: (
              <ColumnTitle
                title={col.title}
                formik={formik}
                onClick={sort => dispatch(qrActions.saveSearchParams({ sort }))}
              />
            ),
            width: col.width,
            render: col.render,
            render: ({ qr_code, qr_name }) => {
              const isDisplay = displayQrCode.includes(qr_code);
              return (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TcButton
                      text={isDisplay ? '非表示' : '表示'}
                      theme="white"
                      style={{
                        width: 60,
                        minWidth: 60,
                        margin: 0,
                        marginLeft: 10,
                      }}
                      onClick={() =>
                        setDisplayQrCode(
                          isDisplay
                            ? displayQrCode.filter(e => e !== qr_code)
                            : [...displayQrCode, qr_code]
                        )
                      }
                    />
                  </div>
                  {isDisplay && (
                    <QRImage
                      style={{ marginTop: 5 }}
                      src={qr_code}
                      qrName={qr_name}
                    />
                  )}
                </div>
              );
            },
          }
        : {
            title: (
              <ColumnTitle
                title={col.title}
                formik={formik}
                sortField={col.sortField}
                onClick={sort => dispatch(qrActions.saveSearchParams({ sort }))}
              />
            ),
            width: col.width,
            dataIndex: col.sortField,
          };
    }),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current => {
        return current.deleted_flag === '0' ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchQrDetail(current))}
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        );
      },
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current => {
        return current.deleted_flag === '0' ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(qrActions.openDeleteModal(current))}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchQrReference(current))}
          />
        );
      },
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="新規登録・QR一覧"
        icon={<Icons.IconStamp className="qr-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={724}>
          <TextBox formik={formik} label="QRコード" fieldName="qr_code" />
          <TextBox formik={formik} label="QR名" fieldName="qr_name" />
          <SelectBox
            formik={formik}
            label="ブランド"
            fieldName="brand_code"
            mode="multiple"
            options={brandList}
          />
          <SelectBox
            formik={formik}
            label="店舗"
            fieldName="shop_code"
            mode="multiple"
            options={shopList}
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={formik.values.deleted_flg === '1'}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flg',
                formik.values.deleted_flg === '1' ? '0' : '1'
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(qrActions.openEditModal(null))}
      />
      <Table
        rowKey="name"
        columns={columns}
        dataSource={qrList}
        onChange={pagination => {
          const offset =
            pagination.current === 1
              ? 0
              : pagination.pageSize * (pagination.current - 1);
          dispatch(qrActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
        pagination={{
          total,
          pageSize: 10,
          current: (searchParams?.offset || 0) / 10 + 1,
          position: ['bottomCenter'],
          showQuickJumper: false,
        }}
        sticky={{ offsetHeader: 60 }}
      />
      <QRModal />
      <QRReferenceModal />
      <DeleteModal
        modalVisible={deleteModalVisible}
        onCancel={() => dispatch(qrActions.closeDeleteModal())}
        item={'QR'}
        itemValue={deleteQr?.qr_code}
        onOk={() => dispatch(qrDelete(deleteQr?.qr_code))}
      />
    </div>
  );
};

export default QR;
