import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lineAccountColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import Icons from '../../constants/Icons';
import {
  fetchLineAccountDelete,
  fetchLineAccountDetail,
  fetchLineAccountList,
  fetchLineAccountReference,
  lineAccountActions,
} from '../../slices/lineAccountSlice';
import TableTitle from '../../components/tableTitle';
import DeleteModal from '../../components/modal/deleteModal';
import TcTable from '../../components/tcTable';
import TcCheckbox from '../../components/tcCheckbox';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import { handlerFormikFieldChange, numberFormat } from '../../utils/fnUtil';
import './style.scss';
import { useUpdateEffect } from 'react-use';
import InputContainer from '../../components/inputContainer';
import LineAccountModal from '../../components/lineAccountModal';
import LineAccountReferenceModal from '../../components/lineAccountModal/reference';
import TextBox from '../../components/util/textBox';

const LineAccount = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { total, total_count, deleteLineAccount, searchParams, lineAccountList } = useSelector(
    state => state.lineAccount
  );

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values =>
      dispatch(lineAccountActions.saveSearchParams(values)),
  });

  useUpdateEffect(() => {
    dispatch(fetchLineAccountList(searchParams));
  }, [searchParams]);

  useEffect(() => {
    dispatch(lineAccountActions.saveSearchParams(null));
    return () => dispatch(lineAccountActions.clear());
  }, [dispatch]);

  const columns = [
    ...lineAccountColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(lineAccountActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        !current.delete_flag ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchLineAccountDetail(current))}
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        !current.delete_flag ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => {
              dispatch(lineAccountActions.openDeleteModal(current));
            }}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchLineAccountReference(current))}
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="LINE公式アカウント管理"
        subTitle={[`全LINE公式アカウント数 ${numberFormat(total_count)}`]}
        icon={<Icons.IconLine />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="公式アカウントCD" fieldName="account_code" />
          <TextBox formik={formik} label="公式アカウント名" fieldName="account_name" />
          <TextBox formik={formik} label="プロバイダID" fieldName="provider_id" />
          <TextBox formik={formik} label="チャネルID" fieldName="channel_id" />
          <TextBox formik={formik} label="チャネルアクセストークン" fieldName="channel_access_token" />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['delete_flag']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'delete_flag',
                !!formik.values['delete_flag'] ? 0 : 1
              )
            }
          />
        </div>
      </div>

      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(lineAccountActions.openEditModal(null))}
      />
      <TcTable
        columns={columns}
        dataSource={lineAccountList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(lineAccountActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <LineAccountModal />
      <LineAccountReferenceModal />
      <DeleteModal
        modalVisible={!!deleteLineAccount}
        onCancel={() => dispatch(lineAccountActions.closeDeleteModal())}
        item={'LINE公式アカウント'}
        itemValue={deleteLineAccount?.account_code}
        onOk={() =>
          dispatch(
            fetchLineAccountDelete({
              account_code: deleteLineAccount?.account_code,
            })
          )
        }
      />
    </div>
  );
};

export default LineAccount;
